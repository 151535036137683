import ClearIcon from "@mui/icons-material/Clear";
import { Button, Grid } from "@mui/material";
import PageTitle from "../PageTitle";

interface PageHeaderProps {
  title: string;
  onClick?: () => void;
}

function MOPageHeader({ title, onClick }: PageHeaderProps) {
  return (
    <Grid container spacing={2} width="100%" marginBottom="20px" alignItems="center">
      <Grid item md={6} sm={12}>
        <PageTitle title={title} />
      </Grid>
      <Grid item md={6} sm={12} justifyContent="right" textAlign="center">
        <Button color="error" variant="contained" startIcon={<ClearIcon />} onClick={onClick}>
          Clear Results
        </Button>
      </Grid>
    </Grid>
  );
}

export default MOPageHeader;
