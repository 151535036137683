import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/material";

import { useEffect, useState } from "react";
import { useFetchTeamInfoQuery } from "../../store/api/infoApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import SuccessSnackbar from "../../components/Snackbar";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: "auto",
  maxWidth: 600,
  position: "relative",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(2),
  color: "blue",
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const CompanyInformation = () => {
  const { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();

  const [showUpdateFinancialInfoDialog, setShowUpdateFinancialInfoDialog] = useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);

  const handleUpdateFinanciald = () => {
    setShowUpdateFinancialInfoDialog(true);
  };

  const handleCloseUpdateFinancialInfoDialog = () => {
    setShowUpdateFinancialInfoDialog(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  useEffect(() => {
    refetchTeamDetails();
  }, []);

  if (isTeamInfoFetchLoading) return <LoadingSpinner />;
  else if (isTeamInfoFetchSuccess && teamDetails)
    return (
      <StyledPaper>
        <TitleTypography variant="h5">Company Info</TitleTypography>
        {/* <EditButton>
          <EditIcon onClick={handleUpdateFinanciald} />
        </EditButton> */}
        <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} width="30vw" severity="success" message="Financial info updated" />

        <Box marginBottom={2} marginTop={2}>
          <Typography>Company Name: {teamDetails?.data?.business_name}</Typography>
        </Box>
        <Box marginBottom={2} marginTop={2}>
          <Typography>Company Email: {teamDetails?.data?.business_email}</Typography>
        </Box>
        <Box marginBottom={2} marginTop={2}>
          <Typography>Company Phone: {teamDetails?.data?.business_phone}</Typography>
        </Box>
      </StyledPaper>
    );
  else return <div>Error fetching user information</div>;
};

export default CompanyInformation;
