import React, { useEffect, useMemo, useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { supplierValues } from "../../types/formValueType";
import { isValidTwoDecimalPrecision } from "../../utils/numberPrecision";
import DialogInput from "../../components/Dialog";
import { CreateEntity } from "../../types/customTypes";
import { useAddNewSupplierMutation } from "../../store/api/supplierApi";
import countryList from "react-select-country-list";
import codes from "country-calling-code";
import { CreateSupplierSchema } from "../../validation/supplier.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from '@mui/lab/LoadingButton';
import useFormPersist from "react-hook-form-persist";
import { clearStorage } from "../../utils/formStorage";
import ClearFormButton from "../../components/ClearFormButton";
import { FinanceEventType } from "../../global/enums/entityEnum";

function CreateSupplier({
  openDialog,
  handleCloseDialog,
  onSuccess,
}: CreateEntity) {
  const {
    register, 
    handleSubmit,
    formState,
    clearErrors,
    watch,
    setValue,
    reset,
  } = useForm<supplierValues>({
    defaultValues: {
      supplierName: "",
      supplierEmail: "",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "United Kingdom",
        region: "",
      },
      phone: {
        countryCode: "44",
        phoneNumber: "",
      },
      openingBalance: 0,
      openingBalanceDate: new Date(),
      openingBalanceType: FinanceEventType.Credit,
    },
    resolver: zodResolver(CreateSupplierSchema),
  });

  const supplierFormValues = "supplierValues"

  useFormPersist(supplierFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { errors } = formState;
  const [errorMessage, setErrorMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [addNewSupplier, mutationResult] = useAddNewSupplierMutation();
  const { isLoading, isSuccess, isError, error, data: newSupplier } = mutationResult;
  const countries = useMemo(() => countryList().getLabels(), []);

  const handleOpeningBalanceInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = parseFloat(e.target.value);
    if (isValidTwoDecimalPrecision(value.toString())) {
      setValue("openingBalance", value);
      return;
    }
    if (!value) {
      value = 0;
      setValue("openingBalance", 0);
    }
  };

  const onSubmit = async (data: supplierValues) => {
    try {
      addNewSupplier(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountryCodeInput = (event: SelectChangeEvent) => {
    setValue("phone.countryCode", event.target.value);
    setCountryCode(event.target.value);
    clearErrors("phone.countryCode");
  };

  useEffect(() => {
    if (isSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      onSuccess(newSupplier);
      reset();
      clearStorage(supplierFormValues);
    } else if (isError) {
      const errMsg =
        "data" in error ? (error.data as string) : "Unable to create supplier";
      setErrorMessage(errMsg);
    }
  }, [isSuccess, isError, error]);

  const handleClearFormButtonClick = () => {
    clearStorage(supplierFormValues)
    reset()
  }

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="New Supplier"
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <Typography gutterBottom sx={{ fontStyle: "italic" }}>
            * indicates required fields
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "16px" }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Supplier Name *"
                  {...register("supplierName", {
                    required: "Supplier Name is required",
                  })}
                  error={!!errors.supplierName}
                  helperText={errors.supplierName?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Supplier Email *"
                  {...register("supplierEmail", {
                    required: "Please enter valid email",
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.supplierEmail}
                  helperText={errors.supplierEmail?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address Line 1 *"
                  {...register("address.addressLine1", {
                    required: "Address field is required",
                  })}
                  error={!!errors.address?.addressLine1}
                  helperText={errors.address?.addressLine1?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address Line 2"
                  {...register("address.addressLine2")}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6}>
                <FormControl sx={{ mt: 1, width: "100%" }}>
                  <Select
                    defaultValue="44"
                    {...register("phone.countryCode", {
                      required: "Please select country code",
                    })}
                    value={watch("phone.countryCode")}
                    onChange={handleCountryCodeInput}
                    error={!!errors.phone?.countryCode}
                  >
                    {codes.map(({ country, countryCodes }) => (
                      <MenuItem key={country} value={countryCodes[0]}>
                        {country + ": +" + countryCodes[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={6}>
                <FormControl sx={{ mt: 1, width: "100%" }}>
                  <TextField
                    {...register("phone.phoneNumber", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^\d+$/,
                        message:
                          "Phone number must contain only numeric characters",
                      },
                      maxLength: {
                        value: 15 - countryCode.length,
                        message: "Phone number cannot be longer than 15 digits",
                      },
                    })}
                    label="Phone *"
                    type="tel"
                    error={
                      !!errors.phone?.phoneNumber || !!errors.phone?.countryCode
                    }
                    helperText={
                      errors.phone?.phoneNumber?.message ||
                      errors.phone?.countryCode?.message
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="City *"
                  {...register("address.city", {
                    required: "This field is required",
                  })}
                  error={!!errors.address?.city}
                  helperText={errors.address?.city?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Region"
                  {...register("address.region")}
                  error={!!errors.address?.region}
                  helperText={errors.address?.region?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    defaultValue="United Kingdom"
                    value={watch("address.country")}
                    {...register("address.country")}
                    onChange={(e) =>
                      setValue("address.country", e.target.value)
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    fullWidth
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Opening Balance"
                  value={watch("openingBalance")}
                  type="number"
                  {...register("openingBalance", {  
                    valueAsNumber: true,
                  })}
                  onChange={handleOpeningBalanceInput}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth> 
                  <InputLabel>Opening Balance Type</InputLabel>
                  <Select
                    label="Opening balance type"
                    defaultValue="Credit"
                    value={watch("openingBalanceType")}
                    {...register("openingBalanceType")}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    fullWidth
                  >
                    {Object.values(FinanceEventType).map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} marginBottom="20px">
                <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading} >
                  Submit
                </LoadingButton>
                <ClearFormButton onClick={handleClearFormButtonClick} />
              </Grid>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Confirm"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default CreateSupplier;
