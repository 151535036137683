import { apiSlice } from "./baseApi";
import { IPagination, IProduct, IQueryResult, IQuerySearchParams } from "../../types/customTypes";
import { purchaseInvoiceApiBase } from "../env";

export const productSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewProduct: builder.mutation({
      query: (newProduct) => ({
        url: purchaseInvoiceApiBase + "/products",
        method: "POST",
        body: newProduct,
      }),
      invalidatesTags: ["Product"],
    }),

    getProducts: builder.query<IQueryResult<IProduct[]>, IQuerySearchParams>({
      query: ({ pageInfo, searchColumns, searchKeyword }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
        };
        return {
          url: purchaseInvoiceApiBase + `/products?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Product"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getProductById: builder.query<IProduct, string>({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/products/${id}`,
        method: "GET",
      }),
      providesTags: ["Product"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getProductAccountStatement: builder.query<any, { productId: string; startDate: string; endDate: string; openingDate: string, pageInfo: IPagination}>({
      query: ({ productId, startDate, endDate, openingDate, pageInfo }) => {
        const queryParams = {
          startDate: startDate,
          endDate: endDate, 
          openingDate,
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
        };
        
        return {
          url: purchaseInvoiceApiBase + `/products/accountStatement/${productId}?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateProduct: builder.mutation({
      query: (updatedFields) => ({
        url: purchaseInvoiceApiBase + `/products/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
      invalidatesTags: ["Product"],
    }),

    deleteProduct: builder.mutation({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Product"],
    }),
  }),
});

export const { useAddNewProductMutation, useGetProductsQuery, useGetProductByIdQuery, useGetProductAccountStatementQuery, useLazyGetProductAccountStatementQuery, useUpdateProductMutation, useDeleteProductMutation } = productSlice;