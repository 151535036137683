import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import CenteredContainer from "./CenteredContainer";

function UnauthorizedAlert() {
  return (
    <CenteredContainer>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Unauthorized Access</Typography>
          <Typography variant="h6">You cannot access this page</Typography>
        </Box>
      </Container>
    </CenteredContainer>
  );
}

export default UnauthorizedAlert;
