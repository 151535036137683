import { apiSlice } from "./baseApi";
import { IInvoice, IQueryResult, IQuerySearchParams, IPagination } from "../../types/customTypes";
import { purchaseInvoiceApiBase } from "../env";
import { InvoiceType } from "../../global/enums/entityEnum";

export const invoiceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: purchaseInvoiceApiBase + "/invoices",
        method: "POST",
        body: newInvoice,
      }),

      invalidatesTags: ["Invoice"],
    }),

    getInvoices: builder.query<IQueryResult<IInvoice[]>, { pageInfo: IPagination; searchColumns: string[]; searchKeyword: string; invoiceType: string }>({
      query: ({ pageInfo, searchColumns, searchKeyword, invoiceType }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
          invoiceType: invoiceType,
        };
        return {
          url: purchaseInvoiceApiBase + `/invoices?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Invoice"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getInvoiceById: builder.query<IInvoice, string>({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/invoices/${id}`,
        method: "GET",
      }),
      providesTags: ["Invoice"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateInvoice: builder.mutation({
      query: (updatedFields) => ({
        url: purchaseInvoiceApiBase + `/invoices/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
      invalidatesTags: ["Invoice"],
    }),
    deleteInvoice: builder.mutation({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/invoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Invoice"],
    }),
  }),
});

export const { useAddNewInvoiceMutation, useGetInvoicesQuery, useGetInvoiceByIdQuery, useUpdateInvoiceMutation, useDeleteInvoiceMutation } = invoiceSlice;
