import React, { ElementType } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { styled } from "styled-components";

const StyledCard = styled(Card)`
  max-width: 400px;
  height: 200px;
`;

const StyledContent = styled(CardContent)`
  display: flex;
  width: 450px;
  gap: 25px;
  align-items: center;
  font-size: 1em;
  margin: 50px 30px;
`;

interface DashboardSummaryProps {
  Icon: ElementType;
  title: string;
  amount: string;
  color: string
}

const DashboardSummary = ({ Icon, title, amount, color }: DashboardSummaryProps) => {
  return (
    <StyledCard>
      <StyledContent>
        {Icon && (
          <Icon fontSize="large" sx={{ width: "80px", height: "80px", color: "#233142"}}/>
        )}
        <Box>
          <Typography fontFamily="cursive " fontSize="1.3rem">
            {title}
          </Typography>
          <Typography fontFamily="cursive" fontSize="2rem" fontWeight="bold">
            {amount}
          </Typography>
        </Box>
      </StyledContent>
    </StyledCard>
  );
};

export default DashboardSummary;
