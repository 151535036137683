import DashboardIcon from "@mui/icons-material/Dashboard";
import PaymentIcon from '@mui/icons-material/Payment';

export type MenuItemInterface = {
  text: string;
  icon: JSX.Element;
  link: string;
};

export const adminListItems = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    link: "/",
  },
  {
    text: "Finance Accounts",
    icon: <PaymentIcon />,
    link: "/manage-finance-account",
  },
];
