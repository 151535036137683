import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import SearchFilter, { FinancingRequestFilterProps } from "../../../components/finance/FinancingRequestFilter";
import FinacingRequestTable from "../../../components/finance/MTable";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import styled from "styled-components";
import { IconButton } from "@mui/material";
import { findFactoringRequestedInvoicesQueryString, useFindFactoringRequestedInvoicesQuery } from "../../../store/api/mInvoiceApi";
import { IPagination } from "../../../types/customTypes";
import { ICustomInvoice, IGetFactoringRequestedInvoices, IGetFinancingRequestsTable, IMinvoice } from "../../../types/financeTypes";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import { Loading } from "../../../components/finance/LoadingComponent";
import ViewInvoicesModal from "../../../components/finance/ViewInvoicesModal";
import MOPageHeader from "../../../components/finance/MOPageHeader";
import DescriptionIcon from "@mui/icons-material/Description";

export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`;

const FinancingRequestManagement: React.FC = () => {
  const defaultQueryParams = {
    min_employee: "",
    max_employee: "",
    min_turnover: "",
    max_turnover: "",
    bid_lte: "",
    bid_gte: "",
    min_invoice_amount: "",
    max_invoice_amount: "",
    sectorModelIn: [],
    sectorModelNotIn: [],
    sectorTypeIn: [],
    sectorTypeNotIn: [],
  };
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);

  const [rows, setRows] = useState<IGetFinancingRequestsTable[]>([]);

  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<findFactoringRequestedInvoicesQueryString | null>(defaultQueryParams);

  const handleSearch = (filters: FinancingRequestFilterProps) => {
    setShowTable(true);
    const queryParams: findFactoringRequestedInvoicesQueryString = {
      min_employee: filters.minNumberOfEmployee,
      max_employee: filters.maxNumberOfEmployee,
      min_turnover: filters.minTurnoverAmount,
      max_turnover: filters.maxTurnoverAmount,
      bid_gte: filters.startDateFrom ? new Date(filters.startDateFrom).toISOString() : null,
      bid_lte: filters.FinishDateUntil ? new Date(filters.FinishDateUntil).toISOString() : null,
      min_invoice_amount: filters.minInvoiceAmount,
      max_invoice_amount: filters.maxInvoiceAmount,
      sectorModelIn: filters.includedSectorModels,
      sectorModelNotIn: filters.excludedSectorModels,
      sectorTypeIn: filters.includedSectorTypes,
      sectorTypeNotIn: filters.excludedSectorTypes,
    };

    setQueryParams(queryParams);
  };
  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const clearTableResult = () => {
    setShowTable(false);
    setRows([]);
  };

  const { data, isSuccess, isFetching } = useFindFactoringRequestedInvoicesQuery(queryParams ? queryParams : defaultQueryParams);

  useEffect(() => {
    if (isSuccess && data) {
      const financingRequests = data.financing_invoices;
      const tableStructure: IGetFinancingRequestsTable[] = [];

      // Iterate over each company in the financing_invoices object
      Object.keys(financingRequests).forEach((companyName: string) => {
        const invoicesArray: IGetFactoringRequestedInvoices[] = (financingRequests as Record<string, any>)[companyName];
        const invoiceArray: ICustomInvoice[] = invoicesArray.map((item) => {
          return { ...item.invoice, financeRequestId: item.id, bid_opens_at: item.bid_opens_at, bid_closes_at: item.bid_closes_at };
        });
        console.log(invoiceArray);
        // Calculate total number of invoices and total amount to be paid for the current company
        let totalInvoices = 0;
        let totalAmountToBePaid = 0;
        invoicesArray.forEach((invoices) => {
          totalInvoices++;
          totalAmountToBePaid += parseFloat(invoices.invoice.amount_to_be_paid);
        });

        // Create a table row for the current company
        const tableRow: IGetFinancingRequestsTable = {
          id: tableStructure.length + 1,
          supplier: companyName,
          totalInvoices: totalInvoices,
          totalAmountToBePaid: totalAmountToBePaid,
          invoices: invoiceArray,
        };

        // Add the table row to the table structure
        tableStructure.push(tableRow);
      });

      // Update the state with the new table structure
      setRows(tableStructure);
    }
  }, [data, isSuccess, queryParams]);

  const columns: GridColDef[] = [
    {
      field: "supplier",
      type: "string",
      headerName: "Supplier",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalInvoices",
      type: "number",
      headerName: "Total Number of Invoices",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalAmountToBePaid",
      type: "number",
      headerName: "Total Amount",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridCellParams) => (
        <>
          <ViewInvoicesModal data={params.row.invoices} supplierName={params.row.supplier} icon={<DescriptionIcon />} />
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container marginBottom={3}>
        <Button
          onClick={toggleFilterVisibility}
          variant="outlined"
          sx={{
            borderColor: "red",
            color: "red",
            "&:hover": {
              color: "red",
              borderColor: "red",
              backgroundColor: "transparent",
            },
          }}
        >
          {isFilterVisible ? "HIDE FILTERS" : "SHOW FILTERS"}
        </Button>
      </Grid>
      {/* Render filter section based on isFilterVisible state */}
      {isFilterVisible && <SearchFilter handleSubmit={handleSearch} />}
      {/* Render table if data is fetched, otherwise show loading indicator */}
      {showTable && (
        <>
          {isFetching ? (
            <Loading />
          ) : (
            <>
              <MOPageHeader title="Financing Requests" onClick={clearTableResult} />
              <FinacingRequestTable rows={rows} columns={columns} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default FinancingRequestManagement;
