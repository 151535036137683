import { Outlet } from "react-router-dom";
import HomePage from "../pages/HomePage";
import { useFetchUserInfoQuery } from "../store/api/infoApi";
import Layout from "./Layout";
import LoadingSpinner from "./LoadingSpinner";
import UnauthorizedAlert from "./UnauthorizedAlert";

function FinanceRoutesAuth() {
  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails } = useFetchUserInfoQuery();

  if (isUserInfoFetchLoading) return <LoadingSpinner />;
  else {
    if (isUserInfoFetchSuccess && userDetails) {
      if (userDetails.data.team_type === "Finance") {
        return (
            <Outlet />
        );
      } else {
        return <UnauthorizedAlert />;
      }
    }
    else{
        return <LoadingSpinner/>
    }
  }
}

export default FinanceRoutesAuth;
