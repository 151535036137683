import styled from "@emotion/styled";
import { Box } from "@mui/material";

const CenteredContainer = styled(Box)`
  width: 90vw;
  height: 70vh;
  display: flex;
  margin: auto;
  margin-top: 200px;
  justify-content: center;
`;

export default CenteredContainer