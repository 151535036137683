import { salesInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const infoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFinanceAccount: builder.mutation<any, any>({
      query: (financeDetails) => ({
        url: salesInvoiceApiBase + "/v1/admin/finance",
        method: "POST",
        body: financeDetails,
        invalidateTags: ["FinanceMembers"],
      }),
    }),
    getFinanceMembers: builder.query({
      query: ({ pageInfo }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
        };
        return {
          url: salesInvoiceApiBase + `/v1/admin/finance?page=${queryParams.page}&perPage=${queryParams.limit}`,

          method: "GET",
        };
      },
      providesTags: ["FinanceMembers"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
  }),
});

export const { useCreateFinanceAccountMutation, useGetFinanceMembersQuery } = infoSlice;
