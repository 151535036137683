import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import CenteredContainer from "../components/CenteredContainer";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

function EmailVerification() {
  return (
    <CenteredContainer>
      <Box textAlign="center">
        <Typography variant="h4" fontWeight="bold">
          {" "}
          Verify your Email{" "}
        </Typography>
        <Typography variant="h6" mt={2}>
          Please check your email for a link to verify your email address.
        </Typography>
        <Typography variant="h6" mt={2}>
          Once verified, you will be able to continue
        </Typography>
        <Box>
          <MailOutlineIcon sx={{ fontSize: "200px" }} color="disabled" />
        </Box>
        <Typography variant="h6" mt={2}>
          Didn't receive any email?{" "}
          <Link to="/send-verification-email">
            <Typography variant="h6" color="primary" component="span" fontWeight="bold">
              Resend
            </Typography>
          </Link>
        </Typography>
        <Typography variant="h6" mt={2}>
          <Link to="/login">
            <Typography variant="h6" color="primary" component="span" fontWeight="bold">
              Go to login
            </Typography>
          </Link>
        </Typography>
      </Box>
    </CenteredContainer>
  );
}

export default EmailVerification;
