import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Container } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../utils/formStorage";
import { createBusinessFormValues } from "./formName";

function BackToHomeLink() {
  const navigate = useNavigate();
  const handleNavigation = () => {
    clearStorage(createBusinessFormValues);
    Promise.resolve().then(() => {
      navigate("/");
    });
  };

  return (
    <Typography variant="body2">
      Go back to{" "}
      <Link onClick={handleNavigation} color="primary" underline="always" style={{ cursor: "pointer" }}>
        HomePage
      </Link>
    </Typography>
  );
}

export default BackToHomeLink;
