export const StockCategoryType = [
  "Goods",
  "Services",
  "Assets"
]

export const SaleType = [
  "Buy",
  "Sell",
  "Buy_Sell"
]

