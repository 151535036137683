import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../../utils/getCookie";

interface IUserState {
  access_token: string;
  refresh_token: string;
}

const initialState: IUserState = {
  access_token: "",
  refresh_token: "",
};

export const authenticationSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    logOut: () => initialState,
    setCredentials: (state, action) => {      
      const { access_token, refresh_token } = action.payload;
      document.cookie = `accessToken=${access_token}; path=/; max-age=3600`;
      document.cookie = `refreshToken=${refresh_token}; path=/; max-age=7200`;
    },
    setUserInfo: (state, action) => {      
      const {id, email, name, surname, team_id } = action.payload;
      const userInfo = {id, email, name, surname, team_id}
      document.cookie = `userInfo=${userInfo}; path=/; max-age=3600`;
    },
  },
});

export default authenticationSlice.reducer;
export const { logOut, setCredentials, setUserInfo } = authenticationSlice.actions;
export const selectAccessToken = () => getCookie("accessToken");
export const selectRefreshToken = () => getCookie("refreshToken");
