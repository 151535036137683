import { Box, Button, Container, InputAdornment, InputLabel, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useRef, useState } from "react";

interface FilePickerProps {
  handleFileUpload: (docType: string, file: File) => void;
  label: string
  isReEdit: boolean
  document: string,
  showDocument: boolean
}

function FilePicker({ handleFileUpload, label, isReEdit, document, showDocument }: FilePickerProps) {
  
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleMuiFileInput = (newFile: File | null) => {
    setFile(newFile);
    if (newFile) {
      handleFileUpload(document, newFile);
    }
  };

  const handleHTMLFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];
    if (file) {
      setFile(file);
      handleFileUpload(document, file);
    }
  };

  return (
    <Box>
        <InputLabel><Typography variant="body1" mt={2}>{label}</Typography></InputLabel>
        <MuiFileInput
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <UploadFileIcon />
                </InputAdornment>
                <Button variant="outlined" color="primary" size="small" onClick={handleFileInputClick}>
                  {isReEdit && showDocument? "Upload new file": "Upload File"}
                </Button>
                <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleHTMLFileInput} />
              </>
            ),
          }}
          value={file}
          onChange={handleMuiFileInput}
          // required={required}
        />
    </Box>
  );
}

export default FilePicker;
