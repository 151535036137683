import dayjs from "dayjs";
import { InvoiceType } from "../global/enums/entityEnum";
import { CreateBusinessValues } from "./formValueType";

export const createBusinessDefaultValues: CreateBusinessValues = {
  business_name: "",
  business_email: "",
  business_phone: "",
  address_line_1: "",
  address_line_2: "",
  country: "United Kingdom",
  city: "Birmingham",
  post_code: "",
  account_number: "",
  sort_code: "",
  registrationOption: "vatNumber",
  business_model: "Financial Services & Insurance",
  business_type: "Personal Company",
  vat_percentage: 0,
  company_reg_number: "",
  vat_number: "",
  tax_id: "",
  isFinish: false,
  isReEdit: false,
  showPreviousBankStatement: false,
  showSoleTraderDocsUploadStatus: false,
  showCompanyDocsUploadStatus: false,
  isRegistrationOptionChanged: false,
  registrationOptionBeforeReEdit: "",
};

export const supplierDefaultValue = {
  supplierName: "",
  supplierEmail: "",
  address: {
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    region: "",
  },
  phone: {
    countryCode: "",
    phoneNumber: "",
  },
  openingBalance: 0,
  openingBalanceDate: new Date(),
};
export const customerDefaultValue = {
  name: "",

  address_line_1: "",
  address_line_2: "",
  region_province: "",
  post_code: "",
  city: "",
  country: "",

  email: "",

  phone: "",
  phoneCountryCode: "",
};
export const invoiceLineDefaultValues = {
  productId: "",
  quantity: 1,
  unitPrice: 0,
  lineGrossAmount: 0,
  lineNetAmount: 0,
  lineVatPercentage: 0,
  lineVatAmount: 0,
  lineDiscountPercentage: 0,
  lineDiscountAmount: 0,
};

export const SinvoiceLineDefaultValues = {
  product: "",
  unit_price: 0,
  subtotal_amount: 0,
  line_total_amount: 0,
  line_vat_amount: 0,
  quantity: 1,
  product_id: "",
  line_vat_percentage: 0,
};

export const invoiceDefaultValues = {
  invoiceNumber: "",
  supplierId: "",
  invoiceType: InvoiceType.Purchase,
  dueDate: dayjs(),
  issueDate: dayjs(),
  description: "",
  totalGrossAmount: 0,
  totalNetAmount: 0,
  totalVatAmount: 0,
  invoiceLines: [invoiceLineDefaultValues],
};

export const productAccountStatementDefaultValues = {
  productId: "",
  startDate: new Date(),
  endDate: new Date(),
};

export const purchaseReturnDefaultValues = {
  ...invoiceDefaultValues,
  invoiceType: InvoiceType.PurchaseReturn,
};

export const sInvoiceDefaultValues = {
  usePayedaInvoiceNumber: true,
  due_date: dayjs().toDate(),
  issued_date: dayjs().toDate(),
  amount_to_be_paid: "",
  subtotal_amount: "",
  total_vat_amount: "",
  total_discount_amount: "",
  customer: "",
  invoice_lines: [SinvoiceLineDefaultValues],
};

export const fastSalesInvoiceDefaultValues = {
  due_date: dayjs().toDate(),
  issued_date: dayjs().toDate(),
  productDescription: "",
  customer_id: "",
  amount_to_be_paid: "",
};

export const supplierAccountStatementDefaultValues = {
  startDate: dayjs(),
  endDate: dayjs(),
  supplierId: "",
};