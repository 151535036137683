import React, { ChangeEvent, useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useLazyVerifyVatQuery } from "../../store/api/createBusinessApi";
import { useNavigate } from "react-router-dom";
import { CreateBusinessValues } from "../../types/formValueType";
import { createBusinessFormValues } from "./formName";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";

function RegisterWith() {
  const [vatNum, setVatNum] = useState("");
  const [taxId, setTaxId] = useState("");
  const [input, setInput] = useState("");
  const [
    verifyVat,
    {
      isSuccess: isVatVerificationSuccessful,
      isError: isVatVerificationFailed,
      error: vatVerificationError,
      isLoading: vatVerificatonIsLoading,
      data: vatInfo,
    },
  ] = useLazyVerifyVatQuery();
  const [errorMessage, setErrorMessage] = useState("");
  const [soleTraderRegOption, setSoleTraderRegOption] = useState("vatNumber");
  const navigate = useNavigate();

  const [defaultInvoiceValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const {
    setValue,
    getValues,
    formState: { errors },
    watch,
  } = useForm<CreateBusinessValues>({
    defaultValues: defaultInvoiceValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  useEffect(() => {
    setValue("registrationOption", "vatNumber");
  }, []);

  const { isReEdit } = getValues();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSoleTraderRegOption(value);
    if (value === "vatNumber") {
      setVatNum(input);
      setValue("vat_number", input);
      setValue("tax_id", "");
    } else if (value === "taxId") {
      setValue("tax_id", input);
      setValue("vat_number", "");
      setTaxId(input);
    }
    setValue("registrationOption", value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (soleTraderRegOption === "vatNumber") {
      verifyVat(vatNum);
    }
    if (soleTraderRegOption === "taxId") {
      navigate("/company-details-form");
    }
  };

  useEffect(() => {
    if (isVatVerificationSuccessful) {
      const { name, vatNumber } = vatInfo.target;
      const companyDetails = { businessData: { company_name: name } };

      Promise.resolve().then(() => {
        navigate("/company-details-form", { state: companyDetails });
      });
    } else if (isVatVerificationFailed) {
      if (vatVerificationError) {
        let errMsg = "Vat verfication failed, Try again";
        console.log(vatVerificationError);
        const errData: any = "data" in vatVerificationError && vatVerificationError.data;
        errMsg = errData.message && errData.message;
        setErrorMessage(errMsg);
      }
    }
  }, [isVatVerificationSuccessful, isVatVerificationFailed, vatVerificationError]);

  const handleTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    setInput(input);
    if (soleTraderRegOption === "vatNumber") {
      setVatNum(input);
      setValue("vat_number", input);
      setValue("tax_id", "");
    } else if (soleTraderRegOption === "taxId") {
      setValue("tax_id", input);
      setValue("vat_number", "");

      setTaxId(input);
    }
  };

  const handleBackButtonClick = () => {
    setValue("registrationOption", "");
    setValue("vat_number", "");
    setValue("tax_id", "");
    Promise.resolve().then(() => {
      navigate("/account-type-selection");
    });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Select An Option</Typography>
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ m: 3 }} variant="standard">
            <FormLabel id="demo-error-radios">Registered with...</FormLabel>
            <RadioGroup aria-labelledby="demo-error-radios" name="soleTraderRegOptions" defaultValue="vatNumber" defaultChecked onChange={handleRadioChange}>
              <FormControlLabel
                value="vatNumber"
                control={
                  <Radio
                    required
                    sx={{
                      color: "#01579b",
                      "&.Mui-checked": {
                        color: "#039be5",
                      },
                    }}
                  />
                }
                label="Vat Number"
              />
              <FormControlLabel
                value="taxId"
                control={
                  <Radio
                    required
                    sx={{
                      color: "#01579b",
                      "&.Mui-checked": {
                        color: "#039be5",
                      },
                    }}
                  />
                }
                label="Tax Id"
              />
            </RadioGroup>
            <TextField
              margin="normal"
              required
              fullWidth
              label={soleTraderRegOption === "vatNumber" ? "Vat Number" : "Tax Id"}
              color="warning"
              onChange={handleTextInput}
            />
            {isVatVerificationFailed && (
              <Alert severity="error">
                <Typography>{errorMessage}</Typography>
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
              }}
              disabled={vatVerificatonIsLoading || soleTraderRegOption === ""}
            >
              {soleTraderRegOption === "vatNumber" ? "Verify" : "Next"}
            </Button>
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
              }}
              onClick={handleBackButtonClick}
            >
              {isReEdit ? "Change Business Entity" : "Back"}
            </Button>
          </FormControl>
        </form>
        {isReEdit && <BackToHomeLink />}
      </Box>
    </Container>
  );
}

export default RegisterWith;
