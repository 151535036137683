import { apiSlice } from "./baseApi";
import { ISalesInvoice, IQuerySearchParamsInvoice, IQueryResultSalesInvoice } from "../../types/customTypes";
import { salesInvoiceApiBase } from "../env";

export const salesInvoiceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewSalesInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: salesInvoiceApiBase + "/v1/invoice/fast",
        method: "POST",
        body: newInvoice,
      }),

      invalidatesTags: ["Invoice"],
    }),

    addNewAdvanceSalesInvoice: builder.mutation({
      query: (newInvoice) => ({
        url: salesInvoiceApiBase + "/v1/invoice",
        method: "POST",
        body: newInvoice,
      }),

      invalidatesTags: ["Invoice"],
    }),

    getSalesInvoices: builder.query<IQueryResultSalesInvoice<ISalesInvoice[]>, IQuerySearchParamsInvoice>({
      query: ({ pageInfo, searchColumns, searchKeyword, status, selectedCustomer }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
          status: status,
          selectedCustomer,
        };
        return {
          url:
            salesInvoiceApiBase +
            `/v1/invoice/outgoing?page=${queryParams.page}&perPage=${queryParams.limit}&selectedStatus=${queryParams.status}&searchField=${searchKeyword}&customer_id=${selectedCustomer}`,

          method: "GET",
        };
      },
      providesTags: ["Invoice"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getSalesInvoiceById: builder.query<ISalesInvoice, string>({
      query: (id) => ({
        url: `/invoices/${id}`,
        method: "GET",
      }),
      providesTags: ["Invoice"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateSalesInvoice: builder.mutation({
      query: (updatedFields) => ({
        url: `/invoices/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
    }),
    deleteSalesInvoice: builder.mutation({
      query: (idsToDelete) => ({
        url: salesInvoiceApiBase + "/v1/invoice/cancel",
        method: "POST",
        body: idsToDelete,
      }),
      transformResponse: (result: any) => {
        return result;
      },
      invalidatesTags: ["Invoice"],
    }),

    sendPaymentLinkWithEmail: builder.mutation({
      query: (invoiceIds) => ({
        url: salesInvoiceApiBase + "/v1/invoice/send-payment-link",
        method: "POST",
        body: invoiceIds,
      }),
      transformResponse: (result: any) => {
        return result;
      },
      invalidatesTags: ["Invoice"],
    }),
  }),
});

export const {
  useAddNewSalesInvoiceMutation,
  useAddNewAdvanceSalesInvoiceMutation,
  useGetSalesInvoicesQuery,
  useGetSalesInvoiceByIdQuery,
  useUpdateSalesInvoiceMutation,
  useDeleteSalesInvoiceMutation,
  useSendPaymentLinkWithEmailMutation,
} = salesInvoiceSlice;
