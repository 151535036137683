import React, { useState, useEffect } from "react";
import { IPagination, IReceipt, IQueryReceipt } from "../../types/customTypes";
import { useGetReceiptsQuery, useGetFilteredReceiptsQuery } from "../../store/api/receiptApi";
import { defaultPaginationModel } from "../../utils/queryUtils";
import { getCookie } from "../../utils/getCookie";
import { Box, Grid, IconButton, CircularProgress } from "@mui/material";
import PageManagementGrid from "../../components/DataGrid";
import { Dialog, DialogContent } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Delete, GetApp } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import SearchDateFilter from "../../components/SearchDateFilter";
import { PaymentTypes } from "../../global/enums/PaymentTypes";
import FilterButton from "../../components/ActionButton";
import { useDeleteReceiptsMutation } from "../../store/api/receiptApi";
import DialogInput from "../../components/Dialog";
import { salesInvoiceApiBase } from "../../store/env";

export default function OutgoingReceipts() {
  const getPaymentTypeText = (paymentType: number) => {
    switch (paymentType) {
      case PaymentTypes.CHEQUE:
        return "Cheque";
      case PaymentTypes.CASH:
        return "Cash";
      case PaymentTypes.OTHER:
        return "Other";
      case PaymentTypes.OB:
        return "OB";
      default:
        return "None";
    }
  };

  const [receipts, setReceipts] = useState<IReceipt[]>([]);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [searchParams, setSearchParams] = useState({
    startDate: "",
    endDate: "",
    receiptId: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [deleteReceipt] = useDeleteReceiptsMutation();
  const { data, isSuccess, isError, isFetching } = useGetReceiptsQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    ...searchParams,
  });
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setReceipts(data.data);
      setTotalItems(data.total_items);
    }
    if (isError) {
      setError("An error occurred while fetching data.");
    }
  }, [data, isSuccess, isError]);

  const handleDownload = async (id: string) => {
    const confirmed = window.confirm("Are you sure you want to download the file?");
    if (confirmed) {
      const url = salesInvoiceApiBase + `/v1/receipt/generate/${id}`;
      window.open(url, "_blank");
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "receipt.pdf";
      downloadLink.click();
    }
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };
  const handleSearchInput = async (params: any) => {
    setSearchParams(params);
    setPaginationModel(defaultPaginationModel);
    setError(null);

    const url = salesInvoiceApiBase + `/v1/receipt/range/generate?start_date=${params.startDate}&end_date=${params.endDate}&receipt_type=${params.receiptId}`;

    const token = getCookie("accessToken");
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      if (response.status != 200) {
        setError(response.statusText);
      } else {
        const confirmed = window.confirm("Are you sure you want to download the file?");
        if (confirmed) {
          const data = await response.blob();

          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(data);
          downloadLink.setAttribute("download", "filteredReceipts.pdf");

          downloadLink.click();

          const newWindow = window.open(downloadLink.href, "_blank");
          if (newWindow) {
            newWindow.focus();
          } else {
            setError("Could not open on a new window ");
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteClick = (id: string) => () => {
    const receipt = receipts.find((receipt) => receipt.id === id);
    if (receipt && receipt.paymentType === PaymentTypes.OB) {
      setIsWarningDialogOpen(true);
    } else {
      setDeleteRowId(id);
      setIsDeleteDialogOpen(true);
    }
  };

  const handleDelete = async () => {
    if (deleteRowId) {
      const requestData = {
        receipt_ids: [deleteRowId],
      };
      setIsDeleteDialogOpen(false);
      setIsWaiting(true);
      try {
        await deleteReceipt(requestData);
        setIsWaiting(false);
        setReceipts((oldSalesInvoices) => oldSalesInvoices.filter((s) => s.id !== deleteRowId));
      } catch (err) {
        setIsWaiting(false);
      }
    }
  };

  const columns: GridColDef[] = [
    { field: "paid_at", headerName: "Payment Date", width: 250 },
    { field: "payer_name", headerName: "Payer Name", width: 250 },
    { field: "payee_name", headerName: "Payee Name", width: 250 },
    { field: "paymentType", headerName: "Payment Type", width: 250, valueGetter: (params) => getPaymentTypeText(params.row.paymentType) },
    {
      field: "invoice_details",
      headerName: "Invoice Number",
      width: 250,
      renderCell: (params: any) => <div>{params.row.invoice_details[0].invoice_number}</div>,
    },
    {
      field: "amountWithCurrency",
      headerName: "Amount",
      width: 250,
      renderCell: (params: any) => (
        <div>
          {params.row.amount} {params.row.currency}
        </div>
      ),
    },
  ];
  const actions = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params: any) => (
      <div>
        <IconButton color="success" title="Download" onClick={() => handleDownload(params.row.simp_id)}>
          <GetApp />
        </IconButton>
        <IconButton color="error" title="Delete Sales Invoice" onClick={handleDeleteClick(params.row.id)}>
          <Delete />
        </IconButton>
      </div>
    ),
  };

  return (
    <Box>
      <Grid item md={6} sm={12}>
        <Typography variant="h5" gutterBottom fontWeight="bold" margin="15px">
          Payments Received
        </Typography>
      </Grid>
      <br></br>
      <DialogInput
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title="Delete Receipts?"
        content="All receipts will be deleted and invoice statuses will be changed to Pending."
      />
      <Dialog open={isWarningDialogOpen} onClose={() => setIsWarningDialogOpen(false)} fullWidth maxWidth="md">
        <DialogContent>
          <Alert severity="warning" style={{ fontSize: "20px" }}>
            You can only delete manually created receipts!
          </Alert>
        </DialogContent>
      </Dialog>
      <Dialog open={isWaiting}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <CircularProgress />
          <p>Please wait while your operation is being processed..</p>
        </Box>
      </Dialog>
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchDateFilter onSearchInput={handleSearchInput} receipt_id="1" />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          {error && <Alert severity="error">{error}</Alert>}
          <Box width="100%">
            <PageManagementGrid
              rows={receipts}
              columnFields={columns}
              onDelete={handleDelete}
              paginationModel={paginationModel}
              isFetching={isFetching}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              customActions={actions}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
