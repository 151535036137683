import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Autocomplete, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

const ProSpan = styled("span")({
  display: "inline-block",
  height: "1em",
  width: "1em",
  verticalAlign: "middle",
  marginLeft: "0.3em",
  marginBottom: "0.08em",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundImage: "url(https://mui.com/static/x/pro.svg)",
});

export const SectorModelArray = [
  {
    value: "accommododationFood",
    label: "Accommodation and Food Services",
  },
  {
    value: "adminSupport",
    label: "Administrative and Support Services",
  },
  {
    value: "artsRecreation",
    label: "Arts and Recreation Services",
  },
  { value: "construction", label: "Construction/Builder" },
  { value: "education", label: "Education and Training" },
  { value: "farming", label: "Farming, forestry and fishing" },
  { value: "financial", label: "Financial Services & Insurance" },
  { value: "manufacturing", label: "Manufacturing" },
  {
    value: "medical",
    label: "Medical/Health Care/Community Services",
  },
  {
    value: "personal",
    label: "Personal, Beauty, Wellbeing and other services",
  },
  {
    value: "professional",
    label: "Professional Services(e.g. Legal, Accounting, Marketing, Consulting)",
  },
  {
    value: "propery",
    label: "Property Operators and Real Estate Services",
  },
  {
    value: "rental",
    label: "Rental&Hiring Services(non Real Estate)",
  },
  {
    value: "repair",
    label: "Repair and Maintenance(Automative & Property)",
  },
  {
    value: "retailTradeFood",
    label: "Retail Trade (Food & Beverage)",
  },
  {
    value: "retailTradeNonFood",
    label: "Retail Trade (Non-Food)",
  },
  {
    value: "technology",
    label: "Technology/Telecommunications services",
  },
  {
    value: "tradesWork",
    label: "Trades work(e.g. Plumber, Carpenter, Electrician)",
  },
  {
    value: "tradeAssociation",
    label: "Trade Association, Association",
  },
  {
    value: "transporLogistics",
    label: "Transport, Logistics, Postal, Warehousing",
  },
  {
    value: "wholeTrade",
    label: "Whole Trade",
  },
];

export const sectorTypesArray = [
  { value: "personalCompany", label: "Personal Company" },
  { value: "limitedCompany", label: "Limited Liability Company" },
  { value: "incorporatedCompany", label: "Incorporated Company" },
  { value: "nongovernmental", label: "Non-Governmental Company" },
];

function Label({ componentName, valueType, isProOnly }: { componentName: string; valueType: string; isProOnly?: boolean }) {
  const content = (
    <span>
      <strong>{componentName}</strong>
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a href="https://mui.com/x/introduction/licensing/#pro-plan" aria-label="Included on Pro package">
            <ProSpan />
          </a>
        </Tooltip>
        {content}
      </Stack>
    );
  }

  return content;
}

export interface FinancingRequestFilterProps {
  minInvoiceAmount: string | null;
  maxInvoiceAmount: string | null;
  minTurnoverAmount: string | null;
  maxTurnoverAmount: string | null;
  minNumberOfEmployee: string | null;
  maxNumberOfEmployee: string | null;
  includedSectorTypes: string[];
  excludedSectorTypes: string[];
  includedSectorModels: string[];
  excludedSectorModels: string[];
  startDateFrom: Date | null;
  FinishDateUntil: Date | null;
}

interface SearchFilterProps {
  handleSubmit: (data: any) => void;
}

const findValueByLabelSectorModels = (labelToFind: string) => {
  const item = SectorModelArray.find((item) => item.label === labelToFind);
  if (!item) return " ";
  return item.value;
};

const findValueByLabelSectorTypes = (labelToFind: string) => {
  const item = sectorTypesArray.find((item) => item.label === labelToFind);
  if (!item) return " ";

  return item.value;
};

const SearchFilter: React.FC<SearchFilterProps> = ({ handleSubmit }) => {
  const [filters, setFilters] = useState<FinancingRequestFilterProps>({
    minInvoiceAmount: "",
    maxInvoiceAmount: "",
    minTurnoverAmount: "",
    maxTurnoverAmount: "",
    minNumberOfEmployee: "",
    maxNumberOfEmployee: "",
    includedSectorTypes: [],
    excludedSectorTypes: [],
    includedSectorModels: [],
    excludedSectorModels: [],
    startDateFrom: null,
    FinishDateUntil: null,
  });

  const [startDate, setStartDate] = useState<String | null>(null);
  const [finishDate, setFinishDate] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleAutocompleteChange = (field: keyof FinancingRequestFilterProps, newValue: string[]) => {
    setFilters((prevFilters) => ({ ...prevFilters, [field]: newValue }));
  };

  useEffect(() => {
    if (startDate) {
      filters.startDateFrom = new Date(startDate.toString());
    }

    if (finishDate) filters.FinishDateUntil = new Date(finishDate);
    setFilters(filters);
  }, [startDate, finishDate]);

  const handleSearchClick = () => {
    const filterBody = {
      ...filters,
    };
    filterBody.includedSectorModels = filters.includedSectorModels.map((model) => findValueByLabelSectorModels(model));

    filterBody.excludedSectorModels = filters.excludedSectorModels.map((model) => findValueByLabelSectorModels(model));

    filterBody.includedSectorTypes = filters.includedSectorTypes.map((model) => findValueByLabelSectorTypes(model));
    filterBody.excludedSectorTypes = filters.excludedSectorTypes.map((model) => findValueByLabelSectorTypes(model));

    handleSubmit(filterBody);
  };

  const handleResetClick = () => {
    setFilters({
      minInvoiceAmount: "",
      maxInvoiceAmount: "",
      minTurnoverAmount: "",
      maxTurnoverAmount: "",
      minNumberOfEmployee: "",
      maxNumberOfEmployee: "",
      includedSectorTypes: [],
      excludedSectorTypes: [],
      includedSectorModels: [],
      excludedSectorModels: [],
      startDateFrom: null,
      FinishDateUntil: null,
    });
    setStartDate(""); // Reset DatePicker value
    setFinishDate(""); // Reset DatePicker value
  };

  return (
    <div>
      <Grid container spacing={2}>
        {/* First Row */}
        <Grid item xs={2}>
          <TextField name="minInvoiceAmount" label="Min Invoice Amount" value={filters.minInvoiceAmount} onChange={handleInputChange} type="number" fullWidth />
        </Grid>
        <Grid item xs={2}>
          <TextField name="maxInvoiceAmount" label="Max Invoice Amount" value={filters.maxInvoiceAmount} onChange={handleInputChange} type="number" fullWidth />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="minTurnoverAmount"
            label="Min Turnover Amount"
            value={filters.minTurnoverAmount}
            onChange={handleInputChange}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="maxTurnoverAmount"
            label="Max Turnover Amount"
            value={filters.maxTurnoverAmount}
            onChange={handleInputChange}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="minNumberOfEmployee"
            label="Min Number of Employees"
            value={filters.minNumberOfEmployee}
            onChange={handleInputChange}
            type="number"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            name="maxNumberOfEmployee"
            label="Max Number of Employees"
            value={filters.maxNumberOfEmployee}
            onChange={handleInputChange}
            type="number"
            fullWidth
          />
        </Grid>

        {/* Second Row */}
        <Grid item xs={3}>
          <Autocomplete
            multiple
            options={sectorTypesArray.map((item) => item.label)}
            filterSelectedOptions
            value={filters.includedSectorTypes}
            onChange={(event, newValue) => handleAutocompleteChange("includedSectorTypes", newValue)}
            renderInput={(params) => <TextField {...params} label="Included Sector Types" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple
            options={sectorTypesArray.map((item) => item.label)}
            filterSelectedOptions
            value={filters.excludedSectorTypes}
            onChange={(event, newValue) => handleAutocompleteChange("excludedSectorTypes", newValue)}
            renderInput={(params) => <TextField {...params} label="Excluded Sector Types" />}
            fullWidth
          />
        </Grid>

        {/* Third Row */}
        <Grid item xs={3}>
          <Autocomplete
            multiple
            options={SectorModelArray.map((item) => item.label)}
            filterSelectedOptions
            value={filters.includedSectorModels}
            onChange={(event, newValue) => handleAutocompleteChange("includedSectorModels", newValue)}
            renderInput={(params) => <TextField {...params} label="Included Sector Models" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            multiple
            options={SectorModelArray.map((item) => item.label)}
            filterSelectedOptions
            value={filters.excludedSectorModels}
            onChange={(event, newValue) => handleAutocompleteChange("excludedSectorModels", newValue)}
            renderInput={(params) => <TextField {...params} label="Excluded Sector Models" />}
            fullWidth
          />
        </Grid>

        {/* Third Row */}
        {/* <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label={<Label componentName="Start Date" valueType="date" />}>
              <DatePicker value={startDate} onChange={(startDate) => setStartDate(startDate)} />
            </DemoItem>
          </LocalizationProvider>
        </Grid> */}
        <Grid item xs={4}>
          <TextField
            label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoItem label={<Label componentName="Finish Date" valueType="date" />}>
              <DatePicker value={finishDate} onChange={(finishDate) => setFinishDate(finishDate)} />
            </DemoItem>
          </LocalizationProvider>
        </Grid> */}

        <Grid item xs={4}>
          <TextField
            label="Valid Until"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={finishDate}
            onChange={(e) => setFinishDate(e.target.value)}
          />
        </Grid>
        {/* Fourth Row */}
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSearchClick}>
            Search
          </Button>
          <Button variant="contained" color="secondary" onClick={handleResetClick} style={{ marginLeft: "1rem" }}>
            Reset
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchFilter;
