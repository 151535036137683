import { Container, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { IInvoice } from "../../../types/customTypes";
import InvoiceDetailItem from "./InvoiceDetailsItem";
import { formatDate } from "../../../utils/dateFormatter";
import { useGetInvoiceByIdQuery } from "../../../store/api/invoiceApi";
import CurrencyList from "currency-list";
import { formatAmount } from "../../../utils/amountFormatter";

function ViewInvoiceDetails() {
  const { invoiceId } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoice>();
  const currencies = useMemo(() => CurrencyList.getAll("en_US"), []);

  const { data, isSuccess: isGetInvoiceSuccess } = useGetInvoiceByIdQuery(invoiceId as string);

  useEffect(() => {
    if (isGetInvoiceSuccess) {
      setInvoiceDetails(data);
    }
  }, [isGetInvoiceSuccess, data]);

  const columns = [
    { field: "productName", headerName: "Product Name", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 200 },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      width: 150,
      valueGetter: (params: any) => {
        const { unitPrice, purchaseCurrency } = params.row;
        const currencySymbol = currencies[purchaseCurrency]?.symbol || "";
        return currencySymbol + formatAmount(unitPrice).toString();
      },
    },
    { field: "lineDiscountAmount", headerName: "Discount %", width: 150 },
    { field: "lineVatPercentage", headerName: "VAT %", width: 150 },
    {
      field: "lineNetAmount",
      headerName: "Net Amount",
      width: 150,
      valueGetter: (params: any) => {
        const { lineNetAmount, purchaseCurrency } = params.row;
        const currencySymbol = currencies[purchaseCurrency]?.symbol || "";
        return currencySymbol + formatAmount(lineNetAmount).toString();
      },
    },
  ];

  return (
    <Container>
      <Grid container justifyContent="flex-end">
        <Grid item md={4}>
          <InvoiceDetailItem label="Invoice Number" value={invoiceDetails?.invoiceNumber} />
        </Grid>
        <Grid item md={4}>
          <InvoiceDetailItem label="Issue Date" value={formatDate(invoiceDetails?.issueDate)} />
        </Grid>
        <Grid item md={4}>
          <InvoiceDetailItem label="Due Date" value={formatDate(invoiceDetails?.dueDate)} />
        </Grid>
        <Grid container justifyContent="flex-end" sx={{ marginTop: "20px" }}>
          <Grid item md={12}>
            <Typography variant="body1" fontWeight="bold">
              Invoicelines
            </Typography>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid rows={invoiceDetails?.invoiceLines ?? []} columns={columns} getRowId={(row) => row.productId} />
            </div>
          </Grid>
          <Grid item md={4} marginTop="20px">
            <InvoiceDetailItem label="Total Gross Amount" value={invoiceDetails?.totalGrossAmount} />
            <InvoiceDetailItem label="Total Discount Amount" value={invoiceDetails?.totalDiscountAmount} />
            <InvoiceDetailItem label="Total Vat Amount" value={invoiceDetails?.totalVatAmount} />
            <InvoiceDetailItem label="Total Net Amount" value={invoiceDetails?.totalNetAmount} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ViewInvoiceDetails;
