import { purchaseInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const statisticSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    totalPurchaseAmount: builder.query<number[], number>({
      query: (year: number) => ({
        url: purchaseInvoiceApiBase + `/statistics/totalPurchaseAmount?targetYear=${year}`,
      }),
    }),
    getSuppliersCount: builder.query<number, void>({
      query: () => ({
        url: purchaseInvoiceApiBase + "/statistics/supplierCount",
      }),
      transformResponse: (response: { data: number }) => response.data, // Extract data from response
    }),
    getPurchaseStat: builder.query<any, any>({
      query: ({ timeInterval, year, month, day }) => ({
        url: purchaseInvoiceApiBase + `/statistics/getStat?timeInterval=${timeInterval}&year=${year}&month=${month}&day=${day}`,
      }),
    }),
  }),
});

export const { useTotalPurchaseAmountQuery, useGetSuppliersCountQuery, useGetPurchaseStatQuery, useLazyGetPurchaseStatQuery } = statisticSlice;
