import { GetApp, Visibility } from "@mui/icons-material";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IPagination, IQuerySearchParamsInvoice, ISalesInvoice } from "../../../../types/customTypes";

import FilterButton from "../../../../components/ActionButton";
import PageManagementGrid from "../../../../components/DataGrid";
import SearchFilterStatus from "../../../../components/FilterStatus";
import { useGetSalesInvoicesQuery } from "../../../../store/api/salesInvoiceApi";
import { defaultPaginationModel } from "../../../../utils/queryUtils";

import { useFetchTeamInfoQuery } from "../../../../store/api/infoApi";

import { useForm } from "react-hook-form";
import CustomerSelect from "../../../../components/CustomerSelect";
import { salesInvoiceApiBase } from "../../../../store/env";
import { useGetFactoringInvoicesQuery } from "../../../../store/api/handleFactoringRequest";
import ManageOffersReceived from "./ManageOffersReceived";

function FactoringRequestedInvoices() {
  const {
    formState: { errors },
    register,
  } = useForm();

  const { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();

  const [salesInvoices, setSalesInvoices] = useState<ISalesInvoice[]>([]);

  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParamsInvoice>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
    status: "Factoring Requested",
    selectedCustomer: "all_customers",
  });

  const [showFilter, setShowFilter] = useState(false);
  const { data, isSuccess, isFetching, refetch: refetchFinancingInvoices } = useGetFactoringInvoicesQuery(queryParams);
  const [totalItems, setTotalItems] = useState(0);

  const [selectedCustomer, setSelectedCustomer] = useState("");

  useEffect(() => {
    refetchTeamDetails();
    refetchFinancingInvoices();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSalesInvoices(data.data);
      setTotalItems(data.total_items);
    }
  }, [isSuccess, data]);

  const handleDelete = async () => {};

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: selectedCustomer,
      status: "Factoring Requested",
    });
  };

  const handleCustomerInput = (customerId: any) => {
    setSelectedCustomer(customerId);
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: customerId,
      status: "Factoring Requested",
    });
  };
  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleFilterChange = () => {
    setQueryParams({
      ...queryParams,
      selectedCustomer: selectedCustomer,
      status: "Factoring Requested",
    });
  };

  const handleDownload = async (id: string) => {
    const confirmed = window.confirm("Are you sure you want to download the file?");
    if (confirmed) {
      const url = salesInvoiceApiBase + `/v1/invoice/generate/${id}`;
      window.open(url, "_blank");
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "salesInvoice.pdf";
      downloadLink.click();
    }
  };

  function getStatusColor(status: string): string {
    switch (status) {
      case "Factoring Requested":
        return "rgb(255, 165, 40)";
      default:
        return "black";
    }
  }

  function getAmountColor(status: string): string {
    switch (status) {
      case "Factoring Requested":
        return "orange";
      default:
        return "orange";
    }
  }

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: showFilter ? 150 : 200,
      renderCell: (params: any) => <div style={{ backgroundColor: getStatusColor(params.row.status) }}>{params.value}</div>,
    },

    {
      field: "bid_start_date",
      headerName: "bid_start_date",
      width: showFilter ? 150 : 250,
      valueFormatter: (params: any) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "bid_end_date",
      headerName: "bid_end_date",
      width: showFilter ? 150 : 250,
      valueFormatter: (params: any) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "invoice_number",
      headerName: "Invoice No",
      width: showFilter ? 150 : 250,
    },
    {
      field: "customer_name",
      headerName: "Customer",
      width: showFilter ? 150 : 250,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: showFilter ? 150 : 250,
      renderCell: (params: any) => (
        <div style={{ color: getAmountColor(params.row.status) }}>
          {params.value} {params.row.currency}
        </div>
      ),
    },
  ];

  const actions = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params: any) => {
      return (
        <div>
          <Link
            to="/viewSalesInvoiceDetails"
            state={{ salesInvoiceDetails: salesInvoices, selectedInvoiceId: params.row.id }}
            color="primary"
            title="View Sales Invoice"
          >
            <IconButton>
              <Visibility />
            </IconButton>
          </Link>
          <IconButton color="success" title="Download" onClick={() => handleDownload(params.row.id)}>
            <GetApp />
          </IconButton>
        </div>
      );
    },
  };

  const handleClearFilter = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: "all_customers",
    });
    setSelectedCustomer("");
  };

  const [openOffersDialog, setOpenOffersDialog] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState([]);

  console.log("setted offers", selectedOffers);
  const [selectedIds, setSelectedIds] = useState([]);
  const handleOpenOffersDialog = () => {
    const selectedRowsData: any = selectedIds.map((id: any) => salesInvoices.find((row: any) => row.id === id));
    console.log("selectedRowsData2", selectedRowsData);

    console.log("selectedRowsData", selectedRowsData[0]?.all_offers);
    setSelectedOffers(selectedRowsData[0]?.all_offers || []);
    setOpenOffersDialog(true);
  };

  const handleCloseOffersDialog = () => {
    setOpenOffersDialog(false);
  };

  const handleRowSelection = (ids: any) => {
    console.log("ids", ids);
    setSelectedIds(ids);
    const selectedRowsData = ids.map((id: any) => salesInvoices.find((row: any) => row.id === id));
    console.log("selectedRowsData1", selectedRowsData);

    console.log("selectedRowsData", selectedRowsData[0]?.all_offers);
    setSelectedOffers(selectedRowsData[0]?.all_offers || []);
  };
  return (
    <Box sx={{ height: "50vh", width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
        </Grid>

        <Grid item md={2} xs={6} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
          <Button variant="contained" color="secondary" onClick={handleOpenOffersDialog}>
            Show Offers
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        {showFilter && (
          <>
            <Grid item sm={12} md={3}>
              <SearchFilterStatus
                onFilterChange={handleFilterChange}
                items={["Factoring Requested"]}
                children={
                  <>
                    <CustomerSelect
                      onChange={handleCustomerInput}
                      register={register}
                      error={errors}
                      selectedCustomerValue={selectedCustomer}
                      setSelectedCustomerValue={setSelectedCustomer}
                    />
                    <Button variant="outlined" color="success" onClick={handleClearFilter}>
                      Clear Filter
                    </Button>
                  </>
                }
              />
            </Grid>
          </>
        )}

        {openOffersDialog && <ManageOffersReceived openDialog={openOffersDialog} handleCloseDialog={handleCloseOffersDialog} offersProp={selectedOffers} />}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <PageManagementGrid
            rows={salesInvoices}
            columnFields={columns}
            onDelete={handleDelete}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            customActions={actions}
            checkboxSelection
            onSelectionModelChange={handleRowSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FactoringRequestedInvoices;
