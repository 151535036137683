import { Box, Button, Typography } from "@mui/material";
import { GridCheckCircleIcon } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

interface SuccessAlertProps {
  successMessage: string;
  buttonText: string;
  linkPath: string;
}

function SuccessAlert({
  successMessage,
  buttonText,
  linkPath,
}: SuccessAlertProps) {
  return (
    <Box textAlign="center">
      <GridCheckCircleIcon sx={{ fontSize: "150px" }} color="success" />
      <Typography variant="h4" gutterBottom mb={3}>
        {successMessage}
      </Typography>
      <Link to={linkPath}>
        <Button variant="contained">
          <Typography variant="h6">{buttonText}</Typography>
        </Button>
      </Link>
    </Box>
  );
}

export default SuccessAlert;
