import { z } from 'zod';
import { FinanceEventType } from '../global/enums/entityEnum';
import { Address } from './address.validation';
import { Phone } from './phone.validation';

export const CreateSupplierSchema = z.object({
    supplierName: z.string().min(1, "Supplier Name is required"),
    supplierEmail: z.string().min(1, "Supplier email is required").email(),
    address: Address,
    phone: Phone,
    openingBalance: z.number().default(0).optional(),
    openingBalanceDate: z.coerce.date(),
    openingBalanceType: z.nativeEnum(FinanceEventType)
});

export const UpdateSupplierSchema = z.object({
  body: z.object({
    supplierName: z.string().nonempty().optional(),
    supplierEmail: z.string().email().optional(),
    address: Address.optional(),
    phoneNumber: Phone.optional(),
    openingBalance: z.number().default(0).optional(),
    openingBalanceDate: z.coerce.date().optional(),
  }),
}); 