export enum InvoiceType {
  Sale = "Sale",
  Purchase = "Purchase",
  PurchaseReturn = "PurchaseReturn",
}

export enum StockCategoryType {
  Goods,
  Services,
  Assets,
  Expenses,
}

export enum NoteType {
  Credit = "Credit",
  Debit = "Debit",
}

export enum FinanceEventType {
  Credit = "Credit",
  Debit = "Debit",
}
