import { z } from "zod";


export const createSubCategorySchema = z.object({
  subCategoryName: z.string().nonempty("Subcategory name is required"),
  stockCategory: z.string().nonempty("Please select stockcategory"),
});

export const updateSubCategorySchema = z.object({
  stockCategory: z.string().nonempty().optional(),
  subCategoryName: z.string().optional(),
});
