import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomerDetailsTable from "./ModalTable";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ICustomCustomer } from "../../types/financeTypes";

export interface ICustomerDetailsModalTable {
  id: number;
  name: string;
  email: string;
  customer_phone: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  post_code: string;
  country: string;
}

interface IViewCustomerDetailsModalProps {
  data?: ICustomCustomer[];
  icon: React.ReactNode;
}

const ViewCustomerDetailsModal: React.FC<IViewCustomerDetailsModalProps> = ({ data, icon }) => {
  const [rows, setRows] = useState<ICustomerDetailsModalTable[]>([]);
  console.log(data);
  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", align: "center", headerAlign: "center", width: 300 },
    { field: "email", headerName: "Email", align: "center", headerAlign: "center", width: 300 },
    { field: "customer_phone", headerName: "Customer Phone", align: "center", headerAlign: "center", width: 250 },
    { field: "address_line_1", headerName: "Address Line 1", align: "center", headerAlign: "center", width: 300 },
    { field: "address_line_2", headerName: "Address Line 2", editable: true, align: "center", headerAlign: "center", width: 300 },
    { field: "city", headerName: "City", align: "center", headerAlign: "center", width: 300 },
    { field: "post_code", headerName: "Post Code", align: "center", headerAlign: "center", width: 300 },
    { field: "country", headerName: "Country", align: "center", headerAlign: "center", width: 300 },
  ];

  useEffect(() => {
    if (data && data.length > 0) {
      const rows = data.map((customer) => {
        const row: ICustomerDetailsModalTable = {
          id: customer.id,
          name: customer.name,
          email: customer.email,
          address_line_1: customer.address_line_1,
          address_line_2: customer.address_line_2 ? customer.address_line_2 : "",
          city: customer.city,
          country: customer.country,
          customer_phone: customer.customer_phone,
          post_code: customer.post_code,
        };
        return row;
      });
      setRows(rows);
    }
  }, [data]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        title="View Customer Details"
        onClick={handleOpenDialog}
        style={{
          color: "#333",
          paddingBottom: "0.8rem",
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>
          <IconButton
            title="Back To Invoices"
            onClick={handleCloseDialog}
            style={{
              color: "#333",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Customer Details
        </DialogTitle>

        <DialogContent>
          <CustomerDetailsTable rows={rows ? rows : []} columns={columns} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewCustomerDetailsModal;
