import { salesInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const infoSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchTeamInfo: builder.query<any, void>({
      query: () => ({
        url: salesInvoiceApiBase + "/v1/account/team",
      }),
    }),
    fetchUserInfo: builder.query<any, void>({
      query: () => ({
        url: salesInvoiceApiBase + "/v1/account/user",
      }),
    }),
  }),
});

export const { useLazyFetchTeamInfoQuery, useFetchTeamInfoQuery, useLazyFetchUserInfoQuery, useFetchUserInfoQuery } = infoSlice;
