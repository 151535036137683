import { salesInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const handleFactoringRequest = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createFinancingRequest: builder.mutation({
      query: (selectedInvoiceIds) => ({
        url: salesInvoiceApiBase + "/v1/financing/request",
        method: "POST",
        body: selectedInvoiceIds,
      }),
    }),

    getFactoringInvoices: builder.query({
      query: ({ pageInfo, searchColumns, searchKeyword, status, selectedCustomer }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
          status: status,
          selectedCustomer,
        };
        return {
          url:
            salesInvoiceApiBase +
            `/v1/financing/invoices?page=${queryParams.page}&perPage=${queryParams.limit}&selectedStatus=${queryParams.status}&searchField=${searchKeyword}&customer_id=${selectedCustomer}`,

          method: "GET",
        };
      },
      providesTags: ["FactoringRequestUserTable"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
  }),
});

export const { useCreateFinancingRequestMutation, useGetFactoringInvoicesQuery } = handleFactoringRequest;
