import { apiSlice } from "./baseApi";
import { IPagination, IQueryResultCustomer, ICustomer } from "../../types/customTypes";

import { salesInvoiceApiBase } from "../env";

//https://dev2.payeda.net/api  POST /v1/customer
export const customerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewCustomer: builder.mutation({
      query: (newCustomer) => ({
        url: salesInvoiceApiBase + "/v1/customer",
        method: "POST",
        body: newCustomer,
      }),

      invalidatesTags: ["Customer"],
    }),

    getCustomers: builder.query<IQueryResultCustomer<ICustomer[]>, IPagination>({
      query: ({ page, pageSize }) => {
        const queryParams = {
          page,
          pageSize,
        };
        return {
          url: salesInvoiceApiBase + `/v1/customer?page=${queryParams.page}&perPage=${queryParams.pageSize}`,
          method: "GET",
        };
      },
      providesTags: ["Customer"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getCustomerById: builder.query<ICustomer, string>({
      query: (id) => ({
        url: `/customer/${id}`,
        method: "GET",
      }),
      providesTags: ["Customer"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateCustomer: builder.mutation({
      query: (updatedCustomer) => ({
        url: salesInvoiceApiBase + "/v1/customer",
        method: "PUT",
        body: updatedCustomer,
        invalidatesTags: ["Customer"],
      }),
    }),
    deleteCustomer: builder.mutation({
      query: (idsToDelete) => ({
        url: salesInvoiceApiBase + "/v1/customer/delete",
        method: "POST",
        body: idsToDelete,
      }),
      transformResponse: (result: any) => {
        return result;
      },
      invalidatesTags: ["Customer"],
    }),
  }),
});

export const {
  useAddNewCustomerMutation,
  useGetCustomersQuery,
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useLazyGetCustomersQuery,
  useLazyGetCustomerByIdQuery,
} = customerSlice;
