import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import ProductSelectionField from "../../../components/ProductSelectionField";
import { useGetProductByIdQuery } from "../../../store/api/productApi";
import { handleNumberInput } from "../../../utils/numberPrecision";
import { salesInvoiceValues } from "../../../types/formValueType";
import { FieldErrors } from "react-hook-form";
import { IProduct } from "../../../types/customTypes";

interface InvoiceLineProps {
  index: number;
  register: any;
  error: FieldErrors<salesInvoiceValues>;
  setValue: any;
  clearError: any;
  remove: any;
}

const SalesInvoiceLine = ({ index, register, error, remove, clearError, setValue }: InvoiceLineProps) => {
  const [selectedProductId, setSelectedProductId] = useState<string>("");

  const { isSuccess: isGetProductSuccess, data: product } = useGetProductByIdQuery(selectedProductId);
  const handleProductChange = (productId: string) => {
    clearError(`invoice_lines.${index}.product`);
    setValue(`invoice_lines.${index}.product`, productId);
  };

  const handleVatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoice_lines.${index}.line_vat_percentage`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoice_lines.${index}.line_vat_percentage`, value);
  };

  const handleAddQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoice_lines.${index}.quantity`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoice_lines.${index}.quantity`, value);
  };

  const handleUnitPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoice_lines.${index}.unit_price`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoice_lines.${index}.unit_price`, value);
  };

  React.useEffect(() => {
    if (isGetProductSuccess) {
      const { purchasePrice, vatRate } = product;
      if (purchasePrice !== null && purchasePrice !== undefined) {
        setValue(`invoice_lines.${index}.unit_price`, purchasePrice);
      }
      if (vatRate !== undefined) {
        setValue(`invoice_lines.${index}.line_vat_percentage`, vatRate);
      }
    }
  }, [selectedProductId, isGetProductSuccess, product]);


  const onNewProductCreation = (productDetails: IProduct) => {
    setValue(`invoice_lines.${index}.product`, productDetails._id);
    setValue(`invoiceLines.${index}.unit_price`, productDetails.purchasePrice)
    setValue(`invoiceLines.${index}.line_vat_percentage`, productDetails.vatRate)
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={2.5} mt={-2}>
        <ProductSelectionField
          selectedProduct={`invoice_lines.${index}.product`}
          setSelectedProduct={setSelectedProductId}
          onProductChange={handleProductChange}
          error={!!error.invoice_lines?.[index]?.product}
          errorMessage={error.invoice_lines?.[index]?.product?.message}
          onCreateProductSuccess={onNewProductCreation}
        /> 
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          id={`unit-price-${index}`}
          label="Unit Price"
          variant="outlined"
          {...register(`invoice_lines[${index}].unit_price`)}
          error={!!error.invoice_lines?.[index]?.unit_price}
          helperText={error.invoice_lines?.[index]?.unit_price?.message}
          onChange={handleUnitPriceChange}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          id={`quantity-${index}`}
          label="Quantity"
          variant="outlined"
          {...register(`invoice_lines[${index}].quantity`)}
          error={!!error.invoice_lines?.[index]?.quantity}
          helperText={error.invoice_lines?.[index]?.quantity?.message}
          onChange={handleAddQuantity}
        />
      </Grid>
      <Grid item xs={2.5}>
        <TextField
          id={`line_vat_percentage-${index}`}
          label="Vat Amount"
          variant="outlined"
          {...register(`invoice_lines[${index}].line_vat_percentage`)}
          error={!!error.invoice_lines?.[index]?.line_vat_percentage}
          helperText={error.invoice_lines?.[index]?.line_vat_percentage?.message}
          onChange={handleVatChange}
        />
      </Grid>
      <Grid item xs={2}>
        <Button onClick={() => remove(index)}>
          <ClearIcon fontSize="large" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default SalesInvoiceLine;
