const purchaseInvoiceApiv1 = "https://desktop.payeda.net/api/v1"
const purchaseInvoiceApiv2 = "https://desktop.payeda.net/api/v2"
const localHostPurchaseInvoiceApi = "http://localhost:5000/v1"

let purchaseInvoiceApiBase = purchaseInvoiceApiv1;
let salesInvoiceApiBase = "https://dev2.payeda.net/api";

let financeBaseApi = salesInvoiceApiBase;

let env = "live"; //live // development

if (env === "local") {
  salesInvoiceApiBase = "http://localhost:5000";
  purchaseInvoiceApiBase = "http://localhost:5001";
  financeBaseApi = "http://localhost:5008";
}

export { purchaseInvoiceApiBase, salesInvoiceApiBase, financeBaseApi };
