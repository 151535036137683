import { Button, Container, Grid, TextField, Alert, Typography } from "@mui/material";

import DialogInput from "../../../../components/Dialog";
import { CreateEntity } from "../../../../types/customTypes";
import { useForm } from "react-hook-form";

function SubmitAlertDialog({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  const { handleSubmit } = useForm<any>({});
  const onSubmit = async (data: any) => {
    console.log(data);
    try {
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating financial info:", error);
    }
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Are you sure to request factoring on selected invoices?"
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Alert severity="warning">Selected invoices will be marked as Factoring Requested. You will not be able to receive payments.</Alert>

            <br />
            <Grid item xs={12} marginBottom="20px">
              <Button type="submit" variant="contained" color="primary">
                Continue
              </Button>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Update"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default SubmitAlertDialog;
