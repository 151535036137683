import { apiSlice } from "./baseApi";
import { IQueryResult, IQuerySearchParams, ISubCategory } from "../../types/customTypes";
import { purchaseInvoiceApiBase } from "../env";

export const subcategorySlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewSubcategory: builder.mutation({
      query: (newSubcategory) => ({
        url: purchaseInvoiceApiBase + "/subcategories",
        method: "POST",
        body: newSubcategory,
      }),
      invalidatesTags: ["Subcategory"],
    }),

    getSubcategories: builder.query<IQueryResult<ISubCategory[]>, IQuerySearchParams>({
      query: ({ pageInfo, searchColumns, searchKeyword }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
        };
        return {
          url: purchaseInvoiceApiBase + `/subcategories?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Subcategory"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    updateSubcategory: builder.mutation({
      query: (updatedFields) => ({
        url: purchaseInvoiceApiBase + `/subcategories/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
    }),
    deleteSubcategory: builder.mutation({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/subcategories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subcategory"],
    }),
  }),
});

export const { useAddNewSubcategoryMutation, useGetSubcategoriesQuery, useUpdateSubcategoryMutation, useDeleteSubcategoryMutation } = subcategorySlice;
