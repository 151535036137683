import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GridColDef } from "@mui/x-data-grid";

interface SearchFilterProps {
  onSearchInput: (searchInput: {
    searchColumns: string[];
    searchKeyword: string;
  }) => void;
  columns: GridColDef[];
}

function SearchFilter({ onSearchInput, columns }: SearchFilterProps) {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedColumns>) => {
    const {
      target: { value },
    } = event;
    const updatedColumns = typeof value === "string" ? value.split(",") : value;

    setSelectedColumns(updatedColumns);
    onSearchInput({
      searchColumns: updatedColumns,
      searchKeyword: searchTerm,
    });
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    onSearchInput({
      searchColumns: selectedColumns,
      searchKeyword: event.target.value,
    });
  };

  return (
    <Card sx={{ height: "100%", width: "95%", marginTop: "20px" }}>
      <CardContent>
        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item sm={12} display="flex">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Search"
                variant="outlined"
                size="small"
                value={searchTerm}
                onChange={handleSearchInput}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl sx={{ mt: 1, width: "100%" }}>
              <InputLabel>Columns</InputLabel>
              <Select
                multiple
                value={selectedColumns}
                onChange={handleChange}
                input={<OutlinedInput label="Columns" />}
                size="medium"
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {columns.map(({ field, headerName }) => (
                  <MenuItem key={field} value={field}>
                    {headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SearchFilter;