import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import CenteredContainer from "../../components/CenteredContainer";
import { Link, useNavigate } from "react-router-dom";
import { deleteAllCookies } from "../../utils/getCookie";
import { clearStorage } from "../../utils/formStorage";
import { createBusinessFormValues } from "./formName";

function CreateBusinessPromptPage() {

  useEffect(() => {
    clearStorage(createBusinessFormValues)
  }, [])
  

  const navigate = useNavigate();

  const handleNextButtonClick = () => {
    navigate("/account-type-selection");
  };

  const handleBackButtonClick = () => {
    deleteAllCookies();
    clearStorage(createBusinessFormValues)
    navigate("/login");
  };

  return (
    <CenteredContainer>
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            boxShadow: 3,
            borderRadius: 2,
            px: 4,
            py: 6,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" gutterBottom>
            Create Business Account
          </Typography>
          <Typography variant="h6"> Create your business account</Typography>

          <Link to="/account-type-selection">
            <Button
              type="button"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                width: 150,
              }}
              onClick={handleNextButtonClick}
            >
              Next
            </Button>
          </Link>
          <Link to="/login">
            <Button
              type="button"
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
              }}
              onClick={handleBackButtonClick}
              // onClick={handleNextButtonClick}
            >
              Back to login
            </Button>
          </Link>
        </Box>
      </Container>
    </CenteredContainer>
  );
}

export default CreateBusinessPromptPage;
