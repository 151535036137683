import { Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { styled } from "styled-components";
import theme from "../theme/theme";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    // color: theme.palette.error.contrastText,
  },
}));

export default StyledTooltip
