import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "./features/dashboardSlice";
import authReducer from "./features/authenticationSlice";
import companyHouseReducer from "./features/companyHouseSlice";
import vatNumReducer from "./features/vatNumberSlice";
import registrationReducer from "./features/registrationSlice";
import userDataReducer from "./features/userSlice";
import uploladFileSlice from "./features/uploadFileSlice";
import uploadStatusSlice from "./features/uploadStatusSlice";
import { apiSlice } from "./api/baseApi";

export const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    auth: authReducer,
    companyHouse: companyHouseReducer,
    vatNumber: vatNumReducer,
    registration: registrationReducer,
    userData: userDataReducer,
    uploadFile: uploladFileSlice,
    uploadStatus: uploadStatusSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});
