import { Box, Grid, Typography } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";
import PageTitle from "./PageTitle";
import DashboardSummary from "./DashboardSummaryCard";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { LineChart } from "@mui/x-charts";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import InventoryIcon from "@mui/icons-material/Inventory";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { useLazyGetPurchaseStatQuery } from "../store/api/statisticsApi";
import { useEffect, useState } from "react";
import { formatAmount } from "../utils/amountFormatter";
import DashboardFilter from "./DashboardFilter";
import { TimeInterval } from "../global/enums/timeInterval";

function Dashboard() {
  const [purchasesAmount, setPurchasesAmount] = useState<number[]>([0]);
  const [totalPurchaseFigure, setTotalPurchaseFigure] = useState("0");
  const [supplierCount, setSupplierCount] = useState("0");
  const monthsOfYear = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const [getPurchaseStat, { isSuccess: isGetPurchaseStatSuccess, isError: isGetPurchaseStatError, data: purchaseStat }] = useLazyGetPurchaseStatQuery();
  const defaultYearFilterText = "Filter by year";
  const defaultMonthFilterText = "Filter by month";
  const defaultDayFilterText = "Filter by day";
  const [yearLabelText, setYearLabelText] = useState(new Date().getFullYear().toString());
  const [monthLabelText, setMonthLabelText] = useState(defaultMonthFilterText);
  const [dayLabelText, setDayLabelText] = useState(defaultDayFilterText);
  const [year, setYear] = useState(new Date().getFullYear());

  const Sale = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  const Payment = [4000, 3000, 2000, 2780, 1890, 2390, 3490];

  const handleYearSelection = (date: Date) => {
    const year = date.getFullYear();
    getPurchaseStat({ timeInterval: TimeInterval.YEAR, year: year });
    setYearLabelText(year.toString());
    setMonthLabelText(defaultMonthFilterText);
    setDayLabelText(defaultDayFilterText);
    setYear(year);
  };

  const handleMonthSelection = (date: Date) => {
    const year = date.getFullYear();
    //Month and day index starts at 0
    const month = date.getMonth() + 1;
    setMonthLabelText(`${month}-${year}`);
    setYearLabelText(defaultYearFilterText);
    setDayLabelText(defaultDayFilterText);
    getPurchaseStat({ timeInterval: TimeInterval.MONTH, year, month });
  };

  const handleDaySelection = (date: Date) => {
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDay() + 1;
    setDayLabelText(`${year}-${month}-${dayOfMonth}`);
    setMonthLabelText(defaultMonthFilterText);
    setYearLabelText(defaultYearFilterText);
    getPurchaseStat({ timeInterval: TimeInterval.DAY, year, month, day });
  };

  useEffect(() => {
    if (isGetPurchaseStatSuccess && purchaseStat) {
      setSupplierCount(purchaseStat.data.supplierCount);
      setTotalPurchaseFigure(purchaseStat.data.totalPurchaseAmount);
      //yearPurchaseAmountArray is only returned when year is changed
      if (purchaseStat.data.yearPurchaseAmountArray) {
        setPurchasesAmount(purchaseStat.data?.yearPurchaseAmountArray);
      }
    }
  }, [isGetPurchaseStatSuccess, isGetPurchaseStatError, purchaseStat]);

  useEffect(() => {
    getPurchaseStat({ timeInterval: TimeInterval.YEAR, year: new Date().getFullYear() });
  }, []);

  const xLabels = monthsOfYear;

  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: "Customer",
      width: 200,
      editable: false,
    },
    {
      field: "totalBought",
      headerName: "Total Quantity Bought",
      width: 200,
      editable: false,
    },
    {
      field: "measurementUnit",
      headerName: "Measurement Unit",
      width: 200,
      editable: false,
    },
    {
      field: "totalSpending ",
      headerName: "Total Spending",
      width: 200,
      editable: false,
    },
  ];
  return (
    <Box>
      <PageTitle title="Dashboard" />
      <Box width="100%" display="flex">
        <DashboardFilter
          handleYearSelection={handleYearSelection}
          handleMonthSelection={handleMonthSelection}
          handleDaySelection={handleDaySelection}
          yearLabelText={yearLabelText}
          monthLabelText={monthLabelText}
          dayLabelText={dayLabelText}
        />
      </Box>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={MonetizationOnOutlinedIcon} title="Total Sales" amount={`TL ${formatAmount(5000)}`} color="#233142" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={AttachMoneyOutlinedIcon} title="Total Sales payment" amount={`TL ${formatAmount(3000)}`} color="blue" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={PeopleAltOutlinedIcon} title="Customers" amount="5" color="orange" />
        </Grid>

        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={ShoppingCartIcon} title="Total Purchase" amount={`TL ${formatAmount(parseFloat(totalPurchaseFigure))}`} color="#233142" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={ReceiptIcon} title="Total Purchase payment" amount={`TL ${formatAmount(15000.85)}`} color="blue" />
        </Grid>
        <Grid item xs={12} md={4}>
          <DashboardSummary Icon={InventoryIcon} title="Suppliers" amount={supplierCount} color="orange" />
        </Grid>
      </Grid>

      <Grid container marginTop={10} textAlign="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold">
            Monthly Sales Performance (2023)
          </Typography>
          <BarChart
            xAxis={[{ scaleType: "band", data: monthsOfYear }]}
            series={[{ data: [4, 3, 5, 2, 5, 8, 1, 4, 1, 9, 4, 5], color: "#0ac775" }]}
            width={750}
            height={300}
            desc="Sales for year 2023"
            title="Sales for year 2023"
          />
          <Typography variant="h5" fontWeight="bold">
            Monthly Payments from Customers (2023 )
          </Typography>

          <BarChart
            xAxis={[{ scaleType: "band", data: monthsOfYear }]}
            series={[{ data: [4, 3, 5, 2, 5, 8, 1, 4, 1, 9, 4, 5], color: "#233142" }]}
            width={750}
            height={300}
            title="Purchase"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LineChart
            width={800}
            height={660}
            series={[
              { data: Sale, label: "Sale", color: "#0ac775" },
              { data: Payment, label: "Payment", color: "#233142" },
            ]}
            xAxis={[{ scaleType: "point", data: xLabels }]}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={10} textAlign="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold">
            {`Monthly Purchase Figure (${year})`}
          </Typography>
          <BarChart
            xAxis={[{ scaleType: "band", data: monthsOfYear }]}
            series={[{ data: purchasesAmount ? purchasesAmount : [0], color: "#0ac775" }]}
            width={750}
            height={300}
            desc="Sales for year 2023"
            title="Sales for year 2023"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataGrid columns={columns} rows={[]} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
