import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
} from "@mui/x-data-grid";
import {
  IPagination,
  IQuerySearchParams,
  ISubCategory,
} from "../../types/customTypes";
import PageHeader from "../../components/PageHeader";
import CreateSubCategory from "./CreateSubCategory";
import {
  useDeleteSubcategoryMutation,
  useGetSubcategoriesQuery,
  useUpdateSubcategoryMutation,
} from "../../store/api/subcategoryApi";
import PageManagementGrid from "../../components/DataGrid";
import { defaultPaginationModel } from "../../utils/queryUtils";
import SuccessSnackbar from "../../components/Snackbar";
import {
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import SearchFilter from "../../components/Filter";
import FilterButton from "../../components/ActionButton";
import { StockCategoryType } from "../../types/fieldOptions";
import StyledTooltip from "../../components/StyledTooltip";

interface SubCategoryRow extends ISubCategory {
  isNew?: boolean;
}

function SubcategoryNameEditInputCell(props: GridRenderEditCellParams) {
  const { value, api } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const hasProduct = props.row.productCount > 0;

  const handleSubcategoryNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;

    setErrorMessage("");

    if (newValue === "") {
      setErrorMessage("Subcategory name cannot be empty");
    } else {
      api.setEditCellValue({ ...props, value: newValue });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasProduct) {
      setErrorMessage("Cannot edit name. Product with subcategory exists");
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <TextField
        value={value}
        onChange={handleSubcategoryNameInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{
          readOnly: hasProduct,
        }}
        fullWidth
      />
    </StyledTooltip>
  );
}

function StockCategoryInputEditCell(props: GridRenderEditCellParams) {
  const hasProduct = props.row.productCount > 0;
  const { value, api } = props;
  const [errorMessage, setErrorMessage] = useState("");

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasProduct) {
      setErrorMessage(
        "Cannot edit stockcategory, product with subcategory already exists"
      );
    }
  };

  const handleStockCategoryChange = (
    event: SelectChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    api.setEditCellValue({ ...props, value: newValue });
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <Select
        value={value || ""}
        readOnly={hasProduct}
        fullWidth
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleStockCategoryChange}
      >
        {StockCategoryType.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </StyledTooltip>
  );
}

export default function ManageSubcategory() {
  const [rows, setRows] = useState<SubCategoryRow[]>([]);
  const [showCreateSubcategoryComponent, setShowCreateSubcategoryComponent] =
    useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [subcategories, setSubcategories] = useState<ISubCategory[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [paginationModel, setPaginationModel] = useState<IPagination>(
    defaultPaginationModel
  );
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });

  const { data, isSuccess, isFetching, refetch } =
    useGetSubcategoriesQuery(queryParams);

  const [updateSubcategory] = useUpdateSubcategoryMutation();
  const [deleteSubcategory] = useDeleteSubcategoryMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      setSubcategories(data.items);
      setTotalItems(data.totalItems);
    }
  }, [data, isSuccess]);

  const handleDelete = (deleteRowId: GridRowId) => {
    if (deleteRowId) {
      deleteSubcategory(deleteRowId).then(() => {
        setRows(rows.filter((row) => row._id !== deleteRowId));
      });
    }
  };

  const handleUpdate = (updatedField: GridRowModel) => {
    updateSubcategory(updatedField);
  };

  const handleAddClick = () => {
    setShowCreateSubcategoryComponent(true);
  };

  const handleCloseDialog = () => {
    setShowCreateSubcategoryComponent(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleSearch = (searchInput: {
    searchColumns: string[];
    searchKeyword: string;
  }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "subCategoryName",
      headerName: "Subcategory name",
      width: showFilter ? 500 : 700,
      align: "left",
      headerAlign: "left",
      renderEditCell: (params) => <SubcategoryNameEditInputCell {...params} />,
      editable: true,
    },
    {
      field: "stockCategory",
      headerName: "Stock Category",
      width: showFilter ? 350 : 500,
      editable: true,
      type: "singleSelect",
      valueOptions: StockCategoryType,
      renderEditCell: (params) => <StockCategoryInputEditCell {...params} />,
    },
  ];

  return (
    <Box>
      <SuccessSnackbar
        open={showSuccessbar}
        handleClose={closeSuccessbar}
        severity="success"
        message="Subcategory successfully created"
      />
      <PageHeader title="Subcategory" onAdd={handleAddClick} />

      {showCreateSubcategoryComponent && (
        <CreateSubCategory
          openDialog={showCreateSubcategoryComponent}
          handleCloseDialog={handleCloseDialog}
          onSuccess={openSuccessbar}
        />
      )}
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <PageManagementGrid
            rows={subcategories}
            columnFields={columns}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            sortModel={[{field: 'subCategoryName', sort: 'asc'}]}

          />
        </Grid>
      </Grid>
    </Box>
  );
}
