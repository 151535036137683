import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styled from "styled-components";

import { ICustomerDetailsModalTable } from "./ViewCustomerDetailsModal";
import { ISupplierDetailsTable } from "./ViewSupplierDetailsModal";

export const StyledDataGridHeader = styled(DataGrid)`
  && .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    font-size: small;
    background-color: #f5f5f5;
  }
`;

interface ITableProps<T> {
  rows: T[];
  columns: GridColDef[];
  backgroundColor?: string;
}

const Modaltable: React.FC<ITableProps<ISupplierDetailsTable | ICustomerDetailsModalTable>> = ({ rows, columns, backgroundColor }) => {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <StyledDataGridHeader
        sx={{ backgroundColor: `${backgroundColor}` }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
      />
    </div>
  );
};

export default Modaltable;
