import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/*This function, named openLinkInNewWindow, takes a link (string) parameter and opens the provided link in a new browser window using window.open.
 This function is used to open document links retrieved from the API.*/
function openLinkInNewWindow(link: string) {
  window.open(link, "_blank");
}

interface DocsState {
  isLoading: boolean;
  hasErrored: boolean;
  errorMessage: string;
  docsLink: string | null;
}

interface FetchDocsParams {
  team_id: string;
  type: string;
  authToken: string | null;
}

const baseUrl = process.env.REACT_APP_BASE_URL;

//This action is responsible for sending a GET request to retrieve document links.
//It takes an object { team_id, type, authToken } as parameters, which represent the team ID, document type, and authentication token required for the request.
export const fetchDocs = createAsyncThunk(
  "docs/fetchDocs",
  async ({ team_id, type, authToken }: FetchDocsParams) => {
    try {
      const response = await axios.get(
        `https://dev2.payeda.net/api/v1/account/docs?id=${team_id}&account_type=0&file_type=${type}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //If the request is successful (fulfilled), it returns the response data containing the document link.
      return response.data;
    } catch (error: any) {
      //If there's an error during the request (rejected), it throws an error with the error message.
      throw new Error(error.message);
    }
  }
);

const initialState: DocsState = {
  isLoading: false,
  hasErrored: false,
  errorMessage: "",
  docsLink: null,
};

const docsSlice = createSlice({
  name: "retrieveFiles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocs.pending, (state) => {
        // This reducer is triggered when the document fetch request is pending. It sets the loading-related state properties (isLoading, hasErrored, errorMessage) accordingly.
        state.isLoading = true;
        state.hasErrored = false;
        state.errorMessage = "";
      })
      .addCase(fetchDocs.fulfilled, (state, action) => {
        //This reducer is triggered when the document fetch request is successful. It sets the loading-related state properties and updates the docsLink property with the retrieved document link.
        state.isLoading = false;
        state.docsLink = action.payload;
        // It also calls the openLinkInNewWindow function to open the document link in a new window if it exists.
        if (action.payload) {
          openLinkInNewWindow(action.payload.data);
        }
      })
      .addCase(fetchDocs.rejected, (state, action) => {
        //This reducer is triggered when there's an error during the document fetch request. It sets the loading-related state properties, sets hasErrored to true, and stores the error message in errorMessage.
        state.isLoading = false;
        state.hasErrored = true;
        state.errorMessage = action.error.message ?? "";
      });
  },
});

export default docsSlice.reducer;
