import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useFetchUserInfoQuery } from "../../store/api/infoApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Divider, Box } from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: "auto",
  maxWidth: 600,
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(2),
  color: "blue",
}));

const PersonalInformation = () => {
  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails, refetch: refetchUserDetails } = useFetchUserInfoQuery();

  useEffect(() => {
    refetchUserDetails();
  }, []);

  if (isUserInfoFetchLoading) return <LoadingSpinner />;
  else if (isUserInfoFetchSuccess && userDetails)
    return (
      <StyledPaper>
        <TitleTypography variant="h5">Personal Info</TitleTypography>

        <Box marginBottom={2} marginTop={2}>
          <Typography>First Name: {userDetails?.data?.name}</Typography>
        </Box>

        <Divider />

        <Box marginBottom={2} marginTop={2}>
          <Typography>Last Name: {userDetails?.data?.surname}</Typography>
        </Box>

        <Divider />
        <Box marginBottom={2} marginTop={2}>
          <Typography>Email: {userDetails?.data?.email}</Typography>
        </Box>
      </StyledPaper>
    );
  else return <div>Error fetching user information</div>;
};

export default PersonalInformation;
