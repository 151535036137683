import { Delete, GetApp, Visibility } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

interface ActionColWithViewButtonProps {
  viewButtonTitle: string;
  downloadButtonTitle: string;
  deleteButtonTitle: string;
  handleViewButtonClick: () => void;
  handleDeleteClick: any;
}

function ActionColWithViewButton({viewButtonTitle, downloadButtonTitle, deleteButtonTitle, handleViewButtonClick, handleDeleteClick }: ActionColWithViewButtonProps) {
  return (
    <Box>
      <IconButton color="primary" title={viewButtonTitle} onClick={handleViewButtonClick}>
          <Visibility />
      </IconButton>
      <IconButton color="success" title={downloadButtonTitle}>
        <GetApp />
      </IconButton>
      <IconButton color="error" title={deleteButtonTitle} onClick={handleDeleteClick}>
        <Delete />
      </IconButton>
    </Box>
  );
}

export default ActionColWithViewButton;
