import { Box, CircularProgress } from "@mui/material";
import styled from "styled-components";

export const LoadingContainer = styled(Box)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  &&& {
    width: 50px;
    height: 50px;
    color: #333;
    display: block;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
`;
export const Loading: React.FC = () => {
  return (
    <LoadingContainer>
      <StyledCircularProgress disableShrink />
    </LoadingContainer>
  );
};
