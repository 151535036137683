import { z } from "zod";
import { SalesInvoiceLineSchema } from "./salesInvoiceLine.validation";

export const createSalesInvoiceSchema = z.object({
  customer_id: z.string(), 
  amount_to_be_paid: z.string(),
  issued_date: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "Issue Date is required." };
      }
      return { message: defaultError };
    },
  }),

  due_date: z.coerce.date().optional(),
  productDescription: z.string().optional(),
  usePayedaInvoiceNumber: z.string(),
  subtotal_amount: z.number().gt(0),
  total_vat_amount: z.number().gte(0),
  total_discount_amount:z.number().gte(0),
  invoice_lines: z.array(SalesInvoiceLineSchema),

});

export const createFastSalesInvoiceSchema = z.object({
  customer_id: z.string(), 
  amount_to_be_paid: z.string(),
  issued_date: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "Issue Date is required." };
      }
      return { message: defaultError };
    },
  }),

  due_date: z.coerce.date().optional(),
  productDescription: z.string().optional(),

});