import { TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useState } from "react";
import { isValidTwoDecimalPrecision } from "../utils/numberPrecision";
import StyledTooltip from "./StyledTooltip";

interface AmountEditFieldProps {
  params: GridRenderEditCellParams;
  max?: number;
  preventEditCriteria?: boolean;
  preventEditErrorMessage?: string;
}

function AmountEditField({
  params,
  max,
  preventEditCriteria = false,
  preventEditErrorMessage = "",
}: AmountEditFieldProps) {
  const { id, value, field } = params;
  const apiRef = useGridApiContext();
  const [errorMessage, setErrorMessage] = useState("");

  const handleNumberInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    if (max !== undefined && newValue > max) {
      return;
    }
    if (isValidTwoDecimalPrecision(newValue.toString())) {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (preventEditCriteria) {
      setErrorMessage(preventEditErrorMessage);
    }
  };

  return (
    <StyledTooltip
      open={!!errorMessage}
      title={preventEditErrorMessage}
    >
      <TextField
        value={value.toString()}
        type="number"
        onChange={handleNumberInput}
        inputProps={{ min: 0, readOnly: preventEditCriteria }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </StyledTooltip>
  );
}

export default AmountEditField;
