
export const isValidTwoDecimalPrecision = (value: string): boolean =>
  /^\d*\.?\d{0,2}$/.test(value);

// The amount input handler ensures the amount entered has at most
// two decimal precision
export const handleNumberInput = (
  number: string,
) => {
  const parsedValue = parseFloat(number);

  if (isNaN(parsedValue) || parsedValue < 0) {
    return 0;
  }
  //if not in two decimal, change to two decimal precision
  if (!isValidTwoDecimalPrecision(parsedValue.toString())) {
    const value = parseFloat(parsedValue.toFixed(2));
    return value;
  }

  return parsedValue;
};
