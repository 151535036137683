import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Alert,
  MenuItem,
  FormControl,
  Typography,
  AlertTitle,
  IconButton,
  Snackbar,
  SnackbarOrigin,
  Box,
} from "@mui/material";
import DialogInput from "../Dialog";
import { Loading } from "./LoadingComponent";

import { IAddInvoiceToBasket, useAddInvoiceToBasketMutation, useGetBasketsByFinanceIdQuery } from "../../store/api/basketApi";
import { IPagination } from "../../types/customTypes";
import { MIQuerySearchParams } from "../../types/financeTypes";
import AlertDialog from "./AlertDialog";

interface IAddInvoiceToBasketDialogProps {
  financeId: string;
  invoiceId: string;
  financing_request_id: string;
  invoiceNumber: string;
  supplierName: string;
  icon: ReactElement;
}

function AddInvoiceToBasketDialog({ financeId, financing_request_id, invoiceId, invoiceNumber, supplierName, icon }: IAddInvoiceToBasketDialogProps) {
  const [addInvoiceToBasket, mutationResult] = useAddInvoiceToBasketMutation();

  const [basketSelected, setBasketSelected] = useState<string | null>(null);

  const [baskets, setbBaskets] = useState<
    {
      basketId: string;
      basketInfo: string;
      hasOffer: number;
    }[]
  >([]);

  const defaultPaginationModel: IPagination = {
    page: 0,
    pageSize: 10,
  };
  const [queryParams, setQueryParams] = useState<MIQuerySearchParams>({
    pageInfo: defaultPaginationModel,
    id: financeId,
  });

  interface State extends SnackbarOrigin {
    open: boolean;
  }
  const { data, isSuccess, isFetching, refetch } = useGetBasketsByFinanceIdQuery(queryParams);

  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const baskets: {
      basketId: string;
      basketInfo: string;
      hasOffer: number;
    }[] = [];
    let wantNew: boolean = true;
    if (data && data.length > 0 && isSuccess) {
      data.map((basket) => {
        if (basket.supplier === supplierName) {
          const invoiceExists = basket.invoicesNumber.includes(invoiceNumber);
          let basketInfo = `<span style="color: green">${basket.hasOffer ? "Has offer /" : ""}</span> Supplier Name: ${basket.supplier} / Total Amount: ${
            basket.requestedTotalAmount
          }`;
          if (invoiceExists) {
            basketInfo += `<span style="color: red">${" / " + invoiceNumber}</span>`;
          }
          baskets.push({
            basketId: basket.id.toString(),
            basketInfo,
            hasOffer: basket.hasOffer,
          });
        } else {
          wantNew = true;
        }
      });
    } else {
      wantNew = true;
    }

    if (wantNew) {
      setbBaskets([...baskets, { basketId: "-1", basketInfo: `Create New Basket`, hasOffer: 0 }]); //q -1
    } else {
      setbBaskets(baskets);
    }
  }, [data]);

  const { isError, error, data: newBasket, isLoading } = mutationResult;

  const [newBasketResponse, setBasketResponse] = useState<{
    isSuccess: boolean;
    message: string;
    basketId: string;
    basketDetailId: number;
  } | null>(null);

  let isAddBasketSuccess = mutationResult.isSuccess;

  useEffect(() => {
    if (isAddBasketSuccess)
      setBasketResponse({
        isSuccess: true,
        message: "Invoice added successfully to the basket",
        basketId: "",
        basketDetailId: 0,
      });
    if (isError) {
      const errorMessage = "Selected invoice already in the basket";
      setBasketResponse({
        isSuccess: false,
        message: errorMessage,
        basketId: "",
        basketDetailId: 0,
      });
    }
    handleCloseDialog();
  }, [isAddBasketSuccess, isError, error]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault(); // Prevent default form submission behavior

    const body: IAddInvoiceToBasket = {
      financeId,
      invoiceId,
      financing_request_id,
      shouldCreateNewBasket: basketSelected !== "-1" ? 0 : 1,
      selectedBasketId: basketSelected ? basketSelected : "", //we can discuss about it later
    };

    try {
      addInvoiceToBasket(body);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFieldChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setBasketSelected(value);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    refetch();

    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setBasketSelected(null);
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        style={{
          color: "#333",
          paddingBottom: "0.8rem",
        }}
        title="Add Invoice To Basket"
        onClick={handleOpenDialog}
      >
        {icon}
      </IconButton>
      {!isLoading ? (
        <AlertDialog
          title="Invoice Added"
          message={isAddBasketSuccess ? "Invoice added successfully to the basket" : "Error while adding invoice to the basket"}
          open={isAddBasketSuccess || isError}
          isError={isError ? true : false}
        />
      ) : (
        <Loading />
      )}

      <DialogInput
        open={isDialogOpen}
        onClose={handleCloseDialog}
        title="Add To Basket"
        content={
          <Container maxWidth="lg">
            <Typography gutterBottom sx={{ fontStyle: "italic" }}>
              * Indicates required fields
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} sx={{ marginTop: "5px" }}>
                <Grid item xs={12}>
                  <Alert icon={false} severity="success">
                    <AlertTitle>Invoice Number</AlertTitle>
                    {invoiceNumber}
                  </Alert>
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextField required label="Choose Basket*" defaultValue="" select fullWidth value={basketSelected} onChange={handleFieldChange()}>
                      {baskets.map((category, index) => (
                        <MenuItem
                          disabled={category.basketInfo.includes(invoiceNumber) || category.hasOffer ? true : false}
                          key={index}
                          value={category.basketId}
                        >
                          <span dangerouslySetInnerHTML={{ __html: category.basketInfo }} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={6} marginBottom="20px">
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        }
        onConfirmText="Confirm"
        onCancelText="Cancel"
        noDefaultButtons
      />
    </>
  );
}

export default AddInvoiceToBasketDialog;
