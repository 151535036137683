
export interface BaseInterface {
    isLoading: boolean;
    hasError: boolean;
    errorMessage: string;
}

export const baseInitialState: BaseInterface = {
    isLoading: false,
    hasError: false,
    errorMessage: "",
};


