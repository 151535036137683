import { z } from "zod";
import { StockCategoryType, SaleType } from "../types/zodTypes";

export const CreateProductSchema = z.object({
  productName: z.string().nonempty("Product name is required"),
  description: z.string().optional(),
  vatRate: z.number().min(0),
  openingStock: z.number().default(0),
  openingDate: z.coerce.date().optional(),
  saleType: z.enum(SaleType),
  purchasePrice: z.number().min(0),
  buyCurrency: z.string().optional(),
  sellPrice: z.number().min(0),
  sellCurrency: z.string().optional(),
  stockCategory: z.enum(StockCategoryType),
  subCategory: z.string().nonempty("Subcategory is required"),
  criticalStockLevel: z.number().default(0),
  unitOfMeasurement: z.string().optional(),
});

export const UpdateProductSchema = z.object({
  productName: z.string().nonempty().optional(),
  description: z.string().optional(),
  vatRate: z.number().min(0).optional(),
  openingStock: z.number().optional(),
  openingDate: z.coerce.date().optional(),
  saleType: z.enum(SaleType).optional(),
  purchasePrice: z.number().min(0).optional(),
  buyCurrency: z.string().optional(),
  sellPrice: z.number().min(0).optional(),
  sellCurrency: z.string().optional(),
  stockCategory: z.enum(StockCategoryType).optional(),
  subCategory: z.string().optional(),
  criticalStockLevel: z.number().optional(),
  unitOfMeasurement: z.string().optional(),
});
