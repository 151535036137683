import React, { useState } from "react";
import {
    Card,
    CardContent,
    Button,
    Grid,
    Stack,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
interface SearchFilterProps {
    onSearchInput: (searchInput: {
        startDate: string;
        endDate: string;
        receiptId: string;
    }) => void;
    receipt_id: string
}

function SearchDateFilter({ onSearchInput,receipt_id}: SearchFilterProps) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleSubmit = () => {
        const jsStartDate = new Date(startDate);
        const jsEndDate = new Date(endDate);

        const formattedStartDate = jsStartDate.toISOString().split('T')[0];
        const formattedEndDate = jsEndDate.toISOString().split('T')[0];
        onSearchInput({ startDate:formattedStartDate, endDate:formattedEndDate ,receiptId:receipt_id});
    };

    return (
        <Card sx={{ height: "100%", width: "95%", marginTop: "20px" }}>
            <CardContent>
                <Grid container sx={{ marginBottom: "20px" }}>
                    <Grid item sm={12} display="flex">
                        <Stack spacing={4} width={800}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={startDate}
                                    label="Start date"
                                    maxDate={endDate}
                                    onChange={(date: any) => {
                                        setStartDate(date);
                                    }}

                                />
                                <DatePicker
                                    value={endDate}
                                    label="End date"
                                    minDate={startDate}
                                    onChange={(date: any) => {
                                        setEndDate(date);
                                    }}
                                />
                            </LocalizationProvider>
                        </Stack>
                    </Grid>
                    <Grid item sm={12} mt={4}>
                        <Button color="secondary" variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>





    );
}

export default SearchDateFilter;