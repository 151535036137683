import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { useLazyFetchTeamInfoQuery, useLazyFetchUserInfoQuery } from "../store/api/infoApi";
import { selectAccessToken } from "../store/features/authenticationSlice";
import Layout from "./Layout";
import LoadingSpinner from "./LoadingSpinner";
import WaitingApprovalPage from "../pages/createBusiness/WaitingApproval";
import CreateBusinessPromptPage from "../pages/createBusiness/CreateBusinessPrompt";

const AuthenticatedRoutes = () => {
  const token = useSelector(selectAccessToken);

  const [
    fetchTeamInfo,
    { isLoading: isTeamInfoFetchLoading, isError: isTeamInfoFetchError, isSuccess: isTeamInfoFetchSuccess, error: teamFetchError, data: teamDetails },
  ] = useLazyFetchTeamInfoQuery();
  const [
    fetchUserInfo,
    { isLoading: isUserInfoFetchLoading, isError: isUserInfoFetchError, isSuccess: isUserInfoFetchSuccess, error: userFetchError, data: userDetails },
  ] = useLazyFetchUserInfoQuery();

  useEffect(() => {
    if (token) {
      fetchTeamInfo();
      fetchUserInfo();
    }
  }, [token]);

  // useEffect(() => {
  //   fetchTeamInfo();
  //   fetchUserInfo();
  // }, []);

  if (!token) {
    return <Navigate to="/login" />;
  } else if (isTeamInfoFetchLoading || isUserInfoFetchLoading) {
    return <LoadingSpinner />;
  } else if (isTeamInfoFetchSuccess && isUserInfoFetchSuccess && userDetails && teamDetails) {

    if (userDetails.data.team_type === "Finance") {
      return (
        <Layout>
          <Outlet />
        </Layout>
      );
    } else if (userDetails.data.team_type === "Admin") {
      return (
        <Layout>
          <Outlet />
        </Layout>
      );
    } else {
      if (teamDetails.data.approve !== 2) {
        return <CreateBusinessPromptPage />;
      } else if (teamDetails.data.awaitingReceivePaymentApproval === true) {
        return (
          <Layout>
            {" "}
            <WaitingApprovalPage />
          </Layout>
        );
      } else {
        return (
          <Layout>
            {" "}
            <Outlet />
          </Layout>
        );
      }
    }
  } else {
    return <LoadingSpinner />;
  }
};

export default AuthenticatedRoutes;
