import { TextField } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import StyledTooltip from "./StyledTooltip";

export function EditEmailField(params: GridRenderEditCellParams) {
  const { id, value, field } = params;
  const [errorMessage, setErrorMessage] = useState('')

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.trim();
    if (isValidEmail(newValue)) {
      params.api.setEditCellValue({ id, field, value: newValue });
      setErrorMessage('')
    }
    else{
      setErrorMessage('Email would be invalid')
    }
  };

  const handleBlur = () => {
    setErrorMessage('')
  }

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <TextField
        value={value}
        onChange={handleEmailInput}
        error={value !== "" && !isValidEmail(value)}
        helperText={
          value !== "" && !isValidEmail(value) ? "Invalid email address" : ""
        }
        onBlur={handleBlur}
      />
    </StyledTooltip>
  );
}

export default EditEmailField;
