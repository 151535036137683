import React,{ useState } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import SalesInvoiceLine from "./SalesInvoiceLine";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { salesInvoiceValues } from "../../../types/formValueType";
import { Grid, Typography } from "@mui/material";
import PageTitle from "../../../components/PageTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { zodResolver } from "@hookform/resolvers/zod";
import dayjs, { Dayjs } from "dayjs";
import CustomerSelect from '../../../components/CustomerSelect';
import { createSalesInvoiceSchema } from "../../../validation/salesInvoice.validationSchema";
import { sInvoiceDefaultValues } from "../../../types/formDefaultValues";
import { useAddNewAdvanceSalesInvoiceMutation } from "../../../store/api/salesInvoiceApi";

export default function SalesInvoice(): JSX.Element {
    const [issueDate, setIssuedDate] = useState<Dayjs>(dayjs());
    const [dueDate, setDueDate] = useState<Dayjs>(dayjs());
    const [selectedCustomer, setSelectedCustomer] = useState("");

    const invoiceFormValues = "advanceInvoiceValues";
  const [defaultInvoiceValues] = useState<salesInvoiceValues>(() => {
    const storedValues = window.sessionStorage.getItem(invoiceFormValues);
    return storedValues ? JSON.parse(storedValues) : sInvoiceDefaultValues;
  });
  const [addNewInvoice, addInvoiceMutationResult] = useAddNewAdvanceSalesInvoiceMutation();
    const {
        control,
        handleSubmit,
        setValue,
        register,
        watch,
        formState: { errors },
        clearErrors,
      } = useForm<salesInvoiceValues>({
        defaultValues: defaultInvoiceValues,
        resolver: zodResolver(createSalesInvoiceSchema),
      });

    const { fields: lines, append, remove } = useFieldArray({
        name: "invoice_lines",
        control,
    });




    const [formData, setFormData] = useState({
        usePayedaInvoiceNumber: "",
        issued_date: '',
        due_date: '',
        amount_to_be_paid: '',
        subtotal_amount: '',
        total_vat_amount: '',
        total_discount_amount: '0',
        customer: '',
        invoice_lines: [
            {
                product: '',
                unit_price: '',
                subtotal_amount: '',
                line_total_amount: '',
                line_vat_amount: '',
                quantity: 1,
                product_id: '',
                line_vat_percentage: ''
            }
        ]
    });

    const handleFormDataChange = (fieldName:any, value:any) => {
        setFormData(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const onSubmit = async (data: any) => {
        const response = await addNewInvoice(data)
    };


    const handleCustomerInput = (customerId: any) => {
        setSelectedCustomer(customerId);
    handleFormDataChange('customer', customerId)
    setValue("customer_id",customerId)

    };
    
    const addDefaultInvoiceLine = () => {
        append({
            product: "",
            unit_price: 0,
            subtotal_amount: 0,
            line_total_amount: 0,
            line_vat_amount: 0,
            quantity: 1,
            product_id: "",
            line_vat_percentage: 0,
        });
    };

    return (
        <Container>
            <PageTitle title="Create Sales Invoice" />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item xs={12} sm={6}>
                            <Controller
                                name="issued_date"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <DatePicker
                                        value={issueDate}
                                        label="Issue date *"
                                        onChange={(date) => {
                                            const isoDateString = date ? date : "";
                                            onChange(isoDateString);
                                            setIssuedDate(dayjs(date));
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Controller
                                name="due_date"
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <DatePicker
                                        value={dueDate}
                                        label="Due date *"
                                        onChange={(date) => {
                                            const isoDateString = date ? date : "";
                                            onChange(isoDateString);
                                            setDueDate(dayjs(date));
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item xs={10} >
                    <CustomerSelect
    onChange={handleCustomerInput}
    register={register}
    error={errors}
    selectedCustomerValue={selectedCustomer}
    setSelectedCustomerValue={setSelectedCustomer}
/>

                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant="h6">Invoice Lines</Typography>
                        {lines.map((line, index) => (
    <React.Fragment key={index}>
      <Grid item xs={12}>
        <SalesInvoiceLine
          index={index}
          register={register}
          error={errors}
          remove={remove}
          setValue={setValue}
          clearError={clearErrors}
         
        />
      </Grid>
      {index < lines.length - 1 && (
        <Grid item xs={12} style={{ marginBottom: '16px' }} />
      )}
    </React.Fragment>
  ))}
                        </Grid>
                        <Grid item xs={12} >
                        <Button
                            variant="contained"
                            color="primary"
                            type="button"
                            onClick={addDefaultInvoiceLine}
                            startIcon={<AddIcon />}
                        >
                            Add Invoice Line
                        </Button>
                        </Grid>
                  
                </Grid>
                <Grid container justifyContent="flex-end">
  <Grid item xs={12} sm={2}>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
      Subtotal:
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 0 }}>
      Discount Total:
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
      Vat total:
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
      Total Due:
    </Typography>
  </Grid>
  <Grid item xs={12} sm={1}>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 2 }}>
      {watch("subtotal_amount")}
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 2 }}>
      {watch("total_discount_amount")}
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 1 }}>
      {watch("total_vat_amount")}
    </Typography>
    <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 1 }}>
      {watch("amount_to_be_paid")}
    </Typography>
  </Grid>
</Grid>

                <LoadingButton type="submit" variant="contained" color="primary">
                    Submit
                </LoadingButton>
            </form>
        </Container>
    );
}
