import { Snackbar } from "@mui/base";
import { Alert, AlertColor } from "@mui/material";

interface SnackbarProps {
  open: boolean;
  handleClose: () => void;
  severity: AlertColor;
  message: string;
  width?: string;
}

function AlertSnackbar({ open, handleClose, severity, message, width }: SnackbarProps) {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: width ?? "50vw", marginBottom: "20px" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default AlertSnackbar;
