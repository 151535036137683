import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CenteredContainer from "../components/CenteredContainer";
import {
  useVerifyTokenMutation,
} from "../store/api/authenticationApi";

export type TokenValue = {
  token: string;
};

function PasswordVerificationCode() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const { register, handleSubmit, formState, clearErrors } =
    useForm<TokenValue>({
      defaultValues: {
        token: "",
      },
    });
  const { errors: formFieldError } = formState;
  const [verifyToken, { isSuccess, isError, error }] = useVerifyTokenMutation();

  const handletokenInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const token = event.target.value;
    if (token.length <= 6) {
      setToken(token);
    }
    clearErrors();
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const onSubmit = (token: TokenValue) => {
    verifyToken({ email, ...token });
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(`/create-new-password/?email=${email}&token=${token}`);
    } else if (isError) {
      const err: any = error;

      const errorMsg = err.data?.message
        ? err.data?.message
        : "OTP verification failed";

      setErrorMessage(errorMsg);
    }
  }, [isSuccess, isError]);

  return (
    <CenteredContainer>
      <Box textAlign="center" width="500px">
        <Typography variant="h4" fontWeight="bold">
          Enter Verification Code
        </Typography>
        <Typography variant="h6" mt={4} mb={5}>
          Enter the code we sent to your email
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="Token"
            value={token}
            fullWidth
            type="number"
            {...register("token", {
              required: "Please enter six digit token",
              validate: (value) =>
                value.length === 6 || "Token should be six digits",
            })}
            error={!!formFieldError.token}
            helperText={formFieldError.token?.message}
            onChange={handletokenInput}
          />
          {errorMessage && (
            <Alert severity="error" sx={{ marginTop: "20px" }}>
              {errorMessage}
            </Alert>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ marginTop: "20px", width: "100%" }}
          >
            Verify
          </Button>
        </form>
      </Box>
    </CenteredContainer>
  );
}

export default PasswordVerificationCode;
