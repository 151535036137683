import InventoryIcon from "@mui/icons-material/Inventory";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import DescriptionIcon from "@mui/icons-material/Description";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

export type MenuItemInfetface = {
  text: string;
  icon: JSX.Element;
  link: string;
};

const ListItems: MenuItemInfetface[] = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    link: "/",
  },
  {
    text: "Supplier",
    icon: <InventoryIcon />,
    link: "/supplier-tabs",
  },
  {
    text: "Customer",
    icon: <PeopleIcon />,
    link: "/manageCustomer",
  },
  {
    text: "SubCategory",
    icon: <CategoryIcon />,
    link: "/manageSubcategory",
  },
  {
    text: "Stock",
    icon: <ProductionQuantityLimitsIcon />,
    link: "/manageStock",
  },
  {
    text: "Puchase Invoice",
    icon: <DescriptionIcon />,
    link: "/manageInvoice",
  },

  {
    text: "Sales Invoice",
    icon: <TextSnippetIcon />,
    link: "/manageSalesInvoice",
  },

  {
    text: "Factoring Requests",
    icon: <TextSnippetIcon />,
    link: "factoringRequests",
  },

  {
    text: "Bank Account",
    icon: <AccountBalanceWalletIcon />,
    link: "/createBankAccount",
  },
  {
    text: "Payments Received",
    icon: <ReceiptLongIcon />,
    link: "/outgoingReceipts",
  },
  {
    text: "Payments Made",
    icon: <ReceiptIcon />,
    link: "/incomingReceipts",
  },
  {
    text: "Settings",
    icon: <SettingsIcon />,
    link: "/settings",
  },
];

export default ListItems;
