import { Box, Checkbox, IconButton } from "@mui/material";
import { GridCellParams, GridColDef, GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BasketDetailsTable from "../../../components/finance/BasketDetailsTable";
import { IBasketDetailsTable } from "../../../types/financeTypes";
import CreateOfferDialog from "../../../components/finance/CreateOfferDialog";
import { useEffect, useState } from "react";
import CreateNewOfferPageHeader from "../../../components/finance/CreateNewOfferPageHeader";
import { useGetBasketByIdQuery, useRemoveBasketDetailsMutation } from "../../../store/api/basketApi";
import { Loading } from "../../../components/finance/LoadingComponent";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import BookmarkRemoveIcon from "@mui/icons-material/BookmarkRemove";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { Link } from "react-router-dom";
import { salesInvoiceApiBase } from "../../../store/env";
import { GetApp } from "@mui/icons-material";

export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`;

const ViewBasketDetails: React.FC = () => {
  const { basketId, supplierName, hasOffer } = useParams();

  const [rows, setRows] = useState<IBasketDetailsTable[]>([]);
  const [removeInvoiceFromBasket, mutationResult] = useRemoveBasketDetailsMutation();
  const [selectedRows, setSelectedRows] = useState<IBasketDetailsTable[]>([]);
  const [supplierId, setSupplierId] = useState<number | null>(null);
  const { isLoading } = mutationResult;
  const { data, isSuccess, isFetching, refetch } = useGetBasketByIdQuery(parseInt(basketId!) as number);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [totalAmount, setTotalAmount] = useState(0);
  useEffect(() => {
    if (isSuccess && data) {
      const basketDetails = data.basket_details;

      let tableStructure: IBasketDetailsTable[] = [];
      if (basketDetails && basketDetails.length > 0) {
        tableStructure = basketDetails.map((basketDetail) => {
          const financing_request = basketDetail.financing_request;
          const invoice = financing_request.invoice;
          return {
            id: invoice.id,
            amount_to_be_paid: parseFloat(invoice.amount_to_be_paid.toString()),
            dueDate: new Date(invoice.due_date),
            issueDate: new Date(invoice.issued_date),
            invoiceNumber: invoice.invoice_number,
            basketDetailId: basketDetail.id,
            bidOpensAt: new Date(financing_request.bid_opens_at),
            bidClosesAt: new Date(financing_request.bid_closes_at),
            invoiceDocId: invoice.invoice_document_id,
          };
        });
      }

      setRows(tableStructure);

      let sum = 0;
      // eslint-disable-next-line array-callback-return
      tableStructure.map((row) => {
        sum += row.amount_to_be_paid;
      });
      setTotalAmount(sum);
      sum = 0;

      if (Array.isArray(basketDetails) && basketDetails.length > 0 && basketDetails[0]) {
        const basket = basketDetails[0];

        if (basket && basket.financing_request) setSupplierId(basket.financing_request.supplier_id);
      }
    }
  }, [data, isSuccess]);

  useEffect(() => {
    console.log(selectedRows);
  }, [selectedRows]);

  const toggleRowSelection = (basketDetailId: number) => {
    const isSelected = selectedRows.some((row) => row.basketDetailId === basketDetailId);

    if (isSelected) {
      setSelectedRows((prevRows) => prevRows.filter((row) => row.basketDetailId !== basketDetailId));
    } else {
      const selectedRow = rows.find((row) => row.basketDetailId === basketDetailId);
      if (selectedRow) {
        setSelectedRows((prevRows) => [...prevRows, selectedRow]);
      }
    }
  };

  const removeInvoiceFromBasketHandler = (basketDetailId: number) => {
    const removeInvoiceBody = { basket_detail_ids: [basketDetailId] };
    removeInvoiceFromBasket(removeInvoiceBody);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, basketDetailId: number) => {
    toggleRowSelection(basketDetailId);
  };

  const removeSelectedRows = () => {
    const basketDetailIds = selectedRows.map((row) => row.basketDetailId);
    const removeInvoiceBody = { basket_detail_ids: basketDetailIds };
    removeInvoiceFromBasket(removeInvoiceBody);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedRows([...rows]);
    } else {
      setSelectedRows([]);
    }
  };

  const handleDownload = async (invoiceDocId: string) => {
    const confirmed = window.confirm("Are you sure you want to download the file?");
    if (confirmed) {
      const url = salesInvoiceApiBase + `/v1/invoice/generate/${invoiceDocId}`;
      window.open(url, "_blank");
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "salesInvoice.pdf";
      downloadLink.click();
    }
  };

  const columns: GridColDef[] = [
    {
      field: "checkbox",
      headerName: "",
      width: 80,
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          onChange={handleSelectAllClick}
          indeterminate={selectedRows.length > 0 && selectedRows.length < rows.length}
          checked={selectedRows.length === rows.length}
        />
      ),
      renderCell: (params: GridCellParams) => (
        <Checkbox
          checked={selectedRows.some((row) => row.basketDetailId === params.row.basketDetailId)}
          onChange={(event) => handleCheckboxChange(event, params.row.basketDetailId)}
        />
      ),
    },
    { field: "invoiceNumber", headerName: "Invoice Number", flex: 1, align: "center", headerAlign: "center" },
    {
      field: "bidOpensAt",
      headerName: "Bid Opens At",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "bidClosesAt",
      headerName: "Bid Closes At",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      align: "center",
      headerAlign: "center",
    },
    {
      field: "issueDate",
      headerName: "Issued Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
      align: "center",
      headerAlign: "center",
    },
    { field: "amount_to_be_paid", headerName: "Amount", flex: 1, align: "center", headerAlign: "center", type: "number" },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      align: "center",
      headerAlign: "center",

      renderCell: (params: GridCellParams) => (
        <>
          <IconButtonStyled color="primary" title="Download Invoice Document" onClick={() => handleDownload(params.row.invoiceDocId)}>
            <GetApp />
          </IconButtonStyled>

          <IconButtonStyled
            color="primary"
            title="Remove Invoice From The Basket"
            onClick={() => {
              removeInvoiceFromBasketHandler(params.row.basketDetailId);
            }}
          >
            <RemoveCircleOutlineIcon />
          </IconButtonStyled>
        </>
      ),
    },
  ];

  return (
    <Box>
      <CreateNewOfferPageHeader
        title=" Invoices In Basket"
        offerDialog={
          <CreateOfferDialog
            hasOffer={+hasOffer!}
            supplierName={supplierName!}
            supplierId={supplierId!}
            totalAmount={totalAmount}
            basketId={parseInt(basketId!)}
          />
        }
        removeButton={
          <IconButtonStyled color="error" title="Remove Selected Invoices From The Basket" onClick={removeSelectedRows}>
            <BookmarkRemoveIcon style={{ color: "red" }} />
          </IconButtonStyled>
        }
      />
      {isFetching || isLoading ? <Loading /> : <BasketDetailsTable rows={rows} columns={columns} />}
    </Box>
  );
};

export default ViewBasketDetails;
