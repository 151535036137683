import { ReactNode, useState } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import SideNavBar from "./Sidebar";

interface Props {
  children: ReactNode;
}

const MainContent = styled.div<{ $isExpanded?: boolean }>`
  margin-left: ${(props) => (props.$isExpanded ? "300px" : "85px")};
  transition: margin-left 0.4s;
`;

const ChildrenContainer = styled.div`
  margin:50px;
`;

const Layout = ({ children }: Props): JSX.Element => {
  const [isExpanded, setExpandState] = useState(false);

  const toggleSidebar = () => {
    setExpandState(!isExpanded);
  };
  return (
    <>
      <SideNavBar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
      <MainContent $isExpanded={isExpanded}>
        <Navbar isExpanded={isExpanded} toggleSidebar={toggleSidebar} />
        <ChildrenContainer>{children}</ChildrenContainer>
      </MainContent>
    </>
  );
};

export default Layout;
