import React, { useEffect, useState } from "react";
import { IPagination } from "../../../types/customTypes";
import BasketTable from "../../../components/finance/MTable";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styled from "styled-components";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import { useGetBasketsByFinanceIdQuery } from "../../../store/api/basketApi";
import { Loading } from "../../../components/finance/LoadingComponent";
import { IBasketTable, MIQuerySearchParams } from "../../../types/financeTypes";
import SimpleHeader from "../../../components/finance/SimpleHeader";
import { useFetchUserInfoQuery } from "../../../store/api/infoApi";
import LoadingSpinner from "../../../components/LoadingSpinner";

export const IconButtonStyled = styled(IconButton)`
  &&& {
    color: #333;
  }
`;

const BasketManagement: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "supplier",
      type: "string",
      headerName: "Supplier",
      width: 300,
    },
    {
      field: "totalInvoiceInBasket",
      headerName: "Total Invoice In Basket",
      type: "number",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      type: "number",
      width: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "hasOffer",
      headerName: "Has Offer",
      type: "boolean",
      width: 100,
    },
    {
      headerAlign: "center",
      align: "center",
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 300,
      renderCell: (params: GridCellParams) => (
        <IconButtonStyled color="primary" title="View Basket Details">
          <Link to={`/viewBasket/${params.row.id}/${params.row.supplier}/hasOffer/${params.row.hasOffer}`} style={{ textDecoration: "none" }}>
            <VisibilityIcon />
          </Link>
        </IconButtonStyled>
      ),
    },
  ];
  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails, refetch: refetchUserDetails } = useFetchUserInfoQuery();

  const [financeId, setFinanceId] = useState<string>(userDetails.data.team_id ? userDetails.data.team_id : "");

  const [baskets, setbBaskets] = useState<IBasketTable[]>([]);

  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<MIQuerySearchParams>({
    pageInfo: paginationModel,
    id: userDetails.data.team_id,
  });
  useEffect(() => {
    if (isUserInfoFetchSuccess && userDetails) setFinanceId(userDetails.data.team_id);
  }, [isUserInfoFetchSuccess, userDetails]);

  const { data, isSuccess, isFetching, refetch } = useGetBasketsByFinanceIdQuery(queryParams);
  useEffect(() => {
    refetch();
    refetchUserDetails();
  }, []);

  useEffect(() => {
    refetch();
    setQueryParams({
      pageInfo: paginationModel,
      id: financeId,
    });
  }, [financeId]);

  useEffect(() => {
    if (isSuccess && data.length > 0) {
      const tableStructure: IBasketTable[] = data.map((basket) => ({
        id: basket.id,
        hasOffer: basket.hasOffer,
        supplier: basket.supplier,
        totalAmount: basket.requestedTotalAmount,
        totalInvoiceInBasket: basket.total_invoices_in_basket,
      }));

      setbBaskets(tableStructure);
    }
  }, [data, isSuccess]);
  if (isUserInfoFetchLoading) return <LoadingSpinner />;
  else if (isUserInfoFetchSuccess && userDetails)
    return (
      <Box>
        <SimpleHeader title="Baskets"></SimpleHeader>
        {isFetching ? <Loading /> : <BasketTable rows={baskets} columns={columns} />}
      </Box>
    );
  else return <div>Error fetching user information</div>;
};

export default BasketManagement;
