import { Outlet} from "react-router-dom";
import { useEffect, useRef } from "react";
import { useRefreshTokenMutation } from "../store/api/authenticationApi";
import usePersist from "../hooks/usePersist";
import { useSelector } from "react-redux";
import {
  selectAccessToken,
  selectRefreshToken,
} from "../store/features/authenticationSlice";

const PersistLogin = () => {
  const [persist] = usePersist();
  const accessToken = useSelector(selectAccessToken);

  const currentRefreshToken = useSelector(selectRefreshToken);

  const effectRan = useRef(false);

  const [refreshToken] = useRefreshTokenMutation();

  useEffect(() => {
    // React 18 Strict Mode
    const verifyRefreshToken = async () => {
      try {
        await refreshToken({
          refresh_token: currentRefreshToken,
        }).unwrap();
      } catch (err) {
        console.error(err);
      }
    };

    if (!accessToken && persist) verifyRefreshToken();

    return () => {
      effectRan.current = true;
    };
    // eslint-disable-next-line
  }, []);

  return <Outlet />;
};

export default PersistLogin;
