import CenteredContainer from "../components/CenteredContainer";
import SuccessAlert from "../components/SuccessAlert";

function NewPasswordSuccess() {
  return (
    <CenteredContainer>
      <SuccessAlert
        successMessage="Your password has been successfully created"
        buttonText="Login to your account"
        linkPath="/login"
      />
    </CenteredContainer>
  );
}

export default NewPasswordSuccess;
