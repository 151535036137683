import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CreateSalesInvoice from "./createSalesInvoice/FastSalesInvoice";
import InvoiceFormComponent from "./createSalesInvoice/AdvanceSalesInvoice";
//import AdvancedSalesInvoice from "./createSalesInvoice/AdvanceSalesInvoice";

const CreateSalesInvoiceTab: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        sx={{ marginBottom: "10px" }}
      >
        <Tab label="Quick invoice" />
        <Tab label="Advance Invoice" />
      </Tabs>
      {currentTab === 0 && <CreateSalesInvoice />}
      {currentTab === 1 && <InvoiceFormComponent />}
    </Box>
  );
};

export default CreateSalesInvoiceTab;