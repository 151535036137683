import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Grid, IconButton, CircularProgress, Button, TextField, Alert } from "@mui/material";
import { Delete, Visibility, GetApp, MailLockOutlined, Add } from "@mui/icons-material";
import { ISalesInvoice, IPagination, IQuerySearchParams, IQuerySearchParamsInvoice } from "../../../types/customTypes";
import DialogInput from "../../../components/Dialog";
import { useDeleteSalesInvoiceMutation, useGetSalesInvoicesQuery, useSendPaymentLinkWithEmailMutation } from "../../../store/api/salesInvoiceApi";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import PageManagementGrid from "../../../components/DataGrid";
import FilterButton from "../../../components/ActionButton";
import SearchFilterStatus from "../../../components/FilterStatus";
import ReceiptIcon from "@mui/icons-material/Receipt";
import QrCodeIcon from "@mui/icons-material/QrCode";

import { Dialog, DialogContent } from "@mui/material";
import { base_url } from "../../../environment";
import QRCode from "qrcode.react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import UpdateFinancialInfo from "../../settings/EditFinancialInfoDialog";
import { useFetchTeamInfoQuery } from "../../../store/api/infoApi";
import BidRangeDateDialog from "./Financing/BidRangeDateDialog";
import SubmitAlertDialog from "./Financing/SubmitAlertDialog";
import { salesInvoiceApiBase } from "../../../store/env";
import CustomerSelect from "../../../components/CustomerSelect";
import { useForm } from "react-hook-form";
import AlertSnackbar from "../../../components/Snackbar";
import { useCreateFinancingRequestMutation, useGetFactoringInvoicesQuery } from "../../../store/api/handleFactoringRequest";
import { useSendBusinessDetailsMutation } from "../../../store/api/createBusinessApi";
import PageTitle from "../../../components/PageTitle";

function SalesInvoiceList() {
  const {
    formState: { errors },
    register,
  } = useForm();

  const { data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();

  const [salesInvoices, setSalesInvoices] = useState<ISalesInvoice[]>([]);
  const [selectedSalesInvoices, setSelectedSalesInvoices] = useState<[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [deleteRowId, setDeleteRowId] = useState<string | null>(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParamsInvoice>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
    status: "Awaiting Payment, Paid, Canceled, Factoring Requested",
    selectedCustomer: "all_customers",
  });

  const [showFilter, setShowFilter] = useState(false);
  const { data, isSuccess, isFetching, refetch: refetchSalesInvoices } = useGetSalesInvoicesQuery(queryParams);
  const { refetch: refetchFinancingInvoices } = useGetFactoringInvoicesQuery({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
    status: "Factoring Requested",
    selectedCustomer: "all_customers",
  });

  const [deleteInvoice] = useDeleteSalesInvoiceMutation();
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();

  const [phoneNum, setPhoneNum] = useState("");
  const [isQRDialogOpen, setIsQRDialogOpen] = useState(false);
  const [qrContent, setQRContent] = useState<string>("");
  const [qrDialogContent, setQRDialogContent] = useState<JSX.Element | null>(null);
  const [isWhatsAppDialogOpen, setIsWhatsAppDialogOpen] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [invoiceURL, setInvoiceURL] = useState<string>("");

  const [showUpdateFinancialInfoDialog, setShowUpdateFinancialInfoDialog] = useState(false);

  const [showBidRangeDialog, setShowBidRangeDialog] = useState(false);
  const [showSubmitAlertDialog, setShowSubmitAlertDialog] = useState(false);

  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [createFinancingClicked, setCreateFinancingClicked] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [wholePaginatedData, setWholePaginatedData] = useState<ISalesInvoice[]>([]);
  const [selectedInvoiceData, setSelectedInvoiceData] = useState<ISalesInvoice[]>([]);

  const [createFinancingRequest, mutationResult] = useCreateFinancingRequestMutation();
  const [sendPaymentLinkMutation, paymentLinkMutationResult] = useSendPaymentLinkWithEmailMutation();

  const { isSuccess: isFinancigSuccess, isError, error } = mutationResult;
  const [errorMessage, setErrorMessage] = useState("");

  console.log("mutation", mutationResult);
  useEffect(() => {
    refetchTeamDetails();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSalesInvoices(data.data);
      setWholePaginatedData([...salesInvoices, ...data.data]);
      setTotalItems(data.total_items);
    }
  }, [isSuccess, data]);

  const handleDeleteClick = (id: string) => () => {
    setDeleteRowId(id);
    setIsDeleteDialogOpen(true);
  };
  const handleSendPaymentLink = async () => {
    if (selectedSalesInvoices.length === 0) {
      alert("Please select at least one invoice to send payment link.");
      setSuccessMessage("Payment links sent successfully to selected customers.");
      setShowSucessbar(true);
      return;
    }
    console.log("selected", selectedSalesInvoices);
    const invoiceIds = selectedSalesInvoices;

    try {
      const response = await sendPaymentLinkMutation({
        // Gönderilecek faturaların kimliklerini veri olarak gönderin.
        invoice_ids: invoiceIds,
      });

      console.log("Payment link sent successfully:", response);

      alert("Payment links sent successfully to selected customers.");
    } catch (error) {
      console.error("Error sending payment link:", error);
      alert("An error occurred while sending payment link. Please try again.");
    }
  };
  useEffect(() => {
    if (isFinancigSuccess) {
      console.log("are you here");
      setErrorMessage("");
      handleCloseBidRangeDialog();
      setErrorMessage("");
      setShowBidRangeDialog(false);
      setSuccessMessage("Successfully requested factoring");
      setShowSucessbar(true);
      refetchSalesInvoices();
      refetchFinancingInvoices();
    }
    if (isError) {
      const err: any = error;
      const errMsg = err.data?.message || "An error occured. Please try again";
      setErrorMessage(errMsg);
    }
  }, [isSuccess, isError, error, isFinancigSuccess]);

  const handleDelete = async () => {
    if (deleteRowId) {
      const requestData = {
        invoice_ids: [deleteRowId],
      };

      setIsDeleteDialogOpen(false);
      setIsWaiting(true);

      try {
        await deleteInvoice(requestData);
        setIsWaiting(false);
        setSalesInvoices((oldSalesInvoices) => oldSalesInvoices.filter((s) => s.id !== deleteRowId));
      } catch (err) {
        setIsWaiting(false);
      }
    }
  };

  const handleAddClick = () => {
    navigate("/createSalesInvoice");
  };
  const handleCloseSnackbar = () => {
    successMessage && setShowSucessbar(false);
    setSuccessMessage("");
  };
  const handleRequestFinancing = () => {
    if (createFinancingClicked) {
      setShowBidRangeDialog(true);
    }
    // check if financial info provided
    if (
      !teamDetails?.data?.financial_info?.number_of_employee ||
      !teamDetails?.data?.financial_info?.year ||
      !teamDetails?.data?.financial_info?.turnover_amount
    ) {
      setShowUpdateFinancialInfoDialog(true);
    } else {
      setShowFilter(true);
      setCreateFinancingClicked(true);
    }
  };

  const handleCloseUpdateFinancialInfoDialog = () => {
    setShowUpdateFinancialInfoDialog(false);
  };
  const handleClouseSubmitAlertDialog = () => {
    setShowSubmitAlertDialog(false);
  };

  const handleCloseBidRangeDialog = () => {
    setErrorMessage("");

    setShowBidRangeDialog(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: selectedCustomer,
    });
  };

  const handleCustomerInput = (customerId: any) => {
    setSelectedCustomer(customerId);
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: customerId,
    });
  };
  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleFilterChange = (selectedFilter: any) => {
    setQueryParams({
      ...queryParams,
      selectedCustomer: selectedCustomer,
      status: selectedFilter,
    });
  };
  const [totalAmount, setTotalAmount] = useState(0);

  const handleRowSelection = (ids: any) => {
    const selectedRowsData = ids.map((id: any) => wholePaginatedData.find((row) => row.id === id));
    console.log("selected", selectedRowsData);
    let selectedTotalAmount = 0;
    selectedRowsData.forEach((row: any) => {
      const amountString = row.amount.replace(/,/g, "");
      const amount = parseFloat(amountString);
      selectedTotalAmount += amount;
    });
    setTotalAmount(selectedTotalAmount);
    setSelectedSalesInvoices(ids);
    setSelectedInvoiceData(selectedRowsData);
  };

  const handleDownload = async (id: string) => {
    const confirmed = window.confirm("Are you sure you want to download the file?");
    if (confirmed) {
      const url = salesInvoiceApiBase + `/v1/invoice/generate/${id}`;
      window.open(url, "_blank");
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "salesInvoice.pdf";
      downloadLink.click();
    }
  };

  const handleGenerateQR = (row: any) => {
    const content = `https://dev2.payeda.net/checkout?id=${row.id}`;
    setPhoneNum(row.customer_phone);
    setQRContent(content);
    const qrSize = 256;
    const qrCodeComponent = <QRCode value={content} size={qrSize} />;

    setIsQRDialogOpen(true);
    setQRDialogContent(qrCodeComponent);
    setInvoiceURL(`https://dev2.payeda.net/checkout?id=${row.id}`);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrContent);
  };

  const openWhatsAppDialog = () => {
    setIsWhatsAppDialogOpen(true);
    setWhatsAppNumber(phoneNum || "");
    setWhatsAppMessage("Click here to pay your invoice " + invoiceURL);
  };
  const handleSendWhatsAppMessage = () => {
    if (!whatsAppNumber) {
      alert("Phone number cannot be empty!");
      return;
    }

    const url = `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${encodeURIComponent(whatsAppMessage)}`;
    window.open(url, "_blank");
    setIsWhatsAppDialogOpen(false);
  };

  function getStatusColor(status: string): string {
    switch (status) {
      case "Awaiting Payment":
        return "rgba(255, 255, 0, 0.5)";
      case "Paid":
        return "rgba(0, 255, 0, 0.5)";
      case "Canceled":
        return "rgba(255, 0, 0, 0.5)";
      case "Factoring Requested":
        return "rgb(255, 165, 40)";
      default:
        return "black";
    }
  }

  function getAmountColor(status: string): string {
    switch (status) {
      case "Awaiting Payment":
        return "#91572b";
      case "Paid":
        return "green";
      case "Canceled":
        return "red";
      case "Factoring Requested":
        return "orange";
      default:
        return "orange";
    }
  }

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: showFilter ? 150 : 200,
      renderCell: (params: any) => <div style={{ backgroundColor: getStatusColor(params.row.status) }}>{params.value}</div>,
    },
    {
      field: "issued_date",
      headerName: "Issued Date",
      width: showFilter ? 150 : 250,
      valueFormatter: (params: any) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: showFilter ? 150 : 250,
      valueFormatter: (params: any) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "invoice_number",
      headerName: "Invoice No",
      width: showFilter ? 150 : 250,
    },
    {
      field: "customer_name",
      headerName: "Customer",
      width: showFilter ? 150 : 250,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: showFilter ? 150 : 250,
      renderCell: (params: any) => (
        <div style={{ color: getAmountColor(params.row.status) }}>
          {params.value} {params.row.currency}
        </div>
      ),
    },
  ];

  const actions = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params: any) => {
      return (
        <div>
          <Link
            to="/viewSalesInvoiceDetails"
            state={{ salesInvoiceDetails: salesInvoices, selectedInvoiceId: params.row.id }}
            color="primary"
            title="View Sales Invoice"
          >
            <IconButton>
              <Visibility />
            </IconButton>
          </Link>
          <IconButton color="success" title="Download" onClick={() => handleDownload(params.row.id)}>
            <GetApp />
          </IconButton>
          {params.row.status === "Awaiting Payment" && (
            <>
              <IconButton color="info" title="Generate QR Code" onClick={() => handleGenerateQR(params.row)}>
                <QrCodeIcon />
              </IconButton>
              <IconButton color="error" title="Delete Sales Invoice" onClick={handleDeleteClick(params.row.id)}>
                <Delete />
              </IconButton>
            </>
          )}
        </div>
      );
    },
  };

  const handleClearFilter = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
      selectedCustomer: "all_customers",
    });
    setSelectedCustomer("");
  };

  return (
    <Box sx={{ height: "50vh", width: "100%" }}>
      {successMessage && <AlertSnackbar open={showSuccessbar} handleClose={handleCloseSnackbar} severity="success" message={successMessage} />}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <PageTitle title={"Sales Invoices"} />
        </Grid>
        <Grid item md={8} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={3} xs={6}>
              <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
            </Grid>
            <Grid item md={3} xs={6}>
              <Button
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "yellow",
                  color: "black",
                  fontWeight: "bold",
                  borderRadius: "5px",
                  textTransform: "none",
                }}
                onClick={handleSendPaymentLink}
              >
                <MailLockOutlined sx={{ marginRight: "5px" }} />
                Send Mail
              </Button>
            </Grid>
            <Grid item md={4} xs={6}>
              <Button fullWidth color={createFinancingClicked ? "primary" : "secondary"} variant="contained" onClick={handleRequestFinancing}>
                {createFinancingClicked ? "Apply Financing on Selected Invoices" : "Request Factoring"}
              </Button>
            </Grid>
            <Grid item md={2} xs={6} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
              <Button fullWidth color="secondary" variant="contained" startIcon={<Add />} onClick={handleAddClick}>
                Create New
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <br />
      <Dialog open={isQRDialogOpen} onClose={() => setIsQRDialogOpen(false)}>
        <DialogContent>
          {qrDialogContent}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
            <Button variant="contained" color="secondary" onClick={copyToClipboard}>
              Copy
            </Button>
            <div style={{ width: "10px" }}></div>
            <Button size="small" color="secondary" variant="contained" startIcon={<WhatsAppIcon />} onClick={() => openWhatsAppDialog()}>
              Whatsapp
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <DialogInput
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        onConfirm={handleDelete}
        title="Delete Sales Invoice?"
        content="Are you sure you want to delete this customer? This action cannot be undone."
      />
      <Dialog open={isWaiting}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            p: 2,
          }}
        >
          <CircularProgress />
          <p>Please wait while your operation is being processed..</p>
        </Box>
      </Dialog>
      <Dialog open={isWhatsAppDialogOpen} onClose={() => setIsWhatsAppDialogOpen(false)}>
        <DialogContent>
          <TextField
            label="Phone Number"
            value={whatsAppNumber}
            onChange={(e) => setWhatsAppNumber(e.target.value)}
            fullWidth
            margin="normal"
            helperText={!whatsAppNumber && "Phone number required!"}
          />
          <TextField
            label="Message"
            value={whatsAppMessage}
            onChange={(e) => setWhatsAppMessage(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <Button variant="contained" color="secondary" onClick={handleSendWhatsAppMessage}>
            Send
          </Button>
        </DialogContent>
      </Dialog>

      {showUpdateFinancialInfoDialog && (
        <UpdateFinancialInfo openDialog={showUpdateFinancialInfoDialog} handleCloseDialog={handleCloseUpdateFinancialInfoDialog} onSuccess={openSuccessbar} />
      )}
      {showBidRangeDialog && (
        <BidRangeDateDialog
          openDialog={showBidRangeDialog}
          handleCloseDialog={handleCloseBidRangeDialog}
          onSuccess={openSuccessbar}
          setSuccessMessage={setSuccessMessage}
          selectedInvoiceData={selectedInvoiceData}
          createFinancingRequest={createFinancingRequest}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}
      {showSubmitAlertDialog && (
        <SubmitAlertDialog openDialog={showSubmitAlertDialog} handleCloseDialog={handleClouseSubmitAlertDialog} onSuccess={openSuccessbar} />
      )}
      <Grid container>
        {showFilter && (
          <>
            <Grid item sm={12} md={3}>
              <SearchFilterStatus
                onFilterChange={handleFilterChange}
                items={["Awaiting Payment", "Paid", "Canceled", "Factoring Requested"]}
                children={
                  <>
                    <CustomerSelect
                      onChange={handleCustomerInput}
                      register={register}
                      error={errors}
                      selectedCustomerValue={selectedCustomer}
                      setSelectedCustomerValue={setSelectedCustomer}
                    />
                    <Button variant="outlined" color="success" onClick={handleClearFilter}>
                      Clear Filter
                    </Button>
                    <br />
                    <br />
                    <Alert icon={<ReceiptIcon fontSize="inherit" />} severity="success">
                      Total Selected: {new Intl.NumberFormat("de-DE", { style: "currency", currency: "GBP" }).format(totalAmount)}
                    </Alert>
                  </>
                }
              />
            </Grid>
          </>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <PageManagementGrid
            rows={salesInvoices}
            columnFields={columns}
            onDelete={handleDelete}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            customActions={actions}
            checkboxSelection
            onSelectionModelChange={handleRowSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SalesInvoiceList;
