import React, { useEffect, useMemo, useState } from "react";
import { GridCellParams, GridRowId, GridValueFormatterParams } from "@mui/x-data-grid";
import { Box, Grid, IconButton } from "@mui/material";
import { Delete, Visibility, GetApp } from "@mui/icons-material";
import { IInvoice, IPagination, IQuerySearchParams } from "../../../types/customTypes";
import { Link, useNavigate } from "react-router-dom";
import DialogInput from "../../../components/Dialog";
import PageHeader from "../../../components/PageHeader";
import { useDeleteInvoiceMutation, useGetInvoicesQuery } from "../../../store/api/invoiceApi";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import PageManagementGrid from "../../../components/DataGrid";
import SearchFilter from "../../../components/Filter";
import FilterButton from "../../../components/ActionButton";
import CurrencyList from "currency-list";
import { formatAmount } from "../../../utils/amountFormatter";
import { InvoiceType } from "../../../global/enums/entityEnum";

function PurchaseReturnInvoice() {
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = React.useState<GridRowId | null>(null);
  const currencies = useMemo(() => CurrencyList.getAll("en_US"), []);

  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const { data, isSuccess, isFetching, refetch } = useGetInvoicesQuery({...queryParams, invoiceType: InvoiceType.PurchaseReturn});
  const [deleteInvoice] = useDeleteInvoiceMutation();
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      console.log(data.items);
      setInvoices(data.items);
      setTotalItems(data.totalItems);
    }
  }, [isSuccess, data]);

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteRowId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    if (deleteRowId) {
      deleteInvoice(deleteRowId)
        .then(() => {
          setInvoices((oldInvoices) => oldInvoices.filter((s) => s._id !== deleteRowId));
        })
        .catch((err) => {
          console.error("Error deleting invoice", err);
        });
    }
    setIsDeleteDialogOpen(false);
  };

  const handleAddClick = () => {
    navigate("/create-purchase-return-invoice");  
  };

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleSearch = (searchInput: { searchColumns: string[]; searchKeyword: string }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const columns = [
    {
      field: "issueDate",
      headerName: "Date",
      width: showFilter ? 200 : 300,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
      width: showFilter ? 250 : 300,
    },
    {
      field: "supplierName",
      headerName: "Supplier",
      width: showFilter ? 300 : 400,
    },
    {
      field: "totalNetAmount",
      headerName: "Amount",
      width: showFilter ? 200 : 300,
      valueGetter: ({ row }: { row: any }) => {
        const { totalNetAmount, invoiceLines } = row;
        const currencySymbol = currencies[invoiceLines[0].purchaseCurrency]?.symbol || "";
        return currencySymbol + formatAmount(totalNetAmount).toString();
      },
    },
  ];

  const actions = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params: GridCellParams) => (
      <div>
        <IconButton color="primary" title="View Invoice">
          <Link to={`/viewInvoice/${params.row._id}`} style={{ textDecoration: "none" }}>
            <Visibility />
          </Link>
        </IconButton>
        {/* <PDFDownloadLink
          document={<InvoicePDF invoiceId={params.row._id} />}
          fileName="Invoice"
        > */}
        <IconButton color="success" title="Download Invoice">
          <GetApp />
        </IconButton>
        {/* </PDFDownloadLink> */}
        <IconButton color="error" title="Delete Invoice" onClick={handleDeleteClick(params.row._id)}>
          <Delete />
        </IconButton>
      </div>
    ),
  };

  return (
    <Box sx={{ height: "50vh", width: "100%" }}>
      <PageHeader title="Return Purchase Invoices" onAdd={handleAddClick} />
      {isDeleteDialogOpen && (
        <DialogInput
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
          title="Delete Invoice?"
          content="Are you sure you want to delete this supplier? This action cannot be undone."
        />
      )}

      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />

      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <PageManagementGrid
            rows={invoices}
            columnFields={columns}
            onDelete={handleDelete}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            customActions={actions}
            sortModel={[{ field: "issueDate", sort: "asc" }]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PurchaseReturnInvoice;
