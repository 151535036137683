import { Box, Grid } from "@mui/material";
import { GridCellParams, GridRowId, GridValueFormatterParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import FilterButton from "../../../components/ActionButton";
import PageManagementGrid from "../../../components/DataGrid";
import SearchFilter from "../../../components/Filter";
import PageHeader from "../../../components/PageHeader";
import SuccessSnackbar from "../../../components/Snackbar";
import { formatAmount } from "../../../utils/amountFormatter";
import CurrencyList from "currency-list";
import { IPagination, IQuerySearchParams, ISupplier, ISupplierNote } from "../../../types/customTypes";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import CreateSupplierDebitNote from "./CreateDebitNote";
import { useDeleteSupplierNoteMutation, useGetSupplierNotesQuery } from "../../../store/api/supplierNoteApi";
import DialogInput from "../../../components/Dialog";
import TabComponentsContainer from "../../../components/TabComponentsContainer";
import { NoteType } from "../../../global/enums/entityEnum";
import ActionColWithViewButton from "../../../components/ActionColWithViewButton";
import PreviewNote from "../PreviewSupplierNote";

function SupplierDebitNote() {
  const [showSuccessbar, setShowSuccessbar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const currencies = useMemo(() => CurrencyList.getAll("en_US"), []);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [deleteRowId, setDeleteRowId] = useState<GridRowId | null>(null);
  const [showCreateSupplierDebitNoteDialog, setShowCreateSupplierDebitNoteDialog] = useState(false);
  const {
    data,
    isSuccess: isFetchSupplierNoteSuccess,
    isLoading: isFetchSupplierNoteLoading,
    refetch,
  } = useGetSupplierNotesQuery({ ...queryParams, noteType: NoteType.Debit });
  const [deleteDebitNote, { isSuccess: isDeleteDebitNoteSuccess, isError: isDeleteDebitError, error: deleteError, data: deletedSupplierNote }] =
    useDeleteSupplierNoteMutation();
  const [supplierNotes, setSupplierNotes] = useState<ISupplierNote[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [noteId, setNoteId] = useState("");
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);

  useEffect(() => {
    if (isFetchSupplierNoteSuccess && data) {
      setSupplierNotes(data.items);
      console.log(data.items);
      setTotalItems(data.totalItems);
    }
  }, [data, isFetchSupplierNoteSuccess]);

  const columns = [
    {
      field: "issueDate",
      headerName: "Date",
      width: showFilter ? 200 : 300,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "refId",
      headerName: "Ref No",
      width: showFilter ? 250 : 300,
    },
    {
      field: "supplierName",
      headerName: "Supplier",
      width: showFilter ? 300 : 400,
    },
    {
      field: "totalAmount",
      headerName: "Amount",
      width: showFilter ? 200 : 300,
      // valueGetter: ({ row }: { row: any }) => {
      //   const { totalAmount } = row;
      //   const currencySymbol = currencies[invoiceLines[0].purchaseCurrency]?.symbol || "";
      //   return currencySymbol + formatAmount(totalNetAmount).toString();
      // },
    },
  ];

  const handleViewButtonClick = (noteId: GridRowId) => {
    setNoteId(noteId as string);
    setIsViewDialogOpen(true);
  };

  const actions = {
    field: "actions",
    headerName: "Actions",
    width: 200,
    renderCell: (params: GridCellParams) => (
      <ActionColWithViewButton
        viewButtonTitle="View Note"
        downloadButtonTitle="Download Note"
        deleteButtonTitle="Delete Note"
        handleDeleteClick={handleDeleteClick(params.row._id)}
        handleViewButtonClick={() => handleViewButtonClick(params.row._id)}
      />
    ),
  };
  const handleAddClick = () => {
    setShowCreateSupplierDebitNoteDialog(true);
  };

  const handleCloseDialog = () => {
    setShowCreateSupplierDebitNoteDialog(false);
  };

  const onSuccessfulCreation = () => {
    setShowSuccessbar(true);
    setSuccessMessage("Successful created");
  };

  const closeSuccessbar = () => {
    setShowSuccessbar(false);
  };

  const handleSearch = (searchInput: { searchColumns: string[]; searchKeyword: string }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  const handleDelete = async () => {
    if (deleteRowId) {
      deleteDebitNote(deleteRowId);
    }
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDeleteRowId(id);
    setIsDeleteDialogOpen(true);
  };

  useEffect(() => {
    if (isDeleteDebitNoteSuccess) {
      setShowSuccessbar(true);
      setSuccessMessage("Successfully deleted");
      setIsDeleteDialogOpen(false);
    }
  }, [isDeleteDebitNoteSuccess, isDeleteDebitError, deleteError]);

  return (
    <Box>
      <PageHeader title="Supplier Debit Notes" onAdd={handleAddClick} />
      <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} severity="success" message={successMessage} />
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
      {isDeleteDialogOpen && (
        <DialogInput
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
          title="Delete Debit Note?"
          content="Are you sure you want to delete this credit note? This action cannot be undone."
        />
      )}
      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <Box width="100%">
            <PageManagementGrid
              rows={supplierNotes ? supplierNotes : []}
              columnFields={columns}
              onDelete={handleDelete}
              //   onUpdate={handleUpdate}
              paginationModel={paginationModel}
              customActions={actions}
              isFetching={false}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              sortModel={[{ field: "supplierName", sort: "asc" }]}
            />
          </Box>
        </Grid>
      </Grid>

      {showCreateSupplierDebitNoteDialog && (
        <CreateSupplierDebitNote openDialog={showCreateSupplierDebitNoteDialog} handleCloseDialog={handleCloseDialog} onSuccess={onSuccessfulCreation} />
      )}
      {isViewDialogOpen && <PreviewNote noteId={noteId} openDialog={isViewDialogOpen} handleCloseDialog={() => setIsViewDialogOpen(false)} />}
    </Box>
  );
}

export default SupplierDebitNote;
