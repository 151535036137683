import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import ProductAccountStatement from "./accountStatement/ManageProductAccountStatement";
import ManageProducts from './ManageProducts'

function ProductTab() {
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };
  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: "10px" }}>
        <Tab label="Products" />
        <Tab label="Product Statement" />
      </Tabs>
      {currentTab === 0 && <ManageProducts />}
      {currentTab === 1 && <ProductAccountStatement />}
    </Box>
  );
}

export default ProductTab;