import { styled } from "styled-components";

const NavContainer = styled.div`
  height: 70px;
  background: #021a36;
`;

const HamburgerSpan = styled.span`
  display: block;
  margin-top: 4px;
  border-radius: 15px;
  height: 4px;
  width: 35px;
  transition: 0.4s;
  background: white;
`;

const Hamburger = styled.button<{ $isExpanded?: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  margin: 20px 50px;
  transition: 0.4s;
`;

interface NavbarProps {
  toggleSidebar: () => void;
  isExpanded: boolean;
}

function Navbar({ toggleSidebar, isExpanded }: NavbarProps) {
  return (
    <NavContainer>
      <Hamburger onClick={toggleSidebar} $isExpanded={isExpanded}>
        <HamburgerSpan></HamburgerSpan>
        <HamburgerSpan></HamburgerSpan>
        <HamburgerSpan></HamburgerSpan>
      </Hamburger>
    </NavContainer>
  );
}

export default Navbar;
