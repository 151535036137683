import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import {
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridValueGetterParams,
  GridValueSetterParams,
} from "@mui/x-data-grid";
import { CellFieldProps, IPagination, IQuerySearchParams, ISupplier } from "../../types/customTypes";
import { formatDate } from "../../utils/dateFormatter";
import PageHeader from "../../components/PageHeader";
import CreateSupplier from "./CreateSupplier";
import { useDeleteSupplierMutation, useGetSuppliersQuery, useUpdateSupplierMutation } from "../../store/api/supplierApi";
import PageManagementGrid from "../../components/DataGrid";
import { defaultPaginationModel } from "../../utils/queryUtils";
import SuccessSnackbar from "../../components/Snackbar";
import { FormControl, Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import SearchFilter from "../../components/Filter";
import FilterButton from "../../components/ActionButton";
import codes from "country-calling-code";
import countryList from "react-select-country-list";
import EditEmailField from "../../components/EmailEditInput";
import StyledTooltip from "../../components/StyledTooltip";
import { formatAmount } from "../../utils/amountFormatter";

interface SupplierRow extends ISupplier {
  isNew?: boolean;
}

interface EditPhoneNumberProps extends CellFieldProps {
  countryCodeValue: string;
  setCountryCodeValue: React.Dispatch<React.SetStateAction<string>>;
  phoneNumberValue: string;
  setPhoneNumberValue: React.Dispatch<React.SetStateAction<string>>;
}

interface CountryListProps extends CellFieldProps {
  countries: string[];
}

const EditSupplierPhoneCell: React.FC<EditPhoneNumberProps> = ({
  id,
  api,
  field,
  countryCodeValue,
  setCountryCodeValue,
  phoneNumberValue,
  setPhoneNumberValue,
}) => {
  const stringValue = api.getCellValue(id, field) || "";
  let [countryCode = "", phoneNumber = ""] = stringValue.split(" ");
  useEffect(() => {
    // Set initial values when the component mounts
    setCountryCodeValue(countryCode);
    setPhoneNumberValue(phoneNumber);
  }, [setCountryCodeValue, setPhoneNumberValue, countryCode, phoneNumber]);

  const handleCountryCodeChange = (e: SelectChangeEvent<string>) => {
    const newCountryCode = e.target.value;
    setCountryCodeValue(newCountryCode);
    countryCode = newCountryCode;
    api.setEditCellValue({
      id,
      field,
      value: `${countryCode} ${phoneNumber}`,
    });
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumberValue(newPhoneNumber);
    api.setEditCellValue({
      id,
      field,
      value: `${countryCode} ${newPhoneNumber}`,
    });
  };

  return (
    <div>
      <Select value={countryCodeValue} onChange={handleCountryCodeChange} variant="outlined">
        {codes.map(({ country, countryCodes }) => (
          <MenuItem key={country} value={countryCodes[0]}>
            {countryCodes[0]}
          </MenuItem>
        ))}
      </Select>
      <TextField type="text" value={phoneNumberValue} onChange={handlePhoneNumberChange} variant="outlined" />
    </div>
  );
};

const EditCountry: React.FC<CountryListProps> = ({ id, api, field, countries }) => {
  const [currentSelectedCountry, setCurrentSelectedCountry] = useState(api.getCellValue(id, field) || "");

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const newSelectedCountry = e.target.value;

    setCurrentSelectedCountry(newSelectedCountry);
    api.setEditCellValue({ id, field, value: newSelectedCountry });
  };

  return (
    <Select label="Country" value={currentSelectedCountry} onChange={handleCountryChange} fullWidth>
      {countries.map((country: string, index: number) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
  );
};

function SupplierNameEditInputCell(props: GridRenderEditCellParams) {
  const { value, api } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const hasInvoice = props.row.invoiceCount > 0;

  const handleSupplierNameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setErrorMessage("");

    if (newValue === "") {
      setErrorMessage("Supplier name cannot be empty");
    } else {
      api.setEditCellValue({ ...props, value: newValue });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasInvoice) {
      setErrorMessage("Cannot edit name. Invoice with supplier already exists");
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <FormControl>
        <TextField
          value={value}
          onChange={handleSupplierNameInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{
            readOnly: hasInvoice,
          }}
          fullWidth
        />
      </FormControl>
    </StyledTooltip>
  );
}

function OpeningBalanceInputCell(props: GridRenderEditCellParams) {
  const { value, api } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const hasInvoice = props.row.invoiceCount > 0;

  const handleOpeningBalanceInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setErrorMessage("");

    if (newValue === "") {
      api.setEditCellValue({ ...props, value: 0 });
    } else {
      api.setEditCellValue({ ...props, value: newValue });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasInvoice) {
      setErrorMessage("Cannot edit field. Invoice with supplier already exists");
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <FormControl>
        <TextField
          value={value}
          onChange={handleOpeningBalanceInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{
            readOnly: hasInvoice,
          }}
          fullWidth
        />
      </FormControl>
    </StyledTooltip>
  );
}

export default function SupplierManagement() {
  const [suppliers, setSuppliers] = useState<SupplierRow[]>([]);
  const [countryCodeValue, setCountryCodeValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [showCreateSupplierDialog, setShowCreateSupplierDialog] = useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const countries = useMemo(() => countryList().getLabels(), []);

  const { data, isSuccess, isFetching, refetch } = useGetSuppliersQuery(queryParams);
  const [updateSupplier] = useUpdateSupplierMutation();
  const [deleteSupplier] = useDeleteSupplierMutation();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setSuppliers(data.items);

      console.log(data.items);

      setTotalItems(data.totalItems);
    }
  }, [data, isSuccess]);

  const handleDelete = async (deleteRowId: GridRowId) => {
    if (deleteRowId) {
      deleteSupplier(deleteRowId)
        .then(() => {
          setSuppliers((oldSuppliers) => oldSuppliers.filter((s) => s._id !== deleteRowId));
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const handleUpdate = async (newRow: GridRowModel) => {
    updateSupplier(newRow);
  };

  const handleAddClick = () => {
    setShowCreateSupplierDialog(true);
  };

  const handleCloseDialog = () => {
    setShowCreateSupplierDialog(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleSearch = (searchInput: { searchColumns: string[]; searchKeyword: string }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const addressValueSetter = (field: string) => (params: GridValueSetterParams) => {
    const { address } = params.row;
    const newValue = params.value?.toString() || "";

    //addressLine1, city and country fields cannot be empty. Previous value is retained
    if (field === "addressLine1" || field === "city" || field === "country") {
      if (newValue.trim() === "") return params.row;
    }
    return { ...params.row, address: { ...address, [field]: newValue } };
  };

  const columns: GridColDef[] = [
    {
      field: "supplierName",
      headerName: "Name",
      width: 400,

      renderEditCell: (params) => <SupplierNameEditInputCell {...params} />,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value === "";
        return { ...params.props, error: hasError };
      },
      editable: true,
    },
    {
      field: "supplierEmail",
      headerName: "Email",
      width: 300,
      editable: true,
      renderEditCell: (params) => <EditEmailField {...params} />,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      editable: true,
      valueGetter: ({ row }) => {
        const { countryCode, phoneNumber } = row.phone || {};
        return `${countryCode || ""} ${phoneNumber || ""}`;
      },
      valueSetter: (params: GridValueSetterParams) => {
        const [countryCode, phoneNumber] = params.value!.toString().split(" ");

        if (!phoneNumber.trim()) {
          // If phone number is empty, prevent editing
          return params.row;
        } else {
          return {
            ...params.row,
            phone: {
              ...params.row.phone,
              countryCode,
              phoneNumber,
            },
          };
        }
      },
      renderEditCell: ({ id, api, field }: any) => (
        <EditSupplierPhoneCell
          id={id}
          api={api}
          field={field}
          countryCodeValue={countryCodeValue}
          setCountryCodeValue={setCountryCodeValue}
          phoneNumberValue={phoneNumberValue}
          setPhoneNumberValue={setPhoneNumberValue}
        />
      ),
    },
    {
      field: "addressLine1",
      headerName: "Address Line 1",
      width: 300,
      valueGetter: ({ row }) => row.address?.addressLine1 || "",
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value === "";
        return { ...params.props, error: hasError };
      },
      valueSetter: addressValueSetter("addressLine1"),
    },
    {
      field: "city",
      headerName: "City",
      width: 200,
      valueGetter: ({ row }) => row.address?.city || "",
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value === "";
        return { ...params.props, error: hasError };
      },
      valueSetter: addressValueSetter("city"),
    },
    {
      field: "region",
      headerName: "Region",
      width: 200,
      editable: true,
      valueGetter: ({ row }) => (row.address?.region ? row.address.region : ""),
      valueSetter: addressValueSetter("region"),
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      editable: true,
      valueGetter: ({ row }) => (row.address?.country ? row.address.country : ""),
      renderEditCell: ({ id, api, field }: any) => <EditCountry id={id} api={api} field={field} countries={countries} />,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value === "";
        return { ...params.props, error: hasError };
      },
      valueSetter: addressValueSetter("country"),
    },
    {
      field: "openingBalance",
      headerName: "Opening Balance",
      width: 200,
      type: "number",
      renderEditCell: (params) => <OpeningBalanceInputCell {...params} />,
      editable: true,
      valueGetter: (params: GridValueGetterParams) => {
        return formatAmount(params?.row?.openingBalance);
      },
    },
    {
      field: "openingBalanceType",
      headerName: "Opening Balance Type",
      width: 200,
      editable: false,
      align: "center",
      headerAlign: "left"
    },
    {
      field: "openingBalanceDate",
      headerName: "Opening Balance Date",
      width: 200,
      valueGetter: (params) => {
        return formatDate(params?.value);
      },
      align: "center",
    },
    {
      field: "totalCredit",
      headerName: "Total credit",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return formatAmount(params?.row?.totalCredit);
      },
    },
    {
      field: "totalDebit",
      headerName: "Total debit",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return formatAmount(params?.row?.totalDebit);
      },
    },  
    {
      field: "balanceDue",
      headerName: "Balance Due",
      width: 200,
      valueGetter: (params: GridValueGetterParams) => {
        return formatAmount(params?.row?.balanceDue);
      },
    },
  ];

  return (
    <Box>
      <PageHeader title="Suppliers" onAdd={handleAddClick} />
      <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} severity="success" message="Supplier successfully created" />
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />

      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <Box width="100%">
            <PageManagementGrid
              rows={suppliers}
              columnFields={columns}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
              paginationModel={paginationModel}
              isFetching={isFetching}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              sortModel={[{ field: "supplierName", sort: "asc" }]}
            />
          </Box>
        </Grid>
      </Grid>

      {showCreateSupplierDialog && <CreateSupplier openDialog={showCreateSupplierDialog} handleCloseDialog={handleCloseDialog} onSuccess={openSuccessbar} />}
    </Box>
  );
}