import React, { useEffect, useState } from "react";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import CenteredContainer from "../components/CenteredContainer";
import { useForm, SubmitHandler } from "react-hook-form";
import { useResetPasswordMutation } from "../store/api/authenticationApi";
import { useLocation, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

type PasswordValues = {
  password: string;
  confirmPassword: string;
};

const passwordSchema = z
  .object({
    password: z
      .string()
      .nonempty("Password field cannot be empty")
      .min(12, "Password should be at least 12 characters")
      .max(30, "Password should not exceed 30 characters")
      .refine(
        (data) => /[a-z]/.test(data),
        "Password should contain at least one lowercase letter"
      )
      .refine(
        (data) => /[A-Z]/.test(data),
        "Password should contain at least one uppercase letter"
      )
      .refine(
        (data) => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(data),
        "Password should contain at least one special character"
      ),
    confirmPassword: z.string().nonempty("Please enter in password again"),
  })
  .refine((data) => data.password === data.confirmPassword, {
    path: ["confirmPassword"],
    message: "Passwords don't match",
  });

function CreateNewPassword() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const token = searchParams.get("token");

  const [errorMessage, setErrorMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
  } = useForm<PasswordValues>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [resetPassword, { isSuccess, isError }] = useResetPasswordMutation();

  const handlePasswordInput = () => {
    clearErrors();
  };

  const handleConfirmPasswordInput = () => {
    clearErrors();
  };

  const onSubmit: SubmitHandler<PasswordValues> = (data) => {
    const newPasswordRequestBody = { email, token, ...data };
    resetPassword(newPasswordRequestBody);
  };

  useEffect(() => {
    if (isSuccess) {
      setErrorMessage("");
      navigate("/new-password-success");
    }
    if (isError) {
      setErrorMessage("An error occured, try again");
    }
  }, [isSuccess, isError]);

  return (
    <CenteredContainer>
      <Box textAlign="center" width="500px">
        <Typography variant="h4" fontWeight="bold" gutterBottom>
          Create New Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="New Password"
            fullWidth
            type="password"
            {...register("password", {
              required: "This field is required",
            })}
            onChange={handlePasswordInput}
            helperText={errors.password?.types?.required}
            margin="normal"
          />
          <TextField
            label="Confirm Password"
            fullWidth
            type="password"
            {...register("confirmPassword")}
            onChange={handleConfirmPasswordInput}
            margin="normal"
          />
          {errors.password || errors.confirmPassword || errorMessage ? (
            <Alert severity="error" sx={{ marginTop: "20px" }}>
              {errors.password?.message ||
                errors.confirmPassword?.message ||
                errorMessage}
            </Alert>
          ) : null}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            sx={{ marginTop: "20px", width: "100%" }}
          >
            Set New Password
          </Button>
        </form>
      </Box>
    </CenteredContainer>
  );
}

export default CreateNewPassword;
