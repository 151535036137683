import React, { useState } from "react";
import { Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";

interface SearchFilterProps {
  onFilterChange: (selectedFilter: string | string[]) => void;
  children: React.ReactNode;
  items: string[];
}

function SearchFilterStatus({ onFilterChange, children, items }: SearchFilterProps) {
  const [selectedFilter, setSelectedFilter] = useState<string[]>([]);

  const handleFilterChange = (event: SelectChangeEvent<string | string[]>) => {
    const value = event.target.value;
    setSelectedFilter(Array.isArray(value) ? value : [value]);
    const selectedValues = Array.isArray(value) ? value.join(", ") : value;
    onFilterChange(selectedValues);
  };

  return (
    <Card sx={{ width: "95%", marginTop: "20px" }}>
      <CardContent>
        <Grid container sx={{ marginBottom: "20px" }}>
          <Grid item sm={12}>
            <FormControl sx={{ mt: 2, width: "100%" }}>
              <InputLabel>Choose Status</InputLabel>
              <Select multiple value={selectedFilter} onChange={handleFilterChange} size="medium">
                {items.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {children}
      </CardContent>
    </Card>
  );
}

export default SearchFilterStatus;
