import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Link, useNavigate } from "react-router-dom";
import { useSelector as useTypedSelector, useDispatch } from "react-redux";
import { uploadFile } from "../../store/features/uploadFileSlice";
import { UploadStatus } from "../../global/enums/docType";
import { fetchUploadStatus } from "../../store/features/uploadStatusSlice";
//import { fetchUploadStatus } from "../../store/uploadStatusSlice";
import { AppDispatch } from "../../store/features/store";
// import { SoloTraderDocs } from ".";
import { useState } from "react";
import { fetchDocs } from "../../store/features/retrieveFilesSlice";
import { getIndexForDocType } from "../../global/enums/docType";
import { DocTypes } from "../../global/enums/docType";
import { selectAccessToken } from "../../store/features/authenticationSlice";
import { useFetchUserInfoQuery } from "../../store/api/infoApi";
import { createBusinessFormValues } from "./formName";
import { useSendBusinessDetailsMutation } from "../../store/api/createBusinessApi";
import { CreateBusinessValues } from "../../types/formValueType";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";
import FilePicker from "../../components/FilePicker";
import FileExistText from "./FileExistText";
import { clearStorage } from "../../utils/formStorage";

export default function SoloTraderDocPage() {
  const authToken = selectAccessToken();
  const { data: userInfo, isSuccess, isError } = useFetchUserInfoQuery();
  const [team_id, setTeamId] = useState("");

  const [uploadStatusMap, setUploadStatusMap] = useState({
    logo: null,
    id_rear: null,
    id_front: null,
  });

  const [businessFormValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const dispatch: AppDispatch = useDispatch();
  const [type, setType] = useState("");

  const userData = useTypedSelector((state: any) => state.userData);
  const uploadStatus = useTypedSelector((state: any) => state.uploadFile.uploadStatus);

  const status = useTypedSelector((state: any) => state.uploadStatus.file);
  console.log(status);

  const {
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm<CreateBusinessValues>({
    defaultValues: businessFormValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  useEffect(() => {
    setValue("isFinish", true);
  }, []);

  const isAllFilesUploaded =
    uploadStatusMap.logo === UploadStatus.UPLOADED && uploadStatusMap.id_rear === UploadStatus.UPLOADED && uploadStatusMap.id_front === UploadStatus.UPLOADED;

  useEffect(() => {
    if (isSuccess && userInfo) {
      setTeamId(userInfo.data.team_id);
    }
  }, [isSuccess, isError, userInfo]);

  const [sendBusinessDetails, { isSuccess: isBusinessDetailsSentSuccess, isError: isBusinessDetailsSentError, isLoading, error: businessDetailsErrors }] =
    useSendBusinessDetailsMutation();

  useEffect(() => {
    if (isBusinessDetailsSentSuccess) {
      clearStorage(createBusinessFormValues)
      navigate("/");
    } else {
      console.log(businessDetailsErrors);
    }
  }, [isBusinessDetailsSentSuccess, isBusinessDetailsSentError]);

  const handleFileUpload = (docType: string, file: File) => {
    if (authToken) {
      dispatch(uploadFile({ docType, file, authToken }))
        .then((resultAction) => {
          if (uploadFile.fulfilled.match(resultAction)) {
            const {
              payload: { status },
            } = resultAction;

            // Update the upload status for the corresponding file based on the received status
            setUploadStatusMap((prevStatus) => ({
              ...prevStatus,
              [docType]: status,
            }));
          }
        })
        .catch((error) => {
          console.error("Error occurred during file upload:", error);
          // Optionally handle the error here
        });

      const indexOfType = getIndexForDocType(docType, DocTypes)?.toString() || "";
      setType(indexOfType);

      setUploadStatusMap((prevStatus) => ({
        ...prevStatus,
        [docType]: UploadStatus.UPLOADING,
      }));
    }
  };

  const navigate = useNavigate();
  const { isReEdit, showSoleTraderDocsUploadStatus } = getValues();
  console.log(showSoleTraderDocsUploadStatus);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const businessFormValues = getValues();

    if (businessFormValues) {
      sendBusinessDetails(businessFormValues);
    }
  };

  useEffect(() => {
    if (authToken) {
      dispatch(fetchUploadStatus({ authToken }));
      dispatch(fetchDocs({ team_id, type, authToken }));
    }
  }, [uploadStatus, dispatch, team_id, authToken]);

  const handleBackButtonClick = () => {
    setValue("showSoleTraderDocsUploadStatus", false);
    Promise.resolve().then(() => {
      navigate("/create-account");
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          marginLeft: 20,
        }}
      >
        <Grid container>
          <CssBaseline />

          <Grid item xs={12} sm={8} md={10} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 3,
                mx: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Documents
              </Typography>
              <Box sx={{ mt: 1 }}>
                <form>
                  <FilePicker handleFileUpload={handleFileUpload} label="Logo" isReEdit document="logo" showDocument={showSoleTraderDocsUploadStatus} />
                  {uploadStatusMap.logo === null && isReEdit && UploadStatus.UPLOADED === status?.docs.logo_status && showSoleTraderDocsUploadStatus && (
                    <FileExistText />
                  )}
                  {/* {UploadStatus.WAITING === status?.docs.logo_status && (
                    <p>Waiting...</p>
                  )} */}
                  {uploadStatusMap.logo === UploadStatus.UPLOADING && (
                    <Typography color="orange" gutterBottom>
                      Uploading
                    </Typography>
                  )}
                  {/* Render status only if upload is completed for the logo */}
                  {uploadStatusMap.logo === UploadStatus.UPLOADED && (
                    <Typography color="green" gutterBottom>
                      Successfully Uploaded
                    </Typography>
                  )}
                  {/* Render status only if upload failed for the logo */}
                  {uploadStatusMap.logo === UploadStatus.FAIL && (
                    <Typography color="red" gutterBottom>
                      Upload failed
                    </Typography>
                  )}
                  <FilePicker handleFileUpload={handleFileUpload} label="ID rear" isReEdit document="id_rear" showDocument={showSoleTraderDocsUploadStatus} />
                  {uploadStatusMap.logo === null && isReEdit && UploadStatus.UPLOADED === status?.docs.id_rear_status && showSoleTraderDocsUploadStatus && (
                    <FileExistText />
                  )}
                  {uploadStatusMap.id_rear === UploadStatus.UPLOADING && (
                    <Typography color="orange" gutterBottom>
                      Uploading
                    </Typography>
                  )}
                  {/* Render status only if upload is completed for the id_rear */}
                  {uploadStatusMap.id_rear === UploadStatus.UPLOADED && (
                    <Typography color="green" gutterBottom>
                      Successfully Uploaded
                    </Typography>
                  )}
                  {/* Render status only if upload failed for the id_rear */}
                  {uploadStatusMap.id_rear === UploadStatus.FAIL && (
                    <Typography color="red" gutterBottom>
                      Upload failed
                    </Typography>
                  )}
                  <FilePicker handleFileUpload={handleFileUpload} label="ID front" isReEdit document="id_front" showDocument={showSoleTraderDocsUploadStatus} />
                  {uploadStatusMap.logo === null && isReEdit && UploadStatus.UPLOADED === status?.docs.id_front_status && showSoleTraderDocsUploadStatus && (
                    <FileExistText />
                  )}
                  {uploadStatusMap.id_front === UploadStatus.UPLOADING && (
                    <Typography color="orange" gutterBottom>
                      Uploading
                    </Typography>
                  )}
                  {/* Render status only if upload is completed for the id_front */}
                  {uploadStatusMap.id_front === UploadStatus.UPLOADED && (
                    <Typography color="green" gutterBottom>
                      Successfully Uploaded
                    </Typography>
                  )}
                  {/* Render status only if upload failed for the id_front */}
                  {uploadStatusMap.id_front === UploadStatus.FAIL && (
                    <Typography color="red" gutterBottom>
                      Upload failed
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                    disabled={!showSoleTraderDocsUploadStatus && !isAllFilesUploaded}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button" 
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                    onClick={handleBackButtonClick}
                  >
                    Back
                  </Button>
                </form>
              </Box>
              {isReEdit && <BackToHomeLink />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
