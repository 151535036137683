import { Controller, useForm } from "react-hook-form";
import DialogInput from "../../../components/Dialog";
import { CreateEntity, ISupplier } from "../../../types/customTypes";
import { supplierNoteValues } from "../../../types/formValueType";
import useFormPersist from "react-hook-form-persist";
import { Alert, Container, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import SupplierSelect from "../../../components/SupplierSelect";
import { handleNumberInput } from "../../../utils/numberPrecision";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearFormButton from "../../../components/ClearFormButton";
import { clearStorage } from "../../../utils/formStorage";
import { createSupplierNoteSchema } from "../../../validation/supplierNote.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateSupplierNoteMutation } from "../../../store/api/supplierNoteApi";
import { NoteType } from "../../../global/enums/entityEnum";

function CreateSupplierCreditNote({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  const [errorMessage, setErrorMessage] = useState("");
  const required = { required: "Required" };
  const [createSupplierNote, { isLoading, isSuccess, isError, error, data: newSupplierNote }] = useCreateSupplierNoteMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    reset,
    control,
  } = useForm<supplierNoteValues>({
    defaultValues: {
      refId: "",
      issueDate: new Date(),
      supplierId: "",
      description: "",
      totalAmount: 0,
      noteType: NoteType.Credit
    },
    resolver: zodResolver(createSupplierNoteSchema),
  });

  const supplierCreditNoteFormValues = "supplierCreditNoteValues";

  useFormPersist(supplierCreditNoteFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const onSubmit = async (data: supplierNoteValues) => {
    console.log(data);

    try {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      createSupplierNote(formData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleIssueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("issueDate");
      setValue("issueDate", date);
    }
  };

  const handleSupplierChange = (supplierId: string) => {
    clearErrors("supplierId");
    setValue("supplierId", supplierId);
  };

  const onNewSupplierCreation = (supplierDetails: ISupplier) => {
    setValue("supplierId", supplierDetails._id);
  };

  const handleTotalAmountInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(`totalAmount`);
    const value = handleNumberInput(event.target.value);
    setValue(`totalAmount`, value);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(supplierCreditNoteFormValues);
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      onSuccess(newSupplierNote);
      reset();
      clearStorage(supplierCreditNoteFormValues);
    } else if (isError && error) {
      const errMsg = "data" in error ? (error.data as string) : "Unable to create supplier note";
      setErrorMessage(errMsg);
    }
  }, [isSuccess, isError, error]);

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Create Credit Note"
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <Typography gutterBottom sx={{ fontStyle: "italic" }}>
            * indicates required fields
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "16px" }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Ref Id *"
                  // value={watch("totalAmount")}
                  {...register("refId")}
                  fullWidth
                  error={!!errors.refId}
                  helperText={errors.refId?.message}
                />
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="issueDate"
                    // defaultValue={new Date()}
                    control={control}
                    render={({ field: { onChange } }) => (
                      <DatePicker
                        value={dayjs(watch("issueDate"))} // Use watch to get the value of "issueDate"
                        label="Issue date *"
                        {...register("issueDate", required)}
                        // onChange={(date) => {
                        //   const isoDateString = date ? date : "";
                        //   onChange(isoDateString);
                        //   setIssuedDate(dayjs(date));
                        //   handleIssueDateChange(date)
                        // }}
                        onChange={handleIssueDateChange}
                        slotProps={{
                          textField: {
                            error: !!errors.issueDate,
                            helperText: errors.issueDate?.message,
                          },
                        }}
                        sx={{ width: "100%" }}
                      />
                    )}
                  />
                </Grid>
              </LocalizationProvider>

              <Grid item xs={12}>
                <SupplierSelect
                  error={errors}
                  onChange={handleSupplierChange}
                  register={register}
                  selectedSupplierValue={watch("supplierId")}
                  onCreateSupplierSuccess={onNewSupplierCreation}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  {...register("description")}
                  fullWidth
                  multiline
                  rows={3}
                  error={!!errors.description}
                  helperText={errors.description?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Total amount *"
                  value={watch("totalAmount")}
                  type="number"
                  {...register("totalAmount", {
                    valueAsNumber: true,
                  })}
                  onChange={handleTotalAmountInput}
                  fullWidth
                  error={!!errors.totalAmount}
                  helperText={errors.totalAmount?.message}
                />
              </Grid>
              <Grid item xs={12} marginBottom="20px">
                <LoadingButton type="submit" variant="contained" color="primary">
                  Submit
                </LoadingButton>
                <ClearFormButton onClick={handleClearFormButtonClick} />
              </Grid>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Confirm"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default CreateSupplierCreditNote;
