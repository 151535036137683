import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { selectAccessToken } from "../store/features/authenticationSlice";

const NonAuthPage = () => {
  const token = useSelector(selectAccessToken);

  return token ? <Navigate to="/" /> : <Outlet />;
};

export default NonAuthPage;
