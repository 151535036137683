import React, { useEffect, useState } from "react";
import { Button, Container, Grid, TextField, Alert, Typography, Snackbar } from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import DialogInput from "../../../../components/Dialog";
import { bidDateValues } from "../../../../types/formValueType";
import { CreateEntity } from "../../../../types/customTypes";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useCreateFinancingRequestMutation } from "../../../../store/api/handleFactoringRequest";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "@mui/material/SnackbarContent";

function BidRangeDateDialog({
  openDialog,
  handleCloseDialog,
  selectedInvoiceData,
  setSuccessMessage,
  createFinancingRequest,
  setErrorMessage,
  errorMessage,
}: any) {
  const { register, handleSubmit, formState, clearErrors, setValue, watch, control } = useForm<bidDateValues>({
    defaultValues: {
      bid_end_date: new Date(),
      bid_start_date: new Date(),
    },
  });

  const { errors } = formState;

  const handleIssueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("bid_start_date");
      setValue("bid_start_date", date);
    }
  };

  const handleDueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("bid_end_date");
      setValue("bid_end_date", date);
    }
  };

  const onSubmit = async (data: bidDateValues) => {
    try {
      if (selectedInvoiceData.length < 1) {
        setErrorMessage("Select at least one invoice");
        return;
      }

      let hasOtherStatus = false;
      const ids = selectedInvoiceData.map((row: any) => {
        hasOtherStatus = row.status !== "Awaiting Payment";
        return row.id;
      });

      // if (hasOtherStatus) {
      //   setErrorMessage("You can only select invoices with status Awaiting Payment");
      //   return;
      // }

      await createFinancingRequest({ ...data, invoice_ids: ids });
      setSuccessMessage("Successfully requested factoring");
    } catch (error) {
      console.error("Error updating financial info:", error);
      setErrorMessage("Error updating financial information");
    }
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Choose bid range dates for financing requests "
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <Typography gutterBottom sx={{ fontStyle: "italic" }}>
            * indicates required fields
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "16px" }}>
                {errorMessage}
              </Alert>
            )}

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={4} sm={6}>
                <Controller
                  name="bid_start_date"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      value={dayjs(watch("bid_start_date"))}
                      label="Bid Start Date *"
                      onChange={handleIssueDateChange}
                      slotProps={{
                        textField: {
                          error: !!errors.bid_start_date,
                          helperText: errors.bid_start_date?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <br />
              <Grid item xs={12} md={4} sm={6}>
                <Controller
                  name="bid_end_date"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      value={dayjs(watch("bid_end_date"))}
                      label="Bid End Date *"
                      onChange={handleDueDateChange}
                      slotProps={{
                        textField: {
                          error: !!errors.bid_end_date,
                          helperText: errors.bid_end_date?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
            </LocalizationProvider>
            <br />
            <Grid item xs={12} marginBottom="20px">
              <Button type="submit" variant="contained" color="primary">
                Apply
              </Button>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Update"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default BidRangeDateDialog;
