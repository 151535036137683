import { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { IInvoiceLine, IProduct } from "../../../types/customTypes";
import ProductSelectionField from "../../../components/ProductSelectionField";
import { useGetProductByIdQuery } from "../../../store/api/productApi";
import { handleNumberInput } from "../../../utils/numberPrecision";
import { invoiceValues } from "../../../types/formValueType";
import { Control, FieldErrors, UseFormGetValues, UseFormWatch, useWatch } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";

interface InvoiceLineProps {
  index: number;
  onLineBlur: (index: number, line: IInvoiceLine) => void;
  register: any;
  error: FieldErrors<invoiceValues>;
  setValue: any;
  clearError: any;
  isInvoiceCreationSuccess: boolean;
  remove: any;
  onRemoveInvoiceLine: (index: number) => void;
  watch: UseFormWatch<invoiceValues>;
  getValues: UseFormGetValues<invoiceValues>;
  control: Control<invoiceValues, any>;
}

export default function InvoiceLine({
  onLineBlur,
  register,
  index,
  error,
  setValue,
  clearError,
  isInvoiceCreationSuccess,
  remove,
  onRemoveInvoiceLine,
  watch,
  getValues,
  control,
}: InvoiceLineProps) {
  const [selectedProductId, setSelectedProductId] = useState<string>("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [vatPercentage, setVatPercentage] = useState(0);
  const [isPriceVatIncluded, setIsPriceVatIncluded] = useState(false);

  const { isSuccess: isGetProductSuccess, data: product } = useGetProductByIdQuery(selectedProductId);

  const handleVatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoiceLines.${index}.lineVatPercentage`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.${index}.lineVatPercentage`, value);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoiceLines.${index}.lineDiscountPercentage`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.${index}.lineDiscountPercentage`, value);
  };

  const handleAddQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoiceLines.${index}.quantity`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.${index}.quantity`, value);
  };

  const handleUnitPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearError(`invoiceLines.${index}.unitPrice`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.${index}.unitPrice`, value);
  };

  const handleProductChange = (productId: string) => {
    clearError(`invoiceLines.${index}.productId`);
    setValue(`invoiceLines.${index}.productId`, productId);
  };

  //Auto fill purchasePrice and vat
  useEffect(() => {
    if (isGetProductSuccess) {
      const { purchasePrice, vatRate, isPurchasePriceVatIncluded } = product;
      if (isPurchasePriceVatIncluded) {
        setIsPriceVatIncluded(isPurchasePriceVatIncluded);
      }
      if (purchasePrice !== null && purchasePrice !== undefined) {
        setValue(`invoiceLines.${index}.unitPrice`, purchasePrice);
      }
      if (vatRate !== undefined) {
        setVatPercentage(vatRate);
        setValue(`invoiceLines.${index}.lineVatPercentage`, vatRate);
      }
    }
  }, [selectedProductId, isGetProductSuccess, product]);

  useEffect(() => {
    clearError("");
    setDiscountPercentage(0);
    setSelectedProductId("");
    setVatPercentage(0);
  }, [isInvoiceCreationSuccess]);

  const handleLineBlur = () => {
    const { invoiceLines } = getValues();

    const { unitPrice, quantity, lineDiscountPercentage, lineVatPercentage } = invoiceLines[index];

    const totalPrice = quantity * unitPrice;
    const discountAmount = (totalPrice * lineDiscountPercentage) / 100;
    const grossAmount = parseFloat((totalPrice - discountAmount).toFixed(2));
    const lineVatAmount = (lineVatPercentage / 100) * grossAmount;
    let netAmount = grossAmount;
    if (!isPriceVatIncluded) {
      netAmount = grossAmount + lineVatAmount;
    }

    setValue(`invoiceLines.${index}.lineGrossAmount`, grossAmount);
    setValue(`invoiceLines.${index}.lineNetAmount`, netAmount);
    setValue(`invoiceLines.${index}.lineDiscountAmount`, discountAmount);
    setValue(`invoiceLines.${index}.lineVatAmount`, lineVatAmount);

    const newLine: IInvoiceLine = {
      productId: selectedProductId,
      quantity: quantity,
      unitPrice: unitPrice,
      lineGrossAmount: grossAmount,
      lineVatPercentage: vatPercentage,
      lineVatAmount: lineVatAmount,
      lineNetAmount: netAmount,
      discountPercentage: discountPercentage,
      discountAmount: discountAmount,
    };

    onLineBlur(index, newLine);
  };

  const watchInvoiceLineValues = useWatch({
    control,
    name: [
      `invoiceLines.${index}.unitPrice`,
      `invoiceLines.${index}.lineDiscountPercentage`,
      `invoiceLines.${index}.lineVatPercentage`,
      `invoiceLines.${index}.quantity`,
    ],
  });

  useEffect(() => {
    handleLineBlur();
  }, [watchInvoiceLineValues]);

  const handleRemoveInvoiceLines = () => {
    remove(index);
    onRemoveInvoiceLine(index);
  };

  const onNewProductCreation = (productDetails: IProduct) => {
    setValue(`invoiceLines.${index}.productId`, productDetails._id);
    setValue(`invoiceLines.${index}.unitPrice`, productDetails.purchasePrice);
    setValue(`invoiceLines.${index}.lineVatPercentage`, productDetails.vatRate);
  };

  return (
    <Grid container spacing={1} alignItems="center" width="200">
      <Grid item md={3} sm={12}>
        <ProductSelectionField
          selectedProduct={watch(`invoiceLines.${index}.productId`)}
          setSelectedProduct={setSelectedProductId}
          onProductChange={handleProductChange}
          error={!!error.invoiceLines?.[index]?.productId}
          errorMessage={error.invoiceLines?.[index]?.productId?.message}
          onCreateProductSuccess={onNewProductCreation}
        />
      </Grid>
      <Grid item md={1.5} sm={12}>
        <TextField
          label="Unit Price *"
          variant="outlined"
          margin="normal"
          type="number"
          fullWidth
          {...register(`invoiceLines.${index}.unitPrice`, {
            valueAsNumber: true,
          })}
          inputProps={{ step: 0.01 }}
          onChange={handleUnitPriceChange}
          onBlur={handleLineBlur}
          error={!!error.invoiceLines?.[index]?.unitPrice}
          helperText={error.invoiceLines?.[index]?.unitPrice?.message}
        />
      </Grid>
      <Grid item md={1.5} sm={12}>
        <TextField
          label="Quantity *"
          variant="outlined"
          margin="normal"
          type="number"
          fullWidth
          inputProps={{ step: 0.01 }}
          {...register(`invoiceLines.${index}.quantity`, {
            valueAsNumber: true,
          })}
          onChange={handleAddQuantity}
          onBlur={handleLineBlur}
          error={!!error.invoiceLines?.[index]?.quantity}
          helperText={error.invoiceLines?.[index]?.quantity?.message}
        />
      </Grid>
      <Grid item md={1.5} sm={12}>
        <TextField
          label="Discount"
          variant="outlined"
          margin="normal"
          type="number"
          fullWidth
          inputProps={{ step: 0.01 }}
          {...register(`invoiceLines.${index}.lineDiscountPercentage`, {
            valueAsNumber: true,
          })}
          onChange={handleDiscountChange}
          error={!!error.invoiceLines?.[index]?.lineDiscountPercentage}
          helperText={error.invoiceLines?.[index]?.lineDiscountPercentage?.message}
          onBlur={handleLineBlur}
        />
      </Grid>
      <Grid item md={1.5} sm={12}>
        <TextField
          id="vat"
          label="VAT"
          variant="outlined"
          margin="normal"
          fullWidth
          type="number"
          {...register(`invoiceLines.${index}.lineVatPercentage`, {
            valueAsNumber: true,
          })}
          inputProps={{ step: 0.01 }}
          error={!!error.invoiceLines?.[index]?.lineVatPercentage}
          helperText={error.invoiceLines?.[index]?.lineVatPercentage?.message}
          onChange={handleVatChange}
          onBlur={handleLineBlur}
        />
      </Grid>
      <Grid item md={2} sm={12}>
        <TextField
          id="amount"
          variant="outlined"
          type="number"
          margin="normal"
          fullWidth
          inputProps={{ step: 0.01 }}
          value={watch(`invoiceLines.${index}.lineGrossAmount`)}
          {...register(`invoiceLines.${index}.lineGrossAmount`, {
            valueAsNumber: true,
          })}
          disabled
          sx={{
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
            },
            width: 150,
          }}
        />
      </Grid>
      <Grid item sm={1}>
        <Button onClick={handleRemoveInvoiceLines}>
          <ClearIcon fontSize="large" />
        </Button>
      </Grid>
    </Grid>
  );
}
