import { z } from 'zod';

export const Address = z.object({
  addressLine1: z.string().nonempty("Please enter address"),
  addressLine2: z.string(),
  state: z.string().optional(),
  region: z.string().optional(),
  city: z.string().nonempty("Please enter valid city"),
  postalCode: z.string().optional(),
  country: z.string().nonempty("Please select country"),  
})