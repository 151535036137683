import CenteredContainer from "../components/CenteredContainer";
import { useForgotPasswordMutation } from "../store/api/authenticationApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailValue } from "../types/formValueType";
import EmailInputForm from "../components/EmailInputForm";

function ForgotPassword() {
   const [email, setEmail] = useState("") 
  const [errorMessage, setErrorMessage] = useState("");
  const [
    forgotPassword,
    {
      isSuccess: isEmailSentSuccessful,
      isError: isEmailSentError,
    error: verificationError,
    },
  ] = useForgotPasswordMutation();

  const navigate = useNavigate();

  const onSubmit = (emailValue: EmailValue) => {
    const {email} = emailValue
    setEmail(email);
    forgotPassword(emailValue);
  };

  useEffect(() => {
    if (isEmailSentSuccessful) {
      navigate(`/verify-token/?email=${email}`);
    } else if (isEmailSentError) {
      const err: any = verificationError;

      const errorMsg = err.data?.message
        ? err.data?.message
        : "Operation was unsuccessful";

      setErrorMessage(errorMsg);
    }
  }, [isEmailSentSuccessful, isEmailSentError]);

  return (
    <CenteredContainer>
      <EmailInputForm
        title="Reset your password"
        subtitle="Enter your email address associated with your account and we'll send you a link to reset your password"
        buttonText="Reset password"
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </CenteredContainer>
  );
}

export default ForgotPassword;
