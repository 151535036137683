import StepWizard from "react-step-wizard";
import DialogInput from "../../../components/Dialog";
import { CreateEntity } from "../../../types/customTypes";
import BankDetailsForm from "./BankDetailsForm";
import UserDetailsForm from "./UserDetailsForm";
import { Wizard, useWizard } from "react-use-wizard";

function CreateFinanceAccount({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="New Finance Account"
      content={
        <Wizard>
          <BankDetailsForm />
          <UserDetailsForm handleCloseDialog={handleCloseDialog} onSuccess={onSuccess} />
        </Wizard>
      }
      noDefaultButtons
    />
  );
}

export default CreateFinanceAccount;
