import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UploadStatus } from "../../global/enums/docType";

interface UploadFilePayload {
  docType: string;
  file: File;
  authToken: string;
}

interface UploadFileState {
  isLoading: boolean;
  hasErrored: boolean;
  errorMessage?: string;
  uploadStatus: { [key: string]: number };
  successfull: boolean;
}

const initialState: UploadFileState = {
  isLoading: false,
  hasErrored: false,
  errorMessage: "",
  successfull: false,
  uploadStatus: {},
};

const baseUrl = process.env.REACT_APP_BASE_URL;

//This action is responsible for sending a POST request to upload a file.
//It takes a payload object with docType, file, and authToken.
export const uploadFile = createAsyncThunk(
  "file/uploadFile",
  async ({ docType, file, authToken }: UploadFilePayload) => {
    try {
      //it creates a FormData object and appends the docType and file to it.
      const formData = new FormData();
      formData.append(docType, file);

      //If the request is successful (fulfilled), it checks the response status
      const response = await axios.post(
        `https://dev2.payeda.net/api/v1/account/docs`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      //If the status is 202, it returns an object with the docType and the UploadStatus.UPLOADED constant.
      if (response.status === 202) {
        return { docType, status: UploadStatus.UPLOADED };
      } else {
        //Otherwise, it returns an object with the docType and the UploadStatus.FAIL constant.
        return { docType, status: UploadStatus.FAIL };
      }
    } catch (error: any) {
      //If there's an error during the request (rejected), it returns an object with the docType and the UploadStatus.FAIL constant.
      throw new Error(error.message);
      return { docType, status: UploadStatus.FAIL };
    }
  }
);

const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadFile.pending, (state) => {
        //This reducer is triggered when the file upload request is pending. It sets the loading-related state properties (isLoading, hasErrored, errorMessage) and successful accordingly.
        state.isLoading = true;
        state.hasErrored = false;
        state.errorMessage = "";
        state.successfull = false;
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        //This reducer is triggered when the file upload request is successful. It sets the loading-related state properties, updates the uploadStatus for the specific document type, and sets successful to true.
        state.isLoading = false;
        const { docType, status } = action.payload;
        state.uploadStatus[docType] = status;
        state.successfull = true;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        //This reducer is triggered when there's an error during the file upload request. It sets the loading-related state properties, sets hasErrored to true, stores the error message in errorMessage, and sets successful to false.
        state.isLoading = false;
        state.hasErrored = true;
        state.errorMessage = action.error.message;
        state.successfull = false;
      });
  },
});

export default uploadFileSlice.reducer;
