import styled from "@emotion/styled";
import { Button, Container } from "@mui/material";
import { Box } from "@mui/system";
import { CalendarIcon } from "@mui/x-date-pickers";
import { forwardRef, HTMLProps } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface YearCustomInputProps extends HTMLProps<HTMLButtonElement> {
  label: string;
}

const CustomInput = forwardRef<HTMLButtonElement, YearCustomInputProps>(({ label, onClick }, ref) => (
  <Button onClick={onClick} ref={ref} variant="contained" startIcon={<CalendarIcon />}>
    {label}
  </Button>
));

const CustomDataPicker = styled(DatePicker)`
  height: 20px;
  width: 1000px;
`;

interface DashboardFilterProps {
  handleYearSelection: (date: Date) => void;
  handleMonthSelection: (date: Date) => void;
  handleDaySelection: (date: Date) => void;
  yearLabelText: string;
  monthLabelText: string;
  dayLabelText: string;
}

function DashboardFilter({ handleYearSelection, handleMonthSelection, handleDaySelection, yearLabelText, monthLabelText, dayLabelText }: DashboardFilterProps) {
  const renderYearContent = (year: number) => {
    const tooltipText = `Tooltip for year: ${year}`;
    return <span title={tooltipText}>{year}</span>;
  };

  const renderMonthContent = (monthIndex: number, shortMonthText: string, longMonth: string) => {
    const fullYear = new Date().getFullYear();
    const tooltipText = `Tooltip for month: ${longMonth} ${fullYear}`;

    return <span title={tooltipText}>{shortMonthText}</span>;
  };

  return (
    <Container component="main" maxWidth="sm" sx={{ marginLeft: 0, marginBottom: 10 }}>
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          height: 80,
          width: 600,
          display: "flex",
          justifyContent: "space-around",
          paddingLeft: 0,
          alignItems: "center",
          marginLeft: 0,
        }}
      >
        <CustomDataPicker
          selected={new Date()}
          renderYearContent={renderYearContent}
          showYearPicker
          dateFormat="yyyy"
          onChange={handleYearSelection}
          showIcon
          customInput={<CustomInput label={yearLabelText} />}
          placeholderText={yearLabelText}
          maxDate={new Date()}
        />
        <CustomDataPicker
          selected={new Date()}
          renderMonthContent={renderMonthContent}
          showMonthYearPicker
          dateFormat="MM/yyyy"
          onChange={handleMonthSelection}
          showIcon
          customInput={<CustomInput label={monthLabelText} />}
          maxDate={new Date()}
        />
        <CustomDataPicker
          dateFormat="yyyy/MM/dd"
          selected={new Date()}
          onChange={handleDaySelection}
          customInput={<CustomInput label={dayLabelText} />}
          maxDate={new Date()}
        />
      </Box>
    </Container>
  );
}

export default DashboardFilter;
