import { zodResolver } from "@hookform/resolvers/zod";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Container, Grid, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DialogInput from "../../components/Dialog";
import { useAddNewSubcategoryMutation } from "../../store/api/subcategoryApi";
import { CreateEntity, ISubCategory } from "../../types/customTypes";
import { subCategoryValues } from "../../types/formValueType";
import { createSubCategorySchema } from "../../validation/subcategory.validation";
import useFormPersist from "react-hook-form-persist";
import { clearStorage } from "../../utils/formStorage";
import ClearFormButton from "../../components/ClearFormButton";

interface CreateSubcategoryProps extends Omit<CreateEntity, "onSuccess"> {
  onSuccess: (newSubcategory: ISubCategory) => void;
}

function CreateSubCategory({ openDialog, handleCloseDialog, onSuccess }: CreateSubcategoryProps) {
  const { register, handleSubmit, formState, reset, watch, setValue } = useForm<subCategoryValues>({
    defaultValues: {
      stockCategory: "",
      subCategoryName: "",
    },
    resolver: zodResolver(createSubCategorySchema),
  });

  const subcategoryFormValues = "subcategoryValues";

  useFormPersist(subcategoryFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { errors } = formState;
  const [errorMessage, setErrorMessage] = useState("");
  const [newSubcategory, setNewSubcategory] = useState<ISubCategory>({ subCategoryName: "", stockCategory: "" });

  const [addNewSubcategory, mutationResult] = useAddNewSubcategoryMutation();
  const { isLoading, isError, isSuccess, error } = mutationResult;

  const onSubmit = async (data: ISubCategory) => {
    addNewSubcategory(data);
    setNewSubcategory(data);
  };

  useEffect(() => {
    if (isSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      onSuccess(newSubcategory);
      clearStorage(subcategoryFormValues);
      reset();
    } else if (isError) {
      const errMsg = "data" in error ? (error.data as string) : "Unable to create subcategory";
      setErrorMessage(errMsg);
    }
  }, [isSuccess, isError, error]);

  const handleClearFormButtonClick = () => {
    clearStorage(subcategoryFormValues);
    reset();
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="New Subcategory"
      noDefaultButtons
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "16px" }}>
              {errorMessage}
            </Alert>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="SubCategory Name"
                  {...register("subCategoryName", {
                    required: "SubCategory name is required",
                  })}
                  error={!!errors.subCategoryName}
                  helperText={errors.subCategoryName?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Select Stock Category"
                  defaultValue=""
                  value={watch("stockCategory")}
                  {...register("stockCategory", {
                    required: "Stock Category is required",
                  })}
                  fullWidth
                  error={!!errors.stockCategory}
                  helperText={errors.stockCategory?.message}
                  select
                >
                  {["Goods", "Services", "Assets", "Expenses"].map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12}>
                <LoadingButton variant="contained" color="primary" type="submit" loading={isLoading}>
                  Submit
                </LoadingButton>
                <ClearFormButton onClick={handleClearFormButtonClick} />
              </Grid>
            </Grid>
          </form>
        </Container>
      }
    />
  );
}

export default CreateSubCategory;
