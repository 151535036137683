import React, { useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DocTypes, UploadStatus } from "../../global/enums/docType";
import { useSelector as useTypedSelector, useDispatch } from "react-redux";
import { uploadFile } from "../../store/features/uploadFileSlice";
import { fetchUploadStatus } from "../../store/features/uploadStatusSlice";
import { AppDispatch } from "../../store/features/store";
import { useState } from "react";
import { fetchDocs } from "../../store/features/retrieveFilesSlice";
import { getIndexForDocType } from "../../global/enums/docType";
import { selectAccessToken } from "../../store/features/authenticationSlice";
import { useFetchUserInfoQuery } from "../../store/api/infoApi";
import { createBusinessFormValues } from "./formName";
import { useSendBusinessDetailsMutation } from "../../store/api/createBusinessApi";
import { CreateBusinessValues } from "../../types/formValueType";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";
import { Uploader } from "uploader"; // Installed by "react-uploader".
import { styled } from "styled-components";
import FilePicker from "../../components/FilePicker";
import FileExistText from "./FileExistText";
import { clearStorage } from "../../utils/formStorage";

export interface CompanyDocs {
  logo: string;
  companyRegDoc: string;
}

const FileInput = styled(TextField)`
  color: "transparent";
`;

function RegisteredCompanyDocPage() {
  const authToken = selectAccessToken();
  const { data: userInfo, isSuccess, isError } = useFetchUserInfoQuery();
  const [team_id, setTeamId] = useState("");
  const storedBusinessValues = window.sessionStorage.getItem(createBusinessFormValues);
  const [businessData, setbusinessData] = useState({});
  const [uploadStatusMap, setUploadStatusMap] = useState({
    logo: null,
    company_certificate: null,
  });

  const [businessFormValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const uploadStatus = useTypedSelector((state: any) => state.uploadFile.uploadStatus);

  const [type, setType] = useState("");

  const status = useTypedSelector((state: any) => state.uploadStatus.file);

  const {
    setValue,
    formState: { errors },
    watch,
    getValues,
  } = useForm<CreateBusinessValues>({
    defaultValues: businessFormValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { isReEdit, showCompanyDocsUploadStatus } = getValues();

  useEffect(() => {
    setValue("isFinish", true);
  }, []);

  useEffect(() => {
    if (storedBusinessValues) {
      setbusinessData(JSON.parse(storedBusinessValues));
    }
  }, [storedBusinessValues]);

  const [sendBusinessDetails, { isSuccess: isBusinessDetailsSentSuccess, isError: isBusinessDetailsSentError, isLoading, error: businessDetailsErrors }] =
    useSendBusinessDetailsMutation();

  useEffect(() => {
    if (isSuccess && userInfo) {
      setTeamId(userInfo.data.team_id);
    }
  }, [isSuccess, isError, userInfo]);

  const dispatch: AppDispatch = useDispatch();

  const handleFileUpload = (docType: string, file: File) => {
    if (authToken) {
      dispatch(uploadFile({ docType, file, authToken }))
        .then((resultAction) => {
          if (uploadFile.fulfilled.match(resultAction)) {
            const {
              payload: { status },
            } = resultAction;

            setUploadStatusMap((prevStatus) => ({
              ...prevStatus,
              [docType]: status,
            }));
          }
        })
        .catch((error) => {
          console.error("Error occurred during file upload:", error);
        });

      const indexOfType = getIndexForDocType(docType, DocTypes)?.toString() || "";
      setType(indexOfType);

      setUploadStatusMap((prevStatus) => ({
        ...prevStatus,
        [docType]: UploadStatus.UPLOADING,
      }));
    }
  };

  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (businessData) {
      sendBusinessDetails(businessData);
    }
  };

  useEffect(() => {
    if (isBusinessDetailsSentSuccess) {
      clearStorage(createBusinessFormValues)
      navigate("/");
    } else {
      console.log(businessDetailsErrors);
    }
  }, [isBusinessDetailsSentSuccess, isBusinessDetailsSentError]);

  useEffect(() => {
    if (authToken) {
      dispatch(fetchUploadStatus({ authToken }));
      dispatch(fetchDocs({ team_id, type, authToken }));
    }
  }, [uploadStatus, dispatch, team_id, authToken]);

  const handleBackButtonClick = () => {
    setValue("showCompanyDocsUploadStatus", false);
    Promise.resolve().then(() => {
      navigate("/create-account");
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
          marginLeft: 20,
        }}
      >
        <Grid container>
          <CssBaseline />

          <Grid item xs={12} sm={8} md={10} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 3,
                mx: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Documents
              </Typography>
              <Box sx={{ mt: 1 }}>
                <form>
                  <FilePicker handleFileUpload={handleFileUpload} label="Logo" isReEdit={isReEdit} document="logo" showDocument={showCompanyDocsUploadStatus}/>
                  {isReEdit === true && uploadStatusMap.logo === null && UploadStatus.UPLOADED === status?.docs.logo_status && showCompanyDocsUploadStatus && (
                    <FileExistText />
                  )}

                  {uploadStatusMap.logo === UploadStatus.UPLOADING && (
                    <Typography color="orange" gutterBottom>
                      Uploading
                    </Typography>
                  )}
                  {uploadStatusMap.logo === UploadStatus.FAIL && (
                    <Typography color="red" gutterBottom>
                      Upload failed
                    </Typography>
                  )}
                  {uploadStatusMap.logo === UploadStatus.UPLOADED && (
                    <Typography color="green" gutterBottom>
                      Successfully Uploaded
                    </Typography>
                  )}

                  <FilePicker handleFileUpload={handleFileUpload} label="Company Registration Document" isReEdit={isReEdit} document="company_certificate" showDocument={showCompanyDocsUploadStatus}/>
                  {uploadStatusMap.company_certificate === null && isReEdit === true && showCompanyDocsUploadStatus &&UploadStatus.UPLOADED === status?.docs.company_certificate_status && (
                    <FileExistText />
                  )}

                  {uploadStatusMap.company_certificate === UploadStatus.UPLOADING && (
                    <Typography color="orange" gutterBottom>
                      Uploading
                    </Typography>
                  )}
                  {uploadStatusMap.company_certificate === UploadStatus.UPLOADED && (
                    <Typography color="green" gutterBottom>
                      Successfully Uploaded
                    </Typography>
                  )}
                  {uploadStatusMap.company_certificate === UploadStatus.FAIL && (
                    <Typography color="red" gutterBottom>
                      Upload failed
                    </Typography>
                  )}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                    onClick={handleSubmit}
                    disabled={!showCompanyDocsUploadStatus && (uploadStatusMap.logo !== UploadStatus.UPLOADED || uploadStatusMap.company_certificate !== UploadStatus.UPLOADED)}
                  >
                    Submit
                  </Button>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                    onClick={handleBackButtonClick}
                  > 
                    Back
                  </Button>
                </form>
              </Box>
              {isReEdit && <BackToHomeLink />}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default RegisteredCompanyDocPage;
