import { Menu, MenuItem, Paper, TextField } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  IPagination,
  IProduct,
  IQuerySearchParams,
} from "../types/customTypes";
import { useGetProductsQuery } from "../store/api/productApi";
import { defaultPaginationModel } from "../utils/queryUtils";
import CreateProduct from "../pages/product/CreateProduct";

interface ProductSelectionProps {
  selectedProduct: string;
  setSelectedProduct: (value: string) => void;
  onProductChange?: (value: string) => void;
  error?: any;
  errorMessage?: string;
  onCreateProductSuccess: (productDetails: IProduct) => void

}

function ProductSelectionField({
  selectedProduct,
  setSelectedProduct,
  onProductChange,
  error,
  errorMessage,
  onCreateProductSuccess
}: ProductSelectionProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewProductComponent, setShowNewProductComponent] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");

  const [paginationModel] = useState<IPagination>(defaultPaginationModel);

  const [queryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });

  const { data, isSuccess: isGetProductSuccess } =
    useGetProductsQuery(queryParams);

  useEffect(() => {
    if (isGetProductSuccess) {
      setProducts(data.items);
      setSelectedProductId(selectedProduct)
    }
  }, [isGetProductSuccess, data]);

  useEffect(() => {
    if (isGetProductSuccess){
      setSelectedProductId(selectedProduct)
    }
  }, [selectedProduct])

  const onNewProductCreation = (newProduct: IProduct) => {
    setProducts((prevProducts) => [...prevProducts, newProduct]);
    onCreateProductSuccess(newProduct)
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedProductId("");
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as string;

    if (selectedValue === "newProduct") {
      setSelectedProduct("");
      setShowNewProductComponent(true);
      setOpenDialog(true);
    } else {
      setShowNewProductComponent(false);
      setOpenDialog(false);
      setSelectedProduct(selectedValue);
      setSelectedProductId(selectedValue)

    }
    if (onProductChange) {
      onProductChange(selectedValue);
    }
  };

  return (
    <>
      <TextField
        id="selectProducts"
        select
        label="Select products *"
        variant="outlined"
        margin="normal"
        fullWidth
        value={selectedProductId}
        onChange={handleProductChange}
        error={error}
        helperText={errorMessage}
      >
        {products.length === 0 ? (
          <MenuItem key="" value="">
            No product available
          </MenuItem>
        ) : (
          [
            products.map((product) => (
              <MenuItem key={product._id} value={product._id}>
                {product.productName}
              </MenuItem>
            )),
          ]
        )}
        <MenuItem value="newProduct">-- Create new product --</MenuItem>
      </TextField>

      {showNewProductComponent && (
        <CreateProduct
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onSuccess={onNewProductCreation}
        />
      )}
    </>
  );
}

export default ProductSelectionField;

// import { MenuItem, TextField } from "@mui/material";
// import Menu from "@mui/material/Menu";
// import Paper from "@mui/material/Paper";
// import { useEffect, useRef, useCallback, useState } from "react";
// import {
//   IPagination,
//   IProduct,
//   IQuerySearchParams,
// } from "../types/customTypes";
// import { useGetProductsQuery } from "../store/api/productApi";
// import { defaultPaginationModel } from "../utils/queryUtils";
// import CreateProduct from "../pages/product/CreateProduct";

interface ProductSelection2Props {
  selectedProduct: string;
  setSelectedProduct: (value: string) => void;
  onProductChange?: (value: string) => void;
  error?: any;
  errorMessage?: string;
}

export function ProductSelectionField2({
  selectedProduct,
  setSelectedProduct,
  onProductChange,
  error,
  errorMessage,
}: ProductSelection2Props) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewProductComponent, setShowNewProductComponent] = useState(false);
  const [selectedProductName, setSelectedProductName] = useState<string>("");

  const [page, setPage] = useState<number>(defaultPaginationModel.page);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterText, setFilterText] = useState<string>("");
  const menuRef = useRef<HTMLDivElement>(null);

  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: { page: page, pageSize: defaultPaginationModel.pageSize },
    searchColumns: [],
    searchKeyword: "",
  });

  const { data, isSuccess: isGetProductSuccess } =
    useGetProductsQuery(queryParams);

  const handleScroll = useCallback(() => {
    const menu = menuRef.current;
    if (
      menu &&
      menu.scrollTop + menu.clientHeight === menu.scrollHeight &&
      Array.isArray(data?.items) &&
      isGetProductSuccess &&
      data &&
      data.items.length > 0
    ) {
      const newPage = queryParams.pageInfo.page + 1;
      const newQueryParams = {
        ...queryParams,
        pageInfo: { ...queryParams.pageInfo, page: newPage },
      };
      setPage(newPage);
      setQueryParams(newQueryParams);
    }
  }, [isGetProductSuccess, data]);

  useEffect(() => {
    if (isGetProductSuccess && data) {
      const newProducts = data.items.filter(
        (newProduct: any) =>
          !products.some(
            (existingProduct) => existingProduct._id === newProduct._id
          )
      );

      setProducts((prevProducts) => [...prevProducts, ...newProducts]);
    }
  }, [isGetProductSuccess, data]);

  const handleCreateProduct = async () => {};

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleProductChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as string;
    setSelectedProduct(selectedValue);
    if (selectedValue === "newProduct") {
      setShowNewProductComponent(true);
      setOpenDialog(true);
    } else {
      setShowNewProductComponent(false);
      setOpenDialog(false);
    }
    if (onProductChange) {
      onProductChange(selectedValue);
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleFilterTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilterText(event.target.value);
  };

  return (
    <>
      <TextField
        id="selectProducts"
        label="Select products"
        variant="outlined"
        margin="normal"
        fullWidth
        value={selectedProductName}
        onClick={handleMenuOpen}
        onChange={handleProductChange}
        error={error}
        helperText={errorMessage}
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <TextField
          label="Search A Product"
          variant="outlined"
          fullWidth
          margin="normal"
          value={filterText}
          onChange={handleFilterTextChange}
        />
        <Paper
          onScroll={handleScroll}
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
          ref={menuRef}
        >
          {products.length === 0 ? (
            <MenuItem key="">No products available</MenuItem>
          ) : (
            [
              <MenuItem
                value="newProduct"
                onClick={() => {
                  setSelectedProduct("newProduct");
                  setShowNewProductComponent(true);
                  setOpenDialog(true);
                  handleMenuClose();
                }}
              >
                -- Create new product --
              </MenuItem>,
              ...products
                .filter((product) =>
                  product.productName
                    .toLowerCase()
                    .includes(filterText.toLowerCase())
                )
                .map((product) => (
                  <MenuItem
                    key={product._id}
                    value={product._id || ""}
                    onClick={() => {
                      const selectedProductId = product._id || "";
                      const productName = product.productName || "";
                      setSelectedProductName(productName);
                      setSelectedProduct(selectedProductId);

                      if (selectedProductId === "newProduct") {
                        setShowNewProductComponent(true);
                        setOpenDialog(true);
                      } else {
                        setShowNewProductComponent(false);
                        if (onProductChange) {
                          onProductChange(selectedProductName);
                        }
                      }

                      handleMenuClose();
                      handleProductChange({
                        target: { value: selectedProductId },
                      } as React.ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    {product.productName}
                  </MenuItem>
                )),
            ]
          )}
        </Paper>
      </Menu>

      {showNewProductComponent && (
        <CreateProduct
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onSuccess={handleCreateProduct}
        />
      )}
    </>
  );
}

// export default ProductSelectionField;
