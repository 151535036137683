import { Button } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface FilterButtonProps {
  showFilter: boolean;
  toggleFilter: () => void;
}

function FilterButton({ showFilter, toggleFilter }: FilterButtonProps) {
  return (
    <Button color="secondary" variant="contained" endIcon={showFilter ? <ExpandMoreIcon /> : <TuneIcon />} onClick={toggleFilter}>
      Filter
    </Button>
  );
}

export default FilterButton;
