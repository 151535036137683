import { Button } from "@mui/material";

interface ClearFormButtonProps {
  onClick: () => void;
}

function ClearFormButton({ onClick }: ClearFormButtonProps) {
  return (
    <Button
      type="button"
      variant="outlined"
      color="primary"
      onClick={() => onClick()}
      sx={{marginLeft: '10px'}}
    >
      Reset
    </Button>
  );
}

export default ClearFormButton;
