import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FastInvoice from "./createInvoice/FastInvoice";
import AdvanceInvoice from "./createInvoice/AdvanceInvoice";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

const CreateInvoiceTab: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(1);
  const navigate = useNavigate();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const handleBackButtonClick = () => {
    navigate("/manageInvoice");
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: "10px" }}>
        <IconButton onClick={handleBackButtonClick} sx={{ marginRight: "20px" }}>
          <ArrowBackIcon fontSize="large" /> {/* Use the ArrowBackIcon as the back button */}
        </IconButton>
        <Tab label="Quick invoice" />
        <Tab label="Advance Invoice" />
      </Tabs>
      {currentTab === 1 && <FastInvoice />}
      {currentTab === 2 && <AdvanceInvoice />}
    </Box>
  );
};

export default CreateInvoiceTab;
