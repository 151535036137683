import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { GridColDef, GridPreProcessEditCellProps, GridRowId, GridRowModel, GridValueSetterParams } from "@mui/x-data-grid";
import { CellFieldProps, IPagination, IQuerySearchParams, ICustomer } from "../../types/customTypes";
import { formatDate } from "../../utils/dateFormatter";
import PageHeader from "../../components/PageHeader";
import CreateCustomer from "./CreateCustomer";
import { GridCellParams } from "@mui/x-data-grid";
import { useGetCustomersQuery, useUpdateCustomerMutation, useDeleteCustomerMutation } from "../../store/api/customerApi";

import PageManagementGrid from "../../components/DataGrid";
import { defaultPaginationModel } from "../../utils/queryUtils";
import SuccessSnackbar from "../../components/Snackbar";
import { Grid, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import SearchFilter from "../../components/Filter";
import FilterButton from "../../components/ActionButton";
import codes from "country-calling-code";
import countryList from "react-select-country-list";
import EditEmailField from "../../components/EmailEditInput";
import { Button } from "@mui/material";
import DialogInput from "../../components/Dialog";

interface CustomerRow extends ICustomer {
  isNew?: boolean;
  selected?: boolean;
}

interface CountryListProps {
  id: GridRowId;
  api: any;
  field: string;
  countries: string[];
}

interface EditCountryCodeProps {
  id: GridRowId;
  api: any;
  field: string;
  countryFieldValue: string;
  setCountryFieldValue: React.Dispatch<React.SetStateAction<string>>;
}

const EditCountryCode: React.FC<EditCountryCodeProps> = ({ id, api, field, countryFieldValue, setCountryFieldValue }) => {
  const stringValue = api.getCellValue(id, field) || "";
  useEffect(() => {
    setCountryFieldValue(stringValue);
  }, [setCountryFieldValue, stringValue]);

  const handleCountryCodeChange = (e: SelectChangeEvent<string>) => {
    const newCountryCode = e.target.value;
    setCountryFieldValue(newCountryCode);
    api.setEditCellValue({
      id,
      field,
      value: newCountryCode,
    });
  };

  return (
    <Select value={stringValue} onChange={handleCountryCodeChange} variant="outlined">
      {codes.map(({ country, countryCodes }) => (
        <MenuItem key={country} value={countryCodes[0]}>
          {countryCodes[0]}
        </MenuItem>
      ))}
    </Select>
  );
};

interface EditPhoneNumberProps extends CellFieldProps {
  phoneNumberValue: string;
  setPhoneNumberValue: React.Dispatch<React.SetStateAction<string>>;
}

const EditPhoneNumber: React.FC<EditPhoneNumberProps> = ({ id, api, field, phoneNumberValue, setPhoneNumberValue }) => {
  const stringValue = api.getCellValue(id, field) || "";
  const [phoneNumber, setPhoneNumber] = useState<string>(stringValue);

  useEffect(() => {
    setPhoneNumberValue(phoneNumber);
  }, [setPhoneNumberValue, phoneNumber]);

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPhoneNumber = e.target.value;
    setPhoneNumber(newPhoneNumber);
    api.setEditCellValue({
      id,
      field,
      value: newPhoneNumber,
    });
  };

  return <TextField type="text" value={phoneNumber} onChange={handlePhoneNumberChange} variant="outlined" />;
};

const EditCountry: React.FC<CountryListProps> = ({ id, api, field, countries }) => {
  const stringValue = api.getCellValue(id, field) || "";
  const [currentSelectedCountry, setCurrentSelectedCountry] = useState(stringValue);

  const handleCountryChange = (e: SelectChangeEvent<string>) => {
    const newSelectedCountry = e.target.value;
    setCurrentSelectedCountry(newSelectedCountry);
    api.setEditCellValue({ id, field, value: newSelectedCountry });
  };

  return (
    <Select label="Country" value={currentSelectedCountry} onChange={handleCountryChange} fullWidth>
      {countries.map((country: string, index: number) => (
        <MenuItem key={index} value={country}>
          {country}
        </MenuItem>
      ))}
    </Select>
  );
};

const CustomerManagement: React.FC = () => {
  const [customers, setCustomers] = useState<CustomerRow[]>([]);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [countryCodeValue, setCountryCodeValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [selectedIds, setselectedIds] = useState<GridRowId[]>([]);
  const [showCreateCustomerDialog, setShowCreateCustomerDialog] = useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [showFilter, setShowFilter] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const countries = useMemo(() => countryList().getLabels(), []);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const { data, isSuccess, isFetching } = useGetCustomersQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
  });
  const [updateCustomer] = useUpdateCustomerMutation();
  const [deleteCustomer] = useDeleteCustomerMutation();

  useEffect(() => {
    if (isSuccess) {
      console.log(data.data);

      setCustomers(data.data);

      setTotalItems(data.total_items);
    }
  }, [data, isSuccess]);

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    const idsToDelete = selectedRows.filter(Boolean);
    setselectedIds(idsToDelete);

    if (idsToDelete.length === 1) {
      const idObject = { idsToDelete: [idsToDelete[0]] };
      deleteCustomer(idObject)
        .then(() => {
          setCustomers((oldCustomers) => oldCustomers.filter((s) => s.customer_id !== undefined && s.customer_id !== idsToDelete[0]));
          setSelectedRows([]);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      deleteCustomer({ idsToDelete })
        .then(() => {
          setCustomers((oldCustomers) => oldCustomers.filter((s) => s.customer_id !== undefined && !idsToDelete.includes(s.customer_id)));
          setSelectedRows([]);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    setIsDeleteDialogOpen(false);
  };

  const handleRowSelection = (selectedIds: GridRowId[]) => {
    setSelectedRows(selectedIds.filter(Boolean));
  };

  const handleUpdate = async (newRow: GridRowModel) => {
    updateCustomer({
      id: newRow.id,
      name: newRow.name,
      email: newRow.email,
      phone: newRow.phone,
      phoneCountryCode: newRow.phoneCountryCode,
      address_line_1: newRow.address_line_1,
      address_line_2: newRow.address_line_2,
      city: newRow.city,
      post_code: newRow.post_code,
      region_province: newRow.region_province,
      country: newRow.country,
    });
  };
  const handleAddClick = () => {
    setShowCreateCustomerDialog(true);
  };

  const handleCloseDialog = () => {
    setShowCreateCustomerDialog(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };
  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleSearch = (searchInput: { searchColumns: string[]; searchKeyword: string }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", editable: true, width: 300 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: true,
      renderEditCell: EditEmailField,
    },
    // {
    //   field: "phoneCountryCode",
    //   headerName: "Country Code",
    //   width: 100,
    //   editable: true,
    //   valueGetter: ({ row }) => row.phoneCountryCode || "",
    //   renderEditCell: ({ id, api, field }: any) => (
    //     <EditCountryCode
    //       id={id}
    //       api={api}
    //       field={field}
    //       countryFieldValue={countryCodeValue}
    //       setCountryFieldValue={setCountryCodeValue}
    //     />
    //   ),
    // },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 300,
      editable: true,
      valueGetter: ({ row }) => row.phone || "",
      renderEditCell: ({ id, api, field }: any) => (
        <EditPhoneNumber id={id} api={api} field={field} phoneNumberValue={phoneNumberValue} setPhoneNumberValue={setPhoneNumberValue} />
      ),
    },
    {
      field: "address_line_1",
      headerName: "Address Line 1",
      flex: 1,
      editable: true,
    },
    // {
    //   field: "address_line_2",
    //   headerName: "Address Line 2",
    //   flex: 1,
    //   editable: true,
    // },
    { field: "city", headerName: "City", flex: 1, editable: true },
    // { field: "post_code", headerName: "Post Code", flex: 1, editable: true },
    //{ field: "region_province", headerName: "Region", flex: 1, editable: true },
    // {
    //   field: "region_province",
    //   headerName: "Region",
    //   width: 100,
    //   editable: true,
    //   valueGetter: ({ row }) => (row.region_province ? row.region_province : ""),
    // },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      editable: true,
      valueGetter: ({ row }) => (row.address?.country ? row.address.country : ""),
      renderEditCell: ({ id, api, field }: any) => <EditCountry id={id} api={api} field={field} countries={countries} />,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const hasError = params.props.value === "";
        return { ...params.props, error: hasError };
      },
    },
    { field: "openingBalance", headerName: "Opening Balance", editable: true, width: 300 },
  ];

  return (
    <Box>
      <PageHeader title="Customer" onAdd={handleAddClick} />
      <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} severity="success" message="Customer successfully created" />
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />
      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <Box width="100%">
            <PageManagementGrid
              rows={customers}
              columnFields={columns}
              onDelete={handleDelete}
              onUpdate={handleUpdate}
              paginationModel={paginationModel}
              isFetching={isFetching}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              checkboxSelection
              onSelectionModelChange={handleRowSelection}
              sortModel={[{ field: "name", sort: "asc" }]}
            />
          </Box>
        </Grid>
      </Grid>

      {showCreateCustomerDialog && <CreateCustomer openDialog={showCreateCustomerDialog} handleCloseDialog={handleCloseDialog} onSuccess={openSuccessbar} />}
      {isDeleteDialogOpen && (
        <DialogInput
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onConfirm={handleDelete}
          title={"Confirm deletion"}
          content="Are you sure you want to delete this data? This action cannot be undone."
        />
      )}

      {selectedRows.length === 1 ? (
        <Button variant="contained" color="secondary" onClick={handleDeleteClick}>
          Delete Customer
        </Button>
      ) : selectedRows.length > 1 ? (
        <Button variant="contained" color="secondary" onClick={handleDeleteClick}>
          Delete Selected Customers
        </Button>
      ) : null}
    </Box>
  );
};

export default CustomerManagement;
