import React, { useEffect, useState } from "react";

import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Container, Grid } from "@mui/material";
import { ITableOffer } from "../../../../types/financeTypes";
import { defaultPaginationModel } from "../../../../utils/queryUtils";
import { IPagination } from "../../../../types/customTypes";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
const ManageOffersReceived = ({ openDialog, handleCloseDialog, offersProp }: any) => {
  const columns: GridColDef[] = [
    {
      field: "supplierName",
      type: "string",
      headerName: "Supplier Name",
      width: 130,
    },
    {
      field: "advancePaymentPercentage",
      headerName: "Advance Payment % ",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "advancePaymentAmount",
      headerName: "Advance Payment Amount",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "advancePaymentMaturity",
      headerName: "Advance Payment Maturity",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expenseAmount",
      headerName: "Expense Amount",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remainingPaymentAmount",
      headerName: "Remaining Payment Amount",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remainingPaymentMaturity",
      headerName: "Remaining Payment Maturity",
      type: "number",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requestedAmount",
      headerName: "Requested Amount",
      type: "number",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "offeredAmount",
      headerName: "Offered Amount",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    { field: "status", type: "string", headerName: "Status", width: 130, align: "center", headerAlign: "center" },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
    },
    {
      field: "offeredAt",
      headerName: "Offered At",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
    },
  ];

  const [offers, setOffers] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  console.log("offers in here", offers);
  useEffect(() => {
    console.log("offers props", offersProp);
    setOffers(offersProp);
  }, [offersProp]);

  useEffect(() => {
    if (offers && offers.length > 0) {
      const tableStructure: any = offers.map((offer) => ({
        id: offer.id,
        supplierName: offer.finance_name,
        advancePaymentPercentage: offer.advance_payment_percentage,
        advancePaymentAmount: parseFloat(offer.advance_payment_amount.toString()),
        advancePaymentMaturity: offer.advance_payment_maturity,
        expenseAmount: parseFloat(offer.expense_amount.toString()),
        remainingPaymentAmount: parseFloat(offer.remaining_payment_amount.toString()),
        remainingPaymentMaturity: offer.remaining_payment_maturity,
        requestedAmount: offer.requestedTotalAmount,
        offeredAmount: offer.offeredTotalAmount,
        status: offer.status,
        offeredAt: offer.createdAt,
        dueDate: offer.valid_until,
      }));

      setData(tableStructure);
    }
  }, [offers]);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth={fullWidth} maxWidth={maxWidth}>
      <DialogTitle id="responsive-dialog-title">{"Offers"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <DataGrid
            rows={data}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default ManageOffersReceived;
