import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useSubmitExchangeCodeMutation } from "../../store/api/bankAccountApi";

export default function BankAccountRedirectPage() {
  const location = useLocation();

  const [exchangeCode, setExchangeCode] = useState("");
  const [linkContainsError, setLinkContainsError] = useState(false);
  const [userId, setUserId] = useState("");

  const [submitExchangeCodeMutation, { isSuccess, isError, isLoading }] = useSubmitExchangeCodeMutation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const error = params.get("error");
    const userId = params.get("state");
    if (error) {
      setLinkContainsError(true);
    }

    if (code) {
      setExchangeCode(code);
    }

    if (userId) {
      setUserId(userId);
    }

    if (!userId || !code) setLinkContainsError(true);
  }, []);

  useEffect(() => {
    if (userId && exchangeCode) {
      submitExchangeCodeMutation({ userId, code: exchangeCode });
    }
  }, [userId, exchangeCode]);

  useEffect(() => {
    if (linkContainsError) {
      errorAlert();
    } else if (exchangeCode) {
      // submitExchangeCode(exchangeCode);
    }
  }, [linkContainsError, exchangeCode]);

  useEffect(() => {
    if (isLoading) {
      loadingAlert();
    }
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    console.log("isSccess", isSuccess);

    if (isSuccess === true) {
      successAlert();
    } else if (isError === true) {
      errorAlert();
    }
    // eslint-disable-next-line
  }, [isSuccess, isError]);

  useEffect(() => {
    if (isError === true) {
      errorAlert();
    }
    // eslint-disable-next-line
  }, [isError]);

  const successAlert = () => {
    Swal.fire({
      title: "Successfully Linked Bank Account",
      icon: "success",

      confirmButtonText: "Go to Console",
      customClass: {
        confirmButton: "custom-confirm-button-class",
      },
    }).then(async (val) => {
      if (val.isConfirmed) {
        window.location.href = `/createBankAccount`;
      }
    });
  };

  const errorAlert = async () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      confirmButtonText: "RETRY",
      //   showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: "Go to dashboard",
    }).then(async (val) => {
      if (val.isConfirmed) {
        // await submitExchangeCode();
      }
      if (val.isDismissed) {
        // window.location.href = `${UI_API_BASE}/dashboard`;
      }
    });
  };

  const loadingAlert = () => {
    Swal.fire({
      title: "Do not close this window, this may take a few minutes.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      showCancelButton: false,
      showConfirmButton: false,
    });
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    ></div>
  );
}
