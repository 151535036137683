import { z } from "zod";

export const SalesInvoiceLineSchema = z.object({
 

  product: z.string(),
  unit_price:  z.number().gt(0),
  subtotal_amount: z.number().gt(0),
  line_total_amount: z.number().gt(0),
  line_vat_amount: z.number().gt(0),
  quantity: z.number().gt(0),
  product_id:z.string(),
  line_vat_percentage: z.number().gte(0),










});
