import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../features/authenticationSlice";
import { getCookie } from "../../utils/getCookie";
import { purchaseInvoiceApiBase } from "../env";
import { deleteAllCookies } from "../../utils/getCookie";

const baseQuery = fetchBaseQuery({
  //https://desktop.payeda.net/api/v1
  //http://localhost:5000/v1
  // baseUrl: purchaseInvoiceApiBase + "/v1",
  baseUrl: purchaseInvoiceApiBase,
  prepareHeaders: (headers, { getState }) => {
    const token = getCookie("accessToken");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
      return headers;
    }
  },
});

const baseQueryWithReauth = async (args: any, api: any, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 403) {
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/refresh", api, extraOptions);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }

  else if(result?.error?.status === 401){
    deleteAllCookies()
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    "Supplier",
    "Subcategory",
    "Product",
    "Invoice",
    "Customer",
    "BankAccount",
    "Receipt",
    "Basket",
    "Team",
    "Offer",
    "FinancingRequest",
    "Finance",
    "FactoringRequestUserTable",
    "FinanceMembers",
    "SupplierNote"
  ],
  endpoints: (builder) => ({}),
});
