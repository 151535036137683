import { apiSlice } from "./baseApi";
import { IGetBasket, IGetBasketById, MIQuerySearchParams } from "../../types/financeTypes";
import { financeBaseApi } from "../env";

export interface IAddInvoiceToBasket {
  financeId: string;
  invoiceId: string;
  financing_request_id: string;
  shouldCreateNewBasket: number;
  selectedBasketId: string;
}

export interface removeBasketBody {
  basket_detail_ids: number[];
}

export const basketSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addInvoiceToBasket: builder.mutation({
      query: (addInvoiceToBasketBody: IAddInvoiceToBasket) => ({
        url: financeBaseApi + "/v1/baskets",
        method: "POST",
        body: addInvoiceToBasketBody,
      }),

      invalidatesTags: ["Basket"],
    }),

    getBasketById: builder.query<IGetBasketById, number>({
      query: (basketId) => ({
        url: financeBaseApi + `/v1/baskets/${basketId}`,
        method: "GET",
      }),
      providesTags: ["Basket"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getBasketByBasketDetailsId: builder.query<IGetBasket, number>({
      query: (basketDetailsId) => ({
        url: financeBaseApi + `/v1/baskets/details/${basketDetailsId}`,
        method: "GET",
      }),
      providesTags: ["Basket"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getBasketsByFinanceId: builder.query<IGetBasket[], MIQuerySearchParams>({
      query: ({ pageInfo, id }) => {
        const queryParams = {
          page: pageInfo.page.toString(),
          limit: pageInfo.pageSize.toString(),
        };
        return {
          url: financeBaseApi + `/v1/baskets/finance/${id}?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Basket"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    removeBasketDetails: builder.mutation({
      query: (removeBasketsBody: removeBasketBody) => ({
        url: financeBaseApi + "/v1/remove-baskets",
        method: "POST",
        body: removeBasketsBody,
      }),

      invalidatesTags: ["Basket"],
    }),
  }),
});

export const {
  useGetBasketByIdQuery,
  useAddInvoiceToBasketMutation,
  useGetBasketByBasketDetailsIdQuery,
  useGetBasketsByFinanceIdQuery,
  useRemoveBasketDetailsMutation,
} = basketSlice;
