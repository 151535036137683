import { Container, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ISalesInvoice } from "../../../types/customTypes";
import InvoiceDetailItem from "../../invoice/manageInvoice/InvoiceDetailsItem";
import { useLocation } from 'react-router-dom';

function ViewSalesInvoiceDetails() {
  const location = useLocation();
  const { salesInvoiceDetails, selectedInvoiceId } = location.state;
  const selectedInvoice = salesInvoiceDetails.find((invoice: ISalesInvoice) => invoice.id === selectedInvoiceId);
  const invoiceLines = selectedInvoice.invoice_lines;

  const columns = [
    { field: "product", headerName: "Product Name", width: 200 },
    { field: "quantity", headerName: "Quantity", width: 200 },
    { field: "unit_price", headerName: "Unit Price", width: 150 },
    { field: "line_discount_amount", headerName: "Discount", width: 150 },
    { field: "line_vat_percentage", headerName: "VAT", width: 150 },
    { field: "line_total_amount", headerName: "Net Amount", width: 150 },
  ];

  return (
    <Container>
      <InvoiceDetailItem
        label="Sales Invoice Number"
        value={selectedInvoice.invoice_number}
      />
      <InvoiceDetailItem
        label="Issue Date"
        value={selectedInvoice.issued_date}
      />
      <InvoiceDetailItem
        label="Due Date"
        value={selectedInvoice.due_date}
      />
      <Typography variant="body1" sx={{ marginTop: '20px' }}>Invoice Lines</Typography>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid rows={invoiceLines} columns={columns} getRowId={(row) => row.quantity} />
      </div>
    </Container>
  );
}

export default ViewSalesInvoiceDetails;
