import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Alert,
  MenuItem,
  FormControl,
  Typography,
  styled,
  AlertTitle,
  IconButton,
  InputLabel,
  Snackbar,
} from "@mui/material";
import DialogInput from "../Dialog";
import { useCreateOfferMutation } from "../../store/api/offerApi";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CurrencyInput from "react-currency-input-field";
import { useForm } from "react-hook-form";
import { OfferBodySchema } from "../../validation/finance/offer.validation";
import { ICreateOfferBody } from "../../types/financeTypes";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface ICreateOfferProps {
  basketId: number;
  supplierId: number;
  supplierName: string;
  totalAmount: number;
  hasOffer: number;
}

function CreateOffer({ basketId, supplierName, totalAmount, supplierId, hasOffer }: ICreateOfferProps) {
  const { handleSubmit } = useForm();

  const typeArray = ["hour", "day", "month", "year"];
  console.log("supplierId", supplierId);
  const [createNewOffer, mutationResult] = useCreateOfferMutation();
  const [formData, setFormData] = useState<Partial<ICreateOfferBody>>({});
  const [advancePaymentAmount, setAdvancePaymentAmount] = useState("");
  const [advancePaymentPercentage, setAdvancePaymentPercentage] = useState("");
  const [advancePaymentMaturity, setAdvancePaymentMaturity] = useState("");
  const [advancePaymentMaturityType, setAdvancePaymentMaturityType] = useState("");
  const [remainingPaymentAmount, setRemainingPaymentAmount] = useState("");
  const [remainingPaymentPercentage, setRemainingPaymentPercentage] = useState("");
  const [remainingPaymentMaturity, setRemainingPaymentMaturity] = useState("");
  const [remainingPaymentMaturityType, setRemainingPaymentMaturityType] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expensePercentage, setExpensePercentage] = useState("");
  const [validUntil, setValidUntil] = useState("");
  const [file, setFile] = useState<File | null>(null);

  const { isSuccess, isError, error, data: newOffer } = mutationResult;

  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

  const handleCloseErrorSnackbar = () => {
    setErrorSnackbarOpen(false);
  };

  const displayErrorSnackbar = (errors: any[]) => {
    const customErrorMessages = errors
      .map((error) => {
        if (error.message.includes("Number")) {
          const fieldName = error.path[error.path.length - 1].replace(/_/g, " "); // Get the last element of path array and replace underscores with spaces
          return error.message.replace("Number", fieldName); // Replace "Number" with the field name
        } else {
          return error.message;
        }
      })
      .join(", ");

    setErrorSnackbarMessage(customErrorMessages);
    setErrorSnackbarOpen(true);
  };

  useEffect(() => {
    if (expenseAmount !== "" && expensePercentage !== "") {
      const totalAdvanceAndExpenseAmount = parseFloat(advancePaymentAmount) + parseFloat(expenseAmount);
      const findRemainingAmount = totalAmount - totalAdvanceAndExpenseAmount;
      setRemainingPaymentAmount(findRemainingAmount + "");

      const totalPercentage = parseFloat(advancePaymentPercentage) + parseFloat(expensePercentage);
      const calculateRemainingPercentage = 100 - totalPercentage;
      setRemainingPaymentPercentage(calculateRemainingPercentage + "");
    } else {
      setRemainingPaymentAmount("");
      setRemainingPaymentPercentage("");
    }
  }, [advancePaymentAmount, advancePaymentPercentage, expenseAmount, expensePercentage, totalAmount]);

  const calculatePerct = (e: number, advance: boolean, expense: boolean) => {
    if (advance) {
      const amount = (totalAmount * e) / 100;
      setAdvancePaymentPercentage(e.toString());
      setAdvancePaymentAmount(amount + "");
    } else if (expense) {
      const amount = (totalAmount * e) / 100;
      setExpensePercentage(e.toString());
      setExpenseAmount(amount + "");
    }
  };

  useEffect(() => {
    if (isSuccess) setShowSuccessSnackbar(true);
    if (isError) setShowErrorSnackbar(true);
    handleCloseDialog();
  }, [isSuccess, isError]);

  const onSubmit = async (data: any) => {
    const offerBody: ICreateOfferBody = {
      basket_id: basketId,
      supplier_id: supplierId,
      finance_account_id: 1,
      offer_data: {
        advance_payment_amount: parseFloat(advancePaymentAmount),
        advance_payment_percentage: parseFloat(advancePaymentPercentage),
        expense_amount: parseFloat(expenseAmount),
        expense_percentage: parseFloat(expensePercentage),
        remaining_payment_amount: parseFloat(remainingPaymentAmount),
        remaining_payment_percentage: parseFloat(remainingPaymentPercentage),
        advance_payment_maturity: +advancePaymentMaturity,
        advance_payment_maturity_type: advancePaymentMaturityType as "hour" | "day" | "month" | "year",
        remaining_payment_maturity: +remainingPaymentMaturity,
        remaining_payment_maturity_type: remainingPaymentMaturityType as "hour" | "day" | "month" | "year",
        offer_document: "1",
        valid_until: new Date(validUntil),
      },
    };
    console.log(offerBody);

    try {
      // Validate offerBody
      const validatedOfferBody = OfferBodySchema.parse(offerBody) as ICreateOfferBody;
      console.log("validatedOfferBody", validatedOfferBody);
      // If validation succeeds, proceed with submission
      createNewOffer(validatedOfferBody);
    } catch (error: any) {
      console.log(error);
      displayErrorSnackbar(error.errors);

      // Handle the validation error here, e.g., display a message to the user
    }
  };

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    switch (field) {
      case "advance_payment_amount":
        setAdvancePaymentAmount(value);
        break;
      case "advance_payment_maturity":
        setAdvancePaymentMaturity(value);
        break;
      case "advance_payment_percentage":
        setAdvancePaymentPercentage(value);
        break;
      case "advance_payment_maturity_type":
        setAdvancePaymentMaturityType(value);
        break;
      case "remaining_payment_amount":
        setRemainingPaymentAmount(value);
        break;
      case "remaining_payment_percentage":
        setRemainingPaymentPercentage(value);
        break;
      case "remaining_payment_maturity":
        setRemainingPaymentMaturity(value);
        break;
      case "remaining_payment_maturity_type":
        setRemainingPaymentMaturityType(value);
        break;
      case "expense_amount":
        setExpenseAmount(value);
        break;
      case "expense_percentage":
        setExpensePercentage(value);
        break;
      case "valid_until":
        setValidUntil(value);
        break;
      default:
        break;
    }
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleUploadClick = () => {
    console.log(file);
  };

  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);

  const handleCloseSnackbar = () => {
    isSuccess && setShowSuccessSnackbar(false);
    isError && setShowErrorSnackbar(false);
  };

  return (
    <>
      <IconButton disabled={(hasOffer === 0 ? false : true) || isSuccess ? true : false} title="Create New Offer" onClick={handleOpenDialog} color="secondary">
        <LocalOfferIcon />
      </IconButton>
      <Snackbar open={errorSnackbarOpen} autoHideDuration={12000} onClose={handleCloseErrorSnackbar}>
        <Alert severity="error">{errorSnackbarMessage}</Alert>
      </Snackbar>

      {isSuccess && (
        <Snackbar open={showSuccessSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="success">
            Offer has been created successfully
          </Alert>
        </Snackbar>
      )}
      {isError && (
        <Snackbar open={showErrorSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            Unable to create Offer
          </Alert>
        </Snackbar>
      )}
      <DialogInput
        open={isDialogOpen}
        onClose={handleCloseDialog}
        title="New Offer"
        content={
          <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
            <Typography gutterBottom sx={{ fontStyle: "italic" }}>
              * Indicates required fields
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container width={"530px"} spacing={2}>
                <Grid item xs={6}>
                  <Alert icon={false} severity="success">
                    <AlertTitle>Supplier Name</AlertTitle>
                    {supplierName}
                  </Alert>
                </Grid>
                <Grid item xs={6}>
                  <Alert icon={false} severity="success">
                    <AlertTitle>Total Amount</AlertTitle>
                    {"$" + totalAmount}
                  </Alert>
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Advance Payment Percentage *</InputLabel>

                  <TextField
                    type="number"
                    fullWidth
                    value={advancePaymentPercentage}
                    onChange={(e) => calculatePerct(parseFloat(e.target.value), true, false)}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Advance Payment Amount</InputLabel>
                  <CurrencyInput
                    customInput={TextField}
                    placeholder="Advance Payment Amount *"
                    allowDecimals={true}
                    decimalsLimit={2}
                    // defaultValue={0}
                    value={advancePaymentAmount ? parseFloat(advancePaymentAmount).toFixed(2) : "0.00"}
                    prefix="GBP "
                    required
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Expense Percentage *</InputLabel>

                  <TextField
                    required
                    type="number"
                    fullWidth
                    value={expensePercentage}
                    onChange={(e) => calculatePerct(parseFloat(e.target.value), false, true)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Expense Amount</InputLabel>

                  <CurrencyInput
                    required
                    customInput={TextField}
                    placeholder="Expense Amount *"
                    allowDecimals={true}
                    decimalsLimit={2}
                    value={expenseAmount ? parseFloat(expenseAmount).toFixed(2) : "0.00"}
                    prefix="GBP "
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Remaining Payment Percentage *</InputLabel>

                  <TextField required type="number" fullWidth value={parseFloat(remainingPaymentPercentage).toFixed(2)} />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Remaining Payment Amount</InputLabel>
                  <CurrencyInput
                    required
                    customInput={TextField}
                    placeholder="Remaining Payment Amount *"
                    allowDecimals={true}
                    decimalsLimit={2}
                    value={remainingPaymentAmount ? parseFloat(remainingPaymentAmount).toFixed(2) : "0.00"}
                    prefix="GBP "
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>Advance Payment Maturity *</InputLabel>

                  <TextField required type="number" fullWidth value={advancePaymentMaturity} onChange={handleFieldChange("advance_payment_maturity")} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ marginTop: "23px" }}
                      InputLabelProps={{
                        sx: { fontSize: "small" }, // Custom style for the label font size
                      }}
                      label="Advance Payment Maturity Type*"
                      defaultValue=""
                      select
                      value={advancePaymentMaturityType}
                      onChange={handleFieldChange("advance_payment_maturity_type")}
                    >
                      {typeArray.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <InputLabel>Remaining Payment Maturity *</InputLabel>

                  <TextField required type="number" fullWidth value={remainingPaymentMaturity} onChange={handleFieldChange("remaining_payment_maturity")} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      required
                      sx={{ marginTop: "23px" }}
                      InputLabelProps={{
                        sx: { fontSize: "small" }, // Custom style for the label font size
                      }}
                      label="Remaining Payment Maturity Type*"
                      defaultValue=""
                      select
                      value={remainingPaymentMaturityType}
                      onChange={handleFieldChange("remaining_payment_maturity_type")}
                    >
                      {typeArray.map((category, index) => (
                        <MenuItem key={index} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    label="Valid Until *"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    onChange={(e) => setValidUntil(e.target.value)}
                    value={validUntil}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button component="label" variant="contained" color="secondary" startIcon={<CloudUploadIcon />} onClick={handleUploadClick}>
                    Offer Document *
                    <VisuallyHiddenInput type="file" onChange={handleFileChange} />
                  </Button>
                </Grid>

                <Grid item xs={6} marginBottom="20px">
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        }
        onConfirmText="Confirm"
        onCancelText="Cancel"
        noDefaultButtons
      />
    </>
  );
}

export default CreateOffer;
