import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyGetSupplierNoteByIdQuery } from "../../store/api/supplierNoteApi";
import { ISupplierNote } from "../../types/customTypes";
import LoadingSpinner from "../../components/LoadingSpinner";
import DialogInput from "../../components/Dialog";
import { formatDate } from "../../utils/dateFormatter";

function NotePreviewItem({ label, value }: { label: string; value: any }) {
  return (
    <Box border="1px solid #ccc" borderRadius={4} p={2} mb={2} display="flex" justifyItems="center" alignItems="center">
      {label === "Description" ? (
        <Typography variant="subtitle1" fontWeight="bold">
          {label}:
          <Typography variant="body1" display="inline" ml={1}>
            {value}
          </Typography>
        </Typography>
      ) : (
        <>
          <Typography variant="subtitle1" fontWeight="bold">
            {label}:
          </Typography>
          <Typography variant="body1" ml={1}>
            {value}
          </Typography>
        </>
      )}
    </Box>
  );
}

function PreviewNote({ openDialog, handleCloseDialog, noteId }: { noteId: string; openDialog: boolean; handleCloseDialog: () => void }) {
  const [noteDetails, setNoteDetails] = useState<ISupplierNote | undefined>();
  const [getNote, { isSuccess, isError, isLoading, data: supplierNote }] = useLazyGetSupplierNoteByIdQuery();

  useEffect(() => {
    if (noteId) {
      getNote(noteId);
    }
  }, [noteId]);

  useEffect(() => {
    if (isSuccess && supplierNote) {
      setNoteDetails(supplierNote);
    }
  }, [isSuccess, supplierNote]);

  if (isLoading) return <LoadingSpinner />;

  return (
    <DialogInput
      title="Supplier Note"
      open={openDialog}
      onClose={handleCloseDialog}
      noDefaultButtons
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <NotePreviewItem label="Ref Id" value={supplierNote?.refId} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NotePreviewItem label="Issue Date" value={formatDate(supplierNote?.issueDate)} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NotePreviewItem label="Supplier" value={supplierNote?.supplierName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <NotePreviewItem label="Total Amount" value={supplierNote?.totalAmount} />
          </Grid>
          <Grid item xs={12}>
            <NotePreviewItem label="Description" value={supplierNote?.description} />
          </Grid>
        </Grid>
      }
    />
  );
}

export default PreviewNote;
