import React, { useEffect, useState } from "react";
import { IPagination } from "../../../types/customTypes";
import { ITableOffer, MIQuerySearchParams } from "../../../types/financeTypes";
import OfferTable from "../../../components/finance/MTable";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Box, Grid } from "@mui/material";
import { useGetAllOffersForFinanceQuery } from "../../../store/api/offerApi";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import { Loading } from "../../../components/finance/LoadingComponent";
import SimpleHeader from "../../../components/finance/SimpleHeader";
import { useFetchUserInfoQuery } from "../../../store/api/infoApi";
import LoadingSpinner from "../../../components/LoadingSpinner";

const OfferManagement: React.FC = () => {
  const columns: GridColDef[] = [
    {
      field: "supplierName",
      type: "string",
      headerName: "Supplier Name",
      width: 130,
    },
    {
      field: "advancePaymentPercentage",
      headerName: "Advance Payment % ",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "advancePaymentAmount",
      headerName: "Advance Payment Amount",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "advancePaymentMaturity",
      headerName: "Advance Payment Maturity",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "expenseAmount",
      headerName: "Expense Amount",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remainingPaymentAmount",
      headerName: "Remaining Payment Amount",
      type: "number",
      width: 200,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "remainingPaymentMaturity",
      headerName: "Remaining Payment Maturity",
      type: "number",
      width: 230,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "requestedAmount",
      headerName: "Requested Amount",
      type: "number",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "offeredAmount",
      headerName: "Offered Amount",
      type: "number",
      width: 150,
      align: "center",
      headerAlign: "center",
    },
    { field: "status", type: "string", headerName: "Status", width: 130, align: "center", headerAlign: "center" },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
    },
    {
      field: "offeredAt",
      headerName: "Offered At",
      width: 200,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
    },
  ];

  const [offers, setOffers] = useState<ITableOffer[]>([]);

  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);

  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails, refetch: refetchUserDetails } = useFetchUserInfoQuery();
  const [financeId, setFinanceId] = useState<string>(userDetails.data.team_id ? userDetails.data.team_id : "");
  const [queryParams, setQueryParams] = useState<MIQuerySearchParams>({
    pageInfo: paginationModel,
    id: userDetails.data.team_id,
  });
  const { data, isSuccess, isFetching, refetch } = useGetAllOffersForFinanceQuery(queryParams);

  useEffect(() => {
    if (isUserInfoFetchSuccess && userDetails) setFinanceId(userDetails.data.team_id);
  }, [isUserInfoFetchSuccess, userDetails]);

  useEffect(() => {
    refetch();
    setQueryParams({
      pageInfo: paginationModel,
      id: financeId,
    });
  }, [financeId]);
  useEffect(() => {
    refetch();
    refetchUserDetails();
  }, []);
  useEffect(() => {
    if (isSuccess && data && data.length > 0) {
      const tableStructure: ITableOffer[] = data.map((offer) => ({
        id: offer.id,
        supplierName: offer.supplier_name,
        advancePaymentPercentage: offer.advance_payment_percentage,
        advancePaymentAmount: parseFloat(offer.advance_payment_amount.toString()),
        advancePaymentMaturity: offer.advance_payment_maturity,
        expenseAmount: parseFloat(offer.expense_amount.toString()),
        remainingPaymentAmount: parseFloat(offer.remaining_payment_amount.toString()),
        remainingPaymentMaturity: offer.remaining_payment_maturity,
        requestedAmount: offer.requestedTotalAmount,
        offeredAmount: offer.offeredTotalAmount,
        status: offer.status,
        offeredAt: offer.createdAt,
        dueDate: offer.valid_until,
      }));

      setOffers(tableStructure);
    }
  }, [data, isSuccess]);
  if (isUserInfoFetchLoading) return <LoadingSpinner />;
  else if (isUserInfoFetchSuccess && userDetails)
    return (
      <Box>
        <Grid container>
          <Grid item sm={12}>
            <SimpleHeader title="Offers"></SimpleHeader>

            {isFetching ? <Loading /> : <OfferTable rows={offers} columns={columns} />}
          </Grid>
        </Grid>
      </Box>
    );
  else return <div>Error fetching user information</div>;
};

export default OfferManagement;
