import { useState, useEffect, Dispatch, SetStateAction } from "react";

type PersistState = boolean;
type SetPersistState = Dispatch<SetStateAction<PersistState>>;

const usePersist = (): [PersistState, SetPersistState] => {
  const storedPersist = localStorage.getItem("persist");
  const initialPersistState: PersistState = storedPersist
    ? JSON.parse(storedPersist)
    : false;

  const [persist, setPersist] = useState<PersistState>(initialPersistState);

  useEffect(() => {
    localStorage.setItem("persist", JSON.stringify(persist));
  }, [persist]);

  return [persist, setPersist];
};

export default usePersist;
