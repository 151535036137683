import { useForm } from "react-hook-form";
import { FinanceAccountValues } from "../../../types/formValueType";
import useFormPersist from "react-hook-form-persist";
import { financeAcccountFormValues } from "./financeAccountStorageName";
import { Container } from "@mui/system";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearFormButton from "../../../components/ClearFormButton";
import { clearStorage } from "../../../utils/formStorage";
import {useWizard} from 'react-use-wizard';


function BankInfo() {

  const {nextStep} = useWizard()

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    reset
  } = useForm<FinanceAccountValues>({
    defaultValues: {
      bank_name: "",
      bank_phone: "",
      bank_email: "",
      name: "",
      email: "",
      password: "",
    },
    // resolver: zodResolver(CreateCustomerSchema),
  });

  useFormPersist(financeAcccountFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  })  ;

  const handlePhoneNumberInput = (phone: string) => {
    clearErrors("bank_phone");
    setValue("bank_phone", phone as string);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(financeAcccountFormValues)
    reset()
  }

  const onSubmit = () => {
    nextStep()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} mt={1}>
      <Grid item sm={12}>
        <TextField
          label="Bank name"
          {...register("bank_name", {
            required: "Required",
          })}
          fullWidth
          error={!!errors.bank_name}
          helperText={errors.bank_name?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <TextField
          label="Bank email"
          {...register("bank_email", {
            required: "Required",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Invalid email address",
            },
          })}
          fullWidth
          error={!!errors.bank_email}
          helperText={errors.bank_email?.message}
        />
      </Grid>
      <Grid item sm={12}>
        <InputLabel>Company Phone Number:</InputLabel>
        <PhoneInput
          value={watch("bank_phone")}
          {...register("bank_phone", {
            required: "Required",
          })}
          onChange={handlePhoneNumberInput}
          inputStyle={{ height: "50px" }}
        />
        {errors.bank_phone && <Typography color="red">{errors.bank_phone?.message}</Typography>}
      </Grid>
      <Grid item xs={12} marginBottom="20px">
        <LoadingButton type="submit" variant="contained" color="primary" >
          Next
        </LoadingButton>
        <ClearFormButton onClick={handleClearFormButtonClick} />
      </Grid>
    </Grid>
    </form>
    
  );
}

export default BankInfo;
