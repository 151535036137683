import { Typography } from "@mui/material";

function FileExistText() {
  return (
    <Typography variant="body1" sx={{ fontStyle: "italic" }} gutterBottom>
      Previously uploaded
    </Typography>
  );
}

export default FileExistText;
