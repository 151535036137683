import { Alert, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
  useForm,
  UseFormClearErrors,
  UseFormRegister,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import DialogInput from "../../../components/Dialog";
import { IProduct } from "../../../types/customTypes";
import { productAccountStatementValues } from "../../../types/formValueType";
import { productAccountStatementDefaultValues } from "../../../types/formDefaultValues";
import dayjs, { Dayjs } from "dayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearFormButton from "../../../components/ClearFormButton";
import { clearStorage } from "../../../utils/formStorage";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { FetchBaseQueryError, FetchBaseQueryMeta, QueryDefinition } from "@reduxjs/toolkit/dist/query";
import ProductSelectionField from "../../../components/ProductSelectionField";

interface AccountStatementCreationFormProps {
  
  openDialog: boolean;
  handleCloseDialog: () => void;
  onSubmission: any;
  clearErrors: UseFormClearErrors<productAccountStatementValues>;
  setValue: UseFormSetValue<productAccountStatementValues>;
  getAccountStatement: LazyQueryTrigger<any>;
  reset: UseFormReset<productAccountStatementValues>;
  control: Control<productAccountStatementValues>;
  register: UseFormRegister<productAccountStatementValues>;
  errors: FieldErrors<productAccountStatementValues>;
  watch: UseFormWatch<productAccountStatementValues>;
  handleClearButtonClick: () => void;
  isLoading: boolean;
  errorMessage: string
}

function AccountStatementCreationForm({
  openDialog,
  handleCloseDialog,
  clearErrors,
  control,
  errors,
  getAccountStatement,
  onSubmission,
  register,
  reset,
  setValue,
  watch,
  handleClearButtonClick,
  isLoading,
  errorMessage
}: AccountStatementCreationFormProps) {
  const [selectedProductId, setSelectedProductId] = useState<string>("");

  const handleStartDateChange = (date: Dayjs | null) => {
    if (date) {
      console.log(date.toString());
      clearErrors("startDate");
      setValue("startDate", date);
    }
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("endDate");
      setValue("endDate", date);
    }
  };

  const handleProductChange = (productId: string) => {
    clearErrors("productId");
    setValue("productId", productId);
  };

  const onNewProductCreation = (productDetails: IProduct) => {
    setValue("productId", productDetails._id);
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Generate Account Statement"
      onConfirmText="Generate"
      content={
        <form onSubmit={onSubmission}>
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "16px" }}>
              {errorMessage}
            </Alert>
          )}
          <Grid container spacing={2} mt={1}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={4} sm={6}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      value={dayjs(watch("startDate"))}
                      label="Start date *"
                      // maxDate={dayjs(dueDate)}
                      maxDate={dayjs(watch("endDate"))}
                      {...register("startDate", {
                        required: "Required",
                      })}
                      onChange={handleStartDateChange}
                      slotProps={{
                        textField: {
                          error: !!errors.startDate,
                          helperText: errors.startDate?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} sm={6}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <DatePicker
                      value={dayjs(watch("endDate"))}
                      label="End date *"
                      // maxDate={dayjs(dueDate)}
                      maxDate={dayjs()}
                      {...register("endDate", {
                        required: "Required",
                      })}
                      onChange={handleEndDateChange}
                      slotProps={{
                        textField: {
                          error: !!errors.endDate,
                          helperText: errors.endDate?.message,
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <ProductSelectionField
                  error={!!errors?.productId}
                  errorMessage={errors?.productId?.message}
                  onProductChange={handleProductChange}
                  setSelectedProduct={setSelectedProductId}
                  selectedProduct={watch("productId")}
                  onCreateProductSuccess={onNewProductCreation}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} marginBottom="20px">
            <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
              Generate
            </LoadingButton>
            <ClearFormButton onClick={handleClearButtonClick} />
          </Grid>
        </form>
      }
      noDefaultButtons
    />
  );
}

export default AccountStatementCreationForm;