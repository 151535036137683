import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { GridRenderEditCellParams } from "@mui/x-data-grid";
import CurrencyList from "currency-list";
import { useMemo } from "react";

function CurrencyEditCell(props: GridRenderEditCellParams) {
  const { value, api } = props;
  const currencies = useMemo(() => CurrencyList.getAll("en_US"), []);

  const handleCurrencyInput = (e: SelectChangeEvent<string>) => {
    api.setEditCellValue({
      ...props,
      value: e.target.value,
    });
  } 

  return (
      <Select
        labelId="sell-currency-label"
        id="sell-currency-select"
        label="Sell currency"
        value={value}
        onChange={handleCurrencyInput}
        fullWidth
        
      >
        {Object.entries(currencies).map(([code, currency], index) => (
          <MenuItem key={index} value={code}>
            {currency.name} ({currency.symbol})
          </MenuItem>
        ))}
      </Select>
  );
}

export default CurrencyEditCell;
