import CenteredContainer from "../components/CenteredContainer";
import { useSendVerificationEmailMutation } from "../store/api/authenticationApi";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EmailValue } from "../types/formValueType";
import EmailInputForm from "../components/EmailInputForm";

function ResendVerificationEmail() {
  const [errorMessage, setErrorMessage] = useState("");
  const [
    sendVerificationEmail,
    {
      isSuccess: isEmailSentSuccessful,
      isError: isEmailSentError,
      error: verificationError,
    },
  ] = useSendVerificationEmailMutation();

  const navigate = useNavigate();

  const onSubmit = (email: EmailValue) => {
    sendVerificationEmail(email);
  };

  useEffect(() => {
    if (isEmailSentSuccessful) {
      navigate("/verify-email");
    } else if (isEmailSentError) {
      const err: any = verificationError;

      const errorMsg = err.data?.message
        ? err.data?.message
        : "Operation was unsuccessful";

      setErrorMessage(errorMsg);
    }
  }, [isEmailSentSuccessful, isEmailSentError]);

  return (
    <CenteredContainer>
      <EmailInputForm
        title="Send Verification Email"
        subtitle="Enter your email address associated with your account and we'll send you a link to verify your email"
        buttonText="Send Verification Email"
        onSubmit={onSubmit}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
    </CenteredContainer>
  );
}

export default ResendVerificationEmail;
