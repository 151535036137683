import React, { FormEventHandler, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import NumbersIcon from "@mui/icons-material/Numbers";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import { useLazyFetchCompanyInfoQuery } from "../../store/api/createBusinessApi";
import CenteredContainer from "../../components/CenteredContainer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CreateBusinessValues } from "../../types/formValueType";
import { createBusinessFormValues } from "./formName";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";

export default function CompanyRegNumForm() {
  const [companyRegNum, setComRegNum] = useState("");
  const [fetchCompanyInfo, { isSuccess, isError, isLoading, error, data: companyInfo }] = useLazyFetchCompanyInfoQuery();
  const [errorMessage, setErrorMessage] = useState("");
  const { state } = useLocation();

  const [defaultInvoiceValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const {
    setValue,
    formState: { errors },
    watch,
    getValues,
    register,
  } = useForm<CreateBusinessValues>({
    defaultValues: defaultInvoiceValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const navigate = useNavigate();
  const {isReEdit} = getValues()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComRegNum(value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    fetchCompanyInfo(companyRegNum);
  };

  useEffect(() => {
    if (isSuccess) {
      setValue("company_reg_number", companyRegNum);
      navigate("/company-details-form", { state: companyInfo });
    } else if (isError) {
      let errMsg = "An error occurred. Try again";
      if (error) {
        console.log(error);
        const errData: any = "data" in error && error.data;
        errMsg = errData.message && errData.message;
      }
      setErrorMessage(errMsg);
    }
  }, [isError, isSuccess, error]);
  

  return (
    <CenteredContainer>
      <Container component="main" maxWidth="md">
        <Box component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 3,
              mx: 3,
              pt: 4,
              pb: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Company Registration Number
            </Typography>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="companyNum"
                  label="Company Registration Number"
                  autoComplete="companyNum"
                  autoFocus
                  color="warning"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <NumbersIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 12 }}
                  {...register("company_reg_number")}
                  onChange={handleChange}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading || companyRegNum === ""}
                  sx={{
                    mt: 2,
                  }}
                  component="button"
                >
                  Submit
                </Button>
                <Link to="/account-type-selection">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 2,
                      mb: 2,
                    }}
                  >
                    {isReEdit ? "Change Business Entity" : "Back"}
                  </Button>
                </Link>
              </form>
              {isError && (
                <Alert severity="error">
                  <Typography>{errorMessage}</Typography>
                </Alert>
              )}
            </Box>
              <BackToHomeLink/>
          </Box>
        </Box>
      </Container>
    </CenteredContainer>
  );
}
