import { baseInitialState, BaseInterface, } from "./baseState";


export interface User {
    email: string;
    password: string;
    confirmPassword: string;
    name: string;
    surname: string;
    team_type: string;
}

export interface AuthState extends BaseInterface {
    authenticated: boolean;
    user: User | null;
}

export const authInitialState : AuthState = {
    ...baseInitialState,
    authenticated: false,
    user: null
}

