import { apiSlice } from "./baseApi";
import { setCredentials } from "../features/authenticationSlice";
import { ILoginCredentials } from "../../types/customTypes";

import { salesInvoiceApiBase } from "../env";
export const authSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<ILoginCredentials, { email: string; password: string }>({
      query: (loginInfo) => ({  
        url: salesInvoiceApiBase + "/auth/signin",
        method: "POST",
        body: loginInfo,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
        } catch (error) {
          console.log(error);
        }
      },
    }),
    signUp: builder.mutation({
      query: (signUpInfo) => ({
        url: salesInvoiceApiBase + "/auth/signup",
        method: "POST",
        body: signUpInfo,
      }),
    }),
    refreshToken: builder.mutation({
      query: (refreshToken) => ({
        url: salesInvoiceApiBase + "/auth/refresh",
        method: "POST",
        body: refreshToken,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    sendVerificationEmail: builder.mutation({
      query: (email) => ({
        url: salesInvoiceApiBase + "/auth/email-verification-link",
        method: "POST",
        body: email,
      }),
    }),
    confirmVerificationEmail: builder.mutation({
      query: (token) => ({
        url: salesInvoiceApiBase + "/auth/verify-email",
        method: "POST",
        body: token,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (email) => ({
        url: salesInvoiceApiBase + "/auth/forgot-password",
        method: "POST",
        body: email,
      }),
    }),
    verifyToken: builder.mutation({
      query: (tokenInfo) => ({
        url: salesInvoiceApiBase + "/auth/verify-password-otp",
        method: "POST",
        body: tokenInfo,
      }),
    }),
    resetPassword: builder.mutation({
      query: (body) => ({
        url: salesInvoiceApiBase + "/auth/reset-password",
        method: "POST",
        body: body,
      }),
    }),

    logout: builder.mutation({
      query: (token) => ({
        url: salesInvoiceApiBase + "/auth/signout",
        method: "POST",
        body: token,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignUpMutation,
  useRefreshTokenMutation,
  useSendVerificationEmailMutation,
  useConfirmVerificationEmailMutation,
  useForgotPasswordMutation,
  useVerifyTokenMutation,
  useResetPasswordMutation,

  useLogoutMutation,
} = authSlice;
