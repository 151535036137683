import { IPagination, IQuerySearchParams } from "../types/customTypes";

export const defaultPaginationModel: IPagination = {
  page: 0,
  pageSize: 10
}

export const defaultQueryParams: IQuerySearchParams = {
  pageInfo: defaultPaginationModel,
    searchColumns: [],
    searchKeyword: "",
}

