import { Box } from "@mui/material";
import FinancialInformation from "./FinancialInformation";
import PersonalInformation from "./PersonalInformation";
import CompanyInformation from "./CompanyInformation";
import ReceivePaymentInfo from "./ReceivePaymentInfo";

const SettingsPage = () => {
  return (
    <>
      <Box marginBottom={2} marginTop={2}>
        <CompanyInformation />
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <ReceivePaymentInfo />
      </Box>

      <Box marginBottom={2} marginTop={2}>
        <FinancialInformation />
      </Box>
      <Box marginBottom={2} marginTop={2}>
        <PersonalInformation />
      </Box>
    </>
  );
};

export default SettingsPage;
