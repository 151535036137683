import { z } from "zod";

export const invoiceLineSchema = z.object({
  productId: z.string().nonempty("Please select product"),
  quantity: z.number().min(1, "Quantity must be at least 1").positive(),
  unitPrice: z.number().gt(0, "Price cannot be 0"),
  lineGrossAmount: z.number().gt(0),
  lineNetAmount: z.number().gt(0),
  lineVatPercentage: z.number().gte(0),
  lineDiscountPercentage: z.number().gte(0),
  lineDiscountAmount: z.number().gte(0),
});
