import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { CircularProgress, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid } from "@mui/material";
import CustomerSelect from "../../../components/CustomerSelect";
import AlertSnackbar from "../../../components/Snackbar";
import PageTitle from "../../../components/PageTitle";
import { useForm } from "react-hook-form";
import { createFastSalesInvoiceSchema } from "../../../validation/salesInvoice.validationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { fastSalesInvoiceDefaultValues } from "../../../types/formDefaultValues";
import { salesInvoiceValues } from "../../../types/formValueType";
import dayjs, { Dayjs } from "dayjs";
import { useAddNewSalesInvoiceMutation } from "../../../store/api/salesInvoiceApi";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { base_url } from "../../../environment";
import QRCode from "qrcode.react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export default function CreateSalesInvoice() {
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [minDate, setMinDate] = useState<Dayjs>(dayjs());
  const [issuedDate, setIssuedDate] = useState<Dayjs>(dayjs());
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs());
  const [totalAmount, setTotalAmount] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [isQRDialogOpen, setIsQRDialogOpen] = useState(false);
  const [isWhatsAppDialogOpen, setIsWhatsAppDialogOpen] = useState(false);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [whatsAppNumber, setWhatsAppNumber] = useState("");
  const [invoiceURL, setInvoiceURL] = useState<string>("");
  const [telNumber, setTelNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [addNewInvoice, addInvoiceMutationResult] = useAddNewSalesInvoiceMutation();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    register,
  } = useForm<salesInvoiceValues>({
    defaultValues: fastSalesInvoiceDefaultValues,
    resolver: zodResolver(createFastSalesInvoiceSchema),
  });

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
    setErrorMessage("");
    setShowSuccessSnackbar(false);
    setShowErrorSnackbar(false);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invoiceURL);
  };

  const openWhatsAppDialog = () => {
    setIsWhatsAppDialogOpen(true);
    const phoneNum = countryCode + telNumber;
    setWhatsAppNumber(phoneNum || "");
    setWhatsAppMessage("Click here to pay your invoice " + invoiceURL);
  };

  const handleSendWhatsAppMessage = () => {
    if (!whatsAppNumber) {
      alert("Phone number cannot be empty!");
      return;
    }
    const url = `https://api.whatsapp.com/send?phone=${whatsAppNumber}&text=${encodeURIComponent(whatsAppMessage)}`;
    window.open(url, "_blank");
    setIsWhatsAppDialogOpen(false);
  };

  const handleDueDateChange = (date: any | null) => {
    if (date) {
      setDueDate(date);
      setValue("due_date", date);
    }
  };

  const handleIssueDateChange = (date: any | null) => {
    if (date) {
      setIssuedDate(date);
      setMinDate(date);
      setValue("issued_date", date);
    }
  };

  const handleCustomerInput = (customerId: any) => {
    setSelectedCustomer(customerId);
    setValue("customer_id", customerId);
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const response = (await addNewInvoice(data)) as {
      data: { invoice_id: string; customer_phone_country_code: string; customer_phone: string; invoice_data: any };
    };

    if ("data" in response) {
      const newInvoiceId = response.data.invoice_id;
      const url = `https://dev2.payeda.net/checkout?id=${newInvoiceId}`;
      setInvoiceURL(url);
      setCountryCode(response.data.invoice_data.customer_phone_country_code);
      setTelNumber(response.data.invoice_data.customer_phone);
      setSuccessMessage("Invoice created successfully.");
      setShowSuccessSnackbar(true);
      setIsQRDialogOpen(true);
    } else {
      setErrorMessage("Failed to create invoice.");
      setShowErrorSnackbar(true);
    }
    setIsLoading(false);
  };

  return (
    <Container sx={{ width: "100vw" }}>
      {successMessage && <AlertSnackbar open={showSuccessSnackbar} handleClose={handleCloseSnackbar} severity="success" message={successMessage} />}
      {errorMessage && <AlertSnackbar open={showErrorSnackbar} handleClose={handleCloseSnackbar} severity="error" message={errorMessage} />}
      <PageTitle title="Create Sales Invoice" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item lg={4} sm={12}>
              <DatePicker label="Issued Date" value={issuedDate} onChange={(date) => handleIssueDateChange(date)} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <DatePicker label="Due Date" value={dueDate} onChange={(date) => handleDueDateChange(date)} minDate={minDate} />
            </Grid>
            <Grid item lg={4} sm={12}>
              <TextField
                id="totalAmount"
                label="Total Amount"
                variant="outlined"
                fullWidth
                {...register("amount_to_be_paid")}
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                required
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <CustomerSelect
              onChange={handleCustomerInput}
              register={register}
              error={errors}
              selectedCustomerValue={selectedCustomer}
              setSelectedCustomerValue={setSelectedCustomer}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="serviceDescription"
              label="Service Description"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              {...register("productDescription")}
              value={serviceDescription}
              onChange={(e) => setServiceDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Create Invoice
            </Button>
          </Grid>
        </Grid>
      </form>
      <Dialog open={isQRDialogOpen} onClose={() => setIsQRDialogOpen(false)}>
        <DialogTitle>Invoice QR Code</DialogTitle>
        <DialogContent>
          <QRCode value={invoiceURL} size={256} />
          <Grid container spacing={2} justifyContent="center" style={{ marginTop: "10px" }}>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={copyToClipboard}>
                Copy
              </Button>
            </Grid>
            <Grid item>
              <Button size="small" color="secondary" variant="contained" startIcon={<WhatsAppIcon />} onClick={() => openWhatsAppDialog()}>
                Whatsapp
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog open={isWhatsAppDialogOpen} onClose={() => setIsWhatsAppDialogOpen(false)}>
        <DialogContent>
          <TextField
            label="Phone Number"
            value={whatsAppNumber}
            onChange={(e) => setWhatsAppNumber(e.target.value)}
            fullWidth
            margin="normal"
            helperText={!whatsAppNumber && "Phone number required!"}
          />
          <TextField
            label="Message"
            value={whatsAppMessage}
            onChange={(e) => setWhatsAppMessage(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <Button variant="contained" color="secondary" onClick={handleSendWhatsAppMessage}>
            Send
          </Button>
        </DialogContent>
      </Dialog>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent>
            <CircularProgress />
            <Typography variant="body1">Loading...</Typography>
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
}
