import { Add } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import PageTitle from "./PageTitle";

interface PageHeaderProps {
  title: string;
  onAdd?: () => void;
  customLeftMargin?: number
}

function PageHeader({ title, onAdd, customLeftMargin }: PageHeaderProps) {
  return (
    <Grid>
      <Grid item md={6} sm={12}>
        <PageTitle title={title} />
      </Grid>
      <Grid item sm={12} md={6} justifyContent="right" textAlign="center">
        <Button color="secondary" variant="contained" startIcon={<Add />} onClick={onAdd} sx={{
          mt: -5,
          mb: -7,
          marginLeft: customLeftMargin? customLeftMargin: 100,
          ":hover": {
            bgcolor: "#004d40",
          },
        }}>
          Create New
        </Button>
      </Grid>
    </Grid>
  );
}

export default PageHeader;