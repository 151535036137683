import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

export const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const StyledSuccessTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const StyledErrorTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledCloseButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

interface Props {
  open: boolean;
  title: string;
  message: string;
  isError?: boolean;
}

export default function AlertDialog({ title, message, open, isError }: Props) {
  const [isOpen, setOpen] = useState(open);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <StyledDialogTitle id="alert-dialog-title">{title}</StyledDialogTitle>
      <StyledDialogContent sx={{ marginTop: "10px" }}>
        {isError ? (
          <StyledErrorTypography variant="body1" id="alert-dialog-description">
            {message}
          </StyledErrorTypography>
        ) : (
          <StyledSuccessTypography variant="body1" id="alert-dialog-description">
            {message}
          </StyledSuccessTypography>
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledCloseButton onClick={handleClose} autoFocus>
          Close
        </StyledCloseButton>
      </StyledDialogActions>
    </Dialog>
  );
}
