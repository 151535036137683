import { apiSlice } from "./baseApi";

export const docSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchDocuments: builder.query<any, any>({
      query: ({ team_id, docType }) => ({
        url: `https://dev2.payeda.net/api/v1/account/docs?id=${team_id}&account_type=0&file_type=${docType}`,
      }),
    }),
  }),
});

export const {useFetchDocumentsQuery, useLazyFetchDocumentsQuery} = docSlice