import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseInterface, baseInitialState } from "../baseState";
import axios from "axios";

interface companyHouseState extends BaseInterface {
    companyHouse: any
}

const initialState : companyHouseState = {
  ...baseInitialState,
  companyHouse: null,
};

export const fetchCompanyHouseResult = createAsyncThunk("companyHouse/fetchCompanyHouseResult", async (registrationNumber) => {
  try {
    const response = await axios.get("https://dev2.payeda.net/api/v1/account/getCompanyInformation?companyNumber=" + registrationNumber);
    return response.data.businessData;
  } catch (error: any) {
    throw new Error(error.message);
  }
});

const companyHouseSlice = createSlice({
  name: "companyHouse",
  initialState,
  reducers: {
    resetCompanyHouse: (state) => {
      state.companyHouse = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyHouseResult.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      .addCase(fetchCompanyHouseResult.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companyHouse = action.payload;
      })
      .addCase(fetchCompanyHouseResult.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.error.message ?? "";
      });
  },
});
export const { resetCompanyHouse } = companyHouseSlice.actions;
export default companyHouseSlice.reducer;
