import { Grid, Typography } from "@mui/material";
import PageTitle from "../PageTitle";

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

interface PageHeaderProps {
  title: string;
  offerDialog: ReactJSXElement;
  removeButton: ReactJSXElement;
}

function CreateNewOfferPageHeader({ title, offerDialog, removeButton }: PageHeaderProps) {
  return (
    <Grid container spacing={2} width="100%" marginBottom="20px" alignItems="center">
      <Grid item md={6} sm={12}>
        <PageTitle title={title} />
      </Grid>
      <Grid item md={6} sm={12} justifyContent="right" textAlign="center">
        <Typography variant="body1">Create New Offer{offerDialog} </Typography>
        <Typography variant="body1">Remove Selected Invoices{removeButton} </Typography>
      </Grid>
    </Grid>
  );
}

export default CreateNewOfferPageHeader;
