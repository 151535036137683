import React, { useEffect, useState } from "react";
import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { EmailValue } from "../types/formValueType";

export interface EmailInputFormProps {
  title: string;
  subtitle: string;
  buttonText: string;
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (email: EmailValue) => void;
}

const EmailInputForm: React.FC<EmailInputFormProps> = ({
  title,
  subtitle,
  buttonText,
  errorMessage,
  setErrorMessage,
  onSubmit,
}) => {
  const { register, handleSubmit, formState, clearErrors } =
    useForm<EmailValue>({
      defaultValues: {
        email: "",
      },
    });
  const { errors: formFieldError } = formState;

  const handleSubmission = (email: EmailValue) => {
    onSubmit(email);
  };

  const onEmailInput = () => {
    clearErrors();
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  return (
    <Box textAlign="center" width="500px">
      <Typography variant="h4" fontWeight="bold">
        {title}
      </Typography>
      <Typography variant="h6" mt={4} mb={5}>
        {subtitle}
      </Typography>
      <form onSubmit={handleSubmit(handleSubmission)}>
        <TextField
          label="Email Address"
          fullWidth
          {...register("email", {
            required: "Please enter email address",
            pattern: {
              value: /^\S+@\S+\.\S+$/,
              message: "Invalid email address",
            },
          })}
          error={!!formFieldError.email}
          helperText={formFieldError.email?.message}
          onChange={onEmailInput}
        />
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: "20px" }}>
            {errorMessage}
          </Alert>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          sx={{ marginTop: "20px", width: "100%" }}
        >
          {buttonText}
        </Button>
      </form>
    </Box>
  );
};

export default EmailInputForm;
