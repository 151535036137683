import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import SupplierDetailsTable from "./ModalTable";
import { IMAddress } from "../../types/customTypes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ICustomSupplier } from "../../types/financeTypes";

export interface ISupplierDetailsTable {
  id: number;
  name: string;
  email: string;
  phone: string;
  turnover_amount: number;
  year: string;
  number_of_emplyoee: number;
  address_line_1: string;
  address_line_2: string;
  country: string;
  city: string;
  post_code: string;
  business_model: string;
  business_type: string;
}

interface IViewSupplierDetailsModalProps {
  data?: ICustomSupplier[];
  icon: React.ReactNode;
}

const ViewSupplierDetailsModal: React.FC<IViewSupplierDetailsModalProps> = ({ data, icon }) => {
  const [rows, setRows] = useState<ISupplierDetailsTable[]>([]);

  useEffect(() => {
    if (data && data.length > 0) {
      const rows = data?.map((supplier) => {
        const row: ISupplierDetailsTable = {
          id: supplier.id,
          address_line_1: supplier.address_line_1,
          address_line_2: supplier.address_line_2 ? supplier.address_line_2 : "",
          business_model: supplier.sector_model.label,
          business_type: supplier.sector_type.label,
          city: supplier.city,
          country: supplier.country,
          email: supplier.company_email,
          name: supplier.company_name,
          number_of_emplyoee: supplier.total_number_of_employee,
          phone: supplier.company_phone,
          post_code: supplier.post_code,
          turnover_amount: supplier.financial_turnover,
          year: supplier.financial_turnover_year,
        };
        return row;
      });
      setRows(rows);
    }
  }, [data]);

  const columns: GridColDef[] = [
    { field: "name", headerName: "Name", align: "center", headerAlign: "center", width: 300 },
    {
      field: "email",
      headerName: "Email",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "phone",
      headerName: "Phone",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "address_line_1",
      headerName: "Address Line 1",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "address_line_2",
      headerName: "Address Line 2",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    { field: "city", headerName: "City", align: "center", headerAlign: "center", width: 300 },
    { field: "post_code", headerName: "Post Code", align: "center", headerAlign: "center", width: 300 },

    {
      field: "country",
      headerName: "Country",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "business_model",
      headerName: "Business Model",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "business_type",
      headerName: "Business Type",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "number_of_emplyoee",
      headerName: "Number of Emplyoee",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "turnover_amount",
      headerName: "Turnover Amount",
      align: "center",
      headerAlign: "center",
      width: 300,
    },
    {
      field: "year",
      headerName: "Year",
      align: "center",
      headerAlign: "center",
      width: 300,
      valueGetter: (params: GridValueGetterParams) => new Date(params.value as string).toLocaleDateString(),
    },
  ];

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        title="View Supplier Details"
        onClick={handleOpenDialog}
        style={{
          color: "#333",
          paddingBottom: "0.8rem",
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>
          <IconButton
            title="Back To Invoices"
            onClick={handleCloseDialog}
            style={{
              color: "#333",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Supplier Details
        </DialogTitle>

        <DialogContent>
          <SupplierDetailsTable rows={rows ? rows : []} columns={columns} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewSupplierDetailsModal;
