import { z } from "zod";
import { invoiceLineSchema } from "./invoiceLine.validation";

export const createInvoiceSchema = z.object({
  invoiceNumber: z.string().nonempty("Invoice Number is required"),
  supplierId: z.string().nonempty("Please select select"),
  invoiceType: z.string(),
  totalGrossAmount: z.number().gt(0),
  totalVatAmount: z.number().gte(0),
  totalDiscountAmount: z.number().gte(0),
  totalNetAmount: z.number().gt(0),
  issueDate: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "Issue Date is required." };
      }
      return { message: defaultError };
    },
  }),

  dueDate: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "Due Date is required." };
      }
      return { message: defaultError };
    },
  }),
  imageUrl: z.string().optional(),
  description: z.string().optional(),
  invoiceLines: z.array(invoiceLineSchema),
}).refine((data) => data.issueDate <= data.dueDate, {
  message: "Issue Date must be on or before Due Date",
  path: ["issueDate"],
}).refine((data) => data.dueDate >= data.issueDate, {
  message: "Due Date must be on or after Issue Date",
  path: ["dueDate"],
});
