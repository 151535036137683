import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Divider, Box } from "@mui/material";

import { useEffect, useState } from "react";
import { useFetchTeamInfoQuery } from "../../store/api/infoApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import SuccessSnackbar from "../../components/Snackbar";
import EditReceivePaymentInfo from "./EditReceivePaymentInfoDialog";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: "auto",
  maxWidth: 600,
  position: "relative",
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(2),
  color: "blue",
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

const ReceivePaymentInfo = () => {
  const { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();

  const [showUpdateFinancialInfoDialog, setShowUpdateFinancialInfoDialog] = useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);

  const handleUpdateFinanciald = () => {
    setShowUpdateFinancialInfoDialog(true);
  };

  const handleCloseUpdateFinancialInfoDialog = () => {
    setShowUpdateFinancialInfoDialog(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  useEffect(() => {
    refetchTeamDetails();
  }, []);

  console.log("bank.ng");
  if (isTeamInfoFetchLoading) return <LoadingSpinner />;
  else if (isTeamInfoFetchSuccess && teamDetails)
    return (
      <StyledPaper>
        <TitleTypography variant="h5">Receive Payment Info</TitleTypography>
        <EditButton>
          <EditIcon onClick={handleUpdateFinanciald} />
        </EditButton>
        <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} width="30vw" severity="success" message="Financial info updated" />

        <Box marginBottom={2} marginTop={2}>
          <Typography>Account Number: {teamDetails?.data?.banking[0]?.account_number}</Typography>
        </Box>

        <Divider />
        <Box marginBottom={2} marginTop={2}>
          <Typography>Sort Code: {teamDetails?.data?.banking[0]?.sort_code}</Typography>
        </Box>

        {showUpdateFinancialInfoDialog && (
          <EditReceivePaymentInfo
            openDialog={showUpdateFinancialInfoDialog}
            handleCloseDialog={handleCloseUpdateFinancialInfoDialog}
            onSuccess={openSuccessbar}
          />
        )}
      </StyledPaper>
    );
  else return <div>Error fetching user information</div>;
};

export default ReceivePaymentInfo;
