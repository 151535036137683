import { IQueryResult, IPagination, ISupplierNote } from "../../types/customTypes";
import { purchaseInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const supplierNoteSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createSupplierNote: builder.mutation({
      query: (newSupplierNote) => ({
        url: purchaseInvoiceApiBase + "/supplierNotes",
        method: "POST",
        body: newSupplierNote,
      }),

      invalidatesTags: ["SupplierNote"],
    }),
    getSupplierNotes: builder.query<IQueryResult<ISupplierNote[]>, { pageInfo: IPagination; searchColumns: string[]; searchKeyword: string; noteType: string }>({
      query: ({ pageInfo, searchColumns, searchKeyword, noteType }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
          noteType
        };
        return {
          url: purchaseInvoiceApiBase + `/supplierNotes?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["SupplierNote"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getSupplierNoteById: builder.query<ISupplierNote, string>({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/supplierNotes/${id}`,
        method: "GET",
      }),
      providesTags: ["SupplierNote"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateSupplierNote: builder.mutation({
      query: (updatedFields) => ({
        url: purchaseInvoiceApiBase + `/supplierNotes/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
    }),
    deleteSupplierNote: builder.mutation({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/supplierNotes/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SupplierNote"],
    }),
  }),
});

export const {
  useCreateSupplierNoteMutation,
  useGetSupplierNotesQuery,
  useGetSupplierNoteByIdQuery,
  useLazyGetSupplierNoteByIdQuery,
  useUpdateSupplierNoteMutation,
  useDeleteSupplierNoteMutation,
} = supplierNoteSlice;
