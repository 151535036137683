import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { CreateBusinessValues } from "../../types/formValueType";
import { createBusinessFormValues } from "./formName";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import { useForm } from "react-hook-form";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";
import { getValue } from "@mui/system";

export default function AccountTypeSelectionPage() {
  const [registrationAccountOption, setRegistrationOption] = useState("");
  const [registrationOptionOnLoad, setRegistrationOptionOnLoad] = useState("");
  const navigate = useNavigate();

  const [defaultInvoiceValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);

    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const {
    setValue,
    formState: { errors },
    watch,
    unregister,
    getValues,
  } = useForm<CreateBusinessValues>({
    defaultValues: defaultInvoiceValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  // useEffect(() => {
  //   setValue("registrationOptionBeforeReEdit", watch("registrationOption"));
  //   setRegistrationOptionOnLoad(getValues("registrationOption"));
  //   console.log(getValues('registrationOption'));
  // }, []);

  const { isReEdit, registrationOptionBeforeReEdit } = getValues();

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value === "companyHouse") {
      setValue("registrationOption", value);
    }
    setRegistrationOption(value);
  };

  const handleNextClick = () => {
    if (registrationAccountOption === "companyHouse" && registrationOptionBeforeReEdit !== "companyHouse" || registrationOptionBeforeReEdit === "companyHouse" && registrationAccountOption !== "companyHouse") {
      setValue("isRegistrationOptionChanged", true);
    } else {
      setValue("isRegistrationOptionChanged", false);
    }
    if (registrationAccountOption === "companyHouse") {
      //The unregistered field are only required for soletraders
      unregister("vat_number");
      unregister("tax_id");

      if (isReEdit && registrationAccountOption !== "companyHouse") {
        setValue("showCompanyDocsUploadStatus", false);
      }
      Promise.resolve().then(() => {
        navigate("/company-reg-num-form");
      });
    } else if (registrationAccountOption === "soleTrader") {
      //The unregistered field are only required for companyHouse
      unregister("company_reg_number");
      if (isReEdit && registrationOptionBeforeReEdit === "companyHouse") {
        setValue("showSoleTraderDocsUploadStatus", false);
      }

      Promise.resolve().then(() => {
        navigate("/sole-trader-reg-options");
      });
    }
  };

  const handleBackButtonClick = () => {
    setValue("registrationOption", "");
    Promise.resolve().then(() => {
      navigate("/create-business-prompt");
    });
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          boxShadow: 3,
          borderRadius: 2,
          px: 4,
          py: 6,
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Select An Option</Typography>
        <FormControl sx={{ m: 3 }} variant="standard">
          <FormLabel id="demo-error-radios">Are you...?</FormLabel>
          <RadioGroup aria-labelledby="demo-error-radios" name="quiz" onChange={handleRadioChange}>
            <FormControlLabel
              value="soleTrader"
              control={
                <Radio
                  required
                  sx={{
                    color: "#01579b",
                    "&.Mui-checked": {
                      color: "#039be5",
                    },
                  }}
                />
              }
              label="sole trader"
            />
            <FormControlLabel
              value="companyHouse"
              control={
                <Radio
                  required
                  sx={{
                    color: "#01579b",
                    "&.Mui-checked": {
                      color: "#039be5",
                    },
                  }}
                />
              }
              label="registered to company house"
            />
          </RadioGroup>

          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
            }}
            disabled={registrationAccountOption === ""}
            onClick={handleNextClick}
          >
            Next
          </Button>
          {!isReEdit && (
            <Button
              type="button"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
              }}
              onClick={handleBackButtonClick}
            >
              Back
            </Button>
          )}
        </FormControl>
        {isReEdit && <BackToHomeLink />}
      </Box>
    </Container>
  );
}
