import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import NumbersIcon from "@mui/icons-material/Numbers";
import InputAdornment from "@mui/material/InputAdornment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useSelector as useTypedSelector, useDispatch } from "react-redux";
import { uploadFile } from "../../store/features/uploadFileSlice";
import { fetchUploadStatus } from "../../store/features/uploadStatusSlice";
import { AppDispatch } from "../../store/features/store";
import { useState } from "react";
import { fetchDocs } from "../../store/features/retrieveFilesSlice";
import { getIndexForDocType, UploadStatus } from "../../global/enums/docType";
import { DocTypes } from "../../global/enums/docType";
import { useForm } from "react-hook-form";
import { CreateBusinessValues } from "../../types/formValueType";
import { useFetchUserInfoQuery } from "../../store/api/infoApi";
import { selectAccessToken } from "../../store/features/authenticationSlice";
import { useNavigate } from "react-router-dom";
import { createBusinessFormValues } from "./formName";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import useFormPersist from "react-hook-form-persist";
import BackToHomeLink from "./NavigateToHome";
import { useFetchDocumentsQuery, useLazyFetchDocumentsQuery } from "../../store/api/filesApi";
import FilePicker from "../../components/FilePicker";
import FileExistText from "./FileExistText";
import ShowBankAccount from "../bankAccount/ShowBankAccount";

export default function CreateAccount() {
  const { data: userInfo, isSuccess, isError } = useFetchUserInfoQuery();
  const [team_id, setTeamId] = useState("");
  const navigate = useNavigate();
  const [uploadStatusMap, setUploadStatusMap] = useState({
    bank_statement: null,
  });

  const [fetchDocuments, { data: bankStatement, isSuccess: isFetchBankStatmentSuccess, isError: isFetchBankStatmentError }] = useLazyFetchDocumentsQuery();

  useEffect(() => {
    console.log(bankStatement);
  }, [isFetchBankStatmentSuccess]);

  useEffect(() => {
    if (isSuccess && userInfo) {
      setTeamId(userInfo.data.team_id);
    }
  }, [isSuccess, isError, userInfo]);

  const [defaultInvoiceValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const {
    setValue,
    formState: { errors },
    watch,
    register,
    getValues,
    handleSubmit,
  } = useForm<CreateBusinessValues>({
    defaultValues: defaultInvoiceValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const uploadStatus = useTypedSelector((state: any) => state.uploadFile.uploadStatus);

  const [type, setType] = useState("");
  const dispatch: AppDispatch = useDispatch();

  const authToken = selectAccessToken();
  const { isReEdit, showPreviousBankStatement } = getValues();

  const status = useTypedSelector((state: any) => state.uploadStatus.file);

  useEffect(() => {
    if (isReEdit && isSuccess) {
      fetchDocuments({ team_id: team_id, docType: DocTypes.BANKSTATEMENT });
    }
  }, [isReEdit, team_id]);

  const handleFileUpload = (docType: string, file: File) => {
    if (authToken) {
      dispatch(uploadFile({ docType, file, authToken }))
        .then((resultAction) => {
          if (uploadFile.fulfilled.match(resultAction)) {
            const {
              payload: { status },
            } = resultAction;

            setUploadStatusMap((prevStatus) => ({
              ...prevStatus,
              [docType]: status,
            }));
          }
        })
        .catch((error) => {
          console.error("Error occurred during file upload:", error);
        });

      const indexOfType = getIndexForDocType(docType, DocTypes)?.toString() || "";
      setType(indexOfType);

      setUploadStatusMap((prevStatus) => ({
        ...prevStatus,
        [docType]: UploadStatus.UPLOADING,
      }));
    }
  };

  useEffect(() => {
    if (authToken) {
      dispatch(fetchUploadStatus({ authToken }));
      dispatch(fetchDocs({ team_id, type, authToken }));
    }
  }, [uploadStatus]);

  const onSubmit = (account: any) => {
    setValue("showPreviousBankStatement", true);
    Promise.resolve().then(() => {
      const { registrationOption } = getValues();
      if (registrationOption === "companyHouse") {
        navigate("/company-doc-upload");
      } else {
        navigate("/sole-trader-doc-upload");
      }
    });
  };

  const handleBackButtonClick = () => {
    setValue("account_number", "");
    setValue("sort_code", "");
    setValue("showPreviousBankStatement", false);
    Promise.resolve().then(() => {
      navigate("/company-details-form");
    });
  };

  return (
    <React.Fragment>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: 8,
            marginLeft: 20,
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={8} md={10} component={Paper} elevation={6} square>
              <Box
                sx={{
                  my: 3,
                  mx: 3,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography component="h1" variant="h5">
                  Account Details
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="accountNum"
                      label="Account Number"
                      autoComplete="accountNum"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ maxLength: 8 }}
                      {...register("account_number", {
                        required: { value: true, message: "Required" },
                      })}
                      error={!!errors.account_number}
                      helperText={errors.account_number?.message}
                    />
                    <TextField
                      margin="normal"
                      fullWidth
                      id="sortCode"
                      label="Sort Code"
                      autoComplete="sortCode"
                      autoFocus
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersIcon />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{ maxLength: 6 }}
                      {...register("sort_code", {
                        required: { value: true, message: "Required" },
                      })}
                      error={!!errors.sort_code}
                      helperText={errors.sort_code?.message}
                    />
                    <FilePicker
                      handleFileUpload={handleFileUpload}
                      label="Bank Statement"
                      isReEdit
                      document="bank_statement"
                      showDocument={showPreviousBankStatement}
                    />
                    {uploadStatusMap.bank_statement === null && isReEdit && isFetchBankStatmentSuccess && bankStatement.data && showPreviousBankStatement && (
                      <FileExistText />
                    )}

                    {uploadStatusMap.bank_statement === UploadStatus.UPLOADING && (
                      <Typography color="orange" gutterBottom>
                        Uploading
                      </Typography>
                    )}
                    {uploadStatusMap.bank_statement === UploadStatus.UPLOADED && (
                      <Typography color="green" gutterBottom>
                        Successfully Uploaded
                      </Typography>
                    )}
                    {uploadStatusMap.bank_statement === UploadStatus.FAIL && (
                      <Typography color="red" gutterBottom>
                        Upload failed
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                      }}
                      disabled={!showPreviousBankStatement && (watch("account_number") === "" || watch("sort_code") === "" || uploadStatusMap.bank_statement !== UploadStatus.UPLOADED)}

                    >
                      Next
                    </Button>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                      }}
                      onClick={handleBackButtonClick}
                    >
                      Back
                    </Button>
                  </form>
                </Box>
                {isReEdit && <BackToHomeLink />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
