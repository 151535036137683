import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authInitialState } from "../AuthState";
import { salesInvoiceApiBase } from "../env";

const initialState = {
  ...authInitialState,
};

export const fetchUserData = createAsyncThunk("userData/fetchUserData", async () => {
  try {
    const response = await axios.get(salesInvoiceApiBase + "/v1/account/user");
    console.log("fetch user data", response.data);
    return response.data;
  } catch (error: any) {
    console.log("error message", error);
    throw new Error(error.message);
  }
});

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.error.message ?? "";
      });
  },
});

export default userDataSlice.reducer;
