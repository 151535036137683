import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#233142",
    },
    secondary: {
      main: "#019656",
      dark: "#016c4e",
    },
    common: {
      white: "#f5ecec",
    },
    error: {
      main: "#ff1744", 
    },
    mode: 'light', 
  },
});

export default theme;
