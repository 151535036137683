import { Box, Grid, Typography } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { ReactNode, useEffect, useState } from "react";
import FilterButton from "../../../components/ActionButton";
import PageManagementGrid from "../../../components/DataGrid";
import DialogInput from "../../../components/Dialog";
import SearchFilter from "../../../components/Filter";
import PageHeader from "../../../components/PageHeader";
import { InvoiceType, NoteType } from "../../../global/enums/entityEnum";
import { useLazyGetProductAccountStatementQuery } from "../../../store/api/productApi";
import { IAccountStatementQueryResult, IPagination, IQuerySearchParams, IProductAccountStatement } from "../../../types/customTypes";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import AccountStatementCreationForm from "./ProductAccountStatementCreationForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { productAccountStatementSchema } from "../../../validation/productAccountStatement.validation";
import useFormPersist from "react-hook-form-persist";
import { useForm } from "react-hook-form";
import { productAccountStatementValues } from "../../../types/formValueType";
// import { productAccountStatementDefaultValues } from "../../../types/formDefaultValues";
import { clearStorage } from "../../../utils/formStorage";
import dayjs from "dayjs";
import { toSentenceCase } from "../../../utils/textFormatter";
import { formatDate } from "../../../utils/dateFormatter";
import { formatAmount } from "../../../utils/amountFormatter";
import { productAccountStatementDefaultValues } from "../../../types/formDefaultValues";

const LabelText = ({ children }: { children: ReactNode }) => {
  return (
    <Typography component="span" fontWeight="bold" display="inline">
      {children}
    </Typography>
  );
};

function ProductAccountStatement() {
  const [showFilter, setShowFilter] = useState(false);
  const [isAccountStatementCreationDialogOpen, setIsAccountStatementCreationDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const productAccountStatementFormValues = "productAccountStatement";
  const [accountStatement, setAccountStatement] = useState<IAccountStatementQueryResult>({ _id: "", documents: [] });
  const [accountStatementDocs, setAccountStatementDocs] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [defaultAccountStatementValues, setDefaultAccountStatementValues] = useState(() => {
    const storedValues = window.sessionStorage.getItem(productAccountStatementFormValues);
    let parsedStoredValues = productAccountStatementDefaultValues;

    if (storedValues) {
      try {
        const parsedValues = JSON.parse(storedValues);
        parsedStoredValues = {
          ...parsedValues,
          startDate: dayjs(parsedValues.startDate),
          endDate: dayjs(parsedValues.endDate),
        };
      } catch (error) {
        console.error("Error parsing stored values:", error);
        // Handle parsing error if needed
      }
    }

    return parsedStoredValues;
  });

  const [getAccountStatement, { isLoading, isSuccess, isError, error, data }] = useLazyGetProductAccountStatementQuery();
  const [totalItems, setTotalItems] = useState(0);
  console.log("Reloading");

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    reset,
    clearErrors,
    watch,
    getValues,
  } = useForm<productAccountStatementValues>({
    defaultValues: defaultAccountStatementValues,
    resolver: zodResolver(productAccountStatementSchema),
  });

  useFormPersist(productAccountStatementFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });
  useEffect(() => {
    if (isSuccess) {
      console.log("In success");

      if (data) {
        setAccountStatement(data);
        setAccountStatementDocs(data.documents);
      }
      if (isError && error) {
        let errMsg = "An error occurred. Try again";
        if (error) {
          console.log(error);
          const errData: any = "data" in error && error.data;
          errMsg = errData.message && errData.message;
        }
        setErrorMessage(errMsg);
      }
      setIsAccountStatementCreationDialogOpen(false);
    }
  }, [isSuccess, data, setAccountStatement, isError, error]);

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  const handleAddClick = () => {
    setIsAccountStatementCreationDialogOpen(true);
  };

  const onSubmit = async (data: productAccountStatementValues) => {
    fetchAccountStatement();
  };

  const handleClearFormButtonClick = () => {
    clearStorage(productAccountStatementFormValues);
    reset(productAccountStatementDefaultValues);
  };

  useEffect(() => {
    if (watch("productId")) {
      fetchAccountStatement();
    }
  }, [paginationModel]);

  const fetchAccountStatement = () => {
    const productId = watch("productId");
    const startDate = watch("startDate").toString();
    const endDate = watch("endDate").toString();
    const date = new Date("Mon, 01 Jan 2024 00:00:00 GMT");
    const openingDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    getAccountStatement({
      productId,
      startDate: new Date(startDate).toISOString(),
      openingDate,
      endDate: new Date(endDate).toISOString(),
      pageInfo: paginationModel,
    })
      .unwrap()
      .then((response) => {
        console.log(response);

        setTotalItems(response.pagination.totalItems);
        setIsAccountStatementCreationDialogOpen(false);
      })
      .catch((error) => {
        console.error("Error fetching account statement:", error);
      });
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "refId",
      headerName: "Ref No",
      width: 250,
      valueGetter: ({ row }: { row: any }) => {
        if (row.refId) {
          return row.refId;
        } else if (row.invoiceNumber) {
          return row.invoiceNumber;
        } else {
          return "";
        }
      },
    },
    {
      field: "creditQuantity",
      headerName: "Total Quantity(CR)",
      width: 200,
      valueGetter: ({ row }: { row: any }) => {
        if (row.invoiceType === InvoiceType.Purchase) {
          return row.invoiceLines.quantity;
        } else {
          return "";
        }
      },
    },
    {
      field: "debitQuantity",
      headerName: "Total Quantity(DB)",
      width: 200,
      valueGetter: ({ row }: { row: any }) => {
        if (row.invoiceType === InvoiceType.PurchaseReturn) {
          return row.invoiceLines.quantity;
        } else {
          return "";
        }
      },
    },

    {
      field: "stockBalance",
      headerName: "Stock Balance",
      width: 200,
    },
  ];
  return (
    <Box width="80%" ml={4}>
      <Box mb={2}>
        <PageHeader title="Product Account Statement" onAdd={handleAddClick} customLeftMargin={50} />
      </Box>
      <Grid container>
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <Box width="100%">
            {data && (
              <Box display="flex" justifyContent="space-between" mr={10} alignItems="center">
                <Typography variant="h6" fontWeight="bold" mt={5}>
                  Account Statement for {toSentenceCase(data.productDetails?.productName)}
                </Typography>
                <Box textAlign="right">
                  <Typography>
                    <LabelText>start Date: </LabelText>
                    {formatDate(data.startDate)}
                  </Typography>
                  <Typography>
                    <LabelText>End Date:</LabelText>
                    {formatDate(data.endDate)}
                  </Typography>
                  <Typography>
                    <LabelText>Opening Stock:</LabelText> {data.openingStock}
                  </Typography>
                </Box>
              </Box>
            )}

            <PageManagementGrid
              rows={accountStatementDocs}
              columnFields={columns}
              onDelete={() => void 0}
              //   onUpdate={handleUpdate}
              paginationModel={paginationModel}
              // customActions={actions}
              isFetching={false}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              sortModel={[{ field: "productName", sort: "asc" }]}
              noActionButton
            />
          </Box>
        </Grid>
      </Grid>
      {isAccountStatementCreationDialogOpen && (
        <AccountStatementCreationForm
          openDialog={isAccountStatementCreationDialogOpen}
          handleCloseDialog={() => setIsAccountStatementCreationDialogOpen(false)}
          clearErrors={clearErrors}
          setValue={setValue}
          control={control}
          register={register}
          errors={errors}
          reset={reset}
          watch={watch}
          handleClearButtonClick={handleClearFormButtonClick}
          getAccountStatement={getAccountStatement}
          onSubmission={handleSubmit(onSubmit)}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      )}
      {/* {isViewDialogOpen && <PreviewNote noteId={noteId} openDialog={isViewDialogOpen} handleCloseDialog={() => setIsViewDialogOpen(false)} />} */}
    </Box>
  );
}

export default ProductAccountStatement;
