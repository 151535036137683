import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, Typography } from "@mui/material";
import { Controller, useForm, useWatch } from "react-hook-form";
import { invoiceValues } from "../../../types/formValueType";
import VatTextfield from "../../../components/VatTextfield";
import ProductSelectionField from "../../../components/ProductSelectionField";
import { useAddNewInvoiceMutation } from "../../../store/api/invoiceApi";
import { useGetProductByIdQuery } from "../../../store/api/productApi";
import AlertSnackbar from "../../../components/Snackbar";
import PageTitle from "../../../components/PageTitle";
import SupplierSelect from "../../../components/SupplierSelect";
import { handleNumberInput } from "../../../utils/numberPrecision";
import { createInvoiceSchema } from "../../../validation/invoice.validationSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { invoiceDefaultValues } from "../../../types/formDefaultValues";
import LoadingButton from "@mui/lab/LoadingButton";
import useFormPersist from "react-hook-form-persist";
import { clearStorage } from "../../../utils/formStorage";
import dayjs, { Dayjs } from "dayjs";
import { IProduct, ISupplier } from "../../../types/customTypes";
import ClearFormButton from "../../../components/ClearFormButton";

export default function CreateInvoice() {
  const [issuedDate, setIssuedDate] = useState<Dayjs>(dayjs());
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs());
  const [unitPrice, setUnitPrice] = useState(0);
  const [isPriceVatIncluded, setIsPriceVatIncluded] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const invoiceFormValues = "fastInvoiceValues";
  const [defaultInvoiceValues] = useState<invoiceValues>(() => {
    const storedValues = window.sessionStorage.getItem(invoiceFormValues);

    return storedValues
      ? JSON.parse(storedValues)
      : {
          ...invoiceDefaultValues,
          issueDate: dayjs(),
          dueDate: dayjs(),
        };
  });

  const { register, handleSubmit, formState, setValue, reset, clearErrors, watch, control, getValues, setError } = useForm<invoiceValues>({
    defaultValues: defaultInvoiceValues,
    resolver: zodResolver(createInvoiceSchema),
  });

  useFormPersist(invoiceFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { errors } = formState;

  const watchInvoiceLineValues = useWatch({
    control,
    name: ["invoiceLines.0.unitPrice", "invoiceLines.0.lineDiscountPercentage", "invoiceLines.0.lineVatPercentage", "invoiceLines.0.quantity"],
  });

  const [addNewInvoice, addInvoiceMutationResult] = useAddNewInvoiceMutation();
  const { isLoading: isAddInvoiceLoading, isSuccess: isAddInvoiceSuccess, isError: isAddInvoiceError, error: addInvoiceError } = addInvoiceMutationResult;

  const { isSuccess: isGetProductSuccess, data: product } = useGetProductByIdQuery(selectedProductId);

  const handleUnitPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("invoiceLines.0.unitPrice");
    const value = handleNumberInput(event.target.value);
    setValue("invoiceLines.0.unitPrice", value);
  };

  const resetFormVariables = () => {
    setSelectedProductId("");
    setIssuedDate(dayjs());
    setDueDate(dayjs());
    setUnitPrice(0);
  };

  const onSubmit = async (data: invoiceValues) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (key === "invoiceLines") {
        value = JSON.stringify(value);
      }
      formData.append(key, value as string);
    });

    addNewInvoice(formData);
  };

  useEffect(() => {
    if (isAddInvoiceSuccess) {
      setErrorMessage("");
      setSuccessMessage("Invoice successfully created");
      setShowSuccessSnackbar(true);
      reset(invoiceDefaultValues);
      resetFormVariables();
      clearStorage(invoiceFormValues);
    } else if (isAddInvoiceError) {
      const errMsg = "data" in addInvoiceError ? (addInvoiceError.data as string) : "Unable to create invoice";
      setErrorMessage(errMsg);
      setShowErrorSnackbar(true);
    }
  }, [isAddInvoiceSuccess, isAddInvoiceError, addInvoiceError]);

  const handleIssueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("issueDate");
      setValue("issueDate", date);
    }
  };

  const handleDueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("dueDate");
      setValue("dueDate", date);
    }
  };

  const handleSupplierInput = (supplierId: string) => {
    clearErrors("supplierId");
    setValue("supplierId", supplierId);
  };

  const onNewSupplierCreation = (supplierDetails: ISupplier) => {
    setValue("supplierId", supplierDetails._id);
  };

  const onNewProductCreation = (productDetails: IProduct) => {
    setValue("invoiceLines.0.productId", productDetails._id);
    setValue("invoiceLines.0.unitPrice", productDetails.purchasePrice);
    setValue("invoiceLines.0.lineVatPercentage", productDetails.vatRate);
  };

  useEffect(() => {
    if (isGetProductSuccess && product) {
      const { purchasePrice, vatRate, isPurchasePriceVatIncluded } = product;
      setIsPriceVatIncluded(isPurchasePriceVatIncluded);

      if (purchasePrice !== undefined && purchasePrice !== null) {
        setValue("invoiceLines.0.unitPrice", purchasePrice);
      }
      if (vatRate !== undefined && vatRate !== null) {
        setValue("invoiceLines.0.lineVatPercentage", vatRate);
      }
    }
  }, [selectedProductId, isGetProductSuccess, product]);

  useEffect(() => {
    const { invoiceLines } = getValues();

    const { unitPrice, quantity, lineDiscountPercentage, lineVatPercentage } = invoiceLines[0];

    const totalPrice = quantity * unitPrice;
    const discountAmount = (totalPrice * lineDiscountPercentage) / 100;
    const grossAmount = parseFloat((totalPrice - discountAmount).toFixed(2));
    const lineVatAmount = (lineVatPercentage / 100) * grossAmount;
    let netAmount = grossAmount;
    if (!isPriceVatIncluded) {
      netAmount = grossAmount + lineVatAmount;
    }
    setValue("invoiceLines.0.lineDiscountAmount", discountAmount);
    setValue("invoiceLines.0.lineGrossAmount", grossAmount);
    setValue("invoiceLines.0.lineVatAmount", lineVatAmount);
    setValue("totalVatAmount", lineVatAmount);
    setValue("totalGrossAmount", grossAmount);
    setValue("totalDiscountAmount", discountAmount);
    setValue("invoiceLines.0.lineNetAmount", netAmount);
    setValue("totalNetAmount", netAmount);
  }, [watchInvoiceLineValues]);

  const handleProductSelection = (productId: string) => {
    clearErrors(`invoiceLines.0.productId`);
    setSelectedProductId(productId);
    setValue("invoiceLines.0.productId", productId);
  };

  const handleCloseSnackbar = () => {
    successMessage && setShowSuccessSnackbar(false);
    errorMessage && setShowErrorSnackbar(false);
  };

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(`invoiceLines.0.quantity`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.0.quantity`, value);
  };

  const handleVatChange = (vatPercentage: number) => {
    setValue("invoiceLines.0.lineVatPercentage", vatPercentage);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors(`invoiceLines.0.lineDiscountPercentage`);
    const value = handleNumberInput(event.target.value);
    setValue(`invoiceLines.0.lineDiscountPercentage`, value);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(invoiceFormValues);
    reset(invoiceDefaultValues);
  };

  const handleIssueDateInput = (event: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>) => {};

  return (
    <Container sx={{ width: "100vw" }}>
      {successMessage && <AlertSnackbar open={showSuccessSnackbar} handleClose={handleCloseSnackbar} severity="success" message={successMessage} />}
      {errorMessage && <AlertSnackbar open={showErrorSnackbar} handleClose={handleCloseSnackbar} severity="error" message={errorMessage} />}
      <PageTitle title="Create Invoice" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item lg={4} sm={12}>
            <TextField
              label="Invoice number *"
              variant="outlined"
              fullWidth
              {...register("invoiceNumber", {
                required: "Invoice number is required",
              })}
              error={!!errors.invoiceNumber}
              helperText={errors.invoiceNumber?.message}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item lg={4} sm={12}>
              <Controller
                name="issueDate"
                // defaultValue={new Date()}
                control={control}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    value={dayjs(watch("issueDate"))} // Use watch to get the value of "issueDate"
                    label="Issue date *"
                    maxDate={dayjs(watch("dueDate"))}
                    {...register("issueDate", {
                      required: "Issue Date is required",
                    })}
                    // onChange={(date) => {
                    //   const isoDateString = date ? date : "";
                    //   onChange(isoDateString);
                    //   setIssuedDate(dayjs(date));
                    //   handleIssueDateChange(date)
                    // }}
                    onChange={handleIssueDateChange}
                    slotProps={{
                      textField: {
                        error: !!errors.issueDate,
                        helperText: errors.issueDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={4} sm={12}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    defaultValue={dayjs(watch("dueDate"))}
                    label="Due date *"
                    minDate={dayjs(watch("issueDate"))}
                    {...register("dueDate", {
                      required: "Due Date is required",
                    })}
                    onChange={handleDueDateChange}
                    slotProps={{
                      textField: {
                        error: !!errors.dueDate,
                        helperText: errors.dueDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>

          <Grid item xs={12}>
            <SupplierSelect
              onChange={handleSupplierInput}
              register={register}
              error={errors}
              selectedSupplierValue={watch("supplierId")}
              onCreateSupplierSuccess={onNewSupplierCreation}
            />
          </Grid>

          <Grid item sm={12} md={9}>
            <ProductSelectionField
              selectedProduct={watch("invoiceLines.0.productId")}
              setSelectedProduct={setSelectedProductId}
              onProductChange={handleProductSelection}
              onCreateProductSuccess={onNewProductCreation}
              error={!!errors.invoiceLines?.[0]?.productId}
              errorMessage={errors.invoiceLines?.[0]?.productId?.message}
            />
          </Grid>
          <Grid item sm={12} md={3}>
            <TextField
              label="Quantity *"
              variant="outlined"
              type="number"
              fullWidth
              margin="normal"
              {...register("invoiceLines.0.quantity", {
                valueAsNumber: true,
              })}
              onChange={handleQuantityChange}
              error={!!errors.invoiceLines?.[0]?.quantity}
              helperText={errors.invoiceLines?.[0]?.quantity?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="serviceDescription"
              label="Service Description"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              value={watch("description")}
              {...register("description")}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <TextField
              id="unitPrice"
              label="Unit Price *"
              type="number"
              variant="outlined"
              fullWidth
              {...register("invoiceLines.0.unitPrice", {
                valueAsNumber: true,
              })}
              inputProps={{ step: 0.01 }}
              onChange={handleUnitPriceChange}
              error={!!errors.invoiceLines?.[0]?.unitPrice}
              helperText={errors.invoiceLines?.[0]?.unitPrice?.message}
            />
            {watch("invoiceLines.0.productId") && (
              <Typography variant="body1" fontStyle="italic" color="gray">
                {isPriceVatIncluded ? "Price is vat included" : "Price is vat excluded"}
              </Typography>
            )}
          </Grid>
          <Grid item md={4} xs={12}>
            <VatTextfield register={register} name="lineVatAmount" vatValue={watch("invoiceLines.0.lineVatPercentage")} onChange={handleVatChange} />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextField
              label="Discount"
              variant="outlined"
              type="number"
              fullWidth
              {...register(`invoiceLines.0.lineDiscountPercentage`, {
                valueAsNumber: true,
              })}
              onChange={handleDiscountChange}
              inputProps={{ step: 0.01 }}
              error={!!errors.invoiceLines?.[0]?.lineDiscountPercentage}
              helperText={errors.invoiceLines?.[0]?.lineDiscountPercentage?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton type="submit" variant="contained" color="primary" loading={isAddInvoiceLoading}>
              Create Invoice
            </LoadingButton>
            <ClearFormButton onClick={handleClearFormButtonClick} />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
