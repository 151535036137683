import { useForm } from "react-hook-form";
import { FinanceAccountValues } from "../../../types/formValueType";
import useFormPersist from "react-hook-form-persist";
import { financeAcccountFormValues } from "./financeAccountStorageName";
import { Container } from "@mui/system";
import { Alert, Grid, InputLabel, TextField, Typography } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearFormButton from "../../../components/ClearFormButton";
import { clearStorage } from "../../../utils/formStorage";
import { useWizard } from "react-use-wizard";
import { useCreateFinanceAccountMutation } from "../../../store/api/financeApi";
import { useEffect, useState } from "react";
import { CreateEntity } from "../../../types/customTypes";

interface FinanceAccountCreationProps {
  handleCloseDialog: () => void;
  onSuccess: (newEntity: any) => void;
}

function UserDetailsForm({ handleCloseDialog, onSuccess }: FinanceAccountCreationProps) {
  const { previousStep } = useWizard();
  const [createFinanceAccount, { isSuccess, isError, isLoading, data: newlyCreatedAccount, error }] = useCreateFinanceAccountMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    reset,
    getValues,
  } = useForm<FinanceAccountValues>({
    defaultValues: {
      bank_name: "",
      bank_phone: "",
      bank_email: "",
      name: "",
      email: "",
      password: "",
    },
    // resolver: zodResolver(CreateCustomerSchema),
  });

  useFormPersist(financeAcccountFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const handlePhoneNumberInput = (phone: string) => {
    clearErrors("bank_phone");
    setValue("bank_phone", phone as string);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(financeAcccountFormValues);
    reset();
  };

  const onSubmit = () => {
    const financeAccountFormValues = getValues();
    createFinanceAccount(financeAccountFormValues);
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseDialog();
      clearStorage(financeAcccountFormValues);
      reset();
      onSuccess(newlyCreatedAccount);
    } else if (isError) {
      let errMsg = "An error occurred. Try again";
      if (error) {
        console.log(error);
        const errData: any = "data" in error && error.data;
        errMsg = errData.message && errData.message;
      }
      setErrorMessage(errMsg);
    }
  }, [isSuccess, isError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {errorMessage && (
        <Alert severity="error" sx={{ marginBottom: "16px" }}>
          {errorMessage}
        </Alert>
      )}

      <Grid container spacing={2} mt={1}>
        <Grid item sm={12}>
          <TextField
            label="First Name"
            {...register("name", {
              required: "Required",
            })}
            fullWidth
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            label="Surname"
            {...register("surname", {
              required: "Required",
            })}
            fullWidth
            error={!!errors.surname}
            helperText={errors.surname?.message}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            label="Email"
            {...register("email", {
              required: "Required",
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: "Invalid email address",
              },
            })}
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            label="Password"
            {...register("password", {
              required: "Required",
            })}
            fullWidth
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Grid>

        <Grid item xs={12} marginBottom="20px">
          <LoadingButton type="button" variant="contained" color="primary" onClick={() => previousStep()} sx={{ marginRight: 1 }}>
            Previous
          </LoadingButton>
          <LoadingButton type="submit" variant="contained" color="primary" loading={isLoading}>
            Submit
          </LoadingButton>
          <ClearFormButton onClick={handleClearFormButtonClick} />
        </Grid>
      </Grid>
    </form>
  );
}

export default UserDetailsForm;
