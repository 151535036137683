import { apiSlice } from "./baseApi";
import { IPagination, IQueryResultReceipt, IReceipt, IQueryReceipt } from "../../types/customTypes";
import { salesInvoiceApiBase } from "../env";

export const receiptSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getReceipts: builder.query<IQueryResultReceipt<IReceipt[]>, IPagination>({
      query: ({ page, pageSize }) => {
        const queryParams = {
          page,
          pageSize,
        };
        return {
          url: salesInvoiceApiBase + `/v1/invoice/outgoing/receipt?page=${queryParams.page}&perPage=${queryParams.pageSize}`,
          method: "GET",
        };
      },
      providesTags: ["Receipt"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    getIncomingReceipts: builder.query<IQueryResultReceipt<IReceipt[]>, IPagination>({
      query: ({ page, pageSize }) => {
        const queryParams = {
          page,
          pageSize,
        };
        return {
          url: salesInvoiceApiBase + `/v1/invoice/incoming/receipt?page=${queryParams.page}&perPage=${queryParams.pageSize}`,
          method: "GET",
        };
      },
      providesTags: ["Receipt"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    getFilteredReceipts: builder.query<IQueryResultReceipt<IReceipt[]>, IQueryReceipt>({
      query: ({ startDate, endDate, receiptId }) => {
        const queryParams = {
          startDate,
          endDate,
          receiptId,
        };
        return {
          url:
            salesInvoiceApiBase +
            `/v1/receipt/range/generate?start_date=${queryParams.startDate}&end_date=${queryParams.endDate}&receipt_type=${queryParams.receiptId}`,
          method: "GET",
        };
      },
      providesTags: ["Receipt"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    deleteReceipts: builder.mutation({
      query: (idsToDelete) => ({
        url: salesInvoiceApiBase + "/v1/receipt/cancel",
        method: "POST",
        body: idsToDelete,
      }),
      transformResponse: (result: any) => {
        return result;
      },
      invalidatesTags: ["Receipt"],
    }),
  }),
});

export const { useGetReceiptsQuery, useGetIncomingReceiptsQuery, useGetFilteredReceiptsQuery, useDeleteReceiptsMutation } = receiptSlice;
