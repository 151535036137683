import { Dialog, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { MIQuerySearchParams } from "../../types/financeTypes";
import { useGetBasketsByFinanceIdQuery } from "../../store/api/basketApi";
import { defaultPaginationModel } from "../../utils/queryUtils";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import React from "react";

interface IViewInvoiceBasketsModalProps {
  financeId: string;
  invoiceNumber: string;
  supplierName: string;
  icon: React.ReactNode;
}

const style = {
  py: 0,
  width: "100%",
  maxWidth: 360,
  borderRadius: 2,
  border: "1px solid",
  borderColor: "divider",
  backgroundColor: "background.paper",
};

const ViewInvoiceBasketsModal: React.FC<IViewInvoiceBasketsModalProps> = ({ financeId, invoiceNumber, supplierName, icon }) => {
  const [queryParams, setQueryParams] = useState<MIQuerySearchParams>({
    pageInfo: defaultPaginationModel,
    id: financeId,
  });
  const { data, isSuccess, isFetching, refetch } = useGetBasketsByFinanceIdQuery(queryParams);

  const [baskets, setbBaskets] = useState<any[]>([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const baskets: {
      basketId: string;
      basketInfo: string;
    }[] = [];
    if (data && data.length > 0 && isSuccess) {
      data.map((basket) => {
        if (basket.supplier === supplierName) {
          const invoiceExists = basket.invoicesNumber.includes(invoiceNumber);
          let basketInfo = `Supplier Name: ${basket.supplier} / Total Amount: ${basket.requestedTotalAmount}`;
          if (invoiceExists) {
            baskets.push({
              basketId: basket.id.toString(),
              basketInfo: basketInfo,
            });
          }
        }
      });
    }

    setbBaskets(baskets);
  }, [data]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  console.log("basketssss", baskets);
  return (
    <>
      <IconButton
        title="View Invoice Baskets"
        onClick={handleOpenDialog}
        style={{
          color: "#333",
          paddingBottom: "0.8rem",
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} maxWidth="lg">
        <DialogTitle>
          <IconButton
            title="Back To Invoices"
            onClick={handleCloseDialog}
            style={{
              color: "#333",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          Baskets of {invoiceNumber}
        </DialogTitle>

        <DialogContent>
          {baskets.length > 0 ? (
            <List sx={style} aria-label="invoice-baskets">
              {baskets.map((basket, index) => (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemIcon>
                      <ShoppingBasketIcon sx={{ color: "#0056b3" }} />
                    </ListItemIcon>
                    <ListItemText primary={basket.basketInfo} />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </React.Fragment>
              ))}
            </List>
          ) : (
            <Typography variant="h6" color={"Highlight"}>
              This invoice does not exist in any basket
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewInvoiceBasketsModal;
