import { Container, Grid, MenuItem, TextField, Alert, FormControlLabel, Checkbox, InputLabel, Select, FormControl, CircularProgress } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateEntity, IPagination, IQuerySearchParams, ISubCategory } from "../../types/customTypes";
import { stockValues } from "../../types/formValueType";
import CreateSubCategory from "../subCategory/CreateSubCategory";
import { isValidTwoDecimalPrecision } from "../../utils/numberPrecision";
import DialogInput from "../../components/Dialog";
import { useAddNewProductMutation } from "../../store/api/productApi";
import { useGetSubcategoriesQuery } from "../../store/api/subcategoryApi";
import { defaultPaginationModel } from "../../utils/queryUtils";
import { StockCategoryType } from "../../types/fieldOptions";
import { CreateProductSchema } from "../../validation/product.validation";
import { zodResolver } from "@hookform/resolvers/zod";
import CurrencyList from "currency-list";
import LoadingButton from "@mui/lab/LoadingButton";
import UseFormPersist from "react-hook-form-persist";
import { clearStorage } from "../../utils/formStorage";
import ClearFormButton from "../../components/ClearFormButton";

function Stock({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  const { register, handleSubmit, formState, setValue, clearErrors, reset, watch } = useForm<stockValues>({
    defaultValues: {
      productName: "",
      description: "",
      stockCategory: "",
      subCategory: "",
      saleType: "",
      sellPrice: 0,
      isSalePriceVatIncluded: false,
      sellCurrency: "GBP",
      purchasePrice: 0,
      isPurchasePriceVatIncluded: false,
      buyCurrency: "GBP",
      vatRate: 0,
      openingStock: 0,
      stockBalance: 0,
      openingDate: new Date(),
      totalBought: 0,
      totalSold: 0,
      criticalStockLevel: 0,
      unitOfMeasurement: "",
    },
    resolver: zodResolver(CreateProductSchema),
  });

  const productFormValues = "productValues";

  UseFormPersist(productFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { errors } = formState;

  const [stockCategorySelection, setStockCategorySelection] = useState("");
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [showCreateSubCategoryComponent, setShowCreateSubCategoryComponent] = useState(false);
  const [subcategory, setSubcategory] = useState("");

  const [saleType, setSaleType] = useState("");
  const [isSaleVatIncluded, setIsSaleVatIncluded] = useState(false);
  const [isPurchaseVatIncluded, setIsPurchaseVatIncluded] = useState(false);
  const [openCreateSubcategoryDialog, setOpenCreateSubcategoryDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const currencies = useMemo(() => CurrencyList.getAll("en_US"), []);

  const [addNewProduct, mutationResult] = useAddNewProductMutation();
  const { isLoading: isAddInvoiceLoading, data: newProduct, isError: isProductAddedError, isSuccess: isProductAddedSuccess, error } = mutationResult;

  const [paginationModel] = useState<IPagination>(defaultPaginationModel);

  const [queryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });

  const { data: subcategories, isSuccess: isFetchCategorySuccess, isLoading: isLoadingSubcategory } = useGetSubcategoriesQuery(queryParams);

  const handleStockCategoryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const stockCategoryValue = event.target.value;
    setStockCategorySelection(stockCategoryValue);
  };

  useEffect(() => {
    const selectedSubCategory = subCategories.find((subCategory) => subCategory.subCategoryName === subcategory);

    if (selectedSubCategory) {
      const stockCategory = selectedSubCategory.stockCategory;
      setStockCategorySelection(selectedSubCategory.stockCategory);
      setValue("stockCategory", selectedSubCategory.stockCategory);
      if (stockCategory === "Services") {
        setValue("criticalStockLevel", 0);
        setValue("openingStock", 0);
      }
    }
  }, [subcategory]);

  const handleSubCategoryInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const categoryValue = event.target.value;

    if (categoryValue === "addNewCategory") {
      setShowCreateSubCategoryComponent(true);
      setOpenCreateSubcategoryDialog(true);
    } else {
      setShowCreateSubCategoryComponent(false);
      setSubcategory(categoryValue);
    }
    setValue("subCategory", categoryValue);
    clearErrors("subCategory");
  };

  const handleSaleTypeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const saleTypeValue = event.target.value;

    if (saleTypeValue === "Sell") {
      setValue("purchasePrice", 0);
      setValue("buyCurrency", "");
    } else if (saleTypeValue === "Buy") {
      setValue("sellPrice", 0);
      setValue("sellCurrency", "");
    }
    setSaleType(saleTypeValue);
    setValue("saleType", saleTypeValue);
    clearErrors("saleType");
  };

  const handleCloseCreateSubcategoryDialog = () => {
    setOpenCreateSubcategoryDialog(false);
    setSubcategory(subcategory);
  };

  const handleVatInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);

    if (isNaN(value)) {
      setValue("vatRate", 0);
    } else if (value >= 0 && value <= 100 && isValidTwoDecimalPrecision(event.target.value)) {
      setValue("vatRate", value);
    }
  };

  const handleSalePriceInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sellPrice = parseFloat(event.target.value);

    if (isValidTwoDecimalPrecision(sellPrice.toString())) {
      setValue("sellPrice", sellPrice);
      return;
    }
    if (!sellPrice) setValue("sellPrice", 0);
  };
  const handleSalePriceCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;
    console.log(checkedValue);

    setIsSaleVatIncluded(checkedValue);
    setValue("isSalePriceVatIncluded", checkedValue);
  };

  const handlePurchasePriceInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const purchasePrice = parseFloat(event.target.value);

    if (isValidTwoDecimalPrecision(purchasePrice.toString())) {
      setValue("purchasePrice", purchasePrice);
      return;
    }

    if (!purchasePrice) setValue("purchasePrice", 0);
  };

  const handlePurchasePriceCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkedValue = event.target.checked;
    console.log(checkedValue);

    setValue("isPurchasePriceVatIncluded", checkedValue);
    setIsPurchaseVatIncluded(checkedValue);
  };

  const handleOpeningStockInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const openingStock = parseFloat(event.target.value);

    if (isValidTwoDecimalPrecision(openingStock.toString())) {
      setValue("openingStock", openingStock);
      return;
    }
    if (!openingStock) setValue("openingStock", 0);
  };

  const handleCriticalStockLevelInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const criticalStockLevel = parseFloat(event.target.value);
    if (isValidTwoDecimalPrecision(criticalStockLevel.toString())) {
      setValue("criticalStockLevel", criticalStockLevel);
      return;
    }
    if (!criticalStockLevel) setValue("criticalStockLevel", 0);
  };

  const handleFormSubmit = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmit)(event);
  };

  const onSubmit: SubmitHandler<stockValues> = async (data) => {

    let salePrice, purchasePrice;
    let vatRate = data.vatRate;

    if (isSaleVatIncluded) {
      const sellPrice = data.sellPrice;
      salePrice = sellPrice - sellPrice * (vatRate / 100);
    } else {
      salePrice = data.sellPrice;
    }
    if (isPurchaseVatIncluded) {
      const buyPrice = data.purchasePrice;
      purchasePrice = buyPrice - buyPrice * (vatRate / 100);
    } else {
      purchasePrice = data.purchasePrice;
    }

    data.purchasePrice = purchasePrice;
    data.sellPrice = salePrice;
    data.stockCategory = stockCategorySelection;
    data.isSalePriceVatIncluded = isSaleVatIncluded;
    data.isPurchasePriceVatIncluded = isPurchaseVatIncluded;

    console.log(data);

    addNewProduct(data);
  };

  useEffect(() => {
    if (isProductAddedSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      onSuccess(newProduct);
      clearStorage(productFormValues);
      reset();
    } else if (isProductAddedError) {
      const errMsg = "data" in error ? (error.data as string) : "Unable to create stock";
      setErrorMessage(errMsg);
    }
  }, [isProductAddedSuccess, isProductAddedError, error]);

  useEffect(() => {
    if (isFetchCategorySuccess) {
      setSubCategories(subcategories.items);
    }
  }, [isFetchCategorySuccess, subcategories]);

  const onCreateSubcategorySuccess = (newSubcategory: ISubCategory) => {
    setSubCategories((prevSubCategories) => [...prevSubCategories, newSubcategory]);
    setSubcategory(newSubcategory.subCategoryName);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(productFormValues);
    reset();
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="New Stock"
      content={
        <Container maxWidth="lg">
          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: "16px" }}>
              {errorMessage}
            </Alert>
          )}
          <form onSubmit={handleFormSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Product Name *"
                  {...register("productName", {
                    required: "Product Name is required",
                  })}
                  error={!!errors.productName}
                  helperText={errors.productName?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="SubCategory *"
                  value={subcategory}
                  fullWidth
                  select
                  {...register("subCategory", {
                    required: "SubCategory is required",
                  })}
                  onChange={handleSubCategoryInput}
                  error={!!errors.subCategory}
                  helperText={errors.subCategory?.message}
                >
                  {isLoadingSubcategory ? (
                    <MenuItem value="" disabled>
                      <CircularProgress size={20} /> Loading Subcategories...
                    </MenuItem>
                  ) : subCategories.length === 0 ? (
                    <MenuItem value="">No sub category available</MenuItem>
                  ) : (
                    [
                      ...subCategories.map((subCategory) => (
                        <MenuItem key={subCategory.subCategoryName} value={subCategory.subCategoryName}>
                          {subCategory.subCategoryName}
                        </MenuItem>
                      )),
                    ]
                  )}
                  <MenuItem value="addNewCategory">---Add New Subcategory---</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Stock Category *"
                  id="selectStockCategory"
                  value={watch("stockCategory")}
                  {...register("stockCategory")}
                  onChange={handleStockCategoryInput}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                >
                  {StockCategoryType.map((category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Sale Type *"
                  value={watch("saleType")}
                  {...register("saleType", {
                    required: "Sale Type is required",
                  })}
                  onChange={handleSaleTypeInput}
                  fullWidth
                  select
                  error={!!errors.saleType}
                  helperText={errors.saleType?.message}
                >
                  {["Buy", "Sell", "Buy_Sell"].map((saleType, index) => (
                    <MenuItem key={index} value={saleType}>
                      {saleType}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Purchase Price"
                  type="number"
                  value={watch("purchasePrice")}
                  InputProps={{
                    readOnly: saleType === "Sell",
                  }}
                  {...register("purchasePrice", { valueAsNumber: true })}
                  onChange={handlePurchasePriceInput}
                  fullWidth
                />
                <FormControlLabel
                  control={<Checkbox onChange={handlePurchasePriceCheckboxChange} />}
                  label="Price is VAT included"
                  disabled={saleType === "Sell"}
                  {...register("isPurchasePriceVatIncluded")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <InputLabel id="buy-currency-label">Buy currency</InputLabel>
                  <Select
                    labelId="buy-currency-label"
                    id="buy-currency-select"
                    label="Buy currency"
                    value={watch("buyCurrency")}
                    onChange={(e) => setValue("buyCurrency", e.target.value)}
                    fullWidth
                    inputProps={{
                      readOnly: saleType === "Sell",
                    }}
                    sx={{ minWidth: "200px" }}
                  >
                    {Object.entries(currencies).map(([code, currency], index) => (
                      <MenuItem key={index} value={code}>
                        {currency.name} ({currency.symbol})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Sell Price"
                  value={watch("sellPrice")}
                  type="number"
                  {...register("sellPrice", { valueAsNumber: true })}
                  onChange={handleSalePriceInput}
                  fullWidth
                  InputProps={{
                    readOnly: saleType === "Buy" || stockCategorySelection === "Assets",
                  }}
                />
                <FormControlLabel
                  control={<Checkbox onChange={handleSalePriceCheckboxChange} />}
                  label="Price is VAT included"
                  disabled={saleType === "Buy" || stockCategorySelection === "Assets"}
                  {...register("isSalePriceVatIncluded")}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl>
                  <InputLabel id="sell-currency-select">Sell Currency</InputLabel>
                  <Select
                    labelId="sell-currency-label"
                    id="sell-currency-select"
                    label="Sell currency"
                    value={watch("sellCurrency")}
                    onChange={(e) => setValue("sellCurrency", e.target.value)}
                    fullWidth
                    inputProps={{
                      readOnly: saleType === "Buy" || stockCategorySelection === "Assets",
                    }}
                    sx={{ minWidth: "200px" }}
                  >
                    {Object.entries(currencies).map(([code, currency], index) => (
                      <MenuItem key={index} value={code}>
                        {currency.name} ({currency.symbol})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Critical Stock Level"
                  value={watch("criticalStockLevel")}
                  type="number"
                  InputProps={{
                    readOnly: stockCategorySelection === "Services",
                  }}
                  {...register("criticalStockLevel", { valueAsNumber: true })}
                  onChange={handleCriticalStockLevelInput}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Opening Stock"
                  value={watch("openingStock")}
                  type="number"
                  {...register("openingStock", { valueAsNumber: true })}
                  onChange={handleOpeningStockInput}
                  fullWidth
                  InputProps={{
                    readOnly: stockCategorySelection === "Services" || stockCategorySelection === "Assets",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Vat %"
                  value={watch("vatRate")}
                  type="number"
                  {...register("vatRate", { valueAsNumber: true })}
                  onChange={handleVatInput}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Unit of measurement" {...register("unitOfMeasurement")} value={watch("unitOfMeasurement")} fullWidth />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton type="submit" variant="contained" color="primary" sx={{ margin: "16px 0" }} loading={isAddInvoiceLoading}>
                Submit
              </LoadingButton>
              <ClearFormButton onClick={handleClearFormButtonClick} />
            </Grid>
          </form>
          {showCreateSubCategoryComponent && (
            <CreateSubCategory
              openDialog={openCreateSubcategoryDialog}
              handleCloseDialog={handleCloseCreateSubcategoryDialog}
              onSuccess={onCreateSubcategorySuccess}
            />
          )}
        </Container>
      }
      noDefaultButtons
    />
  );
}

export default Stock;
