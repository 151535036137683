import { Box, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import PurchaseInvoice from "./ManageInvoice";
import PurchaseReturnInvoice from "./ManageReturnPurchaseInvoice";

function ManageInvoiceTypeTab() {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: "10px" }}>
        <Tab label="Purchase Invoice" />
        <Tab label="Return Purchase Invoice" />
      </Tabs>
      {currentTab === 0 && <PurchaseInvoice />}
      {currentTab === 1 && <PurchaseReturnInvoice />}
    </Box>
  );
}

export default ManageInvoiceTypeTab;
