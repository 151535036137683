import { IQueryResult } from "../../types/customTypes";
import { ICreateOfferBody, IGetAllOffersForFinance, MIQuerySearchParams } from "../../types/financeTypes";
import { financeBaseApi } from "../env";
import { apiSlice } from "./baseApi";

export interface IGetAllOffersForSupplier {}

export const offerSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOffer: builder.mutation({
      query: (createOfferBody: ICreateOfferBody) => ({
        url: financeBaseApi + "/v1/offers",
        method: "POST",
        body: createOfferBody,
      }),

      invalidatesTags: ["Offer"],
    }),

    acceptOffer: builder.mutation({
      query: (acceptOfferBody: { offer_id: number }) => ({
        url: financeBaseApi + "/v1/accept-offer",
        method: "POST",
        body: acceptOfferBody,
      }),

      invalidatesTags: ["Offer"],
    }),

    getAllOffersForFinance: builder.query<IGetAllOffersForFinance[], MIQuerySearchParams>({
      query: ({ pageInfo, id }) => {
        const queryParams = {
          page: pageInfo.page.toString(),
          limit: pageInfo.pageSize.toString(),
        };
        return {
          url: financeBaseApi + `/v1/offers/finance/${id}?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Offer"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getAllOffersForSupplier: builder.query<IQueryResult<IGetAllOffersForSupplier[]>, MIQuerySearchParams>({
      query: ({ pageInfo, id }) => {
        const queryParams = {
          page: pageInfo.page.toString(),
          limit: pageInfo.pageSize.toString(),
        };
        return {
          url: financeBaseApi + `/v1/offers/supplier/${id}?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Offer"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
  }),
});

export const { useAcceptOfferMutation, useCreateOfferMutation, useGetAllOffersForFinanceQuery, useGetAllOffersForSupplierQuery } = offerSlice;
