import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {AuthState, User} from "../AuthState";
import { baseInitialState } from "../baseState";


const initialState: AuthState = {
  ...baseInitialState,
  authenticated: false,
  user: null,
};

export const registrationData = createAsyncThunk(
  "registration/signup",
  async (regUser: User) => {
    const postRequest = {
      method: "POST",
      email: regUser.email,
      password: regUser.password,
      confirm_password: regUser.confirmPassword,
      name: regUser.name,
      surname: regUser.surname,
      team_type: regUser.team_type,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      "https://dev2.payeda.net/api/auth/signup",
      postRequest
    );
    return response.data;
  }
);

const authSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(registrationData.pending, (state) => {
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      .addCase(registrationData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.authenticated = true;
        state.user = action.payload;
      })
      .addCase(registrationData.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.error.message?? "";
      });
  },
});

export default authSlice.reducer;
