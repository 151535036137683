import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Alert, MenuItem } from "@mui/material";
import { ISignup } from "../../types/customTypes";
import { useForm } from "react-hook-form";
import { useSignUpMutation } from "../../store/api/authenticationApi";
import { useNavigate } from "react-router-dom";
import CenteredContainer from "../../components/CenteredContainer";
import { z } from "zod";
import { setCommentRange } from "typescript";

const passwordSchema = z.object({
  password: z
    .string()
    .min(12, "Password should be at least 12 characters")
    .max(30, "Password should not exceed 30 characters")
    .refine(
      (data) => /[a-z]/.test(data),
      "Password should contain at least one lowercase letter"
    )
    .refine(
      (data) => /[A-Z]/.test(data),
      "Password should contain at least one uppercase letter"
    )
    .refine(
      (data) => /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(data),
      "Password should contain at least one special character"
    ),
});

export default function SignUp() {
  const [usageType, setUsageType] = useState("Business");
  const [errorMessage, setErrorMessage] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { register, handleSubmit, formState, clearErrors } = useForm<ISignup>();
  const { errors } = formState;
  const required = { required: "This field is required" };
  const emailPattern =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const navigate = useNavigate();

  const [signup, signUpMutationResult] = useSignUpMutation();
  const { isSuccess, isError, error } = signUpMutationResult;

  const validatePassword = (password: string) => {
    try {
      passwordSchema.parse({ password });
      return;
    } catch (error: any) {
      console.log(error);

      return error.errors[0].message;
    }
  };

  const onSubmit = (signUpDetails: ISignup) => {
    if (password!== confirmPassword) {
      return setErrorMessage("Passwords do not match");
    }
    signup(signUpDetails);
  };

  const closeAlert = () => {
    setErrorMessage("");
  };

  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    clearErrors("password");
  };

  const handleConfirmPasswordInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    clearErrors("confirm_password");
  };

  useEffect(() => {
    if (isSuccess) {
      navigate("/verify-email");
      setErrorMessage("");
    }
    if (isError) {
      const err: any = error;
      const errMsg = err.data?.message || "An error occured. Please try again";

      setErrorMessage(errMsg);
    }
  }, [signUpMutationResult]);

  return (
    <CenteredContainer>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5" fontWeight="bold">
            Sign up
          </Typography>
          {errorMessage && (
            <Alert severity="error" onClose={closeAlert} sx={{ width: "100%" }}>
              {errorMessage}
            </Alert>
          )}
          <Box sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="First Name *"
                    autoFocus
                    {...register("name", required)}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Last Name *"
                    {...register("surname", required)}
                    error={!!errors.surname}
                    helperText={errors.surname?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    id="email"
                    label="Email Address *"
                    autoComplete="email"
                    {...register("email", {
                      required: "Please enter email address",
                      pattern: {
                        value: emailPattern,
                        message: "Invalid email address",
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="usageType"
                    label="Usage Type *"
                    select
                    fullWidth
                    value={usageType}
                    {...register("team_type")}
                    onChange={(event) => setUsageType(event.target.value)}
                  >
                    <MenuItem value="Business">Business</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Password *"
                    type="password"
                    {...register("password", {
                      validate: validatePassword,
                    })}
                    onChange={handlePasswordInput}
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Confirm Password *"
                    type="password"
                    autoComplete="new-password"
                    {...register("confirm_password", required)}
                    onChange={handleConfirmPasswordInput}
                    error={!!errors.confirm_password}
                    helperText={errors.confirm_password?.message}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    name="promotionCode"
                    label="Promotion Code"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField fullWidth name="inviteCode" label="Invite Code" />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, width: "30%", marginLeft: "35%" }}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="/login" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </CenteredContainer>
  );
}
