import { useSelector } from "react-redux";
import { selectAccessToken } from "../store/features/authenticationSlice";
import { Navigate, Outlet} from "react-router-dom";

function CreateBusinessAuth() {
  const token = useSelector(selectAccessToken);  
  
  if (!token) {
    return <Navigate to="/login" />;
  } else return <Outlet />;
}

export default CreateBusinessAuth;
