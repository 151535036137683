import React, { useCallback, useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { IQuerySearchParams, ISupplier } from "../types/customTypes";
import { useGetSuppliersQuery } from "../store/api/supplierApi";
import { defaultPaginationModel } from "../utils/queryUtils";
import FastSupplierCreate from "./FastSupplierCreate";
import { Menu, Paper } from "@mui/material";

interface SupplierSelectProps {
  onChange: (supplierId: string) => void;
  register: any;
  error?: any;
  selectedSupplierValue: string;
  onCreateSupplierSuccess: (supplierDetails: ISupplier) => void
}

const SupplierSelect: React.FC<SupplierSelectProps> = ({
  onChange,
  error,
  register,
  selectedSupplierValue,
  onCreateSupplierSuccess
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewSupplierInput, setShowNewSupplierInput] = useState(false);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const { isSuccess: isGetSuppliersSuccess, data } = useGetSuppliersQuery({
    pageInfo: defaultPaginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [selectedSupplierId, setSelectedSupplierId] = useState("");

  React.useEffect(() => {
    if (isGetSuppliersSuccess && data) {
      setSelectedSupplierId(selectedSupplierValue);
      setSuppliers(data.items);
    }
  }, [isGetSuppliersSuccess]);

  useEffect(() => {
    if (isGetSuppliersSuccess && data) {
      setSelectedSupplierId(selectedSupplierValue);
    }
  }, [selectedSupplierValue]);

  const handleSupplierChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedValue = event.target.value as string;
    setSelectedSupplierId(selectedValue);
    if (selectedValue === "newSupplier") {
      setShowNewSupplierInput(true);
      setOpenDialog(true);
    } else {
      setShowNewSupplierInput(false);
      onChange(selectedValue);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSupplierId('')
  };
  const onNewSupplierCreation = (supplierDetails: ISupplier) => {
    setSuppliers((prevSuppliers) => [...prevSuppliers, supplierDetails]);
    onCreateSupplierSuccess(supplierDetails)
  };

  return (
    <>
      <TextField
        id="selectSupplier"
        select
        label="Select supplier *"
        variant="outlined"
        fullWidth
        margin="normal"
        defaultValue=""
        value={selectedSupplierId}
        {...register("supplierId", { required: "Please select supplier" })}
        onChange={handleSupplierChange}
        error={!!error.supplierId}
        helperText={error?.supplierId?.message}
      >
        {suppliers.length === 0 ? (
          <MenuItem key="">No suppliers available</MenuItem>
        ) : (
          [
            <MenuItem key="select" value="">
              Select an option
            </MenuItem>,
            ...suppliers.map((supplier) => (
              <MenuItem key={supplier._id} value={supplier._id}>
                {supplier.supplierName}
              </MenuItem>
            )),
          ]
        )}

        <MenuItem value="newSupplier">-- Create new supplier --</MenuItem>
      </TextField>
      {showNewSupplierInput && (
        <FastSupplierCreate
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onCreateNewSupplierSuccess={onNewSupplierCreation}
        />
      )}
    </>
  );
};

export default SupplierSelect;

// import React, { useState, useRef, useCallback, useEffect } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import TextField from "@mui/material/TextField";
// import Menu from "@mui/material/Menu";
// import Paper from "@mui/material/Paper";
// import { ISupplier, IQuerySearchParams } from "../types/customTypes";
// import { useGetSuppliersQuery } from "../store/api/supplierApi";
// import { defaultPaginationModel } from "../utils/queryUtils";
// import FastSupplierCreate from "./FastSupplierCreate";

interface SupplierSelect2Props {
  onChange: (supplierId: string) => void;
  register: any;
  error?: any;
  selectedSupplierValue: string;
  setSelectedSupplierValue: React.Dispatch<React.SetStateAction<string>>;
}

export const SupplierSelect2: React.FC<SupplierSelect2Props> = ({ onChange, error, register, selectedSupplierValue, setSelectedSupplierValue }) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewSupplierInput, setShowNewSupplierInput] = useState(false);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [page, setPage] = useState<number>(defaultPaginationModel.page);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterText, setFilterText] = useState<string>("");
  const [selectedSupplierName, setSelectedSupplierName] = useState<string>("");

  const menuRef = useRef<HTMLDivElement>(null);

  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: { page: page, pageSize: defaultPaginationModel.pageSize },
    searchColumns: [],
    searchKeyword: "",
  });

  const { isSuccess: isGetSupplierSuccess, data } = useGetSuppliersQuery(queryParams);

  const handleScroll = useCallback(() => {
    const menu = menuRef.current;
    if (menu && menu.scrollTop + menu.clientHeight === menu.scrollHeight && isGetSupplierSuccess && data && data.items.length > 0) {
      const newPage = queryParams.pageInfo.page + 1;
      const newQueryParams = { ...queryParams, pageInfo: { ...queryParams.pageInfo, page: newPage } };
      setPage(newPage);
      setQueryParams(newQueryParams);
    }
  }, [isGetSupplierSuccess, data]);

  useEffect(() => {
    if (isGetSupplierSuccess && data) {
      const newSuppliers = data.items.filter((newSupplier: any) => !suppliers.some((existingSupplier) => existingSupplier._id === newSupplier._id));

      setSuppliers((prevSupplier) => [...prevSupplier, ...newSuppliers]);
    }
  }, [isGetSupplierSuccess, data]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSupplierChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as string;
    setSelectedSupplierValue(selectedValue);

    if (selectedValue === "newSupplier") {
      setShowNewSupplierInput(true);
      setOpenDialog(true);
    } else {
      setShowNewSupplierInput(false);
      onChange(selectedValue);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedSupplierValue("");
  };
  const onNewSupplierCreation = (supplierDetails: ISupplier) => {
    setSuppliers((prevSuppliers) => [...prevSuppliers, supplierDetails]);
    setSelectedSupplierValue("");
  };
  const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  return (
    <>
      <TextField
        id="selectSupplier"
        label="Select supplier"
        variant="outlined"
        fullWidth
        margin="normal"
        value={selectedSupplierName}
        onClick={handleMenuOpen}
        {...register("supplierId", { required: "Please select supplier" })}
        onChange={handleSupplierChange}
        error={!!error.supplierId}
        helperText={error?.supplierId?.message}
      />
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={handleMenuClose}>
        <TextField label="Search A Supplier" variant="outlined" fullWidth margin="normal" value={filterText} onChange={handleFilterTextChange} />

        <Paper
          onScroll={handleScroll}
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
          ref={menuRef}
        >
          {suppliers.length === 0 ? (
            <MenuItem key="">No suppliers available</MenuItem>
          ) : (
            <>
              <MenuItem
                value="newSupplier"
                onClick={() => {
                  setSelectedSupplierValue("newSupplier");
                  setShowNewSupplierInput(true);
                  setOpenDialog(true);
                  handleMenuClose();
                }}
              >
                -- Create new supplier --
              </MenuItem>
              {suppliers
                .filter((supplier) => supplier.supplierName.toLowerCase().includes(filterText.toLowerCase()))
                .map((supplier) => (
                  <MenuItem
                    key={supplier._id}
                    value={supplier._id || ""}
                    onClick={() => {
                      const selectedSupplierId = supplier._id || "";
                      const supplierName = supplier.supplierName || "";
                      setSelectedSupplierValue(selectedSupplierId);
                      setSelectedSupplierName(supplierName);

                      if (selectedSupplierId === "newSupplier") {
                        setShowNewSupplierInput(true);
                        setOpenDialog(true);
                      } else {
                        setShowNewSupplierInput(false);
                        onChange(selectedSupplierName);
                      }

                      handleMenuClose();
                      handleSupplierChange({
                        target: { value: selectedSupplierId },
                      } as React.ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    {supplier.supplierName}
                  </MenuItem>
                ))}
            </>
          )}
        </Paper>
      </Menu>
      {showNewSupplierInput && <FastSupplierCreate openDialog={openDialog} handleCloseDialog={handleCloseDialog} onCreateNewSupplierSuccess={onNewSupplierCreation} />}
    </>
  );
};

// export default SupplierSelect;
