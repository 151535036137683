import { z } from "zod";

export const OfferBodySchema = z.object({
  finance_account_id: z.number().int().positive("Finance account ID must be a positive integer"),
  supplier_id: z.number().int().positive("Supplier ID must be a positive integer"),
  basket_id: z.number().int().positive("Basket ID must be a positive integer"),
  offer_data: z.object({
    advance_payment_amount: z.number().positive("Advance payment amount must be a positive number"),
    advance_payment_percentage: z.number().positive().max(100, "Advance payment percentage must be between 0 and 100"),
    expense_amount: z.number().positive("Expense amount must be a positive number"),
    expense_percentage: z.number().positive().max(100, "Expense percentage must be between 0 and 100"),
    remaining_payment_amount: z.number().positive("Remaining payment amount must be a positive number"),
    remaining_payment_percentage: z.number().positive().max(100, "Remaining payment percentage must be between 0 and 100"),
    advance_payment_maturity: z.number().int().positive("Advance payment maturity must be a positive integer"),
    advance_payment_maturity_type: z.enum(["hour", "day", "month", "year"]).optional(),
    remaining_payment_maturity: z.number().int().positive("Remaining payment maturity must be a positive integer"),
    remaining_payment_maturity_type: z.enum(["hour", "day", "month", "year"]).optional(),
    offer_document: z.string().nonempty("Offer document is required"),
    valid_until: z.date().min(new Date(), "Valid until date must be in the future"),
  }),
});
