import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  GridColDef,
  GridRenderEditCellParams,
  GridRowId,
  GridRowModel,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  CellFieldProps,
  IPagination,
  IProduct,
  IQuerySearchParams,
  ISubCategory,
} from "../../types/customTypes";
import PageHeader from "../../components/PageHeader";
import CreateProduct from "./CreateProduct";
import {
  useDeleteProductMutation,
  useGetProductsQuery,
  useUpdateProductMutation,
} from "../../store/api/productApi";
import PageManagementGrid from "../../components/DataGrid";
import { defaultPaginationModel } from "../../utils/queryUtils";
import SuccessSnackbar from "../../components/Snackbar";
import SearchFilter from "../../components/Filter";
import FilterButton from "../../components/ActionButton";
import { useGetSubcategoriesQuery } from "../../store/api/subcategoryApi";
import AmountEditField from "../../components/AmountEditField";
import { SaleType, StockCategoryType } from "../../types/fieldOptions";
import StyledTooltip from "../../components/StyledTooltip";
import CurrencyEditCell from "../../components/CurrentEditCell";
import { formatAmount } from "../../utils/amountFormatter";

interface RowData extends IProduct {
  isNew?: boolean;
}

interface EditSubcategory extends CellFieldProps {
  subcategories: ISubCategory[];
}

function EditSubCategory({
  id,
  field,
  api,
  row,
  subcategories,
}: EditSubcategory) {
  const [subcategoryValue, setSubcategoryValue] = useState<string>(
    api.getCellValue(id, field)
  );
  const [criticalStockLevel] = useState(
    api.getCellValue(id, "criticalStockLevel")
  );
  const [openingStock] = useState(api.getCellValue(id, "openingStock"));
  const [errorMessage, setErrorMessage] = useState("");
  const hasInvoice = row.invoiceCount > 0;

  useEffect(() => {
    if (subcategoryValue && subcategories.length > 0) {
      const subcategory = subcategories.find(
        (subcategory) => subcategory.subCategoryName === subcategoryValue
      );
      if (subcategory) {
        const stockCategory = subcategory.stockCategory;
        api.setEditCellValue({
          id,
          field: "stockCategory",
          value: stockCategory,
        });
      }
    }
  }, [subcategoryValue, subcategories, id, api]);

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasInvoice) {
      setErrorMessage(
        "Cannot edit subcategory, invoice with this product already exists"
      );
    }
  };

  const handleSubCategorySelectChange = (e: SelectChangeEvent<string>) => {
    const newSubCategory = e.target.value as string;
    setSubcategoryValue(newSubCategory);

    // Check if the new subcategory value is one of the available options
    if (
      subcategories.some(
        (subcategory) => subcategory.subCategoryName === newSubCategory
      )
    ) {
      const subcategory = subcategories.find(
        (subcategory) => subcategory.subCategoryName === newSubCategory
      );
      if (subcategory) {
        const stockCategory = subcategory.stockCategory;
        api.setEditCellValue({
          id,
          field,
          value: newSubCategory,
        });
        //Service should typically not have a critical stock level
        //So if the stockCategory is Services, the criticalStockLevel
        //and openingStock should be 0
        if (stockCategory === "Services") {
          api.setEditCellValue({
            id,
            field: "criticalStockLevel",
            value: 0,
          });
          api.setEditCellValue({
            id,
            field: "openingStock",
            value: 0,
          });
        } else {
          api.setEditCellValue({
            id,
            field: "criticalStockLevel",
            value: criticalStockLevel,
          });
          api.setEditCellValue({
            id,
            field: "openingStock",
            value: openingStock,
          });
        }

        api.setEditCellValue({
          id,
          field: "stockCategory",
          value: stockCategory,
        });
      }
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <Select
        value={subcategoryValue}
        onChange={handleSubCategorySelectChange}
        variant="outlined"
        inputProps={{
          readOnly: hasInvoice,
        }}
        onBlur={handleBlur}
        onFocus={handleFocus}
        fullWidth
      >
        {subcategories?.map((subcategory: ISubCategory) => (
          <MenuItem key={subcategory._id} value={subcategory.subCategoryName}>
            {subcategory.subCategoryName}
          </MenuItem>
        ))}
      </Select>
    </StyledTooltip>
  );
}

const SaleTypeEditCell: React.FC<CellFieldProps> = ({
  id,
  field,
  api,
  row,
}) => {
  const [saleTypeValue, setSaleTypeValue] = useState<string>(
    api.getCellValue(id, field)
  );
  //Variables to store initial value before editing started
  const [buyPrice] = useState(api.getCellValue(id, "purchasePrice"));
  const [buyCurrency] = useState(api.getCellValue(id, "buyCurrency"));
  const [sellPrice] = useState(api.getCellValue(id, "sellPrice"));
  const [sellCurrency] = useState(api.getCellValue(id, "sellCurrency"));
  const [errorMessage, setErrorMessage] = useState("");
  const hasInvoice = row.invoiceCount > 0;

  const handleSaleTypeChange = (e: SelectChangeEvent<string>) => {
    const newValue = e.target.value as string;

    setSaleTypeValue(newValue);

    api.setEditCellValue({ id, field, value: newValue });

    if (newValue === "Buy") {
      api.setEditCellValue({
        id,
        field: "sellPrice",
        value: 0,
      });
      api.setEditCellValue({
        id,
        field: "sellCurrency",
        value: "",
      });

      //Revert the BuyPrice to previous state
      //Useful for case for the user want to return back to previously
      //selected saletype and not lose the initially set value
      api.setEditCellValue({
        id,
        field: "purchasePrice",
        value: buyPrice,
      });
      api.setEditCellValue({
        id,
        field: "buyCurrency",
        value: buyCurrency,
      });
    } else if (newValue === "Sell") {
      api.setEditCellValue({
        id,
        field: "purchasePrice",
        value: 0,
      });
      api.setEditCellValue({
        id,
        field: "buyCurrency",
        value: "",
      });
      //Revert the SellPrice to previous state
      //Useful for case for the user want to return back to previously
      //selected saletype and not lose the initially set value
      api.setEditCellValue({
        id,
        field: "sellPrice",
        value: sellPrice,
      });
      api.setEditCellValue({
        id,
        field: "sellCurrency",
        value: sellCurrency,
      });
    } else if (newValue === "Buy_Sell") {
      api.setEditCellValue({
        id,
        field: "sellPrice",
        value: sellPrice,
      });
      api.setEditCellValue({
        id,
        field: "sellCurrency",
        value: sellCurrency,
      });
      api.setEditCellValue({
        id,
        field: "purchasePrice",
        value: buyPrice,
      });
      api.setEditCellValue({
        id,
        field: "buyCurrency",
        value: buyCurrency,
      });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasInvoice) {
      setErrorMessage(
        "Cannot edit subcategory, invoice with this product already exists"
      );
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <Select
        value={saleTypeValue}
        onChange={handleSaleTypeChange}
        variant="outlined"
        fullWidth
        onBlur={handleBlur}
        onFocus={handleFocus}
        readOnly={hasInvoice}
      >
        {SaleType.map((saleType) => (
          <MenuItem key={saleType} value={saleType}>
            {saleType}
          </MenuItem>
        ))}
      </Select>
    </StyledTooltip>
  );
};

function ProductNameEditInputCell(props: GridRenderEditCellParams) {
  const { value, api } = props;
  const [errorMessage, setErrorMessage] = useState("");
  const hasInvoice = props.row.invoiceCount > 0;

  const handleProductNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;

    setErrorMessage("");

    if (newValue === "") {
      setErrorMessage("Product name cannot be empty");
    } else {
      api.setEditCellValue({ ...props, value: newValue });
    }
  };

  const handleBlur = () => {
    setErrorMessage("");
  };

  const handleFocus = () => {
    if (hasInvoice) {
      setErrorMessage("Cannot edit name. Invoice with product already exists");
    }
  };

  return (
    <StyledTooltip open={!!errorMessage} title={errorMessage}>
      <TextField
        value={value}
        onChange={handleProductNameInput}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{
          readOnly: hasInvoice,
        }}
        fullWidth
      />
    </StyledTooltip>
  );
}

function StockPage() {
  const [rows, setRows] = useState<RowData[]>([]);
  const [ShowCreateStockComponent, setShowCreateStockComponent] =
    useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPagination>(
    defaultPaginationModel
  );
  const [showFilter, setShowFilter] = useState(false);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const [subcategories, setSubcategories] = useState<ISubCategory[]>([]);

  const { data: subcategoryResult, isSuccess: isGetSubcategoriesSuccess } =
    useGetSubcategoriesQuery(queryParams);

  useEffect(() => {
    if (isGetSubcategoriesSuccess && subcategoryResult) {
      setSubcategories(subcategoryResult.items);
    }
  }, [isGetSubcategoriesSuccess, subcategoryResult]);

  const [totalItems, setTotalItems] = useState(0);
  const {
    data: products,
    isSuccess,
    isFetching,
    refetch: refetchProducts,
  } = useGetProductsQuery(queryParams);
  const [updateProduct] = useUpdateProductMutation();
  const [deletedProduct] = useDeleteProductMutation();

  useEffect(() => {
    refetchProducts();
  }, []);

  useEffect(() => {
    if (isSuccess && products) {
      console.log(products.items);
      
      setRows(products.items);
      setTotalItems(products.totalItems);
    }
  }, [products, isSuccess]);

  const handleDelete = (deleteRowId: GridRowId) => {
    const productToDelete = rows.find((row) => row._id === deleteRowId);
    if (productToDelete) {
      deletedProduct(deleteRowId).then(() => {
        const updatedProducts = rows.filter(
          (product) => product._id !== deleteRowId
        );
        setRows(updatedProducts);
      });
    }
  };

  const handleUpdate = (updatedField: GridRowModel) => {
    updateProduct(updatedField);
  };

  const handleAddClick = () => {
    setShowCreateStockComponent(true);
  };

  const handleCloseDialog = () => {
    setShowCreateStockComponent(false);
  };

  const openSuccessbar = () => {
    setShowSucessbar(true);
  };

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  const handlePageChange = () => {
    setQueryParams({
      ...queryParams,
      pageInfo: paginationModel,
    });
  };

  const toggleFilter = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  useEffect(() => {
    handlePageChange();
  }, [paginationModel]);

  const handleSearch = (searchInput: {
    searchColumns: string[];
    searchKeyword: string;
  }) => {
    setQueryParams({
      ...queryParams,
      searchColumns: searchInput.searchColumns,
      searchKeyword: searchInput.searchKeyword,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "productName",
      headerName: "Product Name",
      width: 200,
      editable: true,
      renderEditCell: (params) => <ProductNameEditInputCell {...params} />,
    },
    {
      field: "subCategory",
      headerName: "Sub Category",
      width: 150,
      editable: true,
      renderEditCell: (props) => (
        <EditSubCategory {...props} subcategories={subcategories} />
      ),
    },
    {
      field: "stockCategory",
      headerName: "Stock Category",
      width: 150,
      type: "singleSelect",
      valueOptions: StockCategoryType,
      editable: true,
      renderEditCell: (params) => {
        const { value } = params;
        return (
          <TextField value={value} variant="outlined" disabled fullWidth />
        );
      },
    },
    {
      field: "saleType",
      headerName: "Sale Type",
      width: 80,
      editable: true,
      renderEditCell: (params) => <SaleTypeEditCell {...params} />,
    },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      type: "number",
      width: 120,
      editable: true,
      renderEditCell: (params) => <AmountEditField params={params} />,
      valueGetter: (params: GridValueGetterParams) => {return formatAmount(params.row.sellPrice)}  

    },
    {
      field: "sellCurrency",
      headerName: "Sell Currency",
      width: 120,
      editable: true,
      renderEditCell: (params) => <CurrencyEditCell {...params} />,
    },
    {
      field: "purchasePrice",
      headerName: "Purchase Price",
      type: "number",
      width: 100,
      editable: true,
      renderEditCell: (params) => <AmountEditField params={params} />,
      valueGetter: (params: GridValueGetterParams) => {return formatAmount(params.row.purchasePrice)}  

    },
    {
      field: "buyCurrency",
      headerName: "Purchase Currency",
      width: 120,
      editable: true,
      renderEditCell: (params) => <CurrencyEditCell {...params} />,
    },
    {
      field: "vatRate",
      headerName: "VAT Rate",
      type: "number",
      width: 100,
      editable: true,
      renderEditCell: (params) => <AmountEditField params={params} max={100} />,
    },
    {
      field: "openingStock",
      headerName: "Opening Stock",
      type: "number",
      width: 120,
      editable: false,

    },
    {
      field: "openingDate",
      headerName: "Opening Date",
      type: "date",
      width: 120,
      editable: false,
      valueGetter: (params) => {
        return new Date(params.value as string);
      },
    },
    {
      field: "totalBought",
      headerName: "Total Bought",
      type: "number",
      width: 120,
      editable: false,
    },
    {
      field: "totalSold",
      headerName: "Total Sold",
      type: "number",
      width: 120,
      editable: false,
    },
    {
      field: "stockBalance",
      headerName: "Stock Balance",
      type: "number",
      width: 120,
      editable: false,

    },
    {
      field: "criticalStockLevel",
      headerName: "Critical Stock Level",
      type: "number",
      width: 150,
      editable: true,
      renderEditCell: (params) => <AmountEditField params={params} />,
    },
    {
      field: "unitOfMeasurement",
      headerName: "Unit of Measurement",
      width: 150,
      editable: true,
    },
  ];

  return (
    <Box sx={{ maxHeight: "90vh" }}>
      <SuccessSnackbar
        open={showSuccessbar}
        handleClose={closeSuccessbar}
        severity="success"
        message="Stock succesfullly created"
      />
      <PageHeader title="Stock" onAdd={handleAddClick} />
      {ShowCreateStockComponent && (
        <CreateProduct
          openDialog={ShowCreateStockComponent}
          handleCloseDialog={handleCloseDialog}
          onSuccess={openSuccessbar}
        />
      )}
      <FilterButton showFilter={showFilter} toggleFilter={toggleFilter} />

      <Grid container>
        {showFilter && (
          <Grid item sm={12} md={3}>
            <SearchFilter columns={columns} onSearchInput={handleSearch} />
          </Grid>
        )}
        <Grid item sm={12} md={showFilter ? 9 : 12}>
          <PageManagementGrid
            rows={rows}
            columnFields={columns}
            onDelete={handleDelete}
            onUpdate={handleUpdate}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            sortModel={[{field: 'productName', sort: 'asc'}]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default StockPage;
