import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { GridColDef, GridCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import InvoiceTable from "./MTable";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { IconButtonStyled } from "../../pages/finance/financingRequest/ManageFinancingRequest";
import { ICustomCustomer, ICustomInvoice, ICustomSupplier, IMinvoice } from "../../types/financeTypes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ViewCustomerDetailsModal from "./ViewCustomerDetailsModal";
import ViewSupplierDetailsModal from "./ViewSupplierDetailsModal";
import ViewInvoiceBasketsModal from "./ViewInvoiceBasketsModal";
import Person4Icon from "@mui/icons-material/Person4";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddInvoiceToBasketDialog from "./AddInvoiceToBasketDialog";
import { salesInvoiceApiBase } from "../../store/env";
import { GetApp } from "@mui/icons-material";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { useFetchUserInfoQuery } from "../../store/api/infoApi";

export interface IInvoiceModalTable {
  id: number;
  invoiceNumber: string;
  issueDate: Date;
  totalAmount: number;
  fiananceRequestId: number;
  bidOpensAt: Date;
  bidClosesAt: Date;
  invoiceDocId: string;
  customerName: string;
  customer: ICustomCustomer;
  supplier: ICustomSupplier;
}

interface IViewInvoicesModalProps {
  data?: ICustomInvoice[];
  supplierName: string;
  icon: React.ReactNode;
}

const ViewInvoicesModal: React.FC<IViewInvoicesModalProps> = ({ data, icon, supplierName }) => {
  const [rows, setRows] = useState<IInvoiceModalTable[]>([]);

  const { isLoading: isUserInfoFetchLoading, isSuccess: isUserInfoFetchSuccess, data: userDetails, refetch: refetchUserDetails } = useFetchUserInfoQuery();

  const [financeId, setFinanceId] = useState<string>(userDetails.data.team_id ? userDetails.data.team_id : "");

  useEffect(() => {
    if (isUserInfoFetchSuccess && userDetails) setFinanceId(userDetails.data.team_id);
  }, [isUserInfoFetchSuccess, userDetails]);

  useEffect(() => {
    refetchUserDetails();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const rows = data?.map((invoice) => {
        const row: IInvoiceModalTable = {
          id: invoice.id,
          invoiceNumber: invoice.invoice_number,
          issueDate: new Date(invoice.issued_date),
          totalAmount: parseFloat(invoice.amount_to_be_paid),
          fiananceRequestId: invoice.financeRequestId!,
          bidOpensAt: new Date(invoice.bid_opens_at),
          bidClosesAt: new Date(invoice.bid_closes_at),
          invoiceDocId: invoice.invoice_document_id,
          customerName: invoice.customer.name,
          customer: invoice.customer,
          supplier: invoice.supplier,
        };
        return row;
      });
      setRows(rows);
    }
  }, [data]);

  const handleDownload = async (invoiceDocId: string) => {
    const confirmed = window.confirm("Are you sure you want to download the file?");
    if (confirmed) {
      const url = salesInvoiceApiBase + `/v1/invoice/generate/${invoiceDocId}`;
      window.open(url, "_blank");
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = "salesInvoice.pdf";
      downloadLink.click();
    }
  };
  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice No",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "bidOpensAt",
      headerName: "Bid Opens At",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "bidClosesAt",
      headerName: "Bid Closes At",
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      align: "center",
      headerAlign: "center",
    },

    {
      field: "totalAmount",
      headerName: "Total Amount",
      type: "number",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      flex: 2,
      align: "center",
      headerAlign: "center",

      renderCell: (params: GridCellParams) => (
        <>
          <IconButtonStyled color="primary" title="Download Invoice Document" onClick={() => handleDownload(params.row.invoiceDocId)}>
            <GetApp />
          </IconButtonStyled>
          <ViewCustomerDetailsModal data={[params.row.customer]} icon={<AccountCircleIcon />} />
          <ViewSupplierDetailsModal data={[params.row.supplier]} icon={<Person4Icon />} />
          <ViewInvoiceBasketsModal
            financeId={financeId}
            invoiceNumber={params.row.invoiceNumber}
            supplierName={supplierName}
            icon={<ShoppingBasketIcon sx={{ color: "#0056b3" }} />}
          />
          <AddInvoiceToBasketDialog
            financeId={financeId}
            invoiceId={params.row.id}
            financing_request_id={params.row.fiananceRequestId}
            invoiceNumber={params.row.invoiceNumber}
            supplierName={supplierName}
            icon={<AddCircleOutlineIcon />}
          />
        </>
      ),
    },
  ];
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton
        title="View Invoices"
        onClick={handleOpenDialog}
        style={{
          color: "#333",
        }}
      >
        {icon}
      </IconButton>
      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>Invoices</DialogTitle>
        <DialogContent>
          <InvoiceTable rows={rows ? rows : []} columns={columns} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewInvoicesModal;
