import { apiSlice } from "./baseApi";
import { IQueryResultBankAccount, IBankAccount, IQueryTransaction, IQueryResultTransaction, ITransaction, IUrl } from "../../types/customTypes";

import { salesInvoiceApiBase } from "../env";
//'/v1/transactions?bank_account_id=${indexId}&from=${controller.formatDate(fromDate)}&to=${controller.formatDate(toDate)}&id=${accountId}'
export const bankAccountSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBankAccounts: builder.query<IQueryResultBankAccount<IBankAccount[]>, void>({
      query: () => ({
        url: salesInvoiceApiBase + "/v1/bank-accounts",
        method: "GET",
      }),
      providesTags: ["BankAccount"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    getTransactions: builder.query<IQueryResultTransaction<ITransaction[]>, IQueryTransaction>({
      query: ({ indexId, fromDate, toDate, accountId }) => {
        const queryParams = {
          indexId, // normal id
          fromDate,
          toDate,
          accountId, // connected_account_id
        };
        return {
          url:
            salesInvoiceApiBase +
            `/v1/transactions?bank_account_id=${queryParams.indexId}&from=${queryParams.fromDate}&to=${queryParams.toDate}&id=${queryParams.accountId}`,
          method: "GET",
        };
      },
      providesTags: ["BankAccount"],
      transformResponse: (result: any) => {
        return result;
      },
    }),
    getUrls: builder.query<IUrl, void>({
      query: () => ({
        url: salesInvoiceApiBase + "/v1/bank-auth-url",
        method: "GET",
      }),
      providesTags: ["BankAccount"],
      transformResponse: (result: any): IUrl => {
        return result;
      },
    }),

    submitExchangeCode: builder.mutation({
      query: ({ userId, code }) => {
        const body = {
          userId,
          exchangeCode: code,
        };
        return {
          url: salesInvoiceApiBase + `/v1/exchangeCode`,
          body: body,
          method: "POST",
        };
      },
      transformResponse: (result: any) => {
        return result;
      },
    }),
  }),
});

export const { useGetBankAccountsQuery, useGetTransactionsQuery, useGetUrlsQuery, useSubmitExchangeCodeMutation } = bankAccountSlice;
