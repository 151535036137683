import { z } from "zod";

export const supplierAccountStatementSchema = z.object({
  supplierId: z.string().min(1, "Required"),
  startDate: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "Start Date is required." };
      }
      return { message: defaultError };
    },
  }),

  endDate: z.coerce.date({
    errorMap: ({ code }, { defaultError }) => {
      if (code === "invalid_date") {
        return { message: "Wrong date format." };
      } else if (code === "invalid_type") {
        return { message: "End Date is required." };
      }
      return { message: defaultError };
    },
  }),
}).refine((data) => data.startDate <= data.endDate, {
    message: "Start Date must be on or before End Date",
    path: ["startDate"],
  }).refine((data) => data.endDate >= data.startDate, {
    message: "End Date must be on or after Start Date",
    path: ["endDate"],
  });;