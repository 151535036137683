import { FinancingInvoices } from "../../types/financeTypes";
import {financeBaseApi } from "../env";
import { apiSlice } from "./baseApi";

export interface IGetFinancingInvoices {
  count: number;
  financing_invoices: FinancingInvoices[];
}

// GET /api/data?company_name=ExampleCompany&max_employee=100&min_employee=50&max_turnover=100000&min_turnover=50000&sectorTypeIn=["Sector1","Sector2"]&sectorModelIn=["Model1","Model2"]&bid_lte=2024-01-01&bid_gte=2023-01-01&min_invoice_amount=1000&max_invoice_amount=5000

export interface findFactoringRequestedInvoicesQueryString {
  // company_name: string;
  max_employee?: string | null;
  min_employee?: string | null;
  max_turnover?: string | null;
  min_turnover?: string | null;
  sectorTypeIn: string[];
  sectorTypeNotIn: string[];
  sectorModelIn: string[];
  sectorModelNotIn: string[];
  bid_lte?: string | null;
  bid_gte?: string | null;
  min_invoice_amount?: string | null;
  max_invoice_amount?: string | null;
}
export const mInvoiceSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    findFactoringRequestedInvoices: builder.query<IGetFinancingInvoices, findFactoringRequestedInvoicesQueryString>({
      query: ({
        bid_gte,
        bid_lte,
        // company_name,
        max_employee,
        max_invoice_amount,
        max_turnover,
        min_employee,
        min_invoice_amount,
        min_turnover,
        sectorModelIn,
        sectorModelNotIn,
        sectorTypeIn,
        sectorTypeNotIn,
      }) => {
        const queryParams: any = {
          bid_gte: bid_gte ? bid_gte : "",
          bid_lte: bid_lte ? bid_lte : "",
          max_employee: max_employee ? max_employee : "",
          max_invoice_amount: max_invoice_amount ? max_invoice_amount : "",
          max_turnover: max_turnover ? max_turnover : "",
          min_employee: min_employee ? min_employee : "",
          min_invoice_amount: min_invoice_amount ? min_invoice_amount : "",
          min_turnover: min_turnover ? min_turnover : "",
          sectorModelIn: JSON.stringify(sectorModelIn),
          sectorModelNotIn: JSON.stringify(sectorModelNotIn),
          sectorTypeIn: JSON.stringify(sectorTypeIn),
          sectorTypeNotIn: JSON.stringify(sectorTypeNotIn),
        };

        if (sectorModelIn.length === 0) delete queryParams.sectorModelIn;
        if (sectorModelNotIn.length === 0) delete queryParams.sectorModelNotIn;
        if (sectorTypeIn.length === 0) delete queryParams.sectorTypeIn;
        if (sectorTypeNotIn.length === 0) delete queryParams.sectorTypeNotIn;
        return {
          url: financeBaseApi + `/v1/financing-invoices?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      transformResponse: (result: any) => {
        return result;
      },
    }),
  }),
});

export const { useFindFactoringRequestedInvoicesQuery } = mInvoiceSlice;
