import React, { useEffect, useMemo, useState } from "react";
import { Button, Container, Grid, TextField, Alert, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { customerValues } from "../../types/formValueType";
import { isValidTwoDecimalPrecision } from "../../utils/numberPrecision";
import DialogInput from "../../components/Dialog";
import { CreateEntity } from "../../types/customTypes";
import { useAddNewCustomerMutation } from "../../store/api/customerApi";
import countryList from "react-select-country-list";
import codes from "country-calling-code";
import { CreateCustomerSchema } from "../../validation/customer.validation";
import { zodResolver } from "@hookform/resolvers/zod";

function CreateCustomer({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  const { register, handleSubmit, formState, clearErrors, watch, setValue, reset } = useForm<customerValues>({
    defaultValues: {
      name: "",
      address_line_1: "",
      address_line_2: "",
      region_province: "",
      post_code: "",
      city: "",
      country: "",

      email: "",
      openingBalance: 0,
      phone: "",
      phoneCountryCode: "",
    },
    resolver: zodResolver(CreateCustomerSchema),
  });
  const { errors } = formState;
  const [errorMessage, setErrorMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const [addNewCustomer, mutationResult] = useAddNewCustomerMutation();
  const { isSuccess, isError, error } = mutationResult;
  const countries = useMemo(() => countryList().getLabels(), []);

  const onSubmit = async (data: customerValues) => {
    console.log(data);
    try {
      await addNewCustomer(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpeningBalanceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(e.target.value);
    if (isValidTwoDecimalPrecision(value.toString())) {
      setValue("openingBalance", value);
      return;
    }
    if (!value) {
      value = 0;
      setValue("openingBalance", 0);
    }
  };

  const setDisplayMessage = () => {
    if (isSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      //onSuccess();
    } else if (isError) {
      const errMsg = "data" in error ? (error.data as string) : "Unable to create customer";
      setErrorMessage(errMsg);
    }
  };

  const handleCountryCodeInput = (event: SelectChangeEvent) => {
    setCountryCode(event.target.value);
    clearErrors("phoneCountryCode");
  };

  useEffect(() => {
    setDisplayMessage();
  }, [mutationResult]);

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="New Customer"
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <Typography gutterBottom sx={{ fontStyle: "italic" }}>
            * indicates required fields
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "16px" }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Customer Name *"
                  {...register("name", {
                    required: "Customer Name is required",
                  })}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Customer Email *"
                  {...register("email", {
                    required: "Please enter valid email",
                    pattern: {
                      value: /^\S+@\S+\.\S+$/,
                      message: "Invalid email address",
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address Line 1 *"
                  {...register("address_line_1", {
                    required: "Address field is required",
                  })}
                  error={!!errors.address_line_1}
                  helperText={errors.address_line_1?.message}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Address Line 2" {...register("address_line_2")} fullWidth />
              </Grid>
              <Grid item sm={6}>
                <FormControl sx={{ mt: 1, width: "100%" }}>
                  <Select
                    {...register("phoneCountryCode", {
                      required: "Please select country code",
                    })}
                    defaultValue="44"
                    onChange={handleCountryCodeInput}
                    error={!!errors.phoneCountryCode}
                  >
                    {codes.map(({ country, countryCodes }) => (
                      <MenuItem key={country} value={countryCodes[0]}>
                        {country + ": +" + countryCodes[0]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={6}>
                <FormControl sx={{ mt: 1, width: "100%" }}>
                  <TextField
                    {...register("phone", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^\d+$/,
                        message: "Phone number must contain only numeric characters",
                      },
                      maxLength: {
                        value: 15 - countryCode.length,
                        message: "Phone number cannot be longer than 15 digits",
                      },
                    })}
                    label="Phone *"
                    type="tel"
                    error={!!errors.phone || !!errors.phoneCountryCode}
                    helperText={errors.phone?.message || errors.phoneCountryCode?.message}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
              <TextField
                label="Region"
                {...register("region_province")}
                error={!!errors.region_province}
                helperText={errors.region_province?.message}
                fullWidth
              />
            </Grid>
            <br />
            <Grid item xs={12}>
              <TextField
                label="Post Code *"
                {...register("post_code", {
                  required: "This field is required",
                })}
                error={!!errors.post_code}
                helperText={errors.post_code?.message}
                fullWidth
              />
              <br />
              <br />
              <Grid item xs={12}>
                <TextField
                  label="City *"
                  {...register("city", {
                    required: "This field is required",
                  })}
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  fullWidth
                />
              </Grid>
              <br />
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    label="Country"
                    defaultValue=""
                    {...register("country")}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                    fullWidth
                  >
                    {countries.map((country, index) => (
                      <MenuItem key={index} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <br />

              <Grid item xs={12}>
                <TextField
                  label="Opening Balance"
                  value={watch("openingBalance")}
                  type="number"
                  {...register("openingBalance", {
                    valueAsNumber: true,
                  })}
                  onChange={handleOpeningBalanceInput}
                  fullWidth
                />
              </Grid>
              <br />
              <Grid item xs={12} marginBottom="20px">
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Confirm"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default CreateCustomer;
