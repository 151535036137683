import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { styled } from "styled-components";
import CenteredContainer from "../../components/CenteredContainer";
import { useDeleteBusinessAccountMutation } from "../../store/api/createBusinessApi";
import { useNavigate } from "react-router-dom";
import { clearStorage } from "../../utils/formStorage";
import { createBusinessFormValues } from "./formName";
const image = require("../../assets/logo.png");

const Logo = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
`;

function WaitingApprovalPage() {
  const [deleteBusinessAccount, {isSuccess: isDeleteAccountSuccessful, isLoading: isDeleteAccountLoading, isError: isDeleteAccountError}] = useDeleteBusinessAccountMutation()
  const navigate = useNavigate()

  const handleDeleteRegistrationData = () => {
    deleteBusinessAccount();
  }

  const handleEditRegistrationData = () => {
    navigate('/company-details-form', {state: {isReEdit: true}})
  }

  useEffect(() => {
    if(isDeleteAccountSuccessful){
      clearStorage(createBusinessFormValues)
      navigate('/create-business-prompt')
    }
  }, [isDeleteAccountSuccessful, isDeleteAccountError])
  

  return (
    <CenteredContainer>
      <Box component="main" maxHeight="md">
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Logo src={image} alt="Payeda Logo" />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h3" gutterBottom>
              Welcome to Payeda
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h5">Your account is awaiting approval</Typography>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <ButtonContainer>
              <Button type="button" variant="contained" sx={{ mr: 2 }} disabled={isDeleteAccountLoading} onClick={handleEditRegistrationData}>
                Edit Registration Data
              </Button>
              <Button type="button" variant="contained" onClick={handleDeleteRegistrationData} disabled={isDeleteAccountLoading}>
                Delete Registration Data
              </Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Box>
    </CenteredContainer>
  );
}

export default WaitingApprovalPage;
