import React, { ReactNode } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

interface DialogInputProps {
  open: boolean;
  onClose: () => void;
  title: string;
  content: ReactNode;
  onCancelText?: string;
  onConfirmText?: string;
  onConfirm?: () => void;
  noDefaultButtons?: boolean;
}

const DialogInput: React.FC<DialogInputProps> = (props) => {
  const {
    open,
    onClose,
    title,
    content,
    onCancelText = "Cancel",
    onConfirmText = "Confirm",
    onConfirm,
    noDefaultButtons,
  } = props;

  return (
    <Box>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        {!noDefaultButtons && (
          <DialogActions>
            <Button onClick={onConfirm}>{onConfirmText}</Button>
            <Button onClick={onClose}>{onCancelText}</Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default DialogInput;
