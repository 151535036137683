import { Typography, Button, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import CenteredContainer from "../components/CenteredContainer";
import { useConfirmVerificationEmailMutation } from "../store/api/authenticationApi";
import SuccessAlert from "../components/SuccessAlert";

function ConfirmEmailVerification() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [isVerificationSuccessful, setIsVerificationSuccessful] =
    useState(false);
  const [isVerificationError, setIsVerificationError] = useState(false);
  const [confirmVerificationEmail, { isSuccess, isError, error }] =
    useConfirmVerificationEmailMutation();

  useEffect(() => {
    confirmVerificationEmail({ token });
  }, [token]);

  useEffect(() => {
    if (isSuccess) {
      setIsVerificationError(false);
      setIsVerificationSuccessful(true);
    }
    if (isError) {
      setIsVerificationSuccessful(false);
      setIsVerificationError(true);
    }
  }, [isSuccess, isError]);

  return (
    <CenteredContainer>
      {isVerificationSuccessful && (
        <SuccessAlert
          successMessage="Your email has been successfully verified"
          buttonText="Login to your account"
          linkPath = "/login"
        />
      )}

      {isVerificationError && (
        <Box textAlign="center">
          <ErrorIcon sx={{ fontSize: "150px" }} color="error" />
          <Typography variant="h4" gutterBottom mb={3}>
            There was a problem verifying the email
          </Typography>
          <Link to="/send-verification-mail">
            <Button variant="contained">
              <Typography variant="h6">Resend Verification Link</Typography>
            </Button>
          </Link>
        </Box>
      )}
    </CenteredContainer>
  );
}

export default ConfirmEmailVerification;
