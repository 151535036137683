import React from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import styled from "styled-components";
import { IBasketTable, IBasketDetailsTable, ITableOffer, IGetFinancingRequestsTable } from "../../types/financeTypes";
import { IInvoiceModalTable } from "./ViewInvoicesModal";
import { ICustomerDetailsModalTable } from "./ViewCustomerDetailsModal";
import { ISupplierDetailsTable } from "./ViewSupplierDetailsModal";

export const StyledDataGridHeader = styled(DataGrid)`
  && .css-yrdy0g-MuiDataGrid-columnHeaderRow {
    font-size: small;
    background-color: #f5f5f5;
  }
`;

interface ITableProps<T> {
  rows: T[];
  columns: GridColDef[];
  backgroundColor?: string;
}

const Mtable: React.FC<
  ITableProps<
    ITableOffer | IBasketTable | ISupplierDetailsTable | IBasketDetailsTable | ICustomerDetailsModalTable | IGetFinancingRequestsTable | IInvoiceModalTable
  >
> = ({ rows, columns, backgroundColor }) => {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <StyledDataGridHeader
        sx={{ backgroundColor: `${backgroundColor}` }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  );
};

export default Mtable;
