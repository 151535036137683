import { TextField } from "@mui/material";
import { isValidTwoDecimalPrecision } from "../utils/numberPrecision";

type VatTextfieldProps = {
  register: any;
  name: string;
  vatValue: number;
  // setVat: (value: number) => void;
  onChange: (vat: number) => void;
};

function VatTextfield({
  register,
  name,
  vatValue,
  // setVat,
  onChange,
}: VatTextfieldProps) {
  const handleVatInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(event.target.value);

    if (isNaN(value)) {
      value = 0;
    } else if (
      value < 0 ||
      value > 100 ||
      !isValidTwoDecimalPrecision(event.target.value)
    ) {
      value = vatValue;
    }

    onChange(value);
  };

  return (
    <TextField
      label="Vat %"
      value={vatValue.toString()}
      type="number"
      onChange={handleVatInput}
      fullWidth
    />
  );
}

export default VatTextfield;
