import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BaseInterface, baseInitialState } from "../baseState";
import axios from "axios";

//const authToken = localStorage.getItem("token");

interface UploadStatus {
  authToken: string | null;
}

interface UploadStatusState extends BaseInterface {
  file: any;
}

const initialState: UploadStatusState = {
  ...baseInitialState,
  file: null,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

//This action is responsible for sending a GET request to check the upload status of a file.
export const fetchUploadStatus = createAsyncThunk(
  "uploadStatus/fetchUploadStatus",
  async ({ authToken }: UploadStatus) => {
    try {
      // It sends a GET request to the specified API endpoint (${baseUrl}/api/v1/account/docs/info) with the authToken in the headers.
      const response = await axios.get(`https://dev2.payeda.net/api/v1/account/docs/info`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      //If the request is successful (fulfilled), it returns the response data, which likely contains information about the uploaded file or file status.
      return response.data;
    } catch (error: any) {
      //If there's an error during the request (rejected), it throws an error with the error message.
      throw new Error(error.message);
    }
  }
);

const uploadStatusSlice = createSlice({
  name: "uploadStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploadStatus.pending, (state) => {
        //This reducer is triggered when the upload status check request is pending. It sets the loading-related state properties (isLoading, hasError, errorMessage) accordingly.
        state.isLoading = true;
        state.hasError = false;
        state.errorMessage = "";
      })
      .addCase(fetchUploadStatus.fulfilled, (state, action) => {
        //This reducer is triggered when the upload status check request is successful. It sets the loading-related state properties and updates the file property with the response data.
        state.isLoading = false;
        state.file = action.payload;
      })
      .addCase(fetchUploadStatus.rejected, (state, action) => {
        //This reducer is triggered when there's an error during the upload status check request. It sets the loading-related state properties, sets hasError to true, and stores the error message in errorMessage.
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.error.message ?? "";
      });
  },
});

export default uploadStatusSlice.reducer;
