import { z } from "zod";

/*export const CreateCustomerSchema = z.object({
    name: z.string().nonempty("Customer Name is required"),
    address_line_1: z.string().nonempty("Please enter address"),
  address_line_2: z.string(),
  region_province: z.string().optional(),
  post_code: z.string().optional(),
  city: z.string().nonempty("Please enter valid city"),
  country: z.string().nonempty("Please select country"),  
    email: z.string().nonempty("Customer email is required").email(),
    phone: z.string().nonempty("Please enter valid phone number"),
  phoneCountryCode: z.string().nonempty("Please select country code"),
});*/
export const CreateCustomerSchema = z.object({
  name: z.string(),
  address_line_1: z.string(),
  address_line_2: z.string(),
  region_province: z.string().optional(),
  post_code: z.string().optional(),
  city: z.string(),
  country: z.string(),
  email: z.string().email(),
  phone: z.string(),
  phoneCountryCode: z.string(),
  openingBalance: z.number().default(0).optional(),
});
export const UpdateCustomerSchema = z.object({
  body: z.object({
    name: z.string().nonempty().optional(),
    email: z.string().email().optional(),
    address_line_2: z.string().optional(),
    region_province: z.string().optional(),
    post_code: z.string().optional(),
    city: z.string().optional(),
    country: z.string().optional(),
    phone: z.string().optional(),
    phoneCountryCode: z.string().optional(),
    openingBalance: z.number().default(0).optional(),
  }),
});
