import { salesInvoiceApiBase } from "../env";
import { apiSlice } from "./baseApi";

export const teamSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateTeamFinancialInfo: builder.mutation({
      query: (financialInfo) => ({
        url: salesInvoiceApiBase + "/v1/account/team/financial",
        method: "PUT",
        body: financialInfo,
      }),
      invalidatesTags: ["Team"],
    }),
  }),
});

export const { useUpdateTeamFinancialInfoMutation } = teamSlice;
