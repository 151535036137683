import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AddIcon from "@mui/icons-material/Add";
import InvoiceLine from "./InvoiceLine";
import { IInvoiceLine, ISupplier } from "../../../types/customTypes";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { invoiceValues } from "../../../types/formValueType";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import PageTitle from "../../../components/PageTitle";

import { useAddNewInvoiceMutation } from "../../../store/api/invoiceApi";
import AlertSnackbar from "../../../components/Snackbar";
import SupplierSelect from "../../../components/SupplierSelect";
import { zodResolver } from "@hookform/resolvers/zod";
import { createInvoiceSchema } from "../../../validation/invoice.validationSchema";
import { invoiceDefaultValues, invoiceLineDefaultValues } from "../../../types/formDefaultValues";
import LoadingButton from "@mui/lab/LoadingButton";
import useFormPersist from "react-hook-form-persist";
import { clearStorage } from "../../../utils/formStorage";
import dayjs, { Dayjs } from "dayjs";
import ClearFormButton from "../../../components/ClearFormButton";
import { InvoiceType } from "../../../global/enums/entityEnum";
import { purchaseReturnDefaultValues } from "../../../types/formDefaultValues";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export default function CreatePurchaseReturnInvoice() {
  const [issueDate, setIssuedDate] = useState<Dayjs>(dayjs());
  const [dueDate, setDueDate] = useState<Dayjs>(dayjs());
  const [invoiceLines, setInvoiceLines] = useState<IInvoiceLine[]>([]);
  const navigate = useNavigate();

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [addNewInvoice, addInvoiceMutationResult] = useAddNewInvoiceMutation();
  const { isLoading: isAddInvoiceLoading, isSuccess: isAddInvoiceSuccess, isError: isAddInvoiceError, error: addInvoiceError } = addInvoiceMutationResult;
  const invoiceFormValues = "purchaseReturnValues";
  const [defaultInvoiceValues] = useState<invoiceValues>(() => {
    const storedValues = window.sessionStorage.getItem(invoiceFormValues);
    return storedValues ? JSON.parse(storedValues) : purchaseReturnDefaultValues;
  });

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    reset,
    clearErrors,
    watch,
    getValues,
  } = useForm<invoiceValues>({
    defaultValues: defaultInvoiceValues,
    resolver: zodResolver(createInvoiceSchema),
  });

  useFormPersist(invoiceFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const {
    fields: lines,
    append,
    remove,
  } = useFieldArray({
    name: "invoiceLines",
    control,
  });

  useEffect(() => {
    setValue("invoiceType", InvoiceType.PurchaseReturn);
  }, []);

  const calculateTotalAmounts = () => {
    let totalNetAmount = 0;
    let totalGrossAmount = 0;
    let totalVatAmount = 0;
    let totalDiscountAmount = 0;

    const invoiceLines = getValues("invoiceLines");

    invoiceLines.forEach((line) => {
      totalNetAmount += line.lineNetAmount;
      totalGrossAmount += line.lineGrossAmount;
      totalVatAmount += line.lineVatAmount;
      totalDiscountAmount += line.lineDiscountAmount;
    });

    setValue("totalNetAmount", parseFloat(totalNetAmount.toFixed(2)));
    setValue("totalGrossAmount", parseFloat(totalGrossAmount.toFixed(2)));
    setValue("totalVatAmount", parseFloat(totalVatAmount.toFixed(2)));
    setValue("totalDiscountAmount", parseFloat(totalDiscountAmount.toFixed(2)));

    return invoiceLines;
  };

  const handleLineInput = (index: number, lineData: IInvoiceLine) => {
    setInvoiceLines((prevInvoiceLines) => {
      const updatedLines = [...prevInvoiceLines];
      updatedLines[index] = lineData;

      return updatedLines;
    });
  };

  useEffect(() => {
    if (lines.length === 0) {
      append(invoiceLineDefaultValues);
    }
  }, []);

  const addNewInvoiceLine = () => {
    append(invoiceLineDefaultValues);
  };

  const onRemoveInvoiceLine = (index: number) => {
    setInvoiceLines((prevInvoiceLines) => prevInvoiceLines.filter((_, i) => i !== index));
  };

  useEffect(() => {
    calculateTotalAmounts();
  }, [invoiceLines]);

  useEffect(() => {
    if (isAddInvoiceSuccess) {
      setSuccessMessage("Invoice successfully created");
      setErrorMessage("");
      setShowSuccessSnackbar(true);
      reset(purchaseReturnDefaultValues);
      clearStorage(invoiceFormValues);
    } else if (isAddInvoiceError) {
      const errMsg = "data" in addInvoiceError ? (addInvoiceError.data as string) : "Unable to create Invoice";

      setErrorMessage(errMsg);
      setSuccessMessage("");
      setShowErrorSnackbar(true);
    }
  }, [isAddInvoiceSuccess, isAddInvoiceError, addInvoiceError]);

  const onSubmit = async (data: invoiceValues) => {
    const formData = new FormData();

    for (const key of Object.keys(data)) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = data[key as keyof invoiceValues];
        if (key === "invoiceLines") {
          formData.append(key, JSON.stringify(value));
        } else {
          formData.append(key, value as string);
        }
      }
    }
    addNewInvoice(formData);
  };

  const onNewSupplierCreation = (supplierDetails: ISupplier) => {
    setValue("supplierId", supplierDetails._id);
  };

  const handleSupplierChange = (supplierId: string) => {
    clearErrors("supplierId");
    setValue("supplierId", supplierId);
  };

  const handleIssueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("issueDate");
      setValue("issueDate", date);
    }
  };

  const handleDueDateChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("dueDate");
      setValue("dueDate", date);
    }
  };

  const handleSupplierInput = (supplierId: string) => {
    clearErrors("supplierId");
    setValue("supplierId", supplierId);
  };

  const handleCloseSnackbar = () => {
    successMessage && setShowSuccessSnackbar(false);
    errorMessage && setShowErrorSnackbar(false);
  };

  const handleClearFormButtonClick = () => {
    clearStorage(invoiceFormValues);
    reset(purchaseReturnDefaultValues);
  };

  const handleBackButtonClick = () => {
    navigate("/manageInvoice");
  };

  return (
    <Container>
      {successMessage && <AlertSnackbar open={showSuccessSnackbar} handleClose={handleCloseSnackbar} severity="success" message={successMessage} />}
      {errorMessage && <AlertSnackbar open={showErrorSnackbar} handleClose={handleCloseSnackbar} severity="error" message={errorMessage} />}
      <Box display="flex">
        <IconButton onClick={handleBackButtonClick}>
          <ArrowBackIcon fontSize="large" /> {/* Use the ArrowBackIcon as the back button */}
        </IconButton>
      </Box>
        <PageTitle title="Create Purchase Return Invoice" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} mt={4}>
          <Grid item md={12} lg={4} sm={12}>
            <TextField
              id="invoiceId"
              label="Invoice number *"
              variant="outlined"
              multiline
              rows={1}
              value={watch("invoiceNumber")}
              {...register("invoiceNumber")}
              error={!!errors.invoiceNumber}
              helperText={errors.invoiceNumber?.message}
            />
          </Grid>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} md={4} sm={6}>
              <Controller
                name="issueDate"
                control={control}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    value={dayjs(watch("issueDate"))}
                    label="Issue date *"
                    maxDate={dayjs(dueDate)}
                    {...register("issueDate", {
                      required: "Issue Date is required",
                    })}
                    onChange={handleIssueDateChange}
                    slotProps={{
                      textField: {
                        error: !!errors.issueDate,
                        helperText: errors.issueDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <Controller
                name="dueDate"
                control={control}
                render={({ field: { onChange } }) => (
                  <DatePicker
                    value={dayjs(watch("dueDate"))}
                    label="Due date *"
                    minDate={issueDate}
                    {...register("dueDate", {
                      required: "Due Date is required",
                    })}
                    onChange={handleDueDateChange}
                    slotProps={{
                      textField: {
                        error: !!errors.dueDate,
                        helperText: errors.dueDate?.message,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </LocalizationProvider>
          <Grid item xs={12}>
            <SupplierSelect
              error={errors}
              onChange={handleSupplierChange}
              register={register}
              selectedSupplierValue={watch("supplierId")}
              onCreateSupplierSuccess={onNewSupplierCreation}
            />
          </Grid>
        </Grid>

        {/* Invoice Line input fields */}
        <h4>Add Invoice Line</h4>
        {lines.map((line, index) => (
          <InvoiceLine
            key={index}
            index={index}
            onLineBlur={handleLineInput}
            register={register}
            error={errors}
            setValue={setValue}
            clearError={clearErrors}
            isInvoiceCreationSuccess={isAddInvoiceSuccess}
            remove={remove}
            onRemoveInvoiceLine={onRemoveInvoiceLine}
            watch={watch}
            getValues={getValues}
            control={control}
          />
        ))}
        <Grid container>
          <Grid item xs={12} sm={9}>
            <Button variant="contained" color="primary" type="button" onClick={addNewInvoiceLine} startIcon={<AddIcon />}>
              Add Invoice Line
            </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
              Subtotal:
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 0 }}>
              Discount Total:
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
              Vat total:
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 8 }}>
              Total Due:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 2 }}>
              {watch("totalGrossAmount")}
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 2 }}>
              {watch("totalDiscountAmount")}
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 1 }}>
              {watch("totalVatAmount")}
            </Typography>
            <Typography fontWeight="bold" variant="h6" component="h2" sx={{ marginLeft: 1 }}>
              {watch("totalNetAmount")}
            </Typography>
          </Grid>
        </Grid>
        <LoadingButton type="submit" variant="contained" color="primary" loading={isAddInvoiceLoading}>
          Submit
        </LoadingButton>
        <ClearFormButton onClick={handleClearFormButtonClick} />
      </form>
    </Container>
  );
}
