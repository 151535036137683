import axios from "axios";
import { baseInitialState, BaseInterface } from "../baseState";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

interface VatNumberState extends BaseInterface{
    vatNumber: number
}

const initialState : VatNumberState = {
    ...baseInitialState,
    vatNumber: 0
}

export const fetchVatNumber = createAsyncThunk("vatNum/fetchVatNum", async (vatNumber) => {
    try {
      const response = await axios.get(`https://dev2.payeda.net/api/v1/account//getHMRCInformation?vat_number=GB${vatNumber}`);
      return response.data;
      
    } catch (error: any) {
      throw new Error(error.message);
    }
  });
  
  const vatNumSlice = createSlice({
    name: "vatNumber",
    initialState,
    reducers: {
      resetVatNum: (state) => {
        state.vatNumber = 0;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchVatNumber.pending, (state) => {
          state.isLoading = true;
          state.hasError = false;
          state.errorMessage = "";
        })
        .addCase(fetchVatNumber.fulfilled, (state, action) => {
          state.isLoading = false;
          state.vatNumber = action.payload;
        })
        .addCase(fetchVatNumber.rejected, (state, action) => {
          state.isLoading = false;
          state.hasError = true;
          state.errorMessage = action.error.message ?? "";
        });
    },
  });
  export const { resetVatNum } = vatNumSlice.actions;
  export default vatNumSlice.reducer;