import { Grid, Typography } from "@mui/material";

interface InvoiceDetailItemProps {
  label: string;
  value: any;
}

function InvoiceDetailItem({ label, value }: InvoiceDetailItemProps) {
  return (
    <Grid container alignItems="flex-end">
      <Typography
        component="span"
        sx={{ fontWeight: "bold", paddingRight: "10px" }}
      >
        {label}:
      </Typography>
      <Typography component="span">{value}</Typography>
    </Grid>
  );
}

export default InvoiceDetailItem;
