import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Container, Select, SelectChangeEvent } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { MenuItem } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import NumbersIcon from "@mui/icons-material/Numbers";
import Button from "@mui/material/Button";
import HomeIcon from "@mui/icons-material/Home";
import PublicIcon from "@mui/icons-material/Public";
import InputLabel from "@mui/material/InputLabel";
import { useForm, useWatch } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { BusinessModel, BusinessType } from "./businessInfo";
import { CreateBusinessValues } from "../../types/formValueType";
import { useLocation, useNavigate } from "react-router-dom";
import { allCountries } from "country-region-data";
import { createBusinessFormValues } from "./formName";
import { createBusinessDefaultValues } from "../../types/formDefaultValues";
import useFormPersist from "react-hook-form-persist";
import { useLazyFetchTeamInfoQuery } from "../../store/api/infoApi";
import BackToHomeLink from "./NavigateToHome";

export default function CompanyDetailsForm() {
  const [regions, setRegions] = useState<string[]>([]);
  const { state } = useLocation();
  const [company_name, setCompanyName] = useState("");
  const [company_number, setCompanyNumber] = useState("");
  const [fetchTeamInfo, { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails }] = useLazyFetchTeamInfoQuery();

  const [defaultInvoiceValues] = useState<CreateBusinessValues>(() => {
    const storedValues = window.sessionStorage.getItem(createBusinessFormValues);
    return storedValues ? JSON.parse(storedValues) : createBusinessDefaultValues;
  });

  const {
    setValue,
    formState: { errors },
    watch,
    register,
    control,
    handleSubmit,
    clearErrors,
    getValues,
  } = useForm<CreateBusinessValues>({
    defaultValues: defaultInvoiceValues,
  });

  useFormPersist(createBusinessFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });

  const { isReEdit } = getValues();

  useEffect(() => {
    if (state) {
      if (state.businessData) {
        setCompanyName(state.businessData.company_name);
        setCompanyNumber(state.businessData.company_number);
      } else if (state.isReEdit) {
        setValue("isReEdit", true);
        fetchTeamInfo();
      }
    }
  }, [state]);

  const selectedCountry = useWatch({
    control,
    name: "country",
  });

  useEffect(() => {
    const countryData = allCountries.find((country) => country[0] === selectedCountry);
    if (countryData) {
      const regions = countryData[2];
      const allRegions = regions.map((region) => region[0]);
      setValue("city", allRegions[0]);
      setRegions(allRegions);
    } else {
      setRegions([]);
    }
  }, [selectedCountry, allCountries]);

  const handleCountryChange = (event: SelectChangeEvent) => {
    const selectedCountry = event.target.value;
    const countryData = allCountries.find((country) => country[0] === selectedCountry);
    if (countryData) {
      const regions = countryData[2];
      const allRegions = regions.map((region) => region[0]);
      setRegions(allRegions);
      setValue("country", selectedCountry);
      setValue("city", allRegions[0]);
    } else {
      setRegions([]);
      setValue("country", selectedCountry);
      setValue("city", "");
    }
  };

  useEffect(() => {
    if (isTeamInfoFetchSuccess && teamDetails) {
      if (isTeamInfoFetchSuccess && teamDetails) {
        setValue("business_name", teamDetails.data.business_name);
        setValue("company_reg_number", teamDetails.data.company_house_id);
        setValue("business_email", teamDetails.data.business_email);
        setValue("business_phone", teamDetails.data.business_phone);
        setValue("address_line_1", teamDetails.data.address.address_line_1);
        setValue("address_line_2", teamDetails.data.address.address_line_2);
        setValue("country", teamDetails.data.address.country);
        setValue("city", teamDetails.data.address.city);
        setValue("post_code", teamDetails.data.address.post_code);
        setValue("business_type", teamDetails.data.business_type);
        setValue("business_model", teamDetails.data.business_model);
        setValue("vat_percentage", teamDetails.data.vat_percentage);
        setValue("account_number", teamDetails.data.banking[0].account_number);
        setValue("sort_code", teamDetails.data.banking[0].sort_code);
        setValue("registrationOption", teamDetails.data.registrationOption);
        setValue("tax_id", teamDetails.data.tax_id);
        setValue("vat_number", teamDetails.data.vat_number);
        setValue("vat_percentage", teamDetails.data.vat_percentage);
        setValue("showPreviousBankStatement", true);
        setValue("registrationOptionBeforeReEdit", teamDetails.data.registrationOption);
      }
    }
  }, [isTeamInfoFetchSuccess, teamDetails]);

  useEffect(() => {
    if(isReEdit){
      if (watch("isRegistrationOptionChanged")) {
        setValue("showCompanyDocsUploadStatus", false);
        setValue("showSoleTraderDocsUploadStatus", false);
      } else if (watch("registrationOption") === "companyHouse") {
        setValue("showCompanyDocsUploadStatus", true);
        setValue("showSoleTraderDocsUploadStatus", false);
      } else {
        setValue("showSoleTraderDocsUploadStatus", true);
        setValue("showCompanyDocsUploadStatus", false);
      }
    }
  }, [watch("registrationOption"), isReEdit]);

  useEffect(() => {
    if (company_name) setValue("business_name", company_name);
    if (company_number) setValue("company_reg_number", company_number);
  }, [company_name, company_number]);

  const navigate = useNavigate();

  useEffect(() => {
    if (company_name) {
      setValue("business_name", company_name);
    }
    if (company_number) {
      setValue("company_reg_number", company_number);
    }
  }, [company_name, company_number]);

  const onSubmit = () => {
    navigate("/create-account");
  };

  const handlePhoneNumberInput = (phone: string) => {
    clearErrors("business_phone");
    setValue("business_phone", phone as string);
  };

  const handleRegionInput = (event: SelectChangeEvent<string>) => {
    const value = event?.target.value;
    setValue("city", value);
  };

  const handleVatInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearErrors("vat_percentage");
    const value = parseFloat(event.target.value);
    if (isNaN(value)) {
      setValue("vat_percentage", 0);
    } else if (value >= 0 && value <= 100) {
      const roundedValue = Math.round(value * 100) / 100;
      setValue("vat_percentage", roundedValue);
    }
  };

  const handleBackClick = () => {
    const { registrationOption } = getValues();
    setValue("business_name", "");
    setValue("business_email", "");
    setValue("company_reg_number", "");
    setValue("business_phone", "");
    setValue("business_name", "");
    setValue("address_line_1", "");
    setValue("address_line_2", "");
    setValue("business_phone", "");
    setValue("country", "United Kingdom");
    Promise.resolve().then(() => {
      setValue("city", "Birmingham");
    });
    setValue("post_code", "");
    setValue("business_type", "Personal Company");
    setValue("business_model", "Financial Services & Insurance");
    setValue("vat_percentage", 0);

    Promise.resolve().then(() => {
      if (registrationOption === "companyHouse") navigate("/company-reg-num-form");
      else if (registrationOption === "vatNumber" || registrationOption === "taxId") {
        navigate("/sole-trader-reg-options");
      }
    });
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <Grid item xs={32} sm={18} md={15} component={Paper} elevation={6} square>
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography component="h1" variant="h4" marginBottom="10px">
                Company Details
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Company Name:</InputLabel>
                    <TextField
                      fullWidth
                      autoComplete="given-name"
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BusinessIcon />
                          </InputAdornment>
                        ),
                        readOnly: company_name || (teamDetails && teamDetails.data.business_name) ? true : false,
                      }}
                      // value={company_name || ""}
                      {...register("business_name", {
                        required: "Field is required",
                      })}
                      error={!!errors.business_name}
                      helperText={errors.business_name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Company Number:</InputLabel>
                    <TextField
                      id="companyRegNumber"
                      fullWidth
                      variant="standard"
                      color="warning"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersIcon />
                          </InputAdornment>
                        ),
                        readOnly: company_number || (teamDetails && teamDetails.data.company_house_id) ? true : false,
                      }}
                      {...register("company_reg_number", {
                        required: "Field is required",
                      })}
                      error={!!errors.company_reg_number}
                      helperText={errors.company_reg_number?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Company Email:</InputLabel>
                    <TextField
                      color="warning"
                      fullWidth
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...register("business_email", {
                        required: "Field is required",
                      })}
                      error={!!errors.business_email}
                      helperText={errors.business_email?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Company Phone Number:</InputLabel>
                    <PhoneInput
                      value={watch("business_phone")}
                      {...register("business_phone", {
                        required: "Required",
                      })}
                      onChange={handlePhoneNumberInput}
                      inputStyle={{ height: "50px" }}
                    />
                    {errors.business_phone && <Typography color="red">{errors.business_phone?.message}</Typography>}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Address Line 1:</InputLabel>
                    <TextField
                      id="address1"
                      fullWidth
                      variant="standard"
                      color="warning"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HomeIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...register("address_line_1", {
                        required: "Field is required",
                      })}
                      error={!!errors.address_line_1}
                      helperText={errors.address_line_1?.message}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Address Line 2:</InputLabel>
                    <TextField
                      id="address2"
                      fullWidth
                      variant="standard"
                      color="warning"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HomeIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...register("address_line_2")}
                      error={!!errors.address_line_1}
                      helperText={errors?.address_line_1?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Country:</InputLabel>
                    <Select
                      label="Country"
                      // defaultValue="United Kingdom"
                      value={allCountries.length > 0 ? watch("country") : ""}
                      {...register("country", {
                        required: "Field is required",
                      })}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      onChange={handleCountryChange}
                      fullWidth
                    >
                      {allCountries.map((country, index) => (
                        <MenuItem key={index} value={country[0]}>
                          {country[0]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Region</InputLabel>
                    <Select
                      label="Region"
                      // defaultValue="Birmingham"
                      value={regions.length > 0 ? watch("city") : ""}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                          },
                        },
                      }}
                      {...register("city", {
                        required: "Field is required",
                      })}
                      onChange={handleRegionInput}
                      fullWidth
                    >
                      {regions.map((region, index) => (
                        <MenuItem key={index} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <InputLabel>Post Code:</InputLabel>
                    <TextField
                      id="postCode"
                      fullWidth
                      variant="standard"
                      color="warning"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PublicIcon />
                          </InputAdornment>
                        ),
                      }}
                      {...register("post_code", {
                        required: "Required",
                      })}
                      error={!!errors.post_code}
                      helperText={errors.post_code?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Business Type:</InputLabel>
                    <TextField
                      select
                      fullWidth
                      color="warning"
                      margin="dense"
                      variant="outlined"
                      {...register("business_type", {
                        required: "Field is required",
                      })}
                      defaultValue="Personal Company"
                      error={!!errors.business_type}
                      helperText={errors.business_type?.message}
                    >
                      {Object.values(BusinessType).map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel>Business Model:</InputLabel>
                    <TextField
                      select
                      fullWidth
                      color="warning"
                      margin="dense"
                      variant="outlined"
                      defaultValue="Financial Services & Insurance"
                      {...register("business_model", {
                        required: "Field is required",
                      })}
                      error={!!errors.business_model}
                      helperText={errors.business_model?.message}
                    >
                      {Object.values(BusinessModel).map((type) => {
                        return (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel>Vat Percentage:</InputLabel>
                    <TextField
                      id="vat%"
                      fullWidth
                      value={watch("vat_percentage")}
                      color="warning"
                      type="number"
                      margin="dense"
                      inputProps={{
                        min: 0,
                        step: 0.01,
                      }}
                      {...register("vat_percentage", {
                        required: "Required",
                        min: { value: 1, message: "Vat should be greater than 0" },
                        valueAsNumber: true,
                      })}
                      onChange={handleVatInput}
                      error={!!errors.vat_percentage}
                      helperText={errors.vat_percentage?.message}
                    />
                  </Grid>
                  <Grid container item>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      Next
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                      }}
                      onClick={handleBackClick}
                    >
                      {isReEdit && watch("registrationOption") === "companyHouse"
                        ? "Change company registration number"
                        : isReEdit
                        ? "Change registration Option"
                        : "Back"}
                    </Button>
                  </Grid>
                  <Grid container item justifyContent="center">
                    {isReEdit && <BackToHomeLink />}
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
