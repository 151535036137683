import { useEffect } from "react";
import { useForm } from "react-hook-form";
import DialogInput from "./Dialog";
import { ISupplier } from "../types/customTypes";
import { TextField } from "@mui/material";
import {
  useAddNewSupplierMutation,
} from "../store/api/supplierApi";
import { supplierDefaultValue } from "../types/formDefaultValues";

interface CreateSupplierProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  onCreateNewSupplierSuccess: (supplierDetails: ISupplier) => void;
}

function FastSupplierCreate({
  openDialog,
  handleCloseDialog,
  onCreateNewSupplierSuccess,
}: CreateSupplierProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ISupplier>({
    defaultValues: supplierDefaultValue,
  });

  const [
    addNewSupplier,
    { data: newSupplier, isSuccess: isAddSupplierSuccess, isError },
  ] = useAddNewSupplierMutation();

  const onSubmit = async (data: ISupplier) => {
    addNewSupplier(data);
  
  };

  useEffect(() => {
    if (isAddSupplierSuccess) {
      onCreateNewSupplierSuccess(newSupplier);
      handleCloseDialog()
    }
  }, [isAddSupplierSuccess]);

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Create New Supplier"
      onConfirmText="Create New Supplier"
      onConfirm={handleSubmit(onSubmit)}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="supplierName"
            label="Supplier Name"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("supplierName", {
              required: "Supplier Name is required",
            })}
            error={!!errors.supplierName}
            helperText={errors.supplierName?.message}
          />
          <TextField
            id="supplierEmail"
            label="Supplier Email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("supplierEmail", {
              required: "Supplier Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            error={!!errors.supplierEmail}
            helperText={errors.supplierEmail?.message}
          />
        </form>
      }
    />
  );
}

export default FastSupplierCreate;
