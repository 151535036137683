import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SupplierManagement from "./ManageSupplier";
import SupplierCreditNote from "./creditNote/ManageSupplierCreditNote";
import SupplierDebitNote from "./debitNote/ManageSupplierDebitNotes";
import SupplierAccountStatement from "./accountStatement/ManageSupplierAccountStatement";

const SupplierTab: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{ marginBottom: "10px" }}>
        <Tab label="Supplier Info" />
        <Tab label="Credit Notes" />
        <Tab label="Debit Notes" />
        <Tab label="Account Statement" />
      </Tabs>
      {currentTab === 0 && <SupplierManagement />}
      {currentTab === 1 && <SupplierCreditNote />}
      {currentTab === 2 && <SupplierDebitNote />}
      {currentTab === 3 && <SupplierAccountStatement/>}


    </Box>
  );
};

export default SupplierTab;