// Enum for document types
export const DocTypes = {
  LOGO: 0,
  BANKSTATEMENT: 1,
  ID_FRONT: 2,
  ID_REAR: 3,
  COMPANYCERTIFICATE: 4,
};

// Enum for upload status
export const UploadStatus = {
  WAITING: 0,
  UPLOADING: 1,
  UPLOADED: 2,
  FAIL: 3,
  DELETING: 4,
};

//This function is defined to retrieve the numeric value associated with a document type based on its string representation.
//It takes two parameters:
//docTypeStr: A string representing the document type.
//enumType: An enum (either DocTypes or UploadStatus) whose values you want to search for the docTypeStr.
export function getIndexForDocType(docTypeStr: string, enumType: any): number | null {
  switch (docTypeStr) {
    case "logo":
      return DocTypes.LOGO;
    case "id_front":
      return DocTypes.ID_FRONT;
    case "id_rear":
      return DocTypes.ID_REAR;
    case "company_certificate":
      return DocTypes.COMPANYCERTIFICATE;
    case "bank_statement":
      return DocTypes.BANKSTATEMENT;
    default:
      return -1;
  }
}
