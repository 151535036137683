import { apiSlice } from "./baseApi";

const CreateBusinessSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCompanyInfo: builder.query<any, string>({
      query: (registrationNumber) => ({
        url: `https://dev2.payeda.net/api/v1/account/getCompanyInformation?companyNumber=${registrationNumber}`,
      }),
    }),
    verifyVat: builder.query<any, string>({
      query: (vatNumber) => ({
        url: `https://dev2.payeda.net/api/v1/account//getHMRCInformation?vat_number=${vatNumber}`,
      }),
    }),
    sendBusinessDetails: builder.mutation<any, any>({
      query: (businessDetails) => ({
        url: `https://dev2.payeda.net/api/v1/account/team`,
        method: 'PUT',
        body: businessDetails
      }),
    }), 
    deleteBusinessAccount: builder.mutation<any, void>({
      query: (businessDetails) => ({
        url: `https://dev2.payeda.net/api/v1/account/team`,
        method: 'DELETE',
        // body: businessDetails
      }),
    }), 
  }),
});

export const {
  useFetchCompanyInfoQuery,
  useLazyFetchCompanyInfoQuery,
  useLazyVerifyVatQuery,
  useSendBusinessDetailsMutation,
  useDeleteBusinessAccountMutation
} = CreateBusinessSlice;
