import PercentIcon from "@mui/icons-material/Percent";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { MenuItemInfetface } from "./businessListItems";
import DashboardIcon from "@mui/icons-material/Dashboard";

export const financeListItems: MenuItemInfetface[] = [
  {
    text: "Dashboard",
    icon: <DashboardIcon />,
    link: "/",
  },
  {
    text: "Offer",
    icon: <PercentIcon />,
    link: "/manageOffer",
  },
  {
    text: "Basket",
    icon: <ShoppingBasketIcon />,
    link: "/manageBasket",
  },
  {
    text: "Financing Request",
    icon: <AccountBalanceIcon />,
    link: "/manageFinancingRequest",
  },
];
