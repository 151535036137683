import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DialogInput from "./Dialog";
import { ICustomer } from "../types/customTypes";
import { TextField } from "@mui/material";
import {
  useAddNewCustomerMutation,
  useLazyGetCustomerByIdQuery,
} from "../store/api/customerApi";
import { customerDefaultValue } from "../types/formDefaultValues";

interface CreateCustomerProps {
  openDialog: boolean;
  handleCloseDialog: () => void;
  onCreateNewCustomerSuccess: (customerDetails: ICustomer) => void;
}

function FastCustomerCreate({
  openDialog,
  handleCloseDialog,
  onCreateNewCustomerSuccess,
}: CreateCustomerProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ICustomer>({
    defaultValues: customerDefaultValue,
  });

  const [newCustomerId, setNewCustomerId] = useState("");
  const [addNewCustomer, { isSuccess: isAddCustomerSuccess, isError }] =
    useAddNewCustomerMutation();

  const [
    getCustomerById,
    {
      data: newlyCreatedCustomer,
      isSuccess: isGetCustomerSuccess,
      isError: isGetCustomerError,
    },
  ] = useLazyGetCustomerByIdQuery();

  const onSubmit = async (data: ICustomer) => {
    const newCustomer: any = await addNewCustomer(data);
    const newCustomerId = newCustomer.data._id;
    setNewCustomerId(newCustomerId);
  };

  useEffect(() => {
    if (newCustomerId) {
      getCustomerById(newCustomerId);
    }
  }, [newCustomerId]);

  useEffect(() => {
    if (isGetCustomerSuccess && newlyCreatedCustomer) {      
      onCreateNewCustomerSuccess(newlyCreatedCustomer);
      handleCloseDialog();
      reset();
    }
  }, [isGetCustomerSuccess, isGetCustomerError]);

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Create New Customer"
      onConfirmText="Create New Customer"
      onConfirm={handleSubmit(onSubmit)}
      content={
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            id="name"
            label="Customer Name"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("name", {
              required: "Customer Name is required",
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <TextField
            id="email"
            label="Customer Email"
            variant="outlined"
            fullWidth
            margin="normal"
            {...register("email", {
              required: "Customer Email is required",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Invalid email address",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </form>
      }
    />
  );
}

export default FastCustomerCreate;
