import React, { useEffect, useState } from "react";
import { Button, Container, Grid, TextField, Alert, Typography } from "@mui/material";
import DialogInput from "../../components/Dialog";
import { CreateEntity } from "../../types/customTypes";
import { financialInfoValues } from "../../types/formValueType";
import { useForm } from "react-hook-form";

import { Dayjs } from "dayjs";

import { useUpdateTeamFinancialInfoMutation } from "../../store/api/teamApi";
import { useFetchTeamInfoQuery } from "../../store/api/infoApi";

function EditReceivePaymentInfo({ openDialog, handleCloseDialog, onSuccess }: CreateEntity) {
  const { register, handleSubmit, formState, clearErrors, control, watch, setValue } = useForm<financialInfoValues>({
    defaultValues: {
      number_of_employee: 0,
      turnover_amount: 0,
      year: new Date(),
    },
  });
  const { isLoading: isTeamInfoFetchLoading, isSuccess: isTeamInfoFetchSuccess, data: teamDetails, refetch: refetchTeamDetails } = useFetchTeamInfoQuery();

  const { errors } = formState;
  const [errorMessage, setErrorMessage] = useState("");
  const [updateTeamFinancialInfo, mutationResult] = useUpdateTeamFinancialInfoMutation();
  const { isSuccess, isError, error } = mutationResult;

  const data = {
    account_number: "10000000",
    sort_code: "012131",
  };
  const setDisplayMessage = () => {
    if (isSuccess) {
      setErrorMessage("");
      handleCloseDialog();
      //onSuccess();
    } else if (isError) {
      const errMsg = "data" in error ? (error.data as string) : "Unable to update financial info";
      setErrorMessage(errMsg);
    }
  };
  useEffect(() => {
    setDisplayMessage();
  }, [mutationResult]);

  const onSubmit = async (data: financialInfoValues) => {
    await updateTeamFinancialInfo(data);
    await refetchTeamDetails();
    console.log(data);
    try {
      // handleCloseDialog();
    } catch (error) {
      console.error("Error updating financial info:", error);
      setErrorMessage("Error updating financial information");
    }
  };

  const handleTurnoverYearChange = (date: Dayjs | null) => {
    if (date) {
      clearErrors("year");
      setValue("year", date);
    }
  };

  const handleNumberOfEmployeesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9.]/g, "") || "0"; // Remove non-numeric characters except "."
    setValue("number_of_employee", parseFloat(value)); // Parse as float and set value
  };

  const handleTurnoverAmountChange = (value: string | undefined | null) => {
    if (value) {
      setValue("turnover_amount", parseFloat(value.replace(/[^0-9.]/g, "")));
    }
  };

  return (
    <DialogInput
      open={openDialog}
      onClose={handleCloseDialog}
      title="Receive Payment Account Change"
      content={
        <Container maxWidth="lg" sx={{ marginTop: "5px" }}>
          <Typography gutterBottom sx={{ fontStyle: "italic" }}>
            * indicates required fields
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            {errorMessage && (
              <Alert severity="error" sx={{ marginBottom: "16px" }}>
                {errorMessage}
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Account Number"
                  type="text"
                  fullWidth
                  defaultValue={data.account_number}
                  error={!!errors.number_of_employee}
                  helperText={errors.number_of_employee?.message}
                  onChange={handleNumberOfEmployeesChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Sort Code"
                  type="text"
                  fullWidth
                  defaultValue={data.sort_code}
                  error={!!errors.number_of_employee}
                  helperText={errors.number_of_employee?.message}
                  onChange={handleNumberOfEmployeesChange}
                />
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12} marginBottom="20px">
              <Button type="submit" variant="contained" color="primary">
                Update
              </Button>
            </Grid>
          </form>
        </Container>
      }
      onConfirmText="Update"
      onCancelText="Cancel"
      noDefaultButtons
    />
  );
}

export default EditReceivePaymentInfo;
