
/*
import React, { useState,useEffect } from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import { useGetCustomersQuery } from "../store/api/customerApi";
import { defaultPaginationModel } from "../utils/queryUtils";
import { ICustomer } from "../types/customTypes";
import FastCustomerCreate from "./FastCustomerCreate";




interface Option {
  value: string;
  label: string;
}

interface CustomerSelectProps {
  onChange: (customerId: string) => void;
  register: any;
  error?: any;
  selectedCustomerValue: string;
  setSelectedCustomerValue: React.Dispatch<React.SetStateAction<string>>;
}

interface LoadOptionsResponse {
  options: Option[];
  hasMore: boolean;
}




const CustomerSelect: React.FC<CustomerSelectProps> = ({
  onChange,
  error,
  register,
  selectedCustomerValue,
  setSelectedCustomerValue,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewCustomerInput, setShowNewCustomerInput] = useState(false);

 

  const [options, setOptions] = useState<readonly any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(defaultPaginationModel.page+1);

  const response = useGetCustomersQuery({
    page: page,
    pageSize: defaultPaginationModel.pageSize,
  });

  const loadOptions = async (
    search: string,
    loadedOptions: readonly any[]
  ): Promise<{ options: readonly any[]; hasMore: boolean }> => {
    if (loading || !hasMore) {
      return { options: loadedOptions, hasMore: false };
    }

    setLoading(true);

   

    const dataOptions = (response?.data?.data || []).map((customer: ICustomer) => ({
      value: customer.customer_id,
      label: customer.name,
    }));

    setOptions((prevOptions) => [...prevOptions, ...dataOptions]);
    setHasMore(dataOptions.length > 0);
    setPage((prevPage) => prevPage + 1);
    setLoading(false);

    return { options: options, hasMore: dataOptions.length > 0 };
  };

  const handleCustomerChange = (selectedOption: any) => {
    setSelectedCustomerValue(selectedOption?.value || "");

    if (selectedOption?.value === "newCustomer") {
      // Handle new customer logic
    } else {
      onChange(selectedOption?.value || "");
    }
  };

  useEffect(() => {
    // Reset the page when the component mounts or selectedCustomerValue changes
    setPage(defaultPaginationModel.page);
  }, [selectedCustomerValue]);



  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomerValue("");
  };

  const onNewCustomerCreation = (customerDetails: ICustomer) => {
    // Your logic to handle new customer creation
  };

  return (
    <>
       <AsyncPaginate
      isClearable
      defaultOptions
      loadOptions={(search, loadedOptions) => loadOptions(search, loadedOptions)}
      value={{
        value: selectedCustomerValue,
        label: selectedCustomerValue,
      }}
      onChange={handleCustomerChange}
      options={options}
      isSearchable
      additional={{
        page: page + 1,
      }}
    />

      {showNewCustomerInput && (
          <FastCustomerCreate
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onCreateNewCustomerSuccess={onNewCustomerCreation}
        />
      )}
    </>
  );
};

export default CustomerSelect;

*/





import React, { useState, useRef, useCallback, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { ICustomer } from "../types/customTypes";
import { useGetCustomersQuery } from "../store/api/customerApi";
import { defaultPaginationModel } from "../utils/queryUtils";
import FastCustomerCreate from "./FastCustomerCreate";

interface CustomerSelectProps {
  onChange: (customerId: string) => void;
  error?: any;
  register: any;
  selectedCustomerValue: string;
  setSelectedCustomerValue: (value: string) => void;
}

const CustomerSelect: React.FC<CustomerSelectProps> = ({
  onChange,
  error,
  selectedCustomerValue,
  setSelectedCustomerValue,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showNewCustomerInput, setShowNewCustomerInput] = useState(false);
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [page, setPage] = useState<number>(defaultPaginationModel.page + 1);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [filterText, setFilterText] = useState<string>(""); // State for filter text
  const menuRef = useRef<HTMLDivElement>(null);
  const [selectedCustomerName, setSelectedCustomerName] = useState<string>("");
  

  const { isSuccess: isGetCustomerSuccess, data } = useGetCustomersQuery({
    page,
    pageSize: defaultPaginationModel.pageSize,
  });

  const handleScroll = useCallback(() => { //call back cunku refeansı yeniden oluşturma
    const menu = menuRef.current; //menu.value >> id of customer  // menu.role = "menuitem"
    if ( // menünün alt kısmına ulaşınca sayfayı bir arttır yeni dataları al
      menu &&
      menu.scrollTop + menu.clientHeight === menu.scrollHeight && // menunun altına ulaştı mı 
      isGetCustomerSuccess &&
      data &&
      'data' in data &&
      Array.isArray(data.data) &&
      data.data.length > 0
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isGetCustomerSuccess, data]);

  useEffect(() => {
    if (isGetCustomerSuccess && data) {
      // scroll down yapınca tekrar tekrar gelen isimlerin var olanlarını filtrele
      const newCustomers = data.data.filter(
        (newCustomer) => !customers.some(
          (existingCustomer) => existingCustomer.id === newCustomer.id
        )
      );

      setCustomers((prevCustomers) => [...prevCustomers, ...newCustomers]);
    }
  }, [isGetCustomerSuccess, data]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCustomerValue("");
  };

  const onNewCustomerCreation = (customerDetails: ICustomer) => {
    setCustomers((prevCustomers) => [...prevCustomers, customerDetails]);
    setSelectedCustomerValue("");
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleCustomerChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  
    const selectedValue = event.target.value as string;
    setSelectedCustomerValue(selectedValue);

    if (selectedValue === "newCustomer") {
      setShowNewCustomerInput(true);
      setOpenDialog(true);
    } else {
      setShowNewCustomerInput(false);
      onChange(selectedValue);
    }
  };

  const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  return (
    <>
      <TextField
        id="selectCustomer"
        label="Select customer"
        variant="outlined"
        fullWidth
        margin="normal"
        value={selectedCustomerName}
        onClick={handleMenuOpen}
        onChange={handleCustomerChange}
        error={!!error.customerId}
        helperText={error?.customerId?.message}
      />
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        <TextField
          label="Search A Customer"
          variant="outlined"
          fullWidth
          margin="normal"
          value={filterText}
          onChange={handleFilterTextChange}
        />
        <Paper
          onScroll={handleScroll}
          style={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
          ref={menuRef}
        >
          {customers.length === 0 ? (
            <MenuItem key="">No customers available</MenuItem>
          ) : (
            [
              /*<MenuItem key="select" value="">
                Select an option
              </MenuItem>,*/
              <MenuItem value="newCustomer" onClick={() => {
                setSelectedCustomerValue("newCustomer");
                setShowNewCustomerInput(true);
                setOpenDialog(true);
                handleMenuClose();
              }}>
                -- Create new customer --
              </MenuItem>,
              ...customers
                .filter(customer => customer.name.toLowerCase().includes(filterText.toLowerCase()))
                .map((customer) => (
                  <MenuItem
                  key={customer.customer_id}
                  value={customer.name|| ""}
                  onClick={() => {
                    const selectedCustomerId = customer.id || "";
                    const customerName = customer.name || "";
                   setSelectedCustomerValue(selectedCustomerId);
                    setSelectedCustomerName(customerName)
                    if (selectedCustomerId === "newCustomer") {
                      setShowNewCustomerInput(true);
                      setOpenDialog(true);
                    } else {
                      setShowNewCustomerInput(false);
                      onChange(customerName);
                    }
                
                    handleMenuClose();
                    handleCustomerChange({ target: { value: selectedCustomerId } } as React.ChangeEvent<{ value: unknown }>); 
                  }}
                  
                >
                  {customer.name}
                </MenuItem>
            

                )),
            ]
          )}

        </Paper>  
      </Menu>
      {showNewCustomerInput && (
        <FastCustomerCreate
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onCreateNewCustomerSuccess={onNewCustomerCreation}
        />
      )}
    </>
  );
};
export default CustomerSelect;




