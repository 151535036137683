export enum BusinessType {
  "Personal Company" = "Personal Company",
  "Limited Liability Company" = "Limited Liability Company",
  "Incorporated Company" = "Incorporated Company",
  "Non-Governmental Company" = "Non-Governmental Company",
  "Sole Trader" = "Sole Trader",
}

export enum BusinessModel {
  "Accommodation and Food Services" = "Accommodation and Food Services",
  "Administrative and Support Services" = "Administrative and Support Services",
  "Arts and Recreation Services" = "Arts and Recreation Services",
  "Construction/Builder" = "Construction/Builder",
  "Education and Training" = "Education and Training",
  "Farming, forestry and fishing" = "Farming, forestry and fishing",
  "Financial Services & Insurance" = "Financial Services & Insurance",
  "Manufacturing" = "Manufacturing",
  "Medical/Health Care/Community Services" = "Medical/Health Care/Community Services",
  "Personal, Beauty, Wellbeing and other services" = "Personal, Beauty, Wellbeing and other services",
  "Professional Services(e.g. Legal, Accounting, Marketing, Consulting)" = "Professional Services(e.g. Legal, Accounting, Marketing, Consulting)",
  "Property Operators and Real Estate Services" = "Property Operators and Real Estate Services",
  "Rental&Hiring Services(non Real Estate)" = "Rental&Hiring Services(non Real Estate)",
  "Repair and Maintenance(Automative & Property)" = "Repair and Maintenance(Automative & Property)",
  "Retail Trade (Food & Beverage)" = "Retail Trade (Food & Beverage)",
  "Retail Trade (Non-Food)" = "Retail Trade (Non-Food)",
  "Technology/Telecommunications services" = "Technology/Telecommunications services",
  "Trades work(e.g. Plumber, Carpenter, Electrician)" = "Trades work(e.g. Plumber, Carpenter, Electrician)",
  "Trade Association, Association" = "Trade Association, Association",
  "Transport, Logistics, Postal, Warehousing" = "Transport, Logistics, Postal, Warehousing",
  "Whole Trade" = "Whole Trade",
}