import { apiSlice } from "./baseApi";
import { IPagination, IQueryResult, IQuerySearchParams, ISupplier } from "../../types/customTypes";
import { purchaseInvoiceApiBase } from "../env";

export const supplierSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewSupplier: builder.mutation({
      query: (newSupplier) => ({
        url: purchaseInvoiceApiBase + "/suppliers",
        method: "POST",
        body: newSupplier,
      }),

      invalidatesTags: ["Supplier"],
    }),

    getSuppliers: builder.query<IQueryResult<ISupplier[]>, IQuerySearchParams>({
      query: ({ pageInfo, searchColumns, searchKeyword }) => {
        const queryParams = {
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
          searchColumns: searchColumns.join(","),
          searchKeyword,
        };
        return {
          url: purchaseInvoiceApiBase + `/suppliers?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Supplier"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getSupplierById: builder.query<ISupplier, string>({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/suppliers/${id}`,
        method: "GET",
      }),
      providesTags: ["Supplier"],
      transformResponse: (result: any) => {
        return result;
      },
    }),

    getSupplierAccountStatement: builder.query<any, { supplierId: string; startDate: string; endDate: string; openingDate: string, pageInfo: IPagination}>({
      query: ({ supplierId, startDate, endDate, openingDate, pageInfo }) => {
        const queryParams = {
          startDate: startDate,
          endDate: endDate, 
          openingDate,
          page: (pageInfo.page + 1).toString(),
          limit: pageInfo.pageSize.toString(),
        };
        
        return {
          url: purchaseInvoiceApiBase + `/suppliers/accountStatement/${supplierId}?${new URLSearchParams(queryParams).toString()}`,
          method: "GET",
        };
      },
      transformResponse: (result: any) => {
        return result;
      },
    }),

    updateSupplier: builder.mutation({
      query: (updatedFields) => ({
        url: purchaseInvoiceApiBase + `/suppliers/${updatedFields._id}`,
        method: "PUT",
        body: updatedFields,
      }),
    }),
    deleteSupplier: builder.mutation({
      query: (id) => ({
        url: purchaseInvoiceApiBase + `/suppliers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Supplier"],
    }),
  }),
});

export const {
  useAddNewSupplierMutation,
  useGetSuppliersQuery,
  useGetSupplierByIdQuery,
  useLazyGetSuppliersQuery,
  useLazyGetSupplierByIdQuery,
  useUpdateSupplierMutation,
  useDeleteSupplierMutation,
  useGetSupplierAccountStatementQuery,
  useLazyGetSupplierAccountStatementQuery,
} = supplierSlice;