import { Box, Container, Grid } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import CreateFinanceAccount from "./createFinanceAccount";
import SuccessSnackbar from "../../components/Snackbar";
import { useGetFinanceMembersQuery } from "../../store/api/financeApi";
import { IPagination } from "../../types/customTypes";
import { defaultPaginationModel } from "../../utils/queryUtils";
import PageManagementGrid from "../../components/DataGrid";

function ManageFinanceAccount() {
  const [showCreateFinanceAccountDialog, setShowCreateFinanceAccountDialog] = useState(false);
  const [showSuccessbar, setShowSucessbar] = useState(false);
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [totalItems, setTotalItems] = useState(0);
  const [financeMembers, setFinancceMembers] = useState([]);

  const closeSuccessbar = () => {
    setShowSucessbar(false);
  };

  const [queryParams, setQueryParams] = useState({
    pageInfo: paginationModel,
  });

  const { data, refetch: refetchTeamDetails, isSuccess, isFetching } = useGetFinanceMembersQuery(queryParams);

  useEffect(() => {
    if (isSuccess) {
      setFinancceMembers(data.data);
      setTotalItems(data.total_items);
    }
  }, [isSuccess, data]);

  const handleDelete = async () => {
    //  if (deleteRowId) {
    //    const requestData = {
    //      invoice_ids: [deleteRowId],
    //    };
    //    setIsDeleteDialogOpen(false);
    //    setIsWaiting(true);
    //    try {
    //      await deleteInvoice(requestData);
    //      setIsWaiting(false);
    //      setSalesInvoices((oldSalesInvoices) => oldSalesInvoices.filter((s) => s.id !== deleteRowId));
    //    } catch (err) {
    //      setIsWaiting(false);
    //    }
    //  }
  };
  const columns: GridColDef[] = [
    {
      field: "bank_name",
      headerName: "Bank Name",
      width: 300,
      editable: true,
    },
    {
      field: "bank_email",
      headerName: "Bank Email",
      width: 300,
      editable: true,
    },
    {
      field: "bank_phone",
      headerName: "Bank Phon",
      width: 300,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 300,
      editable: true,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 300,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      editable: true,
    },
  ];

  const handleAddClick = () => {
    setShowCreateFinanceAccountDialog(true);
  };

  const closeFinanceAccountDialog = () => {
    setShowCreateFinanceAccountDialog(false);
  };

  const onSuccessfulFinanceAccountCreation = () => {
    setShowSucessbar(true);
  };

  return (
    <Box>
      <PageHeader title="Finance Accounts" onAdd={handleAddClick} />
      {/* <SuccessSnackbar open={showSuccessbar} handleClose={closeSuccessbar} severity="success" message="Finance Account successfully created" /> */}
      <Grid container>
        <Grid item sm={12}>
          <PageManagementGrid
            rows={financeMembers}
            columnFields={columns}
            paginationModel={paginationModel}
            isFetching={isFetching}
            totalItems={totalItems}
            setPagination={setPaginationModel}
            onDelete={handleDelete}
          />
        </Grid>
      </Grid>
      {showCreateFinanceAccountDialog && (
        <CreateFinanceAccount
          openDialog={showCreateFinanceAccountDialog}
          onSuccess={onSuccessfulFinanceAccountCreation}
          handleCloseDialog={closeFinanceAccountDialog}
        />
      )}
    </Box>
  );
}

export default ManageFinanceAccount;
