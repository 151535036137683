import { Box, Grid, Typography } from "@mui/material";
import { GridValueFormatterParams } from "@mui/x-data-grid";
import { ReactNode, useEffect, useState } from "react";
import PageManagementGrid from "../../../components/DataGrid";
import DialogInput from "../../../components/Dialog";
import PageHeader from "../../../components/PageHeader";
import { InvoiceType, NoteType } from "../../../global/enums/entityEnum";
import { useLazyGetSupplierAccountStatementQuery } from "../../../store/api/supplierApi";
import { IAccountStatementQueryResult, IPagination, IQuerySearchParams, ISupplierAccountStatement } from "../../../types/customTypes";
import { defaultPaginationModel } from "../../../utils/queryUtils";
import AccountStatementCreationForm from "./SupplierAccountStatementCreationForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { supplierAccountStatementSchema } from "../../../validation/supplierAccountStatement.validation";
import useFormPersist from "react-hook-form-persist";
import { useForm } from "react-hook-form";
import { supplierAccountStatementValues } from "../../../types/formValueType";
import { supplierAccountStatementDefaultValues } from "../../../types/formDefaultValues";
import { clearStorage } from "../../../utils/formStorage";
import dayjs from "dayjs";
import { toSentenceCase } from "../../../utils/textFormatter";
import { formatDate } from "../../../utils/dateFormatter";
import { formatAmount } from "../../../utils/amountFormatter";

const LabelText = ({ children }: { children: ReactNode }) => {
  return (
    <Typography component="span" fontWeight="bold" display="inline">
      {children}
    </Typography>
  );
};

function SupplierAccountStatement() {
  const [showFilter, setShowFilter] = useState(false);
  const [isAccountStatementCreationDialogOpen, setIsAccountStatementCreationDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [paginationModel, setPaginationModel] = useState<IPagination>(defaultPaginationModel);
  const [queryParams, setQueryParams] = useState<IQuerySearchParams>({
    pageInfo: paginationModel,
    searchColumns: [],
    searchKeyword: "",
  });
  const supplierAccountStatementFormValues = "supplierAccountStatement";
  const [accountStatement, setAccountStatement] = useState<IAccountStatementQueryResult>({ _id: "", documents: [] });
  const [accountStatementDocs, setAccountStatementDocs] = useState<any>([]);
  const [defaultAccountStatementValues] = useState<supplierAccountStatementValues>(() => {
    const storedValues = window.sessionStorage.getItem(supplierAccountStatementFormValues);
    let parsedStoredValues = supplierAccountStatementDefaultValues;

    if (storedValues) {
      const parsedValues = JSON.parse(storedValues);

      parsedStoredValues = {
        ...parsedValues,
        startDate: dayjs(parsedValues.startDate),
        endDate: dayjs(parsedValues.endDate),
      };
    }

    return parsedStoredValues;
  });
  const [getAccountStatement, { isLoading, isSuccess, isError, error, data }] = useLazyGetSupplierAccountStatementQuery();
  const [totalItems, setTotalItems] = useState(0);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
    reset,
    clearErrors,
    watch,
    getValues,
  } = useForm<supplierAccountStatementValues>({
    defaultValues: defaultAccountStatementValues,
    resolver: zodResolver(supplierAccountStatementSchema),
  });

  useFormPersist(supplierAccountStatementFormValues, {
    watch,
    setValue,
    storage: window.sessionStorage,
  });
  useEffect(() => {
    if (isSuccess) {
      if (data) {
        setAccountStatementDocs(data.documents);
      }
      // setIsAccountStatementCreationDialogOpen(false);
    }
    if (isError && error) {
      let errMsg = "An error occurred. Try again";
      if (error) {
        console.log(error);
        const errData: any = "data" in error && error.data;
        errMsg = errData.message && errData.message;
      }
      setErrorMessage(errMsg);
    }
  }, [isSuccess, data, isError, error]);

  useEffect(() => {
    clearErrors();
    setErrorMessage("");
  }, [watch("supplierId"), watch("startDate"), watch("endDate")]);

  const handleAddClick = () => {
    setIsAccountStatementCreationDialogOpen(true);
  };

  const onSubmit = async (data: supplierAccountStatementValues) => {
    fetchAccountStatement();
  };

  const handleClearFormButtonClick = () => {
    clearStorage(supplierAccountStatementFormValues);
    reset(supplierAccountStatementDefaultValues);
  };

  useEffect(() => {
    if (watch("supplierId")) {
      fetchAccountStatement();
    }
  }, [paginationModel]);

  const fetchAccountStatement = () => {
    const supplierId = watch("supplierId");
    const startDate = watch("startDate").toString();
    const endDate = watch("endDate").toString();
    const date = new Date("Mon, 01 Jan 2024 00:00:00 GMT");
    const openingDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    getAccountStatement({
      supplierId,
      startDate: new Date(startDate).toISOString(),
      openingDate,
      endDate: new Date(endDate).toISOString(),
      pageInfo: paginationModel,
    })
      .unwrap()
      .then((response) => {
        setTotalItems(response.pagination.totalItems);
        if (!isError) {
          setErrorMessage("");
          setIsAccountStatementCreationDialogOpen(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching account statement:", error);
      });
  };

  useEffect(() => {
    console.log(error);
  }, [error]);

  const columns = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 250,
      valueFormatter: (params: GridValueFormatterParams) => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
    },
    {
      field: "refId",
      headerName: "Ref No",
      width: 250,
      valueGetter: ({ row }: { row: any }) => {
        if (row.refId) {
          return row.refId;
        } else if (row.invoiceNumber) {
          return row.invoiceNumber;
        } else {
          return "";
        }
      },
    },
    {
      field: "debitAmount",
      headerName: "Total Debit(GBP)",
      width: 200,
      valueGetter: ({ row }: { row: any }) => {
        if (row.noteType === NoteType.Debit) {
          return formatAmount(row.totalAmount);
        } else if (row.invoiceType === InvoiceType.PurchaseReturn) {
          return formatAmount(row.totalNetAmount);
        } else {
          return "";
        }
      },
    },
    {
      field: "creditAmount",
      headerName: "Total Credit(GBP)",
      width: 200,
      valueGetter: ({ row }: { row: any }) => {
        if (row.noteType === NoteType.Credit) {
          return formatAmount(row.totalAmount);
        } else if (row.invoiceType === InvoiceType.Purchase) {
          return formatAmount(row.totalNetAmount);
        } else {
          return "";
        }
      },
    },
    {
      field: "balance",
      headerName: "Balance(GBP)",
      width: 200,
      valueGetter: ({ row }: { row: any }) => {
        return formatAmount(row.balance);
      },
    },
  ];
  return (
    <Box width="80%" ml={4}>
      <Box mb={2}>
        <PageHeader title="Supplier Account Statement" onAdd={handleAddClick} customLeftMargin={50} />
      </Box>
      {isDeleteDialogOpen && (
        <DialogInput
          open={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          // onConfirm={handleDelete}
          title="Delete Debit Note?"
          content="Are you sure you want to delete this credit note? This action cannot be undone."
        />
      )}
      <Grid container>
        <Grid item sm={12} md={12}>
          <Box width="100%">
            {data && (
              <Box display="flex" justifyContent="space-between" mr={10} alignItems="center">
                <Typography variant="h6" fontWeight="bold" mt={5}>
                  Account Statement for {toSentenceCase(data.supplierName)}
                </Typography>
                <Box textAlign="right">
                  <Typography>
                    <LabelText>start Date: </LabelText>
                    {formatDate(data.startDate)}
                  </Typography>
                  <Typography>
                    <LabelText>End Date:</LabelText>
                    {formatDate(data.endDate)}
                  </Typography>
                  <Typography>
                    <LabelText>Opening Balance:</LabelText> {formatAmount(data.openingBalance)}
                  </Typography>
                </Box>
              </Box>
            )}

            <PageManagementGrid
              rows={accountStatementDocs}
              columnFields={columns}
              onDelete={() => void 0}
              //   onUpdate={handleUpdate}
              paginationModel={paginationModel}
              // customActions={actions}
              isFetching={false}
              totalItems={totalItems}
              setPagination={setPaginationModel}
              sortModel={[{ field: "supplierName", sort: "asc" }]}
              noActionButton
            />
          </Box>
        </Grid>
      </Grid>
      {isAccountStatementCreationDialogOpen && (
        <AccountStatementCreationForm
          openDialog={isAccountStatementCreationDialogOpen}
          handleCloseDialog={() => setIsAccountStatementCreationDialogOpen(false)}
          clearErrors={clearErrors}
          setValue={setValue}
          control={control}
          register={register}
          errors={errors}
          reset={reset}
          watch={watch}
          handleClearButtonClick={handleClearFormButtonClick}
          getAccountStatement={getAccountStatement}
          onSubmission={handleSubmit(onSubmit)}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      )}
    </Box>
  );
}

export default SupplierAccountStatement;
